import React, { Component} from 'react'
import BannerComponent from './CommonPageComponets/BannerComponent'
// import CategoriesHomeComponet from './HomePageComponets/CategoriesHomeComponet'
// import BrowseByCityComponent from './HomePageComponets/BrowseByCityComponent'
// import RecentListing from './HomePageComponets/RecentListing'
// import DownloadOurApp from './HomePageComponets/DownloadOurApp'
import './HomePageComponets/Home.css'
// import FooterComponent from './CommonPageComponets/FooterComponent'
import HomePageBottomEn from './CommonPageComponets/HomePageBottomEn'
import HomePageBottomAr from './CommonPageComponets/HomePageBottomAr'
import i18next from "i18next";
import { Helmet } from "react-helmet";
// const RecentListing = lazy(() => import('./HomePageComponets/RecentListing'));
// const BrowseByCityComponent = lazy(() => import('./HomePageComponets/BrowseByCityComponent'));
// const CategoriesHomeComponet = lazy(() => import('./HomePageComponets/CategoriesHomeComponet'));
// const DownloadOurApp = lazy(() => import('./HomePageComponets/DownloadOurApp'));
import CategoriesHomeComponet from './HomePageComponets/CategoriesHomeComponet';
import DownloadOurApp from './HomePageComponets/DownloadOurApp';

// lazy(() => import('./HomePageComponets/Home.css'));

class Home extends Component {

    getContent = (lang) => {
        if (lang === 'ar') {
            return {
                title: "البحث عن العقارات - أكبر بوابة عقارات للإيجار والبيع في الإمارات العربية المتحدة",
                description: 'ابحث عن عقارات للإيجار والبيع في دبي والشارقة وعجمان وأبو ظبي وجميع الإمارات العربية المتحدة | استكشف أكثر من 1000 شقة ، فيلا ، تاون هاوس ، مكتب ، أرض ، معسكر عمل.',
                keywords: "ابحث عن العقارات، ابحث عن العقارات في عجمان، ابحث عن العقارات في الإمارات العربية المتحدة، ابحث عن العقارات في دبي، ابحث عن العقارات في الشارقة، موقع العقارات في الإمارات العربية المتحدة، بوابة العقارات في الإمارات العربية المتحدة، ابحث عن العقارات، العقارات، الشقق، الشقق، الفيلا، إيجار العقارات، بيع العقارات ، مشاركة الممتلكات، والإقامة، والشقق الرخيصة، والشقق الميزانية، شقة الميزانية",
                language: "https://www.findproperties.ae/ar/"
            };
        } else {
            return {
                title: "Find Properties - UAE's largest property portal for rent and sale",
                description: 'Find properties for rent and sale in Dubai, Sharjah, Ajman, Abu Dhabi, and all UAE | Explore 1000+ Flats, villas, townhouse, office, land, labor camp.',
                keywords: "Find Properties, Find properties in Ajman, Find properties in UAE, Find properties in Dubai, Find properties in Sharjah, Property website in UAE, Property portal in UAE, findproperties, property, properties, flats, apartments, villa, rent property, sale property, share property, accomodation, cheap flats, budget flats, budget apartment",
                language: "https://www.findproperties.ae/"
            };
        }
    };
    render() {
        // const lang = localStorage.getItem("lang") || "en";
        const { lang } = this.props;
        console.log("Current lang:", lang); // Add this line to log the lang prop
        const content = this.getContent(lang);
        const language = lang == "ar" ? "/ar" : ""
        return (
            <div dir={lang == "en" ? 'ltr' : 'rtl'}>
                <Helmet>
                    <title>{content.title} </title>
                    <meta name="description" content={content.description} />
                    <meta itemprop="name" content={content.title} />
                    <meta itemprop="description" content={content.description}></meta>
                    <meta itemprop="image" content="../../../frontendUI/img/logo.png" />

                    <meta name="keywords" content={content.keywords} />
                    {/* <!-- Facebook Meta Tags --> */}
                    <meta itemProp="image" content="https://findproperties.ae/frontendUI/img/logo.png" />
                    <meta property="og:url" content="https://findproperties.ae" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={content.title} />
                    <meta property="og:description" content={content.description} />
                    <meta property="og:image" content="https://findproperties.ae/frontendUI/img/logo.png" />
                    <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={content.title} />
                    <meta name="twitter:description" content={content.description} />
                    {lang == "en" ? <link rel="canonical" href={"https://findproperties.ae"} /> : <link rel="canonical" href={"https://findproperties.ae/ar/"} />}
                    <link rel="alternate" hreflang="en" href={"https://findproperties.ae"} />
                    <link rel="alternate" hreflang="ar" href={"https://findproperties.ae/ar/"} />

                    <meta name="twitter:image" content="https://findproperties.ae/frontendUI/img/logo.png" />
                    <meta name="robots" content="index, follow" />

                </Helmet>

                <BannerComponent />

                {/* <Suspense fallback=
                    {<div class='lds-ripple center'>
                        <div></div>
                        <div></div>
                    </div>
                    }> */}
                    {/* <RecentListing /> */}
                    {/* <BrowseByCityComponent /> */}
                    <CategoriesHomeComponet />
                    <DownloadOurApp />
                {/* </Suspense> */}
                {lang == "en" ? <HomePageBottomEn /> : <HomePageBottomAr />}
 
            </div>
        )
    }
}
export default Home