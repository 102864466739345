import React, { Component } from 'react'
import '../PageStyles/agentstyle.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { baseurl } from '../../Components/BaseUrl'
import { baseurlImg } from '../../Components/BaseUrl'
import ReactPaginate from 'react-paginate'
import i18next from 'i18next'
import cookie from 'js-cookie'
import { Helmet } from "react-helmet";

const lang = localStorage.getItem("lang") || 'en'
var language = lang == "ar" ? "/ar" : ""

export class SingleAgency extends Component {
    constructor(props){
        super(props)
        this.state = {
            agency:{},
            agents: [],
            properties:  [],
            agentsArr: [],
            propertiesArr: [],
            offset: 0,
            perPage: 10,
            currentPage: 1,
            offsetAg: 0,
            perPageAg: 4,
            currentPageAg: 1,
            agencyName: "",
            agencyProfile: "",
            agentCount: "",
            agencySummary: ""

        }
        this.handlePageClick = this
        .handlePageClick
        .bind(this);
    }
    fetchAgency =() =>{
        this.setState({isLoading:true})
         let id = this.props.match.params.id;
         axios.get(baseurl+"show-agency/"+id)
         .then(response =>{
         
         this.setState({
             agency:response.data.agency, 
             agencySummary: lang=='en'? response.data.agency_name:response.data.agency_name,
            //  agentCount: response.data.agency[0]['agents_count'], 
            //  agencyProfile: response.data.agency[0].profile, 
             agencyName: lang=='en'?response.data.agency_name['name_en']:response.data.agency_name['name_ar'],
             properties: response.data.properties,  agents: response.data.agents
            })
         //console.log(response.data)
         const agentsArr = response.data.agents;
         const slice = agentsArr.slice(this.state.offsetAg, this.state.offsetAg + this.state.perPageAg)
         const postAgentsData = slice.map(agents => <React.Fragment>
                   <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="grid_agents">
                           <div className="elio_mx_list theme-bg-2">{agents.agentproperty_count} Listings</div>
                               <div className="grid_agents-wrap">
                                   <div className="fr-grid-thumb">
                                   <Link to={language+"/agents/"+agents.id} className="vew_agency_btn">
                                       {/* <span className="verified"><img src="./images/verified.svg" className="verify mx-auto" alt /></span> */}
                                    {this.state.agents.profile == ""? 
                                       <img src={baseurlImg + "/uploads/profiles/no_avatar.png"}  alt />:
                                       <img src={baseurlImg + '/uploads/profiles/' + agents.profile}  className="img-fluid mx-auto" alt />
                                    }
                                   </Link>
                                   </div>
                                   <div className="fr-grid-deatil">
                                      <h5 className="fr-can-name"><a href={language+"/agents/"+agents.id}>{lang=="en"? agents.name_en:agents.name_ar}</a></h5>
                                   <span>{}</span>

                                   </div>
                                   <div className="fr-infos-deatil">	
                                   <a href={'mailto:' +agents.email} className="btn agent-btn theme-bg"><i className="fa fa-envelope mr-2" />&nbsp;{i18next.t("email")}</a>
                                   <a href={'tel:' +agents.mobile} className="btn agent-btn theme-black"><i className="fa fa-phone" /></a>
                                   </div>
                               </div>
                               </div>
                           </div>
         </React.Fragment>)
             this.setState({
              pageCountAg: Math.ceil(agentsArr.length / this.state.perPageAg), postAgentsData
         })


         const propertiesArr = response.data.properties;
         const sliceProp = propertiesArr.slice(this.state.offset, this.state.offset + this.state.perPage)
         const postPropertyData = sliceProp.map(property => <React.Fragment>
                               <div className="col-lg-6 col-md-6 col-sm-12">
                                   <div className="property-listing property-2">
                                   <div className="listing-img-wrapper">
                                       <div className="_exlio_125">{property.purpose==1?i18next.t("forrent"): i18next.t("forsale")} | {lang=="en"?property.typeName_en:property.typeName_ar}</div>
                                       <div className="list-img-slide">
                                       <div className="click">
                                       <div>   <Link to={"/single-property/"+property.id} >
                                               <img src={baseurlImg + "/uploads/properties/" + property.image} className="img-fluid mx-auto" alt /></Link>
                                               </div>
                                            </div>
                                       </div>
                                   </div>

                                   <div className="listing-detail-wrapper">
                                   <h5 className="listing-title"><a href={"/single-property/"+property.id}> {lang == "en"
                                                ? property.title_en
                                                : property.title_ar}
                                                </a></h5>
                                       {/* <div className="listing-short-detail-wrap">
                                        <div className="listing-body">
                                            <div class="flex"><span class="right">
                                                {lang == "en"
                                                ? property.typeName_en
                                                : property.typeName_en}</span></div>
                                           <div className="_card_flex_01">

                                         </div>
                                       </div>
                                       </div> */}
                                   </div>
                                   <div className="price-features-wrapper">
                                       <div className="list-fx-features">
                                       {property.beds > -1? 
                                       <div className="listing-card-info-icon">
                                           <div className="inc-fleat-icon"><i class="flaticon-bedroom"></i></div>{property.beds == 0 ? i18next.t("studio") : property.beds}
                                       </div>
                                       :null}
                                       {property.baths>0?
                                       <div className="listing-card-info-icon">
                                           <div className="inc-fleat-icon"><i class="flaticon-bathroom"></i></div>{property.baths}
                                       </div>
                                       :null}
                                       {property.area>0?
                                       <div className="listing-card-info-icon">
                                           <div className="inc-fleat-icon"><i class="flaticon-ruler"></i></div>{property.area}
                                       </div>
                                       :null}
                                       </div>
                                   </div>
                                   <div className="listing-detail-footer">
                                       <div className="footer-first">
                                       <h6 className="listing-card-info-price mb-0 p-0">{property.price.toLocaleString()}</h6>
                                       </div>
                                       <div className="footer-flex">
                                       <Link to={"/single-property/"+property.id} className="prt-view">View Detail</Link>
                                       </div>
                                   </div>
                                   </div>
                              </div>
         </React.Fragment>)
             this.setState({
              pageCount: Math.ceil(propertiesArr.length / this.state.perPage), postPropertyData
         })
         //this.setState({categories:response.data.categories})
       })
         .catch()

    }
    componentDidMount(){
        this.fetchAgency();

     }
     handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * this.state.perPage;

      this.setState({
          currentPage: selectedPage,
          offset: offset
      }, () => {
          this.fetchAgency()

      });
  };
  handlePageClickAg = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageAg;

    this.setState({
        currentPageAg: selectedPage,
        offsetAg: offset
    }, () => {
        this.fetchAgency()

    });
};
    render() {


        //console.log("Agents",  this.state.agencyName)
        return (
                <div>
           <Helmet>
            <title>{this.state.agencyName}</title>
            <meta name="description" content={lang=="en"?this.state.agencyName+ " Browse our latest real estate listings in UAE": this.state.agencyName+ " تصفح أحدث قوائم العقارات لدينا في الإمارات العربية المتحدة"}></meta>
            <meta name="robots" content="index, follow" />
            </Helmet>
                <div className="page-title" style={{background: '#f4f4f4 url(./images/slider-1.jpg)'}} data-overlay={5}>
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                        <div className="breadcrumbs-wrap">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">{i18next.t("agencydetail")}</li>
                            </ol>
                            <h2 className="breadcrumb-title">{this.state.agencyName}</h2>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <section className="gray min">
                    <div className="container">
                    <div className="row">
                        {/* property main detail */}
                        <div className="col-lg-8 col-md-12 col-sm-12">
                        {/* Single Block Wrap */}
                        {this.state.agencySummary.agency_summary_en || this.state.agencySummary.agency_summary_ar?
                        <div className="property_block_wrap">
                            <div className="property_block_wrap_header">
                            <h4 className="property_block_title">{i18next.t("agencyinfo")}</h4>
                            </div>
                            <div className="block-body">
                               <p>
                                   {this.state.agencySummary.agency_summary_en && lang=="en"? this.state.agencySummary.agency_summary_en: null}
                                   {this.state.agencySummary.agency_summary_ar && lang=="ar"? this.state.agencySummary.agency_summary_ar:null}
                               </p>
                            <ul className="social-icons">
                                {/* <li><a className="facebook" href="#"><i className="lni-facebook" /></a></li>
                                <li><a className="twitter" href="#"><i className="lni-twitter" /></a></li>
                                <li><a className="linkedin" href="#"><i className="lni-instagram" /></a></li>
                                <li><a className="linkedin" href="#"><i className="lni-linkedin" /></a></li> */}
                            </ul>
                            </div>
                        </div>:null
                        }
                        {/* Single Block Wrap */}
                        <div className="property_block_wraps">
                            <div className="property_block_wrap_header">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                                <li class="nav-item" role="presentation">
                                    {/* <a className="nav-link" id="pills-agent-tab" data-toggle="pill" href="#pills-agent" role="tab" aria-controls="pills-agent" aria-selected="false">Agent</a> */}
                                    <button class="nav-link active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-agent" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">{i18next.t("agents")}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    {/* <a className="nav-link active" id="pills-property-tab" data-toggle="pill" href="#pills-property" role="tab" aria-controls="pills-property" aria-selected="true">Properties</a> */}
                                    <button class="nav-link " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-property" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{i18next.t("properties")}</button>
                                </li>
                            </ul>
                            </div>
                            <div className="block-bodys">
                            <div className="sidetab-content">
                                <div className="tab-content" id="pills-tabContent">
                                {/* Book Now Tab */}
                                <div className="tab-pane fade" id="pills-property" role="tabpanel" aria-labelledby="pills-property-tab">
                                    <div className="row justify-content-center">
                                    {/* Single Property */}
                                    {this.state.postPropertyData}
                                    <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}/>
                                    {/* End Single Property */}
                                    </div>	
                                </div>
                                {/* Appointment Now Tab */}
                                <div className="tab-pane fade show active" id="pills-agent" role="tabpanel" aria-labelledby="pills-agent-tab">
                                    <div className="row justify-content-center">
                                    {/* Single Agent */}
                                    {this.state.postAgentsData}
                                    <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCountAg}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePageClickAg}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}/>
                                     </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* property Sidebar */}
                        <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="property-sidebar">
                            <div className="agency_gridio_wrap">
                            <div className="agency_gridio_header" style={{background: '#fff url(./images/bg2.png)no-repeat'}}>
                                <span className="agents_count">{} {i18next.t("agents")}</span>
                            </div>
                            <div className="agency_gridio_caption">
                                <div className="agency_gridio_thumb">
                                <a href="#"> 
                                    {this.state.agencySummary.logo == undefined? 
                                    <img src={baseurlImg + "/uploads/profiles/no_avatar.png"}  alt />:
                                    <img src={baseurlImg + '/uploads/profiles/' + this.state.agencySummary.logo} className="img-fluid" alt />}
                                </a>	
                                </div>
                                <div className="agency_gridio_txt">
                                <h4><Link to="#">{this.state.agencyName}</Link></h4>
                                <span className="agt_gridio_ocat">{''}</span>
                                {/* <a href="#" data-toggle="modal" data-target="#autho-message" className="vew_agency_btn">{i18next.t("sendmessage")}</a>	 */}
                                </div>
                                <div className="row"></div>
                            </div>
                            </div>   
                        </div>
                        </div>
                    </div>					
                    </div>	
                </section>
                </div>
        )
    }
}

export default SingleAgency
