import React, { useState } from 'react';
import xml2js from 'xml2js';

const XmlContentConverter = () => {
  const [inputXml, setInputXml] = useState('');
  const [outputXml, setOutputXml] = useState('');

  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      setInputXml(event.target.result);
    };

    reader.readAsText(file);
  };

  const convertXml = () => {
    const parser = new xml2js.Parser();
    parser.parseString(inputXml, (parseErr, result) => {
      if (parseErr) {
        console.error('Error parsing XML:', parseErr);
        return;
      }

      // Modify URLs with Arabic content in the <loc> element
      if (result.urlset && result.urlset.url) {
        const modifiedUrls = result.urlset.url.map((url, index) => {
          if (index % 2 !== 0 && result.urlset.url[index - 1]) {
            const englishUrl = result.urlset.url[index - 1].loc[0].replace('https://findproperties.ae/', 'https://findproperties.ae/ar/');
            return {
              ...url,
              loc: [englishUrl],
            };
          }
          return url;
        });

        result.urlset.url = modifiedUrls;
      }

      // Convert the modified XML object back to XML
      const builder = new xml2js.Builder({ renderOpts: { pretty: true, indent: '  ', newline: '\n' } });
      const modifiedXml = builder.buildObject(result);

      setOutputXml(modifiedXml);
    });
  };

  const handleDownload = () => {
    const blob = new Blob([outputXml], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'modified_sitemap.xml';
    a.click();
  };

  return (
    <div>
      <h2>XML Content Converter</h2>
      <div>
        <input type="file" accept=".xml" onChange={handleUpload} />
      </div>
      <div>
        <button onClick={convertXml}>Convert</button>
        <button onClick={handleDownload}>Download</button>
      </div>
      <div>
        <h3>Input XML:</h3>
        <pre>{inputXml}</pre>
        <h3>Output XML:</h3>
        <pre>{outputXml}</pre>
      </div>
    </div>
  );
};

export default XmlContentConverter;
