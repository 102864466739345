import axios from "axios";
import { baseurl } from "./Components/BaseUrl";
import { store } from "./store";

var TOKEN = '';
store.subscribe(listener);
function select(state) {
    return state.auth.token
}
function listener() {
    let token = select(store.getState())
   
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    TOKEN = `Bearer ${token}`;
}
const fetchClient = () => {
    const defaultOptions = {
         baseURL: baseurl,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
    const instance = axios.create(defaultOptions);
    instance.interceptors.request.use((config) => {
        const token = TOKEN;
        config.headers['Authorization'] = token;
        return config;
    });
    return instance;
}
export default fetchClient();