import React, { Component } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { baseurl } from '../Components/BaseUrl';
import Swal from 'sweetalert2'

class ContactUs extends Component {
    constructor (){
        super();
        this.state ={

        }
    }
    
    sendEmail =() =>{
        const data= {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            company: this.state.company,
            subject: this.state.subject,
            message: this.state.message
        }
        console.log("email data", data)

        axios.post(baseurl+"contactEmail", data).then(response=>{
            console.log(response.data)

            Swal.fire({
                title: 'Message Sent',
                text: 'We will contact you soon! Thanks',
                icon: 'success',
                confirmButtonText: '<a href="/">Go to Home</a>',
                // footer: ''

                })
        }) 
        
    }
    
    nameOnChange =(e)=> {
      this.setState({
          name: e.target.value
      })
    }
    emailOnChange =(e)=> {
        this.setState({
            email: e.target.value
        })
    }
    phoneOnChange =(e)=> {
        this.setState({
            phone: e.target.value
        }) 
    }
    companyOnChange =(e)=> {
        this.setState({
            company: e.target.value
        })   
    }
    subjectOnChange =(e)=> {
        this.setState({
            subject: e.target.value
        }) 
    }
    messageOnChange =(e)=> {
        this.setState({
            message: e.target.value
        })
    }
    
    componentDidMount (){

    }
    render() {
        return (
                <div>
                    <Helmet>
                        <meta name="robots" content="noindex" />
                    </Helmet>
                {/* ============================ Page Title Start================================== */}
                <div className="page-title" style={{background: '#f4f4f4 url(./images/contact_findproperties.jpg)'}} data-overlay={5}>
                    <div className="ht-80" />
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                        <div className="_page_tetio">
                            <div className="pledtio_wrap"><span>Get In Touch</span></div>
                            <h2 className="text-light mb-0">Get Help &amp; Friendly Support</h2>
                            <p>Looking for help or any support? We's available 24 hour a day.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="ht-120" />
                </div>
                {/* ============================ Page Title End ================================== */}
                {/* ============================ Agency List Start ================================== */}
                <section className="pt-0">
                    <div className="container">
                    <div className="row align-items-center pretio_top">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="contact-box">
                            <i className="ti-shopping-cart theme-cl" />
                            <h4>Contact Sales</h4>
                            <p>sales@findproperties.ae</p>
                            <span>+971-50-3128-233</span>
                        </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="contact-box">
                            <i className="ti-user theme-cl" />
                            <h4>Information</h4>
                            <p>info@findproperties.ae</p>
                            {/* <span>+971-50-3128-233</span> */}
                        </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="contact-box">
                            <i className="ti-comment-alt theme-cl" />
                            <h4>Start Live Chat</h4>
                            <span>Message</span>
                            <span className="live-chat">Live Chat</span>
                        </div>
                        </div> */}
                    </div>
                    {/* row Start */}
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                        <div className="property_block_wrap">
                            <div className="property_block_wrap_header">
                            <h4 className="property_block_title">Fill The Form</h4>
                            </div>
                            <div className="block-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input onChange={this.nameOnChange} type="text" className="form-control simple" required/>
                                </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input onChange={this.emailOnChange} type="email" className="form-control simple" required/>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label>Company Name</label>
                                    <input onChange={this.companyOnChange} type="text" className="form-control simple" required/>
                                </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input onChange={this.phoneOnChange} type="tel" className="form-control simple" required/>
                                </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Subject</label>
                                <input onChange={this.subjectOnChange} type="text" className="form-control simple" />
                            </div>
                            <div className="form-group">
                                <label>Message</label>
                                <textarea onChange={this.messageOnChange} className="form-control simple" defaultValue={""} />
                            </div>
                            <div className="form-group">
                                <button onClick={this.sendEmail} className="btn btn-theme" type="submit">Submit Request</button>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-7">
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14417.0297258281!2d55.4457768!3d25.3961921!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe997ba9a0775dee!2sFind%20Properties!5e0!3m2!1sen!2sae!4v1638948769115!5m2!1sen!2sae" width="100%" height={412} style={{border: 0}} allowFullScreen loading="lazy" />
                        </div> */}
                    </div>
                    {/* /row */}		
                    </div>	
                </section>
                </div>

        )
    }
}

export default ContactUs
