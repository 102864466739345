import React, { Component } from 'react'
import AllBlogs from './AllBlogs'

class TouristDestination extends Component {
    render() {
        return (
            <div>
               <AllBlogs categoryId="4"></AllBlogs>
            </div>
        )
    }
}

export default TouristDestination
