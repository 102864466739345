import React, { Component } from 'react'

export default class ResponsiveDisplayAd extends Component {
    constructor(props){
        super(props)

        // console.log("my Ad Class", this.props.classApply)

    }
    componentDidMount() {
        // (window.adsbygoogle = window.adsbygoogle || []).push({})

       }
   
      render () {
       return(
           <>         
           {/* <div style={{marginTop: '20px', marginBottom: '20px'}}>
             <ins className={"adsbygoogle "+ this.props.classApply}
                style={{display:'block', textAlign:'center'}}
                data-ad-client="ca-pub-6069660640889179"
                data-ad-slot="5537763567"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
           </div> */}
           </>

           
           )
       }
}
