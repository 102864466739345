import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import axios from "axios";
import { baseurl } from "../../Components/BaseUrl";
import User from "./User";
import { connect } from "react-redux";
import cookie from "js-cookie";
import { baseurlImg } from "../../Components/BaseUrl";
import $ from "jquery";
import ResponsiveDisplayAd from "../../Components/Adsense/ResponsiveDisplayAd";
import { store } from "../../store";


import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import { Dropdown } from 'react-bootstrap';



class Navigation extends Component {
  constructor(props)
  {
      super(props);
      this.state= {
          email:'',
          password: '',
          errors:{},
          isLoading:false,
          check_status:false,
          input: {},
          validationerrors: {},
          showModal: false,

      }
  }
  toggleLanguage = () => {
    const { isArabic } = this.props;
    const currentPath = window.location.pathname;

    if (isArabic) {
      // Remove "/ar" segment for English version
      const newPath = currentPath.replace('/ar', '');
      window.location.href = newPath || '/'; // Redirect to root if newPath is empty
    } else if (currentPath.startsWith('/ar')) {
      // Remove "/ar" segment for English version
      const newPath = currentPath.replace('/ar', '');
      window.location.href = newPath || '/'; // Redirect to root if newPath is empty
    } else {
      // Add "/ar" segment for Arabic version
      const newPath = `/ar${currentPath}`;
      window.location.href = newPath;
    }
  };
  changeToArabic(e) {
    console.log("url", decodeURI(window.location.href));
    var urlLoc = window.location.href;

    //find the index of nth occurernce of string in string
    var occurenceIndex = urlLoc.split("/", 3).join("/").length;
    let afterLanguae;

    if (urlLoc.split("/")[4] === undefined) {
      afterLanguae = "";
    } else {
      afterLanguae = urlLoc.substring(occurenceIndex, urlLoc.length);
    }
    var urlArabic = urlLoc.substring(0, occurenceIndex) + "/ar" + afterLanguae;
    var urlEnglish = urlLoc.split("/ar")[0] + urlLoc.split("ar")[1];

    e.preventDefault();
    const oldlang = localStorage.getItem("lang");
    if (oldlang != "ar") {
      localStorage.setItem("lang", "ar");
      if (urlLoc.includes("for-rent") ) {
        window.location.href = "/ar/للايجار/عقارات/الامارات";
      }else if(urlLoc.includes("for-sale")) {
        window.location.href = "/ar/للبيع/عقارات/الامارات";
      }
      else if(urlLoc.includes("for-sharing")){
        window.location.href = "/ar/سكن-مشترك/عقارات/الامارات";
      }
           
       else if (urlLoc.includes("blogs")) {
        window.location.href = "/ar/blogs";
      } 
      else if (urlLoc.includes("favoirites")) {
        window.location.href = "/ar/favoirites";
      } 
      else if (urlLoc.includes("my-ads")) {
        window.location.href = "/ar/my-ads";
      } 
      else if (urlLoc.includes("profileSetting")) {
        window.location.href = "/ar/profileSetting";
      } 
      else {
        window.location.href = urlArabic;
      }
    } else {
      window.location.reload();
      // window.location.href = urlArabic;
    }
  }
  handleRememberMe =(e)=>{
    this.setState({check_status:!this.state.check_status})
   } 
  changeToEnglish(e) {
    console.log("url", decodeURI(window.location.href));
    var urlLoc = window.location.href;

    //find the index of nth occurernce of string in string
    var occurenceIndex = urlLoc.split("/", 3).join("/").length;
    let afterLanguae;

    if (urlLoc.split("/")[4] === undefined) {
      afterLanguae = "";
    } else {
      afterLanguae = urlLoc.substring(occurenceIndex, urlLoc.length);
    }
    var urlArabic = urlLoc.substring(0, occurenceIndex) + "/ar" + afterLanguae;
    var urlEnglish = urlLoc.split("/ar")[0] + urlLoc.split("ar")[1];
    e.preventDefault();
    const oldlang = localStorage.getItem("lang");
    if (oldlang != "en") {
      localStorage.setItem("lang", "en");
      var urlLoc = decodeURI(window.location.href);
      var urlSplit = urlLoc.split("/ar");
      //   window.location.reload();
      if (urlLoc.includes("للايجار")) {
        window.location.href = "/for-rent/properties/uae";
     } 
     else if(urlLoc.includes("للبيع") ) {
       window.location.href = "/for-sale/properties/uae";

     }
     else if(urlLoc.includes("سكن-مشترك")){
       window.location.href = "/for-sharing/properties/uae";
     }
       else if (urlLoc.includes("/ar/blogs")) {
        window.location.href = "/blogs/";
      } 
      else if (urlLoc.includes("/ar/favoirites")) {
        window.location.href = "/favoirites/";
      } 
      else if (urlLoc.includes("/ar/my-ads")) {
        window.location.href = "/my-ads/";
      } 
      else if (urlLoc.includes("/ar/profileSetting")) {
        window.location.href = "/profileSetting/";
      } 
      
      else {
        window.location.href = urlEnglish;
      }
    } else {
      window.location.reload();
      // window.location.href = urlSplit[0]+urlSplit[1];
    }
  }
  handleLogout = () => {
    this.props.logout();
  };
  componentDidMount() {
    $("#aside-trigger").on("click", function () {
      // alert("hellow")
      $("#humbergerMenu").toggleClass("open");
      $("body").toggleClass("aside-open");
    });
    $(".main-aside .menu-item-has-children > a").on("click", function (e) {
      e.preventDefault();

      alert(this.id);

      var submenu = $(this).next(".submenu");

      if ($(this).parent().hasClass("active")) {
        submenu.slideUp(200);
        $(this).parent().siblings().find(".submenu").slideUp(200);
        $(this).parent().removeClass("active");
      } else {
        $(this)
          .closest("ul")
          .find(".menu-item-has-children")
          .find(".submenu")
          .slideUp(200);
        $(this)
          .closest("ul")
          .find(".menu-item-has-children")
          .removeClass("active");
        $(this).parent().addClass("active");
        submenu.slideDown(200);
      }
    });
    //   this.fetchAllBlogsInMenu();
  }
  //  fetchAllBlogsInMenu =()=>{
  //     axios.get(baseurl + "blogscategories")
  //     .then(response =>{
  //         this.setState({blogCategories: response.data.blogcategories})
  //         console.log (response.data.blogcategories)
  //      })
  // }
  // blogCatHandleClick =(id, e)=>{
  //     this.props.onCatagory(id)
  //  }
  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  handleHideModal = () => {
    this.setState({ showModal: false });
  }
  render() {
    const userdata = store.getState((state) => state);
    let user = userdata.auth.user;
    const token = userdata.auth.token;
    const lang = localStorage.getItem("lang") || "en";
    const username = user.name;
    const username_ar = user.name_ar;
    const profile = user.profile;
    const email = user.email;
    var language = lang == "ar" ? "/ar" : "";

    return (
      <div>
        <nav className="navbar">
          <div className="container">
            {/* Logo */}
            <div className="header-controls">
              <div id="aside-trigger" className="aside-toggler aside-trigger">
                <span />
                <span />
                <span />
              </div>
            </div>
            {/* Menu */}
            <ul className="nav-menu nav-menu-social align-to-right d-none d-lg-block d-md-block">

              {this.props.loggedIn ? (
                <li>
                      <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                      <div className="btn-group account-drop p-0">
                              <button
                                type="button"
                                className="btn btn-order-by-filt"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <img src={baseurlImg + "/uploads/profiles/" + profile} className="avater-img" alt
                                />
                              </button>   
                              </div>    
                      </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={language +"/my-ads"}><i className="fa fa-comment" />{" "}{i18next.t("myads")}</Dropdown.Item>
                    <Dropdown.Item href={language +"/profileSetting"}> <i className="fa fa-cog" /> {i18next.t("setting")}</Dropdown.Item>
                    <Dropdown.Item onClick={this.handleLogout}><i className="fa fa-sign-out" />{" "}{i18next.t("logout")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
       
                </li>
              ) : (
                <li>
                  <a
                    // id="loginForModal"
                    // data-bs-toggle="modal"
                    href="#"
                    // role="button"
                    onClick={this.handleShowModal}
                    className="alio_green"
                  >
                    <i className="fa fa-user"></i>
                    <span className="dn-lg">{i18next.t("login")}</span>
                  </a>
                </li>
              )}
            </ul>
            <ul className="navbar-nav">
              <li className="menu-item menu-item-has-children">
                <Link to={language + "/"}>{i18next.t("home")}</Link>
              </li>
              <li className="menu-item menu-item-has-children">
                {/* <L href="/for-rent/properties/uae" >{i18next.t("properties")}</a> */}
                <Link
                  to={{
                    pathname:
                      lang == "en"
                        ? "/for-rent/properties/uae"
                        : "/ar/for-rent/properties/uae",
                    state: {
                      passLocation: [],
                      passsubLocationSend: [],
                      passPurpose: 1,
                      passType: lang == "en" ? "properties" : "عقارات",
                      locForUrl: lang == "en" ? "uae" : "الامارات",
                    },
                  }}
                >
                  {i18next.t("properties")}
                </Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to={language + "/agency/"}>{i18next.t("agencies")}</Link>
              </li>
              <li className="menu-item menu-item-has-children"></li>
              <li className="menu-item menu-item-has-children">
                <Link to={language+"/blogs"} id="blogs-menu">
                  {i18next.t("blogs")}
                </Link>
                <ul className="submenu">
                  <li className="menu-item">
                    <Link to={language + "/area-guide/"}>
                      {i18next.t("areaguide")}
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to={language + "/building-guide/"}>
                      {i18next.t("buildingguide")}
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to={language + "/tips/"}>{i18next.t("tips")}</Link>
                  </li>
                  <li className="menu-item">
                    <Link to={language + "/tourist-destination/"}>
                      {i18next.t("touristdestination")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="language-selector d-xl-none d-lg-none d-xl-block" style={{ margin: "0 7px" }}>
              {lang == "en" ? (
                <button onClick={this.toggleLanguage} style={{ padding: "2px", fontSize: "1rem", color: "#707e9c" }} className="dropdown-item" href="">
                  العربية
                </button>
              ) : (
                <button
                  onClick={this.toggleLanguage}
                  style={{ padding: "2px", fontSize: "1rem", color: "#707e9c" }}
                  className="dropdown-item" href="">
                  English
                </button>
              )}
            </div>
            <div className="submitListing d-non d-lg-block d-md-block">
              {token == "" || token == null ? (
                <button className="submit" onClick={this.handleShowModal} >
                  {i18next.t("submitlisting")} <i className="fas fa-plus" />
                </button>
                 ) : (
                <Link
                  to={lang == "en" ? "/add-listing" : "/ar/add-listing"}
                  className=""
                >
                  {i18next.t("submitlisting")} <i className="fas fa-plus" />{" "}
                </Link>
              )}
            </div>
            <Link to={"/" + language} className="navbar-brand">
              {" "}
              <img style={{height: '45px'}} src="/frontendUI/img/logo-Find.png" alt="Find Properties" />{" "}
            </Link>
          </div>
        </nav>
        <User show={this.state.showModal} onHide={this.handleHideModal} />
        {/* <div className="my-float d-lg-none d-sm-block d-md-none">
          {token == "" || token == null ? (
                <a
                  id="loginForModal"
                  data-bs-toggle="modal"
                  href="#login"
                  role="button"             
                >
                  Submit Listing +
                </a>
              ) : (
                <Link to={lang == "en" ? "/add-listing" : "/ar/add-listing"} >
                    <i class="fa fa-plus my-float"></i>
                </Link>
              )}
        </div> */}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
