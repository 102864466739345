import React, { Component } from 'react'
import { baseurl, baseurlImg } from "../../Components/BaseUrl";
import cookie from 'js-cookie'
import { connect } from 'react-redux';
import '../../pages/Admin/Settings/MyProfile'
import $ from 'jquery'
import i18next from "i18next"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import API from '../../API';
import { store } from '../../store';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropagateLoader from "react-spinners/PropagateLoader";

import { Modal, Button } from 'react-bootstrap';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Redirect } from 'react-router-dom'; // Import Redirect from react-router-dom


const userdata = store.getState((state) => state);
let user = userdata.auth.user;
const token = userdata.auth.loggedIn;


var forms = document.querySelectorAll('.needs-validation')

// Loop over them and prevent submission
Array.prototype.slice.call(forms)
    .forEach(function (form) {
        form.addEventListener('submit', function (event) {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
            }

            form.classList.add('was-validated')
        }, false)
    })

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            password: '',
            loginUser: '',
            loginPass: '',
            userType: 0,
            phone: '',
            displayLoginError: false,
            registerLoading: false,
            loginLoading: false,
            input: {},
            validationerrors: {},

            hasError: false,
            errorMessage: "",
            hasloginError: false,
            errorloginMessage: "",
            redirectToHome: false
        }
    }


    handleInput = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value })
    }

    fullNameChangeHandle = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        let input = this.state.input;
        input[e.target.name] = e.target.value;


        this.setState({ fullName: e.target.value, input: input })
    }
    emailChangeHandle = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        let input = this.state.input;
        input[e.target.name] = e.target.value;

        this.setState({ email: e.target.value, input: input })
    }
    passwordChangeHandle = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        let input = this.state.input;
        input[e.target.name] = e.target.value;

        this.setState({ password: e.target.value, input: input })
    }
    phoneChangeHandle = (e) => {

        let input = this.state.input;
        input["phone"] = e;

        this.setState({ phone: e, input: input })
    }

    sendSMS = (e) => {
        const url = "https://elitbuzz-me.com/sms/smsapi?api_key=C200343061a1e16b4924a3.21883164&type=text&contacts=00971547963078&senderid=MFRE&msg=New%20User%20is%20registered%20on%20Findproperties,%20Website"

        axios.post(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        ).then((response) => {
            console.log("message send", response.data)
        });
    }

    validatelogin() {
        let input = this.state.input;
        let validationerrors = {};
        let isValid = true;



        if (!input["loginUser"]) {
            isValid = false;
            validationerrors["loginUser"] = "Please enter email.";
        }


        if (!input["loginPass"]) {
            isValid = false;
            validationerrors["loginPass"] = "Please enter your password .";
        }






        if (typeof input["loginUser"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["loginUser"])) {
                isValid = false;
                validationerrors["loginUser"] = "Please enter valid email .";
            }
        }



        this.setState({
            validationerrors: validationerrors
        });

        return isValid;
    }

    validate() {
        let input = this.state.input;
        let validationerrors = {};
        let isValid = true;

        if (!input["fullName"]) {
            isValid = false;
            validationerrors["fullName"] = "Please enter your Name";
        }

        if (!input["email"]) {
            isValid = false;
            validationerrors["email"] = "Please enter your email Address.";
        }


        if (!input["password"]) {
            isValid = false;
            validationerrors["password"] = "Please enter your password .";
        }

        if (!input["phone"]) {
            isValid = false;
            validationerrors["phone"] = "Please enter your mobile .";
        }




        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                validationerrors["email"] = "Please enter valid email address.";
            }
        }



        this.setState({
            validationerrors: validationerrors
        });

        return isValid;
    }

    handleReg = (e) => {
        e.preventDefault();
        const data = { email: this.state.email, password: this.state.password, fullName: this.state.fullName, userType: this.state.userType, mobile: this.state.phone }
        if (this.validate()) {
            this.setState({ registerLoading: true })
            API.post("register", data)
                .then(res => {
                    this.setState({ registerLoading: false })

                    if (res ?.data.success) {
                        $(".ti-close").click();
                        this.setState({ hasError: false, errorMessage: "" })

                        this.props.setCredentials(res.data)


                        this.sendSMS()



                    }
                    else {
                        this.setState({ hasError: true, errorMessage: res ?.data.msg})
                    }

                }
                ).catch(() => this.setState({ registerLoading: false }))
        }

    }
    handleLogin = async () => {
        const data = { email: this.state.loginUser, password: this.state.loginPass }
        if (this.validatelogin()) {
            this.setState({ loginLoading: true })
            await API.post("login", data)
                .then(res => {

                    this.setState({ loginLoading: false })

                    if (res ?.data.success) {
                        this.setState({ hasloginError: false, errorloginMessage: "",  redirectToHome :true  })
                        this.props.setCredentials(res.data)

                        // $(".ti-close").click();
                        // $("body").removeClass("modal-open");
                        // $("#login").removeClass("show");
                        // $("#login").css("display", "none");
                        // $("#login").attr("role","");
                        // $(".modal-backdrop").remove();
                        window.location.reload()

                    }
                    else {
                        this.setState({ hasloginError: true, errorloginMessage: res ?.data.msg})

                        if ($('.errorAuthentication').length) {
                        } else {

                            // $('<span className="errorAuthentication"><i className="fa fa-exclamation-circle" /> Wrong password. Try again or click <a style="font-weight: 100; font-size: 14px" href="/forgot-password">Forgot password</a> to reset it.</span>' ).insertAfter( "#password" );
                        }
                    }
                }).catch(function (error) {
                    // console.log(error.response.data);
                    //  this.setState({displayLoginError: true})            
                    if ($('.errorAuthentication').length) {
                    } else {
                        $('<span className="errorAuthentication"><i className="fa fa-exclamation-circle" /> Wrong password. Try again or click <a style="font-weight: 100; font-size: 14px" href="/forgot-password">Forgot password</a> to reset it.</span>').insertAfter("#password");
                    }
                });
        }

    }
    modClose = () => {
        $("body").removeClass("modal-open");
        $("#login").removeClass("show");
        $("#login").css("display", "none");
        $("#login").attr("role", "");
        $(".modal-backdrop").remove();
        // window.location.reload();
        $('.float-action').show()
    }

    loginUserName = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let input = this.state.input;
        input[e.target.name] = e.target.value;
        this.setState({
            loginUser: e.target.value, input: input
        })
    }
    loginPassword = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let input = this.state.input;
        input[e.target.name] = e.target.value;
        this.setState({
            loginPass: e.target.value, input: input
        })
    }
    handleLogout = (e) => {
        e.preventDefault();
        //   cookie.remove("token")
        //   cookie.remove("role")
        //   cookie.remove("name")
        //   cookie.remove("name_ar")
        //   cookie.remove("profile")
        //   cookie.remove("email")      
        this.props.logout();

        // this.props.history.push('/login')
    }
    userTypeOnChange = (e) => {
        this.setState({ userType: e.target.value })
    }
    phoneOnChange = (e) => {
        this.setState({ phone: e.target.value })
    }
    render() {
        const lang = localStorage.getItem("lang") || "en";

        if (this.state.redirectToHome) {
            return <Redirect to="/" />;
        }
    
        // var someTabTriggerEl = document.querySelector('#pills-forget-pass')
        // var tab = new bootstrap.Tab(someTabTriggerEl)
        // forgetPassTrig
        // tab.show()

        //    console.log(user)
        return (
            <div>
                {/* Log In Modal */}
                {!token ?
                    <Modal show={this.props.show} onHide={this.props.onHide}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                        <Modal.Body>
                                    <div className="resp_log_wrap">
                                    <div className="resp_log_thumb" style={{ background: 'url(../../../frontendUI/img/register-findproperties.jpg)no-repeat' }} />
                                    <div className="resp_log_caption">
                                        <div className="edlio_152">
                            <Tabs
                                defaultActiveKey="login"
                                id="uncontrolled-tab-example"
                                className="mb-4"
                                                                >
                                <Tab eventKey="login" title={i18next.t("login")}>
                                    <div className="login-form">
                                        <form className="row g-3 needs-validation" noValidate>
                                            {this.state.hasloginError ? <span className='alert alert-danger'> {this.state.errorloginMessage}</span> : null}
                                            <div className="form-group">
                                                <label>{i18next.t("email")}</label>
                                                <div className="input-with-icon">
                                                    <input onChange={this.loginUserName} name="loginUser" type="email" value={this.state.loginUser} placeholder="Email" className="form-control" />
                                                    <i className="ti-user" />
                                                    <div className="text-danger">{this.state.validationerrors.loginUser}</div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{i18next.t("password")}</label>
                                                <div id="password" className="input-with-icon">
                                                    <input onChange={this.loginPassword} name="loginPass" type="password" value={this.state.loginPass} placeholder="Password" className="form-control" />
                                                    <div className="text-danger">{this.state.validationerrors.loginPass}</div>
                                                    <i className="ti-unlock" />
                                                </div>
                                                {this.state.displayLoginError && <span className="errorAuthentication"><i className="fa fa-exclamation-circle" /> Wrong password. Try again or click <a style="font-weight: 100; font-size: 14px" href="/forgot-password">Forgot password</a> to reset it.</span>}
                                            </div>
                                            <div className="form-group">
                                                <div className="eltio_ol9">
                                                    <div className="eltio_k1">
                                                        <input id="dd" className="checkbox-custom" name="dd" type="checkbox" />
                                                        <label htmlFor="dd" className="checkbox-custom-label">{i18next.t("rememberme")}</label>
                                                    </div>
                                                    <div className="eltio_k2">
                                                        <a href="/forgot-password"><i className="fas fa-user-plus mr-2" />  {i18next.t("lostyourpassword")}</a>
                                                        {/* <a href="#"  ></a> */}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    {i18next.t("agency/agent/login")} <a href="https://dashboard.findproperties.ae">{i18next.t("click-here")}</a>
                                                </div>
                                            </div>
                                            <div className="form-group">

                                                <button disabled={this.state.loginLoading ? true : false} type="button" onClick={this.handleLogin} className="btn btn-md full-width pop-login">
                                                    {this.state.loginLoading ?
                                                        <PropagateLoader color={"#FFFFFF"} loading={true} size={3} /> : i18next.t("login")}

                                                </button>


                                            </div>
                                        </form>
                                    </div>                                       
                                </Tab>
                                <Tab eventKey="register" title={i18next.t("register")}>

                                    <div className="login-form">
                                        <form className="row g-3 needs-validation" noValidate>
                                            {this.state.hasError ? <span className='alert alert-danger'>{this.state.errorMessage}</span> : null}
                                            <div className="form-group">
                                                <label>{i18next.t("fullname")}</label>
                                                <div className="input-with-icon">
                                                    <input type="text" name="fullName" onChange={this.fullNameChangeHandle} className="form-control" />
                                                    <div className="text-danger">{this.state.validationerrors.fullName}</div>
                                                    <i className="ti-user" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{i18next.t("email")}</label>
                                                <div className="input-with-icon">
                                                    <input type="email" name="email" onChange={this.emailChangeHandle} className="form-control" required />
                                                    <div className="text-danger">{this.state.validationerrors.email}</div>
                                                    <i className="ti-user" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{i18next.t("password")}</label>
                                                <div className="input-with-icon">
                                                    <input type="password" name="password" onChange={this.passwordChangeHandle} className="form-control" />
                                                    <div className="text-danger">{this.state.validationerrors.password}</div>
                                                    <i className="ti-unlock" />
                                                </div>
                                            </div>
                                            <div dir="ltr" style={{ direction: 'ltr!important' }} className="form-group">
                                                <label>{i18next.t("phone")}</label>
                                                <div className="input-with-icon" style={{ direction: 'ltr!important' }}>
                                                    {/* <input type="text" onChange={this.phoneChangeHandle}  className="form-control" name="phone" id="reg-phone" tooltip="Enter" pattern="^\d{9,9}$" title="Please enter 9 digit number after the code!" placeholder="5XXXXXXXX"  required />
                                                 {/* <i className="ti-unlock" /> */}
                                                    {/* <span className="mobile-number">+971</span>  */}
                                                    <PhoneInput
                                                        inputStyle={{ height: "3.5rem", width: "100%" }}
                                                        name="phone"
                                                        country={'ae'}
                                                        value={this.state.phone}
                                                        onChange={this.phoneChangeHandle}
                                                    />
                                                    <div className="text-danger">{this.state.validationerrors.phone}</div>

                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="eltio_ol9">
                                                    <div className="eltio_k1">
                                                        <input id="dds" className="checkbox-custom" name="dds" type="checkbox" />
                                                        <label htmlFor="dds" className="checkbox-custom-label">{i18next.t("termsandconditions")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">


                                                <button disabled={this.state.registerLoading ? true : false} type="button" onClick={this.handleReg} className="btn btn-md full-width pop-login">
                                                    {this.state.registerLoading ?
                                                        <PropagateLoader color={"#FFFFFF"} loading={true} size={3} /> : i18next.t("register")}

                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Tab>

                                {/* <div className="resp_log_wrap">
                                    <div className="resp_log_thumb" style={{ background: 'url(../../../frontendUI/img/register-findproperties.jpg)no-repeat' }} />
                                    <div className="resp_log_caption">
                                        <span id="mod-close" onClick={this.props.onHide} className="mod-close" data-bs-dismiss="modal" aria-hidden="true"><i className="ti-close" /></span>
                                        <div className="edlio_152">
                                            <ul className="nav nav-pills tabs_system center" id="pills-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="pills-login-tab" data-bs-toggle="pill" href="#pills-login" role="tab" aria-controls="pills-login" aria-selected="true"><i className="fas fa-sign-in-alt mr-2" />  {i18next.t("login")}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link " id="pills-signup-tab" data-bs-toggle="pill" href="#pills-signup" role="tab" aria-controls="pills-signup" aria-selected="false"><i className="fas fa-user-plus mr-2" />  {i18next.t("register")}</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">

                                            </div>
                                            <div className="tab-pane fade " id="pills-signup" role="tabpanel" aria-labelledby="pills-signup-tab">

                                            </div>
                                            <div className="tab-pane fade" id="pills-forget-pass" role="tabpanel" aria-labelledby="pills-forget-pass-tab">
                                                <div className="form-group">
                                                    <label>{i18next.t("password")}</label>
                                                    <div id="password" className="input-with-icon">
                                                        <input onChange={this.loginPassword} type="password" value={this.state.loginPass} placeholder="Password" className="form-control" />
                                                        <i className="ti-unlock" />
                                                    </div>
                                                    {this.state.displayLoginError && <span className="errorAuthentication"><i className="fa fa-exclamation-circle" /> Wrong password. Try again or click <a style="font-weight: 100; font-size: 14px" href="/forgot-password">Forgot password</a> to reset it.</span>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}
                            </Tabs>
                           </div>  
                           </div>
                           </div>

                        </Modal.Body>

                    </Modal>
                    //  <div className="modal fade" style={{zIndex: 9999999,  background: 'white'}} id="login" tabIndex={-1} role="dialog" aria-labelledby="registermodal" aria-hidden="true">
                    //     <div className="modal-dialog modal-xl login-pop-form" role="document">
                    //         <div className="modal-content overli" id="registermodal">
                    //         <div className="modal-body p-0">
                    //             <div className="resp_log_wrap">
                    //             <div className="resp_log_thumb" style={{background: 'url(../../../frontendUI/img/register-findproperties.jpg)no-repeat'}} />
                    //             <div className="resp_log_caption">
                    //                 <span id="mod-close" onClick={this.modClose} className="mod-close" data-bs-dismiss="modal" aria-hidden="true"><i className="ti-close" /></span>
                    //                 <div className="edlio_152">
                    //                 <ul className="nav nav-pills tabs_system center" id="pills-tab" role="tablist">
                    //                     <li className="nav-item">
                    //                         <a  className="nav-link active" id="pills-login-tab" data-bs-toggle="pill" href="#pills-login" role="tab" aria-controls="pills-login" aria-selected="true"><i className="fas fa-sign-in-alt mr-2" />  {i18next.t("login")}</a>
                    //                     </li>
                    //                     <li className="nav-item">
                    //                          <a className="nav-link" id="pills-signup-tab" data-bs-toggle="pill" href="#pills-signup" role="tab" aria-controls="pills-signup" aria-selected="false"><i className="fas fa-user-plus mr-2" />  {i18next.t("register")}</a>
                    //                     </li>
                    //                 </ul>
                    //                 </div>
                    //                 <div className="tab-content" id="pills-tabContent">
                    //                 <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">
                    //                     <div className="login-form">
                    //                     <form className="row g-3 needs-validation" noValidate>
                    //                         {this.state.hasloginError ?<span className='alert alert-danger'> {this.state.errorloginMessage}</span>:null}
                    //                         <div className="form-group">
                    //                         <label>{i18next.t("email")}</label>
                    //                         <div className="input-with-icon">
                    //                             <input onChange= {this.loginUserName} name="loginUser" type="email" value={this.state.loginUser} placeholder="Email" className="form-control" />
                    //                             <i className="ti-user" />
                    //                             <div className="text-danger">{this.state.validationerrors.loginUser}</div>
                    //                         </div>
                    //                         </div>
                    //                         <div className="form-group">
                    //                         <label>{i18next.t("password")}</label>
                    //                         <div id="password" className="input-with-icon">
                    //                             <input onChange= {this.loginPassword} name="loginPass" type="password" value={this.state.loginPass} placeholder="Password" className="form-control" />
                    //                             <div className="text-danger">{this.state.validationerrors.loginPass}</div>
                    //                             <i className="ti-unlock" />
                    //                         </div>
                    //                         {this.state.displayLoginError && <span className="errorAuthentication"><i className="fa fa-exclamation-circle" /> Wrong password. Try again or click <a style="font-weight: 100; font-size: 14px" href="/forgot-password">Forgot password</a> to reset it.</span>}
                    //                         </div>
                    //                         <div className="form-group">
                    //                         <div className="eltio_ol9">
                    //                             <div className="eltio_k1">
                    //                             <input id="dd" className="checkbox-custom" name="dd" type="checkbox" />
                    //                                 <label htmlFor="dd" className="checkbox-custom-label">{i18next.t("rememberme")}</label>
                    //                             </div>	
                    //                             <div className="eltio_k2">
                    //                                 <a href="/forgot-password"><i className="fas fa-user-plus mr-2" />  {i18next.t("lostyourpassword")}</a>
                    //                                  {/* <a href="#"  ></a> */}
                    //                              </div>	
                    //                         </div>

                    //                          <div className="form-group">
                    //                            {i18next.t("agency/agent/login")} <a href="https://dashboard.findproperties.ae">{i18next.t("click-here")}</a>
                    //                         </div>
                    //                         </div>
                    //                         <div className="form-group">

                    //                         <button disabled={this.state.loginLoading?true:false} type="button" onClick={this.handleLogin} className="btn btn-md full-width pop-login">
                    //                             {this.state.loginLoading? 
                    //                              <PropagateLoader color={"#FFFFFF"} loading={true}  size={3} /> : i18next.t("login")}

                    //                              </button>


                    //                         </div>
                    //                     </form>
                    //                     </div>
                    //                 </div>
                    //                 <div className="tab-pane fade" id="pills-signup" role="tabpanel" aria-labelledby="pills-signup-tab">

                    //                     <div className="login-form">
                    //                     <form className="row g-3 needs-validation" noValidate>
                    //                     {this.state.hasError? <span className='alert alert-danger'>{this.state.errorMessage}</span>:null}
                    //                         <div className="form-group">
                    //                         <label>{i18next.t("fullname")}</label>
                    //                         <div className="input-with-icon">
                    //                             <input type="text" name="fullName" onChange={this.fullNameChangeHandle} className="form-control" />
                    //                             <div className="text-danger">{this.state.validationerrors.fullName}</div>
                    //                             <i className="ti-user" />
                    //                         </div>
                    //                         </div>
                    //                         <div className="form-group">
                    //                         <label>{i18next.t("email")}</label>
                    //                         <div className="input-with-icon">
                    //                             <input type="email" name="email" onChange={this.emailChangeHandle} className="form-control" required/>
                    //                             <div className="text-danger">{this.state.validationerrors.email}</div>
                    //                             <i className="ti-user" />
                    //                         </div>
                    //                         </div>
                    //                         <div className="form-group">
                    //                         <label>{i18next.t("password")}</label>
                    //                         <div className="input-with-icon">
                    //                             <input type="password" name="password" onChange={this.passwordChangeHandle} className="form-control" />
                    //                             <div className="text-danger">{this.state.validationerrors.password}</div>
                    //                             <i className="ti-unlock" />
                    //                         </div>
                    //                         </div>
                    //                         <div dir="ltr" style={{direction: 'ltr!important'}} className="form-group">
                    //                         <label>{i18next.t("phone")}</label>
                    //                             <div className="input-with-icon" style={{direction: 'ltr!important'}}>
                    //                                 {/* <input type="text" onChange={this.phoneChangeHandle}  className="form-control" name="phone" id="reg-phone" tooltip="Enter" pattern="^\d{9,9}$" title="Please enter 9 digit number after the code!" placeholder="5XXXXXXXX"  required />
                    //                                 {/* <i className="ti-unlock" /> */}
                    //                                 {/* <span className="mobile-number">+971</span>  */}
                    //                                 <PhoneInput
                    //                                     inputStyle	= {{height: "3.5rem", width: "100%"}}
                    //                                     name="phone"
                    //                                     country={'ae'}
                    //                                     value={this.state.phone}
                    //                                     onChange={this.phoneChangeHandle}
                    //                                     />
                    //                                  <div className="text-danger">{this.state.validationerrors.phone}</div>

                    //                             </div>
                    //                         </div>

                    //                         <div className="form-group">
                    //                         <div className="eltio_ol9">
                    //                             <div className="eltio_k1">
                    //                             <input id="dds" className="checkbox-custom" name="dds" type="checkbox" />
                    //                             <label htmlFor="dds" className="checkbox-custom-label">{i18next.t("termsandconditions")}</label>
                    //                             </div>	
                    //                         </div>
                    //                         </div>
                    //                         <div className="form-group">


                    //                         <button disabled={this.state.registerLoading?true:false} type="button" onClick={this.handleReg} className="btn btn-md full-width pop-login">
                    //                             {this.state.registerLoading? 
                    //                              <PropagateLoader color={"#FFFFFF"} loading={true}  size={3} /> : i18next.t("register")}

                    //                              </button>
                    //                         </div>
                    //                     </form>
                    //                     </div>
                    //                 </div>
                    //                 <div  className="tab-pane fade" id="pills-forget-pass" role="tabpanel" aria-labelledby="pills-forget-pass-tab">
                    //                     <div className="form-group">
                    //                             <label>{i18next.t("password")}</label>
                    //                             <div id="password" className="input-with-icon">
                    //                                 <input onChange= {this.loginPassword} type="password" value={this.state.loginPass} placeholder="Password" className="form-control" />
                    //                                 <i className="ti-unlock" />
                    //                             </div>
                    //                             {this.state.displayLoginError && <span className="errorAuthentication"><i className="fa fa-exclamation-circle" /> Wrong password. Try again or click <a style="font-weight: 100; font-size: 14px" href="/forgot-password">Forgot password</a> to reset it.</span>}
                    //                             </div>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //             </div>
                    //         </div>
                    //         </div>
                    //     </div>
                    //     </div>
                    : null}



            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCredentials: (user) => dispatch({ type: "SET_LOGIN", payload: user }),
        logout: () => dispatch({ type: "SET_LOGOUT" }),
        signup: () => dispatch({ type: "SET_SIGNUP" })

    }
}

export default connect(null, mapDispatchToProps)(User)
