import React, { Component } from "react";
import SinglePropertyHeader from "./SinglePropertyHeader";
import $ from "jquery";
import { baseurl, websitebaseurl } from "../../../Components/BaseUrl";
import { baseurlImg } from "../../../Components/BaseUrl";
import { Link } from "react-router-dom";
import i18next from "i18next";
import MortageCalculator from "../../../Utility/MortageCalculator";
import SinglePropertyMobile from "./SingleProperty.css";
import cookie from "js-cookie";
import Swal from 'sweetalert2'
import { Helmet } from "react-helmet";

import Moment from "react-moment";
import "moment-timezone";
import Pusher from "pusher-js";
import ReactHtmlParser from 'react-html-parser';
import ImageGallery from 'react-image-gallery';
import SideAds from "../../../Components/SideAds";
import { connect } from "react-redux";
import API from "../../../API";
import { store } from "../../../store";
import RiseLoader from "react-spinners/RiseLoader";

import './image-gallery.css'

const lang = localStorage.getItem("lang") || "en";
// const pp= this.state.property;
const userdata = store.getState(state => state);
const token = userdata.auth.token
const activeUser = userdata.auth.user.id;


$('#agentchatbutonId').on('click', function () {
  $("#callOnSignle").show()
  $('.float-action').show()

});


//   $('#mod-close').on('click' , function(){
// });
// $('.float-action').addClass("hide" )
// console.log("open")
// }
var language = lang == "ar" ? "ar/" : ""

export class SingleProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nearByModalValue: "properties",
      propertiesbtn: "active",
      schoolsbtn: "",
      restaurantbtn: "d-sm-none",
      hospitalsbtn: " ",
      parksbtn: "",
      supBtn: "d-sm-none ",
      propertyTypeRefs: ["restaurant", "park"],
      property: {},
      amenties: [],
      medias: [],
      similarProperties: [],
      propertyLocation: "",
      messageToAgent: "",

      messages: [],
      messageBody: "",

      input: {},
      errors: {},
      inputMessage: {},
      errorsTM: {},
    };
    // this.hadnleRes = this.hadnleRes.bind(this)
    this.restaurantOutSideClick = this.restaurantOutSideClick.bind(this);
    this.parksOutsideClick = this.parksOutsideClick.bind(this);
    this.hospitalOutsideClick = this.hospitalOutsideClick.bind(this);
    this.propertyClickOutside = this.propertyClickOutside.bind(this);
    this.supermarketOutSideClick = this.supermarketOutSideClick.bind(this);
    // this.propClick = this.propClick.bind(this)
  }
  decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };
  messageInputOnChange = (e) => {
    e.preventDefault();
    this.setState({ messageToAgent: e.target.value });
  };
  sendMessage = (agentId, e) => {
    e.preventDefault();
    const data = { messageBody: this.state.messageToAgent, reciever: agentId };
    // console.log("send Message", data);
    //   const messageTop = document.querySelector('#messageReply');
    //   messageTop.scrollIntoView({
    //     behavior: 'smooth'
    //   }, 500)
    //   $([document.documentElement, document.body]).animate({
    //     scrollTop: $("#messageReply").offset().top
    // }, 2000);

    $('#messageWindow').scrollTop($('#messageWindow').height());


    API.post("newmessage", data)
      .then((response) => {
        // console.log("send Message", response.data);
        // Swal.fire({
        //   title: 'Message Sent',
        //   text: 'Do you want to continue',
        //   icon: 'success',
        //   confirmButtonText: '<a href="/message">Go to Inbox</a>',
        //   // footer: ''

        // })
      });
  };
  supermarketOutSideClick() {
    // $("#propertybrn").addClass("active");
    this.setState({ propertyTypeRefs: ["supermarket", "shopping_mall"] });
    setTimeout(() => {
      this.initMap();
    }, 500);
    $("#map").hide();
    $("#nearbymap").show();
  }


  propertyClickOutside() {
    // $("#modal-btns-header>button").removeClass("active");
    // $("#propertybrn").addClass("active");
    this.setState({
      propertyTypeRefs: ["secondary_school", "primary_school", "university"],
    });
    setTimeout(() => {
      this.initMap();
    }, 500);
    $("#map").hide();
    $("#nearbymap").show();
  }
  hospitalOutsideClick() {
    // $("#modal-btns-header>button").removeClass("active");
    // $("#hosClick").addClass("active");
    this.setState({ propertyTypeRefs: ["hospital", "pharmacy"] });
    setTimeout(() => {
      this.initMap();
    }, 500);
    $("#map").hide();
    $("#nearbymap").show();
  }
  restaurantOutSideClick() {
    // window.$('#nearby').modal();
    // $("#modal-btns-header>button").removeClass("active");
    $("#restbtn").addClass("active");
    this.setState({ propertyTypeRefs: ["restaurant", "cafe"] });
    setTimeout(() => {
      this.initMap();
    }, 500);
    $("#map").hide();
    $("#nearbymap").show();
  }
  parksOutsideClick() {
    // $("#modal-btns-header>button").removeClass("active");
    //  $("#parkbtn").addClass("active");
    // alert("parkClick")
    //window.$('#nearby').modal();
    this.setState({ propertyTypeRefs: ["park"] });
    setTimeout(() => {
      this.initMap();
    }, 500);
    $("#map").hide();
    $("#nearbymap").show();
  }
  propClick = () => {
    // $("#modal-btns-header>button").removeClass("active");
    $("#propertybrn").addClass("active");
    this.setState({
      propertyTypeRefs: ["hospitals"],
      propertiesbtn: "active",
      schoolsbtn: "",
      restaurantbtn: "",
      hospitalsbtn: "",
      parksbtn: "",
      supBtn: "",
    });
    setTimeout(() => {
      this.initMap();
    }, 500);
  };
  schClick = () => {
    this.setState({
      propertyTypeRefs: ["secondary_school", "primary_school", "university"],
      schoolsbtn: "active",
      propertiesbtn: "",
      restaurantbtn: "",
      hospitalsbtn: "",
      parksbtn: "",
      supBtn: "",
    });
    setTimeout(() => {
      this.initMap();
    }, 500);
  };
  resClick = () => {
    this.setState({
      propertyTypeRefs: ["restaurant", "cafe"],
      restaurantbtn: "active",
      propertiesbtn: "",
      schoolsbtn: "",
      hospitalsbtn: "",
      parksbtn: "",
      supBtn: "",
    });
    setTimeout(() => {
      this.initMap();
    }, 500);
  };
  supClick = () => {
    this.setState({
      propertyTypeRefs: ["supermarket", "shopping_mall"],
      restaurantbtn: "",
      propertiesbtn: "",
      schoolsbtn: "",
      hospitalsbtn: "",
      supBtn: "active",
    });
    setTimeout(() => {
      this.initMap();
    }, 500);
  };

  handleFormErrorSend = () => {
    let inputMessage = this.state.inputMessage;
    let errorsTM = {};
    let formIsValid = true;
    if (!inputMessage["message"]) {
      formIsValid = false;
      errorsTM["message"] = "Message can not be Empty!";
    }

    this.setState({
      errors: errorsTM,
    });
    return formIsValid;
  };
  hadnleFormError = () => {
    let input = this.state.input;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!input["location"]) {
      formIsValid = false;
      errors["location"] = "Location cannot be empty!";
    }

    if (!input["price"]) {
      formIsValid = false;
      errors["price"] = "Price cannot be empty!";
    }
    if (!input["bed"]) {
      formIsValid = false;
      errors["bed"] = "Bedroom cannot be empty!";
    }
    if (!input["bath"]) {
      formIsValid = false;
      errors["bath"] = "Bathroom cannot be empty!";
    }
    if (!input["area"]) {
      formIsValid = false;
      errors["area"] = "Area cannot be empty!";
    }
    if (!input["type"]) {
      formIsValid = false;
      errors["type"] = "Property type cannot be empty!";
    }
    if (!input["purpose"]) {
      formIsValid = false;
      errors["purpose"] = "Purpose cannot be empty!";
    }
    if (typeof input["name"] !== "undefined") {
      if (!input["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleUserChat = (user, e) => {
    API.get("allmessages/" + user.id)
      .then((response) => {
        this.setState({
          messages: response.data.messages,
          messageContent: 1,
          recieverUserName: user.name,
          recieverId: user.id,
        });
      });
    if (window.innerWidth <= 760) {
      this.setState({ showBrokerNameOnchat: false });
    }
    window.scrollTo(150, '1300px');
    // document.getElementById('messageReply').scrollIntoView();
    // document.getElementById('messageReply').scrollIntoView(alignToTop)

  };
  messageSendHandler = (e) => {
    let inputMessage = this.state.inputMessage;
    inputMessage[e.target.name] = e.target.value;
    this.setState({
      // message: e.target.value, inputMessage ,
      messageBody: e.target.value, inputMessage,
      recieverId: this.state.property.user_id,
    });
  };
  submitMessage = () => {
    // e.preventDefault();
    document.getElementById('messageWindow').scrollTop = 430;

    // alert($('.dash-msg-content').height())

    if (this.handleFormErrorSend()) {
      const data = {
        messageBody: this.state.messageBody,
        reciever: this.state.property.user_id
      };
      API.post("newmessage", data)
        .then((response) => {
          this.setState({ messageBody: "" });
          //this.setState({messageBody:""})
          //this.fetchData();
        });
    }
  };

  hospClick = () => {
    this.setState({
      propertyTypeRefs: ["hospital", "pharmacy"],
      hospitalsbtn: "active",
      propertiesbtn: "",
      schoolsbtn: "",
      restaurantbtn: "",
      parksbtn: "",
      supBtn: "",
    });
    setTimeout(() => {
      this.initMap();
    }, 500);
  };

  parClick = () => {
    this.setState({
      propertyTypeRefs: ["park"],
      parksbtn: "active",
      propertiesbtn: "",
      schoolsbtn: "",
      restaurantbtn: "",
      hospitalsbtn: "",
      supBtn: "",
    });
    setTimeout(() => {
      this.initMap();
    }, 500);
  };

  handleScroll = e => {
    if (window.pageYOffset >= 750) {
      $("div#callOnSignle").css({ 'position': 'fixed', 'bottom': '0', 'z-index': '9990', 'width': '100%', 'background': '#fff' });
      // alert("scrolled top")
    } else {
      $("div#call-single").css({ 'position': 'unset', 'z-index': '9990', });
    }
    // console.log(window.scrollY)
  }


  callClickProperty = (property, e) => {
    // this.setState({
    //   phone: property.mobile,
    //   agentName: property.name,
    // });
   
    const sender = "Find Properties"
    const message = "You have got a call from Find Properties for your property" + " https://findproperties.ae/single-property/" + this.state.property.id

    API.post("https://elitbuzz-me.com/sms/smsapi?api_key=C200343061a1e16b4924a3.21883164&type=text&contacts=00" + this.state.property.mobile + "&senderid=MFRE&msg=" + message
    ).then((response) => {

    });


    const data = { property_id: this.state.property.id }
    API.post("calltrack", data
    ).then((response) => {
    });
  }
  componentDidMount() {

    setTimeout(() => {
      this.renderMap();
    }, 5000);
    window.addEventListener('scroll', this.handleScroll);


    //  else {
    //       $( "#callOnSignle" ).removeClass("hide" )
    //       $('.float-action').removeClass("hide" )
    //     }

    // $( "#callOnSignle").hide()
    // $('.float-action').hide()
    // $('#staticBackdrop').modal({ show: true });
    $('#closeChatWindow').on('click', function () {
      //Your code here
      // $(".ti-close").focus();
      // $("body").removeClass('modal-open');
      // $("#staticBackdrop").removeClass('show');
      // $("#staticBackdrop").css("display", "none");
      // $("#staticBackdrop").attr("role","");
      // $("body").remove("modal-backdrop")
      // window.location.reload()
      $("body").removeClass("modal-open");
      $("#staticBackdrop").removeClass("show");
      $("#staticBackdrop").css("display", "none");
      $("#staticBackdrop").attr("role", "");
      $(".modal-backdrop").remove();

    });
    $('#agentchatbutonId').on('click', function () {

      if (token) {
        $(".ti-close").focus();
        $("body").addClass('modal-open');
        $("#staticBackdrop").addClass('show');
        $("#staticBackdrop").css("display", "block");
        $("#staticBackdrop").attr("role", "dialog");
        $("body").append('<div className="modal-backdrop fade show"></div>')
        // window.location.reload()
      } else {
        $(".ti-close").focus();
        $("body").addClass('modal-open');
        $("#login").addClass('show');
        $("#login").css("display", "block");
        $("#login").attr("role", "dialog");
        $("body").append('<div className="modal-backdrop fade show"></div>')
        //  window.location.reload()

      }


    });
    $('#closeChatWindow').on('click', function () {
      if ($(window).width() > 600) {
        $("#callOnSignle").hide()
      }
      else {
        $("#callOnSignle").show()
      }
      $('.float-action').show()

    });
    // if($('#closeChatWindow').click()){
    //   $( "#callOnSignle" ).removeClass("hide" )
    //   console.log("close") 
    // }



    // var pos = $('.call-single').scrollTop();

    // if($(pos ==0)){
    // $(".call-single").css({'position':'fixed', 'bottom': '0', 'z-index':'9999'});
    // }

    // $('#callOnSignle').on('scroll', function() {
    //   var scrollTop = $(this).scrollTop();
    //   if (scrollTop + $(this).innerHeight() >= this.scrollHeight) {
    //     alert('end reached');
    //   } else if (scrollTop <= 0) {
    //     alert('Top reached');
    //   } else {
    //     alert('None');
    //   }
    // });



    var loadScript = function (src) {
      var tag = document.createElement("script");
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName("body")[0].appendChild(tag);
    };

    loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAJJRGny08rq3_3BwSvmQn3g7BbjbL4oB4&callback=initMap&libraries=localContext&v=beta&channel=2"
    );
    //    loadScript('https://polyfill.io/v3/polyfill.min.js?features=default')
    $("#numberShowbtn").click(function () {
      $("#telephoneNumb").text($("#number").data("last"));
      $("#numberShowbtn").hide();
      // alert($('#number').data('last'))
    });
    $("#restaurant").click(function () {
      // alert("restaurant clicked")
      // $("#supbtn").addClass("active");
      //this.setState({propertyTypeRefs: ["restaurant", "cafe"],
      // })
    });

    //  numberShowbtn = () => {
    //   this.setState({ title: "New title" });
    //   };
    $(document).ready(function () {
      $("#nearbymap").hide();
      if ($(window).width() <= 768) {
        $('#agent-contact-box').insertBefore('#property-block');
      }

    });

    this.fetchData();
    // Message
    const pusher = new Pusher("2955db0221fb16cb6441", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe("chat");
    channel.bind("message", (newMessage) => {
      this.setState({ messages: [...this.state.messages, newMessage] });
    });
   

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }
  renderMap = () => {
    window.initMap = this.initMap;
  };
  // handleMedia = media => {
  //   console.log(media)
  //   this.setState({media: })
  // }
  hadnleRes = () => {
    // $('#nearby').modal('show');
  };
  initMap = () => {
    //let map;
    const center = { lat: 25.1972018, lng: 55.2721877 };

    const google = window.google;
    const myCoordinates = {
      lat: this.state.propertyLocation["lat"],
      lng: this.state.propertyLocation["lng"],
    };
    // console.log("myCordinated", myCoordinates);
    const lcMapView = new google.maps.localContext.LocalContextMapView({
      placeChooserViewSetup: {
        layoutMode: google.maps.localContext.PlaceChooserLayoutMode.BLOCK_END,
      },
      // directionsOptions: { origin: myCoordinates },
      element: document.querySelector("#map-container"),
      placeTypePreferences: this.state.propertyTypeRefs,
      maxPlaceCount: 14,
      directionsOptions: { origin: myCoordinates },
      // directionsOptions:  {origin: {lat: this.state.propertyLocation['lat'], lng: this.state.propertyLocation['lng']}}
    });
    const map = lcMapView.map;
    map.setOptions({
      center: myCoordinates,
      zoom: 14,
    });
    new google.maps.Marker({
      position: myCoordinates,
      map,
    });
    google.maps.event.addDomListener(window, "resize", function () {
      map.setCenter(myCoordinates);
    });
    //   google.maps.event.addDomListener(window, "resize", function() {
    //     var bound = new google.maps.LatLngBounds();
    //     for(var i in $scope.markers)
    //     {
    //        bound.extend($scope.markers[i].getPosition());
    //     }
    //     $scope.map.fitBounds(bound);
    // });

    // console.log("the state", this.state.propertyTypeRefs);
  };
  fetchData = () => {
    let id = this.props.match.params.id;
    this.setState({ isLoading: true });
    API.get("show-property/" + id)
      .then((response) => {
        this.setState({ isLoading: false });
        var data = response.data.medias;
        var i;
        for (i = 0; i < data.length; i++) {
          data[i].original = baseurlImg + "/uploads/properties/" + data[i].image;
          data[i].thumbnail = baseurlImg + "/uploads/properties/" + data[i].image;

          // data[i].premiumbutton = false

          API.get("allmessages/" + response.data.property.user_id)
            .then((response) => {
              this.setState({
                messages: response.data.messages,
                messageContent: 1,
                // recieverUserName: user.name,
                // recieverId: user.id,
              });
              
            });
        }

        this.setState({
          property: response.data.property,
          medias: data,
          propertyLocation: response.data.property.location,
          amenties: response.data.amenities,
          similarProperties: response.data.similar,
        });
      })
      .catch(

        this.setState({
          isLoading: -1
        })

      );
  };

  render() {
    // console.log("medias state", this.state.medias);
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>

        <div>

          {/* <SingleProperty /> */}
          {this.state.medias.length > 0 ? (
            // <SinglePropertyHeader handleMedia={this.state.medias} />

            <div style={{ maxWidth: '1100px', margin: '0 auto' }} className="h-full">
              <ImageGallery thumbnailPosition='left' items={this.state.medias} />
            </div>
          ) :
            // <>
            <div style={{ maxWidth: '1100px', margin: '0 auto' }} className="">
              <img style={{ height: '550px', width: '100%!important', objectFit: 'cover', }} src={baseurlImg + "/uploads/properties/" + this.state.property.image} />
              {/* <div class='lds-ripple center'>
              <div></div>
              <div></div>
          </div>  */}
              {/* </> */}
            </div>

          }

          <section className="pt-4">
            <div className="container">
              <div className="row">
                {/* property main detail */}
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="property_info_detail_wrap mb-4">
                    <div className="property_info_detail_wrap_first">
                      <div className="pr-price-into">
                        <ul className="prs_lists">
                          {this.state.property.beds > -1 ?
                            <li>
                              <span className="bed">
                                {this.state.property.beds == 0 ? i18next.t("studio") : this.state.property.beds + " " + i18next.t("bedroom")}
                              </span>
                            </li>
                            : null}
                          {this.state.property.baths > 0 ?
                            <li>
                              <span className="bath">
                                {this.state.property.baths} {i18next.t("bathroom")}
                              </span>
                            </li>
                            : null}
                          {/* <li><span className="gar">1 Garage</span></li> */}
                          <li>
                            {this.state.property.area > 0 ?
                              <span className="sqft">
                                {this.state.property.area} {i18next.t("sqft")}
                              </span> : null}
                          </li>
                        </ul>
                        <div className="row">
                          <div>
                            <h2 style={{ float: "" }}>
                              <span className="frequency"> {i18next.t("aed") + ""}</span>{this.state.property.price ? this.state.property.price.toLocaleString() : ""}
                              {this.state.property.rent_frequency == 1 ? <span className="frequency"> {i18next.t("yearly")}</span> : this.state.property.rent_frequency == 2 ? <span> {i18next.t("monthly")}</span> : this.state.property.rent_frequency == 3 ? <span> {i18next.t("weekly")}</span> : this.state.property.rent_frequency == 3 ? <span> {i18next.t("daily")}</span> : null}
                            </h2>
                          </div>
                          <div>
                            <h1>
                              {lang == "en"
                                ? this.state.property.title_en
                                : this.state.property.title_ar}
                            </h1>
                            <h5 className='listing-title'>
                              <a style={{ color: "#555" }}>
                                <i className="fa fa-map-marker"></i>
                                {lang == "en" ?
                                  " " + this.state.propertyLocation["area_en"] + ", " + this.state.propertyLocation["emirate_en"] : this.state.propertyLocation["area_ar"] + ", " + this.state.propertyLocation["emirate_ar"]}
                              </a>
                            </h5>
                          </div>
                          <div>
                            <h1>
                              {/* {lang == "en"
                              ? this.state.property.location["location_name_en"]
                              : this.state.property.location.location_name_ar} */}
                            </h1>
                          </div>

                        </div>
                        {/* 
                      <span>
                        <i className="lni-map-marker" />{" "}
                        {lang == "en"
                          ? this.state.propertyLocation["streetorbuild_en"] +
                            " , " +
                            this.state.propertyLocation["area_en"] +
                            " , " +
                            this.state.propertyLocation["emirate_en"]
                          : this.state.propertyLocation["streetorbuild_ar"] +
                            " , " +
                            this.state.propertyLocation["area_ar"] +
                            " , " +
                            this.state.propertyLocation["emirate_ar"]}
                      </span> */}
                      </div>
                      {/* <div className="float-action">
                        <a id="agentchatbutonId" data-bs-toggle="modal" type="button" className="my-float">
                          <i className="ti-comment-alt"></i>
                        </a>
                      </div> */}
                      <div id="callOnSignle" className="sigle-property-contact">
                        <div className="listing-gallery-wrapper call-single">
                          <a
                            href={"tel: 00" + this.state.property.mobile}
                            onClick={this.callClickProperty.bind(this, this.state.property.id)}
                            className="btn-custom btn-sm secondary"
                            tabIndex={0}>
                            <i className="fa fa-phone" aria-hidden="true">
                              {" "}
                            </i>
                            &nbsp;&nbsp;Call&nbsp;&nbsp;&nbsp;&nbsp;
                          </a>
                        </div>
                        <div className="listing-gallery-wrapper call-single">
                          <a
                            href={"mailto:" + this.state.property.email}
                            className="btn-custom btn-sm secondary"
                            tabIndex={0}>
                            <i className="fa fa-envelope" /> &nbsp; {i18next.t("email")}
                          </a>
                        </div>
                        <div className="listing-gallery-wrapper call-single">
                          <a href={"https://wa.me/" + this.state.property.mobile + "?text=Hey%20" + this.state.property.name_en + "%20I am%20interested%20in%20getting%20more%20information%20about%20this%20property%20you%20posted%20on%20Find%20Properties, Link:" + websitebaseurl + "single-property/" + this.state.property.id}
                            onClick={() => {
                              // const message= "You have got a what's app message from FindProperties for your property"+ "https://findproperties/single-property/"+property.id     
                              const data = { property_id: this.state.property.id }
                              API.post("whatsapptrack", data
                              ).then((response) => {
                              });
                            }}
                            className="btn-custom btn-sm secondary"
                            tabIndex={0}>
                            <i className="fab fa-whatsapp" />
                          </a>
                        </div>

                      </div>
                    </div>
                    <div className="property_detail_section">
                      <div className="prt-sect-pric">
                        <ul className="_share_lists">
                          {/* <li>
                          <a href="#">
                            <i className="fa fa-bookmark" />
                          </a>
                        </li> */}
                          {/* <li>
                          <a href="#">
                            <i className="fa fa-share" />
                          </a>
                        </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* Single Block Wrap */}
                  <div className="property_block_wrap">
                    <div className="property_block_wrap_header">
                      <h4 className="property_block_title">{i18next.t("aboutproperty")}</h4>
                    </div>
                    <div className="block-body">
                      {ReactHtmlParser(lang == "en"
                        ? this.state.property.details_en
                        : this.state.property.details_ar)}

                      {/* <pre style={{ direction: "unset" }}> */}
                      {/* <pre style={{direction: lang=='en'?"ltr":"rtl"}}> */}'


                      {/* </pre>  */}
                    </div>
                  </div>
                  {/* Single Block Wrap */}
                  {this.state.amenties.length > 0 ? (
                    <div className="">
                      <div className="property_block_wrap_header">
                        <h4 className="property_block_title">{i18next.t("amenties")}</h4>
                      </div>
                      <div className=" block-body">
                        <div className="row amentiesGroup">
                          {this.state.amenties.map((amen) => {
                            if (amen.status == 1)
                              return (
                                <div className="eachAmenties">
                                  <i className={`fas fa-${amen.icon_code}`}></i>
                                  <span
                                    style={{
                                      textTransform: "lowercase",
                                      display: "block",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {lang == "en"
                                      ? amen.feature_en
                                      : amen.feature_ar}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="">
                    <div className="property_block_wrap_header">
                      <h4 className="property_block_title">{i18next.t("location")}</h4>
                    </div>
                    <div className="item-navigation">
                      <div className="tab-content">
                        <div
                          role="tabpanel"
                          className="tab-pane active show"
                          id="map"
                        >
                          <iframe
                            src={
                              "https://maps.google.com/maps?q=" +
                              this.state.propertyLocation["lat"] +
                              "," +
                              this.state.propertyLocation["lng"] +
                              "&z=15&output=embed"
                            }
                            width={800}
                            style={{ border: 0, height: "50vh", width: '100%', margin: '0 auto' }}
                          // allowFullScreen
                          />
                        </div>

                        <div role="tabpanel" className="tab-pane" id="streetview">
                          {/* <iframe
                          src="https://www.google.com/maps/embed?pb=!1m0!3m2!1sen!2s!4v1489861898447!6m8!1m7!1sGz9bS-GXSJE28jHD19m7KQ!2m2!1d52.45191646727986!2d1.451480542718656!3f0!4f0!5f0.8160813932612223"
                          width={800}
                          height={450}
                          style={{ border: 0 }}
                          allowFullScreen
                        /> */}
                        </div>
                      </div>
                    </div>
                    <div
                      // className=""
                      id="nearbymap"
                      className="mapouter col-sm-12"
                    >
                      <div
                        style={{ maxHeight: "350px!important", width: "100%" }}
                        className="gmap_canvas"
                      >
                        <div
                          style={{
                            height: "480px!important",
                            width: "300px!important",
                          }}
                          className="map-container"
                          id="map-container"
                        ></div>
                      </div>
                    </div>
                    <div className="property_block_wrap nearby-location-area">
                      {/* <h5 className="sm-title">Nearby</h5> */}
                      <div className="row">
                        {/* <div className="nearby-btns">
                        <div className="location-wrap">
                          <button
                            className="nearby-location-area d-flex align-items-center"
                            type="button"
                            // data-bs-toggle="modal"
                            // data-bs-target="#nearby"
                            onClick={this.propertyClickOutside}
                            className="btn-custom btn-sm secondary"
                            style={{
                              backgroundImage: 'url("./images/map.png")',
                            }}
                          >
                            <i className="fas fa-school" aria-hidden="true" />
                            <span>Schools/Colleges</span>
                          </button>
                        </div>
                      </div> */}
                        <div className="nearby-btns">
                          <div className="location-wrap">
                            <button
                              name="park"
                              // className="nearby-location-area d-flex align-items-center"
                              type="button"
                              // data-bs-toggle="modal"
                              // data-bs-target="#nearby"
                              onClick={this.parksOutsideClick}
                              className="btn-custom btn-sm secondary"
                              style={{
                                backgroundImage: 'url("./images/map.png")',
                              }}
                            >
                              <i
                                style={{ color: "#000" }}
                                className="fa fa-tree"
                                aria-hidden="true"
                              />
                              <span>{i18next.t("parks")}</span>
                            </button>
                          </div>
                        </div>
                        <div className="nearby-btns">
                          <div className="location-wrap">
                            <button
                              // className="nearby-location-area d-flex align-items-center"
                              type="button"
                              value="restaurant"
                              onClick={this.restaurantOutSideClick}
                              id="restaurant"
                              className="btn-custom btn-sm secondary"
                              style={{
                                backgroundImage: 'url("./images/map.png")',
                              }}
                            >
                              <i className="fas fa-utensils" aria-hidden="true" />
                              <span>{i18next.t("restaurants")}</span>
                            </button>
                          </div>
                        </div>
                        <div className="nearby-btns">
                          <div className="location-wrap">
                            <button
                              // className="nearby-location-area d-flex align-items-center"
                              type="button"
                              value="supermarket"
                              onClick={this.supermarketOutSideClick}
                              id="supermarket"
                              className="btn-custom btn-sm secondary"
                              style={{
                                backgroundImage: 'url("./images/map.png")',
                              }}
                            >
                              <i
                                className="fas fa-shopping-cart"
                                aria-hidden="true"
                              />
                              <span>{i18next.t("marketmalls")}</span>
                            </button>
                          </div>
                        </div>
                        <div className="nearby-btns">
                          <div className="location-wrap">
                            <button
                              // className="nearby-location-area d-flex align-items-center"
                              type="button"
                              // data-bs-toggle="modal"
                              // data-bs-target="#nearby"
                              onClick={this.hospitalOutsideClick}
                              className="btn-custom btn-sm secondary"
                              style={{
                                backgroundImage: 'url("./images/map.png")',
                              }}
                            >
                              <i className="fas fa-hospital" aria-hidden="true" />
                              <span>{i18next.t("healthfacility")}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div
                      className="modal fade"
                      id="nearby"
                      tabindex="-1"
                      aria-labelledby="callModalLabel"
                      aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered ">
                        <div className="modal-content">
                          <div id="modal-btns-header" className="modal-header">
                            <button
                              id="propertybrn"
                              onClick={this.propClick}
                              className={this.state.propertiesbtn} >
                              Properties
                            </button>
                            <button
                              onClick={this.schClick}
                              className={this.state.schoolsbtn}>
                              Schools
                            </button>
                            <button
                             id="parkbtn"
                              onClick={this.parClick}
                              className={this.state.parksbtn}
                            >
                              Parks
                            </button>
                            <button
                              id="hosClick"
                              onClick={this.hospClick}
                              className={this.state.hospitalsbtn}>
                              Health
                            </button>
           
                            <button
                               id="supbtn"
                              onClick={this.supClick}
                              className={this.state.supBtn}
                            >
                              Supermarkets
                            </button>
                            <button
                              id="restbtn"
                              onClick={this.resClick}
                              className={this.state.restaurantbtn}
                              data="dataSup">
                              Restaurants
                            </button>

                            <a
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></a>
                          </div>
                          <div className="modal-body row" style={{ margin: "0" }}>
                            <div className="mapouter col-sm-8">
                              <div
                                className="gmap_canvas">
                                <div
                                  style={{height: "400px" }}
                                  className="map-container"
                                  id="map-container"
                                ></div>
                              </div>
                            </div>
                            <div className="agentContact col-sm-4">
                              <div className="sider_blocks_wrap">
                                <div className="side-booking-body">
                                  <div className="agent-_blocks_title">
                                    <div className="agent-_blocks_thumb">
                                      <img
                                        src={
                                          baseurlImg +
                                          "/uploads/profiles/" +
                                          this.state.property.profile
                                        }
                                        alt
                                      />
                                    </div>
                                    <div className="agent-_blocks_caption">
                                      <h4>
                                        {lang == "en"
                                          ? this.state.property.name_en
                                          : this.state.property.name_ar}
                                      </h4>
                                      <span className="approved-agent">
                                        <i className="ti-check" />
                                        approved
                                      </span>
                                    </div>
                                    <div className="clearfix" />
                                  </div>
                                  <button 
                                    href="#"
                                    className="agent-btn-contact"
                                    data-toggle="modal"
                                    data-target="#autho-message"
                                  >
                                    <i className="ti-comment-alt" />
                                    Message
                                  </button>
                                  <span
                                    id="number"
                                    data-last={this.state.property.mobile}
                                  >
                                    <i className="ti-headphone-alt" />
                                    {/* <a href="tel: +9715555855"  className="modal fade" id="exampleModalToggle" tabindex="-1">Call</a> */}
                      {/* <a
                                      href="tel: +9715555855"
                                      data-bs-toggle="modal"
                                      href="#exampleModalToggle"
                                      role="button"
                                    >
                                      Call
                                    </a>
                                  </span>
                                </div>
                                <div
                                  className="modal fade"
                                  id="exampleModalToggle"
                                  aria-hidden="true"
                                  aria-labelledby="exampleModalToggleLabel"
                                  tabindex="-1"
                                >
                                  <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          style={{ textAlign: "centered" }}
                                          className="modal-title"
                                          id="exampleModalToggleLabel"
                                        >
                                          Contact us
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div
                                        className="modal-body"
                                        style={{
                                          width: "80%",
                                          margin: "0 auto",
                                        }}
                                      >
                                        <p style={{ textAlign: "center" }}>
                                          <b>Madina Real Estate</b>
                                        </p>
                                        <div className="row border">
                                          <div className="col">Mobile</div>
                                          <div className="col">
                                            :{" "}
                                            <a href="tel: 97511554">
                                              +9715556654
                                            </a>
                                          </div>
                                        </div>
                                        <div className="row border">
                                          <div className="col">Agent</div>
                                          <div className="col">John Doe</div>
                                        </div>
                                        <div
                                          className="row"
                                          style={{ paddingTop: "2rem" }}
                                        >
                                          <div className="col">
                                            Please mention that you have seen
                                            this Ad on findproperties.com
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="sider_blocks_wrap">
                                <div className="side-booking-body">
                                  <div className="agent-_blocks_title"></div>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div> 
                    </div>*/}
                    </div>
                  </div>
                  <div id="property-block" className="property_block_wrap">
                    <h4 className="property_block_title">{i18next.t("similarproperties")}</h4>
                    <div className="similar-group">
                      {/* Similar Properties */}
                      {this.state.similarProperties.map((similar) => (
                        <div className="listing similar" aria-hidden="false">
                          <div className="listing-thumbnail">
                            <a
                              href={"/" + language + "single-property/" + similar.id}
                              style={{ direction: lang == "en" ? "ltr" : "rtl" }}>
                              <img
                                src={
                                  baseurlImg +
                                  "/uploads/properties/" +
                                  similar.image
                                }
                                alt
                              />
                            </a>
                            {/* <div className="listing-badges">
                            <span className="listing-badge featured"> */}
                            {/* <i className="fa fa-star" />{" "} */}
                            {/* </span>
                            <span className="listing-badge sale">
                              {similar.purpose == 1 ? "For rent" : "For Sale"}
                            </span>
                          </div> */}
                            {/* <div className="listing-controls">
                          <a href="#" className="favorite" tabIndex={0}>
                            <i className="far fa-heart" />
                          </a>
                          <a href="#" className="compare" tabIndex={0}>
                            <i className="fas fa-sync-alt" />
                          </a>
                        </div> */}
                          </div>
                          <div className="listing-body">
                            <div className="flex">
                              <span className="listing-price">
                                {similar.price.toLocaleString()}
                                <span>{i18next.t("aed")}
                                  {similar.rent_frequency == 1 ? <span> {i18next.t("yearly")}</span> : similar.rent_frequency == 2 ? <span> {i18next.t("monthly")}</span> : similar.rent_frequency == 3 ? <span> {i18next.t("weekly")}</span> : similar.rent_frequency == 3 ? <span> {i18next.t("daily")}</span> : null}
                                </span>
                              </span>
                              <span className="typeName">
                                {lang == "en"
                                  ? similar.typeName_en
                                  : similar.typeName_ar}
                              </span>
                            </div>
                            <h5 className="listing-title">
                              <a
                                href={"/" + language + "single-property/" + similar.id}
                                style={{
                                  direction: lang == "en" ? "ltr" : "rtl",
                                }}>
                                {lang == "en"
                                  ? similar.title_en
                                  : similar.title_ar}
                              </a>
                            </h5>
                            <div
                              style={{ direction: lang == "en" ? "ltr" : "rtl" }}
                              className="acr-listing-icons"
                            >
                              {similar.beds > -1 ?
                                <div
                                  className="acr-listing-icon"
                                  data-toggle="tooltip"
                                  title="Beds"
                                >
                                  <i className="flaticon-bedroom" />
                                  <span className="acr-listing-icon-value">
                                    {similar.beds == 0 ? i18next.t("studio") : similar.beds}
                                  </span>
                                </div>
                                : null}
                              {similar.baths > 0 ?
                                <div
                                  style={{
                                    direction:
                                      lang == "en"
                                        ? "ltr!important"
                                        : "rtl!important",
                                  }}
                                  className="acr-listing-icon"
                                  data-toggle="tooltip"
                                  title="Bathrooms"
                                >
                                  <i className="flaticon-bathroom" />
                                  <span className="acr-listing-icon-value">
                                    {similar.baths}
                                  </span>
                                </div>
                                : null}
                              {similar.area > -1 ?

                                <div
                                  className="acr-listing-icon"
                                  data-toggle="tooltip"
                                  title="Square Feet">
                                  <i className="flaticon-ruler" />
                                  <span className="acr-listing-icon-value">
                                    {similar.area}
                                  </span>
                                </div>
                                : null}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <div className="property_block_wrap">
                  </div> */}
                  {/* Single Block Wrap */}
                </div>
                {/* property Sidebar */}
                {/* <div className="col-lg-4 col-md-12 d-none d-sm-block"> */}
                <div className="col-lg-4 col-md-12">
                  <div className="property-sidebar">
                    {/* Agent Detail */}
                    <div className="sider_blocks_wrap">
                      <div id="agent-contact-box" className="side-booking-body">
                        <div className="agent-_blocks_title">
                          <div className="agent-_blocks_thumb">
                            <a href={"/agents/" + this.state.property.agent_id}>

                              {this.state.property.profile == undefined ?
                                <img src={baseurlImg + "/uploads/profiles/no_avatar.png"} alt /> :
                                <img src={baseurlImg + '/uploads/profiles/' + this.state.property.profile} alt />
                              }
                            </a>

                          </div>
                          <div className="agent-_blocks_caption">
                            <h4>
                              <a href={"/agents/" + this.state.property.agent_id}>
                                {lang == "en"
                                  ? this.state.property.name_en
                                  : this.state.property.name_ar}
                              </a>
                            </h4>
                            {/* <span className="approved-agent">
                              <i className="ti-check" />
                              {i18next.t("approved")}
                            </span> */}
                            <a style={{}} href={"/agencies/" + this.state.property.agency_id}> {lang == "en" ? this.state.property.agency_name_en : this.state.property.agency_name_ar}</a>

                          </div>

                          {/* <div className="d-none d-xs-none d-sm-none d-md-flex"> */}
                          {/* <div className="d-md-block">
                                      <img width="100px" style={{align: 'center', margin: '0 auto'}}
                                        src={
                                          baseurlImg +
                                          "/uploads/profiles/" +
                                          this.state.property.logo
                                        }
                                        alt
                                      />
                         </div> */}
                          {/* <div className="clearfix" /> */}
                        </div>
                        {/* <a
                        id="agentchatbutonId"
                        type="button"
                        className="agent-btn-contact"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop">
                        <i style={{padding: '3px'}} className="ti-comment-alt" />
                        {i18next.t("sendmessage")}
                      </a> */}
                        <span id="number" className="d-none d-sm-block" data-last={this.state.property.mobile}>
                          {/* <span> */}
                          <i className="ti-headphone-alt" />
                          {/* <a className="see">+355(44)35...Show</a> */}
                          <span style={{ padding: '3px' }} id="numberShowbtn" onClick={this.changeTitle}>
                            {i18next.t("shownumber")}
                          </span>
                          <a
                            id="telephoneNumb"
                            href={"tel:" + this.state.property.mobile}
                          ></a>
                          {/* </span> */}
                        </span>
                      </div>
                      <SideAds />
                    </div>

                    <div
                      className="modal fade chatModal"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true">
                      <div
                        className="modal-dialog modal-sm modal-dialog-centered"
                        style={{ width: "350px" }}>
                        <div className="modal-content">
                          <div
                            className="modal-header"
                            style={{ background: "#519fff" }}>
                            <h5 className="modal-title" style={{ color: "#fff" }}>
                              {i18next.t("meettheagent")}
                            </h5>
                            <a
                              id="closeChatWindow"
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></a>
                          </div>
                          <div className="modal-body">
                            <div className="sidebar-widget">
                              {/* Author Start */}
                              <div className="media sidebar-author listing-agent">
                                <a href="#">
                                  <img
                                    src={
                                      baseurlImg +
                                      "/uploads/profiles/" +
                                      this.state.property.profile
                                    }
                                    alt="agent"
                                  />
                                </a>
                                <div className="media-body">
                                  <h6>
                                    {" "}
                                    <Link to="">
                                      {lang == "en"
                                        ? this.state.property.name_en
                                        : this.state.property.name_ar}
                                    </Link>{" "}
                                  </h6>
                                  <span> <a style={{}} href={"/agencies/" + this.state.property.agency_id}>
                                    {lang == "en" ? this.state.property.agency_name_en : this.state.property.agency_name_ar}</a>
                                  </span>
                                </div>
                              </div>
                              <div id="messageWindow" className="dash-msg-content  col-sm-10 ">
                                {this.state.messages.map((message) => (
                                  <>
                                    {message.reciever == activeUser ? (
                                      <div className="message-plunch">
                                        <div className="sender-name">
                                          <span className="message-moment-sender">
                                            <Moment fromNow>
                                              {message.created_at}
                                            </Moment>
                                          </span>
                                          <span className="bold">
                                            {this.state.recieverUserName}
                                          </span>
                                        </div>
                                        <div className="dash-msg-text">
                                          <p>{message.message}</p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="message-plunch me">
                                        <div className="reciever-name">
                                          <span className="message-moment-reciever">
                                            <Moment fromNow>
                                              {message.created_at}
                                            </Moment>
                                          </span>
                                          <span>Me</span>
                                        </div>
                                        <div className="dash-msg-text">
                                          <p>{message.message}</p>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ))}
                                <span className="error">
                                  {this.state.errors["message"]}
                                </span>
                                <div id="messageReply" className="message-reply">
                                  <textarea
                                    onChange={this.messageSendHandler}
                                    cols={40}
                                    rows={1}
                                    name="message"
                                    className="form-control with-light"
                                    value={this.state.messageBody}
                                    placeholder="Your Message"
                                    defaultValue={""}
                                  />
                                  <button
                                    style={{ color: "#fff", width: "0.5rem%" }}
                                    onClick={this.submitMessage}
                                    type="button"
                                    className="btn theme-bg" >
                                    <i className="right fa fa-paper-plane"></i>
                                  </button>

                                </div>
                              </div>
                              {/* Author End */}
                              {/* Contact Start */}
                              {/* <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.property.email}
                                placeholder={this.state.property.email}
                                name="email"
                                disabled
                              />
                            </div> */}
                              {/* <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value="Logged In user Phone"
                                placeholder="Logged In user Phone"
                                name="phone"
                                defaultValue
                                disabled
                              />
                            </div> */}
                              {/* <div className="sendChat">
                                <div className="form-group">
                                  <textarea
                                    onChange={this.messageInputOnChange}
                                    name="message"
                                    rows={3}
                                    placeholder="Hi, I found your property on Find Properties. Please contact me. Thank you."
                                    className="form-control"
                                    // defaultValue={""}
                                  />
                                </div>
                                <button
                                  onClick={this.submitMessage.bind(
                                    this,
                                    this.state.property.agent_id
                                  )}
                                  className="btn-custom primary light btn-block"
                                >
                                  {i18next.t("sendmessage")}
                                </button>
                              </div> */}
                              {/* Contact End */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Similar Property */}
                    <div className="sidebar-widgets">
                      {this.state.property.price > 0 && this.state.property.purpose == 2 ? (
                        <MortageCalculator price={this.state.property.price} />
                      ) : null}
                      {/* {console.log("passed price", this.state.property.price)} */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>

      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default connect(null, mapDispatchToProps)(SingleProperty);