import React, { Component } from 'react'
import Navigation from './Navigation'
import i18next from 'i18next'
import $ from 'jquery'
import MobileMenu from './MobileMenu'



// console.log("urlEnglish", urlArabic)
export default class HeaderComponent extends Component {
  
  toggleLanguage = () => {
    const { isArabic } = this.props;
    const currentPath = window.location.pathname;

    if (isArabic) {
      // Remove "/ar" segment for English version
      const newPath = currentPath.replace('/ar', '');
      window.location.href = newPath || '/'; // Redirect to root if newPath is empty
    } else if (currentPath.startsWith('/ar')) {
      // Remove "/ar" segment for English version
      const newPath = currentPath.replace('/ar', '');
      window.location.href = newPath || '/'; // Redirect to root if newPath is empty
    } else {
      // Add "/ar" segment for Arabic version
      const newPath = `/ar${currentPath}`;
      window.location.href = newPath;
    }
  };
  // componentDidMount() {
  //   console.log('componentDidMount props:', this.props.lang);
  // }
  getContent = (lang) => {

    if (lang === 'ar') {
        return {
            title: "البحث عن العقارات - أكبر بوابة عقارات للإيجار والبيع في الإمارات العربية المتحدة",
            description: 'ابحث عن عقارات للإيجار والبيع في دبي والشارقة وعجمان وأبو ظبي وجميع الإمارات العربية المتحدة | استكشف أكثر من 1000 شقة ، فيلا ، تاون هاوس ، مكتب ، أرض ، معسكر عمل.',
            keywords: "ابحث عن العقارات، ابحث عن العقارات في عجمان، ابحث عن العقارات في الإمارات العربية المتحدة، ابحث عن العقارات في دبي، ابحث عن العقارات في الشارقة، موقع العقارات في الإمارات العربية المتحدة، بوابة العقارات في الإمارات العربية المتحدة، ابحث عن العقارات، العقارات، الشقق، الشقق، الفيلا، إيجار العقارات، بيع العقارات ، مشاركة الممتلكات، والإقامة، والشقق الرخيصة، والشقق الميزانية، شقة الميزانية",
            language: "https://www.findproperties.ae/ar/"
        };
    } else {
        return {
            title: "Find Properties - UAE's largest property portal for rent and sale",
            description: 'Find properties for rent and sale in Dubai, Sharjah, Ajman, Abu Dhabi, and all UAE | Explore 1000+ Flats, villas, townhouse, office, land, labor camp.',
            keywords: "Find Properties, Find properties in Ajman, Find properties in UAE, Find properties in Dubai, Find properties in Sharjah, Property website in UAE, Property portal in UAE, findproperties, property, properties, flats, apartments, villa, rent property, sale property, share property, accomodation, cheap flats, budget flats, budget apartment",
            language: "https://www.findproperties.ae/"
        };
    }
};

  changeToArabic (e){
  }
  changeToEnglish (e){
  }
    // changeToArabic (e){

    //   console.log("url", decodeURI(window.location.href))
    //   var urlLoc =  window.location.href
                
    //   //find the index of nth occurernce of string in string
    //   var occurenceIndex= urlLoc.split("/", 3).join("/").length;
    
    //   let afterLanguae;
      
    //   if(urlLoc.split("/")[4] === undefined ) {
    //     afterLanguae =""
    //   } 
    //   else {
    //     afterLanguae =urlLoc.substring(occurenceIndex, urlLoc.length)
    //   }
    

    //   var urlArabic= urlLoc.substring(0, occurenceIndex) + "/ar" +afterLanguae 
    //   var urlEnglish = urlLoc.split("/ar")[0] + urlLoc.split("ar")[1]

    //     e.preventDefault();
    //     const oldlang = localStorage.getItem("lang");
    //     if(oldlang !="ar"){
    //       localStorage.setItem("lang","ar");
    //       if (urlLoc.includes("for-rent") ) {
    //         window.location.href = "/ar/للايجار/عقارات/الامارات";
    //       }else if(urlLoc.includes("for-sale")) {
    //         window.location.href = "/ar/للبيع/عقارات/الامارات";
    //       }
    //       else if(urlLoc.includes("for-sharing")){
    //         window.location.href = "/ar/سكن-مشترك/عقارات/الامارات";
    //       }
              
    //       else if (urlLoc.includes("blogs") ) {
    //          window.location.href = "/ar/blogs"
    //       } 
    //       else if (urlLoc.includes("favoirites")) {
    //         window.location.href = "/ar/favoirites";
    //       } 
    //       else if (urlLoc.includes("my-ads")) {
    //         window.location.href = "/ar/my-ads";
    //       } 
    //       else if (urlLoc.includes("profileSetting")) {
    //         window.location.href = "/ar/profileSetting";
    //       } 
    //      else {
    //          window.location.href = urlArabic
    //       }
    //   }    
    //   else {
    //      window.location.reload();
    //     // window.location.href = urlArabic;
    //   }
    // }
    // changeToEnglish (e){
      

// var urlLoc =  window.location.href
          
// //find the index of nth occurernce of string in string
// var occurenceIndex= urlLoc.split("/", 3).join("/").length;
// let afterLanguae;

// if(urlLoc.split("/")[4] === undefined ) {
//   afterLanguae =""
// } 
// else {
//   afterLanguae = urlLoc.substring(occurenceIndex, urlLoc.length)
 
// }

// var urlArabic= urlLoc.substring(0, occurenceIndex) + "/ar" +afterLanguae 
// var urlEnglish = urlLoc.split("/ar")[0] + urlLoc.split("ar")[1]


//       e.preventDefault();
//       const oldlang = localStorage.getItem("lang");
//       if(oldlang !="en"){
//           localStorage.setItem("lang","en");
//           var urlLoc =  decodeURI(window.location.href)
//           var urlSplit = urlLoc.split("/ar")
//         //   window.location.reload();
//          if (urlLoc.includes("للايجار")) {
//              window.location.href = "/for-rent/properties/uae";
//           } 
//           else if(urlLoc.includes("للبيع") ) {
//             window.location.href = "/for-sale/properties/uae";

//           }
//           else if(urlLoc.includes("سكن-مشترك")){
//             window.location.href = "/for-sharing/properties/uae";
//           }
//           else if (urlLoc.includes("/ar/blogs")) {
//             window.location.href = "/blogs/";
//           } 
//           else if (urlLoc.includes("/ar/favoirites")) {
//             window.location.href = "/favoirites/";
//           } 
//           else if (urlLoc.includes("/ar/my-ads")) {
//             window.location.href = "/my-ads/";
//           } 
//           else if (urlLoc.includes("/ar/profileSetting")) {
//             window.location.href = "/profileSetting/";
//           } 
          
//           else {
//              window.location.href = urlEnglish
//           }

//       }
//       else {
//         window.location.reload();
//             // window.location.href = urlSplit[0]+urlSplit[1];
//        }
    // }
    render() {
      const { lang } = this.props;
      console.log("Current lang:", lang); // Add this line to log the lang prop
      const content = this.getContent(lang);

           return (
            <header className="main-header"  dir={lang=="en"?'ltr':'rtl'}>
                {/* Top Header Start */}
                <div className="top-header">
                <div className="container">
                    <div className="top-header-inner">
                    <ul className="social-media">
                        <li> <a target="_blank" href="https://www.facebook.com/findpropertiesuae"> <i className="fab fa-facebook-f" /> </a> </li>
                        <li> <a target="_blank" href="https://www.instagram.com/findpropertiesofficial/"> <i className="fab fa-instagram" /> </a> </li>
                    </ul>
                    <div className="language-selector" style={{margin: '0 7px'}}>
                        {lang=='en'?<button onClick={this.toggleLanguage} style={{padding: '2px'}}  className="dropdown-item" href="" >
                           العربية</button>:                        
                            <button onClick={this.toggleLanguage} style={{padding: '2px'}} className="dropdown-item" href="" >
                              English</button>}

                    </div>      
                    <ul className="top-header-nav">
                          {/* <li className="nav-item dropdown">
                            <button style={{color: '#000', padding: '5px', lineHeight: '1.8rem!important'}} className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {lang=="en"?<><span className="flag-icon flag-icon-us"> </span> English</>:<><span className="flag-icon flag-icon-ae"> </span> العربية</>} </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <a onClick={this.changeToEnglish} style={{padding: '2px'}} className="dropdown-item" href="" ><span className="flag-icon flag-icon-us"> </span>  English</a>
                                <a onClick={this.changeToArabic} style={{padding: '2px'}}  className="dropdown-item" href="" ><span className="flag-icon flag-icon-ae"> </span>  العربية</a>
                            </div>
                        </li> */}
                         {/* <li> <a href="login.html"> {i18next.t("login")}</a> </li>
                        <li>&nbsp;/&nbsp;</li>
                        <li><a href="register.html"> {i18next.t("signup")}</a> </li> */}
                    </ul>
                    </div>
                </div>
                </div>
                {/* Top Header End */}
                <Navigation />
                <MobileMenu />

            </header>
        )
    }
}
