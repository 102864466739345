import React, { Component } from 'react'
import { ReactDOM } from 'react';
import i18next from "i18next"


class MortageCalculator extends Component {
    constructor(props){
        super(props);
        this.state={
          principal: this.props.price,
          rate: 2.75,
          term: 25,
          downpayment: 0,
          payment: 0

        }
    }
    mortgageCalc =(rate, term, principal, downpayment)=> {
        //findDOMNode to pull entered values into function   
        // console.log("rate", rate);
        // console.log("term", term)
        // console.log("price", principal)
        // console.log("downpayment", downpayment)

        //Can use a whole number percentage or decimal
        // const rate= this.state.rate
        // const term = this.state.term
        // const principal= this.state.principal
        // alert(rate)

        if(rate > 1){
            rate = rate * 0.01;
        }
        else if(0<rate<1) {
            rate = rate;  
        }else {
            rate = 0;
        }
        //Can accept term in years or months
        // if(term <= 30){
        //     term = term * 12;
        // }else{
        //     term = term;
        // }
        var principal= principal - downpayment
        var monthlyRate = rate/12;
        var factor = Math.pow(monthlyRate + 1, term);
        var numerator = monthlyRate * factor;
        var denominator = factor - 1;
        var quotient =  numerator/denominator;
        var payment = principal * quotient;
        // console.log('$' + payment.toFixed(2));
        return payment.toFixed(2);
      }

     componentDidMount(){
       this.setState({payment: this.mortgageCalc(this.state.rate , this.state.term, this.state.principal, this.state.principal*.1 )});      

     }
      handleChangePrincipal =(e) => {
        e.preventDefault();
        const principal = e.target.value;
    
        if (principal>0) {
          this.setState({payment: this.mortgageCalc(this.state.rate, this.state.term, principal, this.state.downpayment), principal: principal});
        }else {
            this.setState({principal: 0})
        }
      }
      handleChangeRate =(e) =>  {
        e.preventDefault();
        // const regex = new RegExp(/[^0-9]/, 'g');
         const rate = e.target.value;
    
          if (rate >0) {
            this.setState({payment: this.mortgageCalc(rate , this.state.term, this.state.principal, this.state.downpayment ), rate: rate});      
          }else {
            this.setState({rate: 0})
          }
    }
     handleChangeTerm =(e) =>  {
        e.preventDefault();
          this.setState({payment: this.mortgageCalc(this.state.rate, e.target.value === null?1:e.target.value, this.state.principal, this.state.downpayment), term: e.target.value.length == 0?0:e.target.value});
    }
     handleChangeDP =(e) =>  {
        e.preventDefault();
        this.setState({payment: this.mortgageCalc(this.state.rate, this.state.term, this.state.principal,  e.target.value ), downpayment: e.target.value });
     }
     
    render() {
        return (
          
            <div className="form-group" style={{background: '#fff',borderRadius: '9px', padding: '20px'}}>
                <h4> {i18next.t("mortagecalculator")} </h4>
                <form className="form-group">
                 <label style={{color: '#101737', marginTop: '7px', marginBottom: '0px'}}>{i18next.t("totalprice")}</label>
                <input type="number"  min="0" className="form-control" ref="principal"  value={this.state.principal} onChange={this.handleChangePrincipal} />
                <label style={{color:  '#101737', marginTop: '7px', marginBottom: '0px'}}>{i18next.t("loan")}</label>
                <input type="number" min="0"  className="form-control" ref="rate" defaultValue={this.state.term} onChange={this.handleChangeTerm} defaultValue="25"/>
                <label style={{color: '#101737', marginTop: '7px', marginBottom: '0px'}}>{i18next.t("interestrate")}</label>
                <input type="number"  min="0" className="form-control" ref="term"  value={this.state.rate} onChange={this.handleChangeRate} defaultValue="2.75"/>
                <label style={{color: '#101737', marginTop: '7px', marginBottom: '0px'}}>{i18next.t("downpayment")}</label>
                <input type="number"  min="0" className="form-control" ref="term" defaultValue={this.state.downpayment} onChange={this.handleChangeDP} placeholder={this.props.price*.1}/>
                
                {/* {this.state.payment==0?null: */}
                    <div>
                        <p>{i18next.t("estimatedmonthly")}</p>
                        <p style={{fontWeight: '700', textAlign: 'center', fontSize: '20px'}}>{this.state.payment +" "+ i18next.t("aed")}</p>
                    </div>
                 {/* } */}
                </form>        
          </div> )
        
    }
}

export default MortageCalculator
