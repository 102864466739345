import React, { Component } from 'react'
import jQuery from 'jquery'
import './addlisting.css'
import i18next from 'i18next'
import Swal from 'sweetalert2'
import cookie from 'js-cookie'
import JoditEditor from "jodit-react";
import toast, { Toaster } from 'react-hot-toast';
import ClipLoader from "react-spinners/ClipLoader";
import Select from 'react-select';
import Helmet from "react-helmet";

import $ from 'jquery'
import API from '../API'
import { baseurlImg } from '../Components/BaseUrl'
import { store } from '../store'
let map;
let service;
let infowindow;
let lang = localStorage.getItem("lang") || "en";



const config = {
    readonly: false,
    uploader: {
        url: 'https://services.findproperties.ae/api/uploader'
    },
    toolbar: false,
}

const role = cookie.get("role");
const imagesArray = [];
class EditAgentProperty extends Component {

    constructor(props) {
        super(props);
        this.state = {
            beds: [],
            baths: [],
            alllocations: [],
            emirates: [],
            areas: [],
            subareas: [],
            property: {},
            medias: [],
            categories: [],
            healthandfitness: [],
            amenitiesfeatures: [],
            miscellaneous: [],
            securityandtechnology: [],
            propertytypes: [],
            agents: [],
            agent: {},
            propertyType_en: "",
            propertyType_ar: "",
            propertytype_id: "0",
            propertyTypeDisabld: "disabled",
            rentOrSale: "",
            bathroomfeature: "row d-none",
            furnishstatusfields: "mb-3 d-none",
            rentfileds: "row d-none",
            occupancyfiled: "row d-none",
            readyoffPlanfield: "row d-none",
            landfeatures: "row d-none",
            location_name_en: "",
            location_name_ar: "",
            address: "",
            address_ar: "",
            country: "",
            country_ar: "",
            emirate: "",
            emirate_ar: "",
            area: "",
            area_ar: "",
            subarea_en: "",
            subarea_ar: "",
            streetorbuild: "",
            streetorbuild_ar: "",
            firnished: 0,
            checked: false,
            gym: false,
            swimmingpool: false,
            medical: false,
            sauna_steem: false,
            disability_access: false,
            built_in_wardobes: false,
            barbaque_area: false,
            cafeteria: false,
            kitchen: false,
            central_ac: false,
            garden: false,
            furnished: false,
            disposal: false,
            kidsplay: false,
            parking: false,
            balcony: false,
            atm_acxeess: false,
            water_view: false,
            landmark_view: false,
            day_care: false,
            security_guard: false,
            cctv: false,
            internet: false,
            satelite_cable_tv: false,
            title_en: "",
            title_ar: "",
            description_en: "",
            description_ar: "",
            square_area: 0,
            price: 0,
            bedroom: -1,
            bathroom: 0,
            rent_frequency: 0,
            completion_status: 0,
            ownership_status: 0,
            min_contract_period: 0,
            vacating_period: 0,
            maintainance_fee: 0,
            paid_by: 0,
            permit_number: 0,
            videoRows: [],
            allfiles: [],
            video_source: "",
            video_title: "",
            video_link: "",
            purpose: 3,
            image: "",
            featuredimage: null,
            imageShow: null,
            agentLoading: false,
            lat: 25.1972018,
            lng: 55.2721877,
            selectedEmirate: 0,
            sele: 0,
            selectedArea: 0,
            selectedSubarea: 0,
            selectedAreaOption: {},
            selectedSubareaOption: {},
            mapvalue: "",
            selectPlace: {},

        }
        this.mapContainer = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleFeaturedImage = this.handleFeaturedImage.bind(this);

    }



    componentDidMount() {




        // var loadScript = function(src) {
        //   var tag = document.createElement('script');
        //   tag.async = false;
        //   tag.src = src;
        //   document.getElementsByTagName('body')[0].appendChild(tag);
        //   }
        //   loadScript('./vendor/global/global.min.js')
        //   loadScript('./js/dashboard/dotted-map-init.js')
        //   loadScript('./js/custom.min.js')
        this.fetchData();
        jQuery(document).ready(function () {
            jQuery('.form-wizard-next-btn').click(function () {
                var parentFieldset = jQuery(this).parents('.wizard-fieldset');
                var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
                var next = jQuery(this);
                var nextWizardStep = true;

                parentFieldset.find('.wizard-required').each(function () {
                    var thisValue = jQuery(this).val();

                    var category_id = jQuery("#category_id").val();
                    var type_id = jQuery("#type_id").val();
                    var purpose = jQuery("#purpose").val();
                    var agent_id = jQuery("#agent_id").val();

                    var location_selected = jQuery("#location_selected").val();
                    if (location_selected < 1) {
                        jQuery("#location_selected").css("border", "2px solid red")
                        nextWizardStep = false;
                    } else {
                        jQuery("#location_selected").css("border", "2px solid #eee")
                    }
                    if (category_id < 1) {

                        jQuery(this).siblings(".wizard-category-error").slideDown();
                        nextWizardStep = false;


                    } else {

                        jQuery(this).siblings(".wizard-category-error").slideUp();
                    }



                    if (type_id < 1) {

                        jQuery(this).siblings(".wizard-type-error").slideDown();
                        nextWizardStep = false;
                    } else {

                        jQuery(this).siblings(".wizard-type-error").slideUp();
                    }

                    if (purpose < 1) {
                        jQuery(this).siblings(".wizard-purpose-error").slideDown();
                        nextWizardStep = false;
                    } else {
                        jQuery(this).siblings(".wizard-purpose-error").slideUp();
                    }

                    if (agent_id < 1) {
                        jQuery(this).siblings(".wizard-agent-error").slideDown();
                        nextWizardStep = false;
                    } else {
                        jQuery(this).siblings(".wizard-agent-error").slideUp();
                    }

                    // if( type_id < 1) {
                    //     jQuery(this).siblings(".wizard-form-error").slideDown();
                    //     nextWizardStep = false;
                    // }else
                    // if( purpose < 1) {
                    //     jQuery(this).siblings(".wizard-form-error").slideDown();
                    //     nextWizardStep = false;
                    // }else
                    // if( agent_id < 1) {
                    //     jQuery(this).siblings(".wizard-form-error").slideDown();
                    //     nextWizardStep = false;
                    // }else


                    if (thisValue == "") {
                        jQuery(this).siblings(".wizard-form-error").slideDown();
                        nextWizardStep = false;
                    }
                    else {
                        jQuery(this).siblings(".wizard-form-error").slideUp();
                    }
                });
                if (nextWizardStep) {
                    next.parents('.wizard-fieldset').removeClass("show", "400");
                    currentActiveStep.removeClass('active').addClass('activated').next().addClass('active', "400");
                    next.parents('.wizard-fieldset').next('.wizard-fieldset').addClass("show", "400");
                    jQuery(document).find('.wizard-fieldset').each(function () {
                        if (jQuery(this).hasClass('show')) {
                            var formAtrr = jQuery(this).attr('data-tab-content');
                            jQuery(document).find('.form-wizard-steps .form-wizard-step-item').each(function () {
                                if (jQuery(this).attr('data-attr') == formAtrr) {
                                    jQuery(this).addClass('active');
                                    var innerWidth = jQuery(this).innerWidth();
                                    var position = jQuery(this).position();
                                    jQuery(document).find('.form-wizard-step-move').css({ "left": position.left, "width": innerWidth });
                                } else {
                                    jQuery(this).removeClass('active');
                                }
                            });
                        }
                    });
                }
            });
            //click on previous button
            jQuery('.form-wizard-previous-btn').click(function () {
                var counter = parseInt(jQuery(".wizard-counter").text());;
                var prev = jQuery(this);
                var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
                prev.parents('.wizard-fieldset').removeClass("show", "400");
                prev.parents('.wizard-fieldset').prev('.wizard-fieldset').addClass("show", "400");
                currentActiveStep.removeClass('active').prev().removeClass('activated').addClass('active', "400");
                jQuery(document).find('.wizard-fieldset').each(function () {
                    if (jQuery(this).hasClass('show')) {
                        var formAtrr = jQuery(this).attr('data-tab-content');
                        jQuery(document).find('.form-wizard-steps .form-wizard-step-item').each(function () {
                            if (jQuery(this).attr('data-attr') == formAtrr) {
                                jQuery(this).addClass('active');
                                var innerWidth = jQuery(this).innerWidth();
                                var position = jQuery(this).position();
                                jQuery(document).find('.form-wizard-step-move').css({ "left": position.left, "width": innerWidth });
                            } else {
                                jQuery(this).removeClass('active');
                            }
                        });
                    }
                });
            });
            //click on form submit button
            jQuery(document).on("click", ".form-wizard .form-wizard-submit", function () {
                var parentFieldset = jQuery(this).parents('.wizard-fieldset');
                var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
                parentFieldset.find('.wizard-required').each(function () {
                    var thisValue = jQuery(this).val();

                    if (thisValue == "") {
                        jQuery(this).siblings(".wizard-form-error").slideDown();
                    }
                    else {
                        jQuery(this).siblings(".wizard-form-error").slideUp();
                    }
                });
            });
            // focus on input field check empty or not
            jQuery(".form-control").on('focus', function () {
                var tmpThis = jQuery(this).val();
                if (tmpThis == '') {
                    jQuery(this).parent().addClass("focus-input");
                }
                else if (tmpThis != '') {
                    jQuery(this).parent().addClass("focus-input");
                }
            }).on('blur', function () {
                var tmpThis = jQuery(this).val();
                if (tmpThis == '') {
                    jQuery(this).parent().removeClass("focus-input");
                    jQuery(this).siblings('.wizard-form-error').slideDown("3000");
                }
                else if (tmpThis != '') {
                    jQuery(this).parent().addClass("focus-input");
                    jQuery(this).siblings('.wizard-form-error').slideUp("3000");
                }
            });
            jQuery("#title_en").keypress(function (event) {
                var ew = event.which;
                if (ew == 32)
                    return true;
                if (48 <= ew && ew <= 57)
                    return true;
                if (65 <= ew && ew <= 90)
                    return true;
                if (97 <= ew && ew <= 122)
                    return true;
                return false;
            });

            jQuery("#description_en").keypress(function (event) {
                var ew = event.which;
                if (ew == 32)
                    return true;
                if (48 <= ew && ew <= 57)
                    return true;
                if (65 <= ew && ew <= 90)
                    return true;
                if (97 <= ew && ew <= 122)
                    return true;
                return false;
            });
        });

    }
    _suggestionSelect(result, lat, lng, text) {
        console.log(result, lat, lng, text)
    }
    rentOnChange_Handle = (e) => {
        e.preventDefault()
        e.target.value == 1 ? this.setState({ rentfileds: "row" }) : this.setState({ rentfileds: "d-none" })
        this.setState({ purpose: e.target.value })
    }
    propertyType_enHandle = (e) => {


        this.setState({ propertytype_id: e.target.value })

        let id = e.target.value;
        API.get("/propertytypeById/" + id)
            .then(response => {
                // console.log(response.data)
                this.setState({
                    occupancyfiled: response.data.occupancy == 1 ? "mb-3 d-block" : "mb-3 d-none",
                    furnishstatusfields: response.data.furnishedornot == 1 ? "mb-3 d-block" : "mb-3 d-none",
                    readyoffPlanfield: response.data.readyoffplan == 1 ? "mb-3 d-block" : "d-none",
                    bathroomfeature: response.data.bedandbath == 1 ? "row d-block" : "d-none",
                    categoryNameEn: response.data.name_en,
                    categoryNameAr: response.data.name_ar,
                })
            })
            .catch(err => console.log("error"))



    }
    category_enHandle = (e) => {
        e.preventDefault();
        const token = cookie.get("token")

        this.setState({ categoryId: e.target.value })
        this.setState({ propertyTypeDisabld: "" })

        if (e.target.value == 23) {
            this.setState({ furnishedStatus: "mb-3 d-block" })
        }
        API.get("/propertytypeByCatId/" + e.target.value)
            .then(response => {
                //    console.log(response.data)
                this.setState({ isLoading: false, propertytypes: response.data.propertytypes })
            })
            .catch(err => console.log("error"))
    }

    // handleChecked= (e) =>{
    //     this.setState({firnished:!this.state.firnished})
    //  }
    // Amenties

    handleHealthAndFitness = (health, e) => {

        let index = this.state.healthandfitness.indexOf(health);
        console.log("health feature", health);

        var i;
        for (i = 0; i < this.state.healthandfitness.length; i++) {
            if (i == index) {
                this.state.healthandfitness[i].status = !this.state.healthandfitness[i].status == true ? 1 : 0
                this.setState({})

            }
        }

    }

    handleFeatures = (feature, e) => {

        let index = this.state.amenitiesfeatures.indexOf(feature);
        console.log(feature);

        var i;
        for (i = 0; i < this.state.amenitiesfeatures.length; i++) {
            if (i == index) {
                this.state.amenitiesfeatures[i].status = !this.state.amenitiesfeatures[i].status == true ? 1 : 0
                this.setState({})

            }
        }

    }
    handleMiscellaneous = (miscell, e) => {

        let index = this.state.miscellaneous.indexOf(miscell);

        var i;
        for (i = 0; i < this.state.miscellaneous.length; i++) {
            if (i == index) {
                this.state.miscellaneous[i].status = !this.state.miscellaneous[i].status == true ? 1 : 0
                this.setState({})

            }
        }

    }

    handleSecurityandTechnology = (secandtech, e) => {

        let index = this.state.securityandtechnology.indexOf(secandtech);

        var i;
        for (i = 0; i < this.state.securityandtechnology.length; i++) {
            if (i == index) {
                this.state.securityandtechnology[i].status = !this.state.securityandtechnology[i].status == true ? 1 : 0
                this.setState({})

            }
        }

    }
    handleGym = (e) => {
        this.setState({ gym: !this.state.gym })
    }
    handleSwimmingbool = (e) => {
        this.setState({ swimmingpool: !this.state.swimmingpool })
    }
    handleMedical = (e) => {
        this.setState({ medical: !this.state.medical })
    }
    handleSaunasteem = (e) => {
        this.setState({ sauna_steem: !this.state.sauna_steem })
    }
    handleDisabilityaccess = (e) => {
        this.setState({ disability_access: !this.state.disability_access })
    }
    handleBuiltinwardobes = (e) => {
        this.setState({ built_in_wardobes: !this.state.built_in_wardobes })
    }
    handleBarbaquearea = (e) => {
        this.setState({ barbaque_area: !this.state.barbaque_area })
    }
    handleCafeteria = (e) => {
        this.setState({ cafeteria: !this.state.cafeteria })
    }

    handleKitchen = (e) => {
        this.setState({ kitchen: !this.state.kitchen })
    }
    handleCentralac = (e) => {
        this.setState({ central_ac: !this.state.central_ac })
    }

    handleGarden = (e) => {
        this.setState({ garden: !this.state.garden })
    }
    handleFurnished = (e) => {
        this.setState({ furnished: !this.state.furnished })
    }
    handleDisposal = (e) => {
        this.setState({ disposal: !this.state.disposal })
    }
    handleKidsplay = (e) => {
        this.setState({ kidsplay: !this.state.kidsplay })
    }
    handleParking = (e) => {
        this.setState({ parking: !this.state.parking })
    }
    handleBalcony = (e) => {
        this.setState({ balcony: !this.state.balcony })
    }

    handleAtmacxeess = (e) => {
        this.setState({ atm_acxeess: !this.state.atm_acxeess })
    }
    handleWaterview = (e) => {
        this.setState({ water_view: !this.state.water_view })
    }
    handleLandmarkview = (e) => {
        //console.log(e.target.checked)
        this.setState({ landmark_view: !this.state.landmark_view })
    }
    handleDaycare = (e) => {
        //console.log(e.target.checked)
        this.setState({ day_care: !this.state.day_care })
    }

    handleSecurityguard = (e) => {
        //console.log(e.target.checked)
        this.setState({ security_guard: !this.state.security_guard })
    }
    handleCctv = (e) => {
        //console.log(e.target.checked)
        this.setState({ cctv: !this.state.cctv })
    }
    handleInternet = (e) => {
        //console.log(e.target.checked)
        this.setState({ internet: !this.state.internet })
    }
    handleSatelitecabletv = (e) => {
        //console.log(e.target.checked)
        this.setState({ satelite_cable_tv: !this.state.satelite_cable_tv })
    }

    // propery details
    handleTitleEn = (e) => {
        e.preventDefault()
        console.log(e.target.value)
        this.setState({ title_en: e.target.value })
    }
    handleTitleAr = (e) => {
        this.setState({ title_ar: e.target.value })
    }
    handleDescriptionEn = (e) => {
        this.setState({ description_en: e.target.value })
    }
    handleDescriptionAr = (e) => {
        this.setState({ description_ar: e.target.value })
    }
    handleSquarearea = (e) => {
        this.setState({ square_area: e.target.value })
    }
    handlePrice = (e) => {

        this.setState({ price: e.target.value })
    }
    handleBedroom = (e) => {

        this.setState({ bedroom: e.target.value })
    }
    handleBathroom = (e) => {

        this.setState({ bathroom: e.target.value })
    }
    handleRentFrequency = (e) => {
        this.setState({ rent_frequency: e.target.value })
    }

    handleMinimumcontractperiod = (e) => {
        this.setState({ min_contract_period: e.target.value })
    }
    handleVactingPeriod = (e) => {
        this.setState({ vacating_period: e.target.value })
    }
    handleMaintainaceFee = (e) => {
        this.setState({ maintainance_fee: e.target.value })
    }
    handlePaidby = (e) => {
        this.setState({ paid_by: e.target.value })
    }
    handlePermitNumber = (e) => {
        this.setState({ permit_number: e.target.value })
    }
    handleVideo_source = (e) => {
        e.preventDefault()
        this.setState({ video_source: e.target.value })
    }
    handleVideo_link = (e) => {
        e.preventDefault()
        this.setState({ video_link: e.target.value })
    }
    handleVideo_title = (e) => {
        e.preventDefault()
        this.setState({ video_title: e.target.value })
    }


    fetchData = () => {
        //handle select on Change
        const token = cookie.get("token")
        this.setState({ isLoading: true })

        API.get("/bedsandbaths")
            .then(response => {
                this.setState({ beds: response.data.beds, baths: response.data.baths })
            })
            .catch(err => console.log("error"))

        this.setState({ isLoading: true })

        API.get("/api/alllocations")
            .then(response => {
                this.setState({ alllocations: lang == "en" ? response.data.locations : response.data.locations_ar })
            })
            .catch(err => console.log("error"))





        let id = this.props.match.params.id;
        API.get("/properties/" + id)
            .then(response => {
                var arraaa = response.data.propertysubarea == "" ? response.data.propertysubarea : response.data.propertysubarea.label
                var valum = response.data.propertysubarea == "" ? 0 : response.data.propertysubarea.value

                this.setState({
                    isLoading: false,
                    medias: response.data.medias, property: response.data.property, firnished: response.data.property.furnishing,
                    address: response.data.property.address, address_ar: response.data.property.address_ar,
                    title_en: response.data.property.title_en, title_ar: response.data.property.title_ar,
                    description_en: response.data.property.details_en, description_ar: response.data.property.details_ar,
                    country: response.data.property.country_en, country_ar: response.data.property.country_ar,
                    emirate: response.data.property.emirate_en, emirate_ar: response.data.property.emirate_ar,
                    area: response.data.property.area_en, area_ar: response.data.property.area_ar,
                    subarea_en: response.data.property.subarea_en, subarea_ar: response.data.property.subarea_ar,
                    streetorbuild: response.data.property.streetorbuild_en, streetorbuild_ar: response.data.property.streetorbuild_ar,
                    rent_frequency: response.data.property.rent_frequency, paid_by: response.data.property.paid_by,
                    completion_status: response.data.property.completion_status, ownership_status: response.data.property.ownership_status,
                    vacating_period: response.data.property.vacating_period,
                    square_area: response.data.property.area,
                    price: response.data.property.price,
                    bedroom: response.data.property.beds,
                    bathroom: response.data.property.baths,
                    // purpose: response.data.property.purpose,
                    featuredimage: response.data.property.featuredimage,
                    selectedEmirate: response.data.property.emirate_id, selectedArea: response.data.property.area_id,
                    selectedSubarea: valum,
                    selectedAreaOption: lang == "en" ? response.data.propertyarea : response.data.propertyarea_ar,
                    areas: lang == "en" ? response.data.currentareas : response.data.currentareas_ar,
                    selectedSubareaOption: lang == "en" ? response.data.propertysubarea : response.data.propertysubarea_ar,
                    subareas: lang == "en" ? response.data.currentsubareas : response.data.currentsubareas_ar,
                    // amenites
                    gym: response.data.propertyfeturedetail.gym == 1 ? true : false, swimmingpool: response.data.propertyfeturedetail.swimmingpool == 1 ? true : false, medical: response.data.propertyfeturedetail.medical == 1 ? true : false,
                    sauna_steem: response.data.propertyfeturedetail.saunasteam == 1 ? true : false, disability_access: response.data.propertyfeturedetail.disabilityaccess == 1 ? true : false,
                    built_in_wardobes: response.data.propertyfeturedetail.builtinwardobes == 1 ? true : false, barbaque_area: response.data.propertyfeturedetail.barbaquearea == 1 ? true : false,
                    cafeteria: response.data.propertyfeturedetail.cafeteria == 1 ? true : false, kitchen: response.data.propertyfeturedetail.kitchen == 1 ? true : false,
                    central_ac: response.data.propertyfeturedetail.centralac == 1 ? true : false, garden: response.data.propertyfeturedetail.garden == 1 ? true : false,
                    furnished: response.data.propertyfeturedetail.furnished == 1 ? true : false, disposal: response.data.propertyfeturedetail.disposal == 1 ? true : false,
                    kidsplay: response.data.propertyfeturedetail.kidsplay == 1 ? true : false, parking: response.data.propertyfeturedetail.parking == 1 ? true : false, balcony: response.data.propertyfeturedetail.balcony == 1 ? true : false,
                    atm_acxeess: response.data.propertyfeturedetail.atmacxeess == 1 ? true : false, water_view: response.data.propertyfeturedetail.waterview == 1 ? true : false,
                    landmark_view: response.data.propertyfeturedetail.landmarkview == 1 ? true : false, day_care: response.data.propertyfeturedetail.daycare == 1 ? true : false,
                    security_guard: response.data.propertyfeturedetail.securityguard == 1 ? true : false, cctv: response.data.propertyfeturedetail.cctv == 1 ? true : false,
                    internet: response.data.propertyfeturedetail.internet == 1 ? true : false, satelite_cable_tv: response.data.propertyfeturedetail.satelitecabletv == 1 ? true : false,

                    //  categoryId:response.data.property.category_id,
                    propertytype_id: response.data.property.propertytypes_id, rentfileds: response.data.property.purpose == 1 ? "row" : "row d-none",
                    healthandfitness: response.data.healthandfitness, amenitiesfeatures: response.data.features, miscellaneous: response.data.miscellaneous,
                    securityandtechnology: response.data.securityandtechnology,
                    video_source: response.data.property.video_source, video_title: response.data.property.video_title, video_link: response.data.property.video_link,
                    bathroomfeature: response.data.property.propertytypes_id == 1 ? "row d-block" : "d-none",
                    lat: response.data.property.lat,
                    lng: response.data.property.lng,
                    selectPlace: lang == "en" ? response.data.fulladdress : response.data.fulladdress_ar


                })

                $("#location_selected").val(response.data.fulladdress["label"])

                // this.mapfunction()

                this.initMap();



            })
            .catch(err => console.log("error"))


        API.get("/categories")
            .then(response => {
                this.setState({ isLoading: false, categories: response.data.categories })

            })
            .catch(err => console.log("error"))

        API.get("/agents")
            .then(response => {
                this.setState({ isLoading: false, agents: response.data.agents })

            })
            .catch(err => console.log("error"))
        API.get("/propertytypeForSharing")
            .then(response => {
                this.setState({ propertytypes: response.data.propertytypes })

                this.setState({
                    occupancyfiled: response.data.occupancy == 1 ? "mb-3 d-block" : "mb-3 d-none",
                    furnishstatusfields: response.data.furnishedornot == 1 ? "mb-3 d-block" : "mb-3 d-none",
                    landfields: response.data.landornot == 1 ? "mb-3 d-block" : "mb-3 d-none",
                    comandresfields: response.data.comandresornot == 1 ? "mb-3 d-block" : "mb-3 d-none",
                    readyoffPlanfield: response.data.readyoffplan == 1 ? "mb-3 d-block" : "d-none",
                    landfeatures: response.data.readyoffplan == 1 ? "row d-block" : "d-none",
                    bathroomfeature: response.data.bedandbath == 1 ? "row d-block" : "d-none",

                    categoryNameEn: response.data.name_en,
                    categoryNameAr: response.data.name_ar,
                })
            })
            .catch(err => console.log("error"))


    }

    saveData = (e) => {
        e.preventDefault();


        const token = cookie.get("token")
        let id = this.props.match.params.id;

        const data = {
            // category_id:this.state.categoryId,
            propertytypes_id: this.state.propertytype_id,
            lat: $("#lat").val(), lng: $("#lng").val(),
            firnished: this.state.firnished, 
            purpose: this.state.purpose,
            //Amenties
            gym: this.state.gym == true ? 1 : 0, swimmingpool: this.state.swimmingpool == true ? 1 : 0, medical: this.state.medical == true ? 1 : 0,
            sauna_steem: this.state.sauna_steem == true ? 1 : 0, disability_access: this.state.disability_access == true ? 1 : 0,
            built_in_wardobes: this.state.built_in_wardobes == true ? 1 : 0, barbaque_area: this.state.barbaque_area == true ? 1 : 0,
            cafeteria: this.state.cafeteria == true ? 1 : 0, kitchen: this.state.kitchen == true ? 1 : 0,
            central_ac: this.state.central_ac == true ? 1 : 0, garden: this.state.garden == true ? 1 : 0,
            furnished: this.state.furnished == true ? 1 : 0, disposal: this.state.disposal == true ? 1 : 0,
            kidsplay: this.state.kidsplay == true ? 1 : 0, parking: this.state.parking == true ? 1 : 0, balcony: this.state.balcony == true ? 1 : 0,
            atm_acxeess: this.state.atm_acxeess == true ? 1 : 0, water_view: this.state.water_view == true ? 1 : 0,
            landmark_view: this.state.landmark_view == true ? 1 : 0, day_care: this.state.day_care == true ? 1 : 0,
            security_guard: this.state.security_guard == true ? 1 : 0, cctv: this.state.cctv == true ? 1 : 0,
            internet: this.state.internet == true ? 1 : 0, satelite_cable_tv: this.state.satelite_cable_tv == true ? 1 : 0,
            // property details
            title_en: this.state.title_en, title_ar: this.state.title_ar,
            description_en: this.state.description_en, description_ar: this.state.description_ar,
            square_area: this.state.square_area, price: this.state.price,
            bedroom: this.state.bedroom, bathroom: this.state.bathroom,
            rent_frequency: this.state.rent_frequency, min_contract_period: this.state.min_contract_period,
            completion_status: this.state.completion_status, ownership_status: this.state.ownership_status,
            vacating_period: this.state.vacating_period, maintainance_fee: this.state.maintainance_fee,
            paid_by: this.state.paid_by, permit_number: this.state.permit_number,
            video_source: this.state.video_source, video_link: this.state.video_link, video_title: this.state.video_title,
            featuredimage: this.state.featuredimage,
            referencenumber: this.state.referencenumber,
            selectPlace: this.state.selectPlace,
            oldfeaturedimage: this.state.property.image,

        }


        this.setState({ isLoading: true })
        API.put("/agentproperties/" + id, data)
            .then(response => {
                console.log("after update", response.data)
                if (response.data > 0) {

                    const formData = new FormData()
                    formData.append("property_id", response.data);
                    for (let i = 0; i < this.state.image.length; i++) {
                        formData.append("images[]", this.state.image[i]);

                    }
                    API.post("/agentpropertiesuploads", formData)
                        .then(response => {
                            this.setState({ isLoading: false })
                            Swal.fire({
                                title: "Done!",
                                text: "Successfully updated.",
                                icon: "success",
                                timer: 2000,
                                button: false
                            })
                            this.props.history.push("/my-ads")
                        })
                        .catch(e => console.log("error from "))
                }
            })
            .catch(e => console.log("error"))
    }
    handleFeaturedImage = (e) => {
        this.setState({ isMax: false })
        var maxfilesize = 3145728;
        var filesize = e.target.files[0].size;
        if (filesize > maxfilesize) {
            // console.log("more than 1 m")
            this.setState({ isMax: true })
            return;
        }


        let files = e.target.files || e.dataTransfer.files;

        if (!files.length)
            return;
        this.createImage(files[0]);
        this.setState({ imageShow: URL.createObjectURL(e.target.files[0]) })
    }
    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                featuredimage: e.target.result
            })
        };
        reader.readAsDataURL(file);
    }
    handleChange = (e) => {
        this.setState({ isMaxUpload: false })
        var maxfilesize = 3145728;


        for (let i = 0; i < e.target.files.length; i++) {
            var filesize = e.target.files[i].size;
            if (filesize > maxfilesize) {

                lang == "en" ? toast.error("Please upload an image smaller than 3MB " + e.target.files[i].name) : toast.error("الرجاء تحميل صور اقل من 3 ميغا" + e.target.files[i].name)

                return;
            }
            imagesArray.push(e.target.files[i]);
        }
        this.setState({
            image: imagesArray,
        });
    }




    removeFile = (myfile, e) => {
        e.preventDefault();
        let index = this.state.image.indexOf(myfile)

        if (!myfile || myfile < 0) {
            return;
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    var mydata = this.state.image
                    var loopData = []
                    var i;
                    for (i = 0; i < mydata.length; i++) {
                        console.log("i", i)
                        if (i == index) {
                            mydata.splice(index, 1);
                        }
                    }
                    this.setState({ image: mydata })

                }
            })
        }


    }

    removeFileFromserver = (media, e) => {
        e.preventDefault();
        let index = this.state.medias.indexOf(media)

        const token = cookie.get("token")
        const data = { media: media };
        if (!media.id || media.id < 1) {
            return;
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    API.post("/deletemedia", data)
                        .then(response => {

                            var mydata = this.state.medias
                            var loopData = []
                            var i;
                            for (i = 0; i < mydata.length; i++) {
                                if (i == index) {
                                    mydata.splice(index, 1);
                                }
                            }
                            this.setState({ medias: mydata })


                        })
                        .catch(err => console.log(err))
                }
            })
        }
    }

    handleOnFurnished = (e) => {
        this.setState({ firnished: 1 })
    }

    handleOnUnFurnished = (e) => {
        this.setState({ firnished: 0 })
    }

    handleCompletionStatus = (e) => {
        e.preventDefault();

        this.setState({ completion_status: e.target.value })
    }

    handleOwnerStatus = (e) => {
        e.preventDefault();

        this.setState({ ownership_status: e.target.value })
    }

    buttonRender = () => {
        if (this.state.isLoading) {
            return <a href="javascript:;" className="form-wizard-submit rounded" >

                <ClipLoader color={"white"} loading={true} size={15} /> &nbsp;&nbsp;{lang == "en" ? "Proccessing" : "جاري معالجة البيانات"}

            </a>
        }
        return (
            <a href="javascript:;" className="form-wizard-submit rounded" onClick={this.saveData}>{lang == "en" ? "Submit" : "حفظ"}</a>

        )

    }

    handleLocation = (opt) => {

        if (opt.value > 0) {
            $("#location_selected").val(opt.label)
            this.setState({ selectPlace: opt })
            this.initMap();
        }
    }


    renderMap = () => {
        window.initMap = this.initMap;
    };
    initMap = () => {

        let service;
        let infowindow;
        const request = {
            query: $("#location_selected").val(),
            fields: ["name", "geometry"],
        };

        infowindow = new window.google.maps.InfoWindow();
        map = new window.google.maps.Map(
            document.getElementById("google-map"),
            {

                center: new window.google.maps.LatLng(this.state.lat, this.state.lng),
                zoom: 15,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                scrollwheel: false,
                draggable: true,
                gestureHandling: "cooperative",
                // styles: MAP_STYLES
            }
        );

        service = new window.google.maps.places.PlacesService(map);
        service.findPlaceFromQuery(request, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                for (let i = 0; i < results.length; i++) {
                    this.createMarker(results[0]);
                }

                map.setCenter(results[0].geometry.location);

                $("#lat").val(results[0].geometry.location.lat())
                $("#lng").val(results[0].geometry.location.lng())
            }
        });


    };
    createMarker(place) {
        if (!place.geometry || !place.geometry.location) return;

        const marker = new window.google.maps.Marker({
            map,
            position: place.geometry.location,
            draggable: true
        });

        window.google.maps.event.addListener(marker, 'dragend', function (event) {

            $("#lat").val(this.getPosition().lat())
            $("#lng").val(this.getPosition().lng())
        });

        map.addListener("click", (e) => {

            $("#lat").val(e.latLng.lat())
            $("#lng").val(e.latLng.lng())
            map.setCenter(marker.setPosition(e.latLng));
        });


    }


    render() {
        // const token = cookie.get("token")
        const userdata = store.getState(state => state);
        let user = userdata.auth.user
        const token = userdata.auth.token

        return (
            <div className="container-fluid">
                <Toaster />
                <div className="row">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{lang == "en" ? "Edit Property" : " تعديل البيانات"}</h3>
                        </div>
                        <div className="card-body">
                            <section className="wizard-section">
                                <div className="row ">

                                    <div className="">
                                        <div className="form-wizard">
                                            <form action method="post" role="form">
                                                <div className="form-wizard-header">

                                                    <ul className="list-unstyled form-wizard-steps clearfix">
                                                        <li className="active"><span>1</span></li>
                                                        <li><span>2</span></li>
                                                        <li><span>3</span></li>

                                                    </ul>
                                                </div>
                                                <fieldset className="wizard-fieldset show">
                                                    <h4>{lang == "en" ? "Property Information" : "تفاصيل العقار"}</h4>
                                                    {/* <div className="form-group">
      <select onChange={this.category_enHandle} class="form-select wizard-required" style={{ height: '2.75rem' }} aria-label="form-select"  id="category_id">
      <option value={this.state.property.category_id}>{lang=="en"?this.state.property.category_name_en:this.state.property.category_name_ar}</option>
                                      {this.state.categories.map((cate) => (
                                          <option value={cate.id}>{cate.name_en}</option>
                                      )
                                      )}
                                      </select>
        <div className="wizard-category-error" />
      </div> */}

                                                    <div className="form-group">
                                                        <label> {i18next.t("propertytype")}</label>
                                                        <select onChange={this.propertyType_enHandle} class="form-select wizard-required" style={{ height: '2.75rem' }} aria-label="form-select" id="type_id">
                                                            <option value={this.state.property.propertytype_id}>{lang == "en" ? this.state.property.typeName_en : this.state.property.typeName_ar}</option>
                                                            {this.state.propertytypes.map((ppty) => (
                                                                <>
                                                                    {ppty.id != this.state.property.propertytype_id ? <option value={ppty.id}>{lang == "en" ? ppty.typeName_en : ppty.typeName_ar}</option> : null}

                                                                </>
                                                            )
                                                            )}
                                                        </select>

                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label> {i18next.t("purpose")}</label>
                                                        <select onChange={this.rentOnChange_Handle} class="form-select wizard-required" style={{ height: '2.75rem' }} aria-label="form-select" id="purpose" >
                                                            {this.state.property.purpose == 1 ?
                                                                <>
                                                                    <option value="1">{lang == "en" ? "For Rent" : "للايجار"}</option>
                                                                    <option value='2'>{lang == "en" ? "For Sale" : "للبيع"}</option>
                                                                    <option value='3'>{lang == "en" ? "For Sharing" : "سكن مشترك"}</option>

                                                                </>
                                                                :
                                                                <>
                                                                    <option value='2'>{lang == "en" ? "For Sale" : "للبيع"}</option>
                                                                    <option value='1'>{lang == "en" ? "For Rent" : "للبيع"}</option>
                                                                    <option value="3">{lang == "en" ? "For Sharing" : "سكن مشترك"}</option>
                                                                </>
                                                            }
                                                        </select>

                                                    </div> */}

                                                    <div class={this.state.furnishstatusfields}>

                                                        <div className="form-group">

                                                            <div className="wizard-form-radio">
                                                                <input name="radio-name" id="radio1" type="radio" checked={this.state.firnished == 1 ? true : false} onClick={this.handleOnFurnished} />
                                                                <label htmlFor="radio1">{i18next.t("furnished")}</label>
                                                            </div>
                                                            &nbsp;
                                                            &nbsp;
                                                            <div className="wizard-form-radio">
                                                                <input name="radio-name" id="radio2" type="radio" checked={this.state.firnished == 0 ? true : false} onClick={this.handleOnUnFurnished} />
                                                                <label htmlFor="radio2">{i18next.t("unfurnished")}</label>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    <h4>{lang == "en" ? "Location and Address" : "تفاصيل العنوان"}</h4>

                                                    <div className='row'>
                                                        <div className="col-md-12">
                                                            <div className="mb-2">
                                                                <Select
                                                                    className=" wizard-required"
                                                                    options={this.state.alllocations}
                                                                    onChange={opt => this.handleLocation(opt)}
                                                                    value={this.state.selectPlace}
                                                                    id="location_selected"
                                                                />
                                                            </div>
                                                            {/* <input type='text' id="area_selected" className="form-control wizard-required" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ display: 'none' }}>
                                                        <div className="col-md-3">
                                                            <input type="text" className="form-control" id="lat" value={this.state.lat} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input type="text" className="form-control" id="lng" value={this.state.lng} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-10">
                                                        <div className="col-md-12">
                                                            <div className="mb-5">
                                                                <section className="map-wrapper-container">

                                                                    <div className="map-wrapper">
                                                                        <div className="map-container" id="google-map"></div>
                                                                        <Helmet>
                                                                            <script
                                                                                type="text/javascript"
                                                                                charset="UTF-8"
                                                                                async={true}
                                                                                defer={true}
                                                                                src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCq6FzlBsSuT-S2zaRh2Rd-i4205DCu57s&callback=initMap&libraries=places"

                                                                            />
                                                                        </Helmet>
                                                                    </div>
                                                                </section>                                      </div>
                                                        </div>

                                                    </div>

                                                    <div className="form-group">
                                                        <label> {i18next.t("title_en")}</label>
                                                        <input type="text" defaultValue={this.state.property.title_en} className="form-control wizard-required" id="title_en" placeholder={i18next.t("title_en")} onChange={this.handleTitleEn} maxLength={65} />

                                                    </div>

                                                    <div className="form-group">
                                                        <label> {i18next.t("title_ar")}</label>
                                                        <input type="text" defaultValue={this.state.property.title_ar} className="form-control" id="fname" placeholder={i18next.t("title_ar")} onChange={this.handleTitleAr} maxLength={65} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>{i18next.t("description_en")}</label>
                                                        <JoditEditor
                                                            //ref={editor}
                                                            value={this.state.description_en}
                                                            config={config}
                                                            tabIndex={1} // tabIndex of textarea
                                                            onBlur={newContent => this.setState({ description_en: newContent })} // preferred to use only this option to update the content for performance reasons
                                                            onChange={newContent => { }}
                                                        />

                                                    </div>

                                                    <div className="form-group">
                                                        <label>{i18next.t("description_ar")}</label>
                                                        <JoditEditor
                                                            //ref={editor}
                                                            value={this.state.description_ar}
                                                            config={config}
                                                            tabIndex={1} // tabIndex of textarea
                                                            onBlur={newContent => this.setState({ description_ar: newContent })} // preferred to use only this option to update the content for performance reasons
                                                            onChange={newContent => { }}
                                                        />

                                                    </div>

                                                    <div className="form-group">
                                                        {i18next.t("area")}
                                                        <input type="number" defaultValue={this.state.property.area} className="form-control wizard-required" id="fname" placeholder={i18next.t("area")} onChange={this.handleSquarearea} />

                                                    </div>

                                                    <div className="form-group">
                                                        <label> {i18next.t("price")}</label>
                                                        <input type="number" defaultValue={this.state.property.price} className="form-control wizard-required" id="price" placeholder={i18next.t("price")} onChange={this.handlePrice} />

                                                    </div>

                                                    <div className={this.state.bathroomfeature}>
                                                        <div className="form-group">
                                                            <label>{lang == "en" ? "Bath room" : "الحمامات"}</label>
                                                            <select className="form-select form-select-lg mb-3" style={{ fontSize: 'unset', height: '2.75rem' }} aria-label="form-select-lg example" onChange={this.handleBathroom}>
                                                                <option value={this.state.property.baths}>
                                                                    {this.state.property.baths < 1 ? "select" : this.state.property.baths}
                                                                </option>

                                                                {this.state.baths.map((bath) => (
                                                                    <>
                                                                        {bath.id != this.state.property.baths ? <option value={bath.id}>{bath.name}</option> : null}
                                                                    </>
                                                                )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{lang == "en" ? "Bed room" : "الغرف"}</label>

                                                            <select className="form-select form-select-lg mb-3" style={{ fontSize: 'unset', height: '2.75rem' }} aria-label="form-select-lg example" onChange={this.handleBedroom}>

                                                                <option value={this.state.property.beds}>

                                                                    {this.state.property.beds < 1 ? this.state.property.beds == -1 ? "select" : "studio" : this.state.property.beds}
                                                                </option>
                                                                {this.state.beds.map((bed) => (
                                                                    <>
                                                                        {bed.id != this.state.property.beds ? <option value={bed.id}>{bed.name}</option> : null}
                                                                    </>
                                                                )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={this.state.landfeatures}>
                                                        <div className="mb-3">
                                                            <select className="form-select form-select-lg mb-3" style={{ fontSize: 'unset', height: '2.75rem' }} aria-label="form-select-lg example" onChange={this.handleCompletionStatus}>
                                                                {this.state.property.completion_status == 1 ? <>
                                                                    <option value="1">{lang == "en" ? "Ready" : "  جاهز "}</option>
                                                                    <option value="2">{lang == "en" ? "Off Plan" : "  خارج الخطة "}</option>
                                                                </> : null
                                                                }
                                                                {this.state.property.completion_status == 2 ? <>
                                                                    <option value="2">{lang == "en" ? "Off Plan" : "  خارج الخطة "}</option>
                                                                    <option value="1">{lang == "en" ? "Ready" : "  جاهز "}</option>
                                                                </> : null}
                                                                {this.state.property.completion_status == 0 ? <>
                                                                    <option value="0">{lang == "en" ? "Completion Status " : " حالة إكمال "}</option>
                                                                    <option value="1">{lang == "en" ? "Ready" : "  جاهز "}</option>
                                                                    <option value="2">{lang == "en" ? "Off Plan" : "  خارج الخطة "}</option></> : null}

                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <select className="form-select form-select-lg" style={{ fontSize: 'unset', height: '2.75rem' }} aria-label="form-select-lg example" onChange={this.handleOwnerStatus}>
                                                                {this.state.property.ownership_status == 1 ? <>
                                                                    <option value="1">{lang == "en" ? "Freshhold " : "   عقد جديد "}</option>
                                                                    <option value="2">{lang == "en" ? "Leasehold " : "   مسؤول المستأجرة "}</option></> : null}
                                                                {this.state.property.ownership_status == 2 ? <>
                                                                    <option value="2">{lang == "en" ? "Leasehold " : "   مسؤول المستأجرة "}</option>
                                                                    <option value="1">{lang == "en" ? "Freshhold " : "   عقد جديد "}</option>
                                                                </> : null}

                                                                {this.state.property.ownership_status == 0 ? <>
                                                                    <option value="0">{lang == "en" ? "OwnerShip Status " : "  حالة الملكية  "}</option>
                                                                    <option value="1">{lang == "en" ? "Freshhold " : "   عقد جديد "}</option>
                                                                    <option value="2">{lang == "en" ? "Leasehold " : "   مسؤول المستأجرة "}</option></> : null}


                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={this.state.rentfileds}>
                                                        <h4>{lang == "en" ? "Rent Details" : "تفاصيل الإيجار"}</h4>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="mb-2">
                                                                <select className="form-select form-select-lg" style={{ fontSize: 'unset', height: '2.75rem' }} aria-label="form-select-lg example" onChange={this.handleRentFrequency}>
                                                                    {this.state.property.rent_frequency == 1 ?
                                                                        <>
                                                                            <option value="1">{lang == "en" ? "Yearly" : "سنوي"}</option>
                                                                            <option value="2">{lang == "en" ? "Monthly" : "شهري"}</option>
                                                                            <option value="3">{lang == "en" ? "Weekly" : "اسبوعي"}</option>
                                                                            <option value="4">{lang == "en" ? "Daily" : "يومي"}</option>
                                                                        </>
                                                                        : null
                                                                    }
                                                                    {this.state.property.rent_frequency == 2 ?
                                                                        <>
                                                                            <option value="2">{lang == "en" ? "Monthly" : "شهري"}</option>
                                                                            <option value="1">{lang == "en" ? "Yearly" : "سنوي"}</option>
                                                                            <option value="3">{lang == "en" ? "Weekly" : "اسبوعي"}</option>
                                                                            <option value="4">{lang == "en" ? "Daily" : "يومي"}</option>
                                                                        </>
                                                                        : null
                                                                    }
                                                                    {this.state.property.rent_frequency == 3 ?
                                                                        <>
                                                                            <option value="3">{lang == "en" ? "Weekly" : "اسبوعي"}</option>
                                                                            <option value="1">{lang == "en" ? "Yearly" : "سنوي"}</option>
                                                                            <option value="2">{lang == "en" ? "Monthly" : "شهري"}</option>
                                                                            <option value="4">{lang == "en" ? "Daily" : "يومي"}</option>
                                                                        </>
                                                                        : null
                                                                    }
                                                                    {this.state.property.rent_frequency == 4 ?
                                                                        <>
                                                                            <option value="4">{lang == "en" ? "Daily" : "يومي"}</option>
                                                                            <option value="1">{lang == "en" ? "Yearly" : "سنوي"}</option>
                                                                            <option value="2">{lang == "en" ? "Monthly" : "شهري"}</option>
                                                                            <option value="3">{lang == "en" ? "Weekly" : "اسبوعي"}</option>

                                                                        </>
                                                                        : null
                                                                    }
                                                                    {this.state.property.rent_frequency == 0 ? <>
                                                                        <option value="0">{i18next.t("rentfrequency")} </option>
                                                                        <option value="1">{lang == "en" ? "Yearly" : "سنوي"}</option>
                                                                        <option value="2">{lang == "en" ? "Monthly" : "شهري"}</option>
                                                                        <option value="3">{lang == "en" ? "Weekly" : "اسبوعي"}</option>
                                                                        <option value="4">{lang == "en" ? "Daily" : "يومي"}</option></> : null}

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="mb-3">
                                                                {i18next.t("mincontract")}
                                                                <input type="number" defaultValue={this.state.property.min_contract_period} className="form-control" placeholder={i18next.t("mincontract")} onChange={this.handleMinimumcontractperiod} id="mincontract" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="mb-2">
                                                                {i18next.t("paidby")}
                                                                <select className="form-select form-select-lg" style={{ fontSize: 'unset', height: '2.75rem' }} aria-label="form-select-lg example" onChange={this.handlePaidby} id="paidby">
                                                                    {this.state.property.paid_by == 1 ? <>
                                                                        <option value="1">{lang == "en" ? "LandLord" : "المالك"}</option>
                                                                        <option value="2">{lang == "en" ? "Tenant" : "مستأجر"}</option>
                                                                    </> : null

                                                                    }
                                                                    {this.state.property.paid_by == 2 ? <>
                                                                        <option value="2">{lang == "en" ? "Tenant" : "مستأجر"}</option>
                                                                        <option value="1">{lang == "en" ? "LandLord" : "المالك"}</option>

                                                                    </> : null}
                                                                    {this.state.property.paid_by == 0 ? <>
                                                                        <option value="0">{i18next.t("paidby")}</option>
                                                                        <option value="1">{lang == "en" ? "LandLord" : "المالك"}</option>
                                                                        <option value="2">{lang == "en" ? "Tenant" : "مستأجر"}</option></> : null}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 mb-2">
                                                            <div className="mb-3">
                                                                {i18next.t("mantianancefee")}
                                                                <input type="number" defaultValue={this.state.property.maintainance_fee} className="form-control" placeholder={i18next.t("mantianancefee")} onChange={this.handleMaintainaceFee} id="mantianancefee" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="mb-3">
                                                                {i18next.t("vacatingperiod")}
                                                                <select className="form-select form-select-lg" style={{ fontSize: 'unset', height: '2.75rem' }} aria-label="form-select-lg example" onChange={this.handleVactingPeriod} id="vacatingperiod" >
                                                                    {this.state.property.vacating_period == 1 ?
                                                                        <>
                                                                            <option value="1">{lang == "en" ? "Year" : "سنة"}</option>
                                                                            <option value="2">{lang == "en" ? "Month" : "شهر"}</option>
                                                                            <option value="3">{lang == "en" ? "Week" : "اسبوع"}</option>
                                                                            <option value="4">{lang == "en" ? "Day" : "يوم"}</option>
                                                                        </> : null}
                                                                    {this.state.property.vacating_period == 2 ?
                                                                        <>
                                                                            <option value="2">{lang == "en" ? "Month" : "شهر"}</option>
                                                                            <option value="1">{lang == "en" ? "Year" : "سنة"}</option>
                                                                            <option value="3">{lang == "en" ? "Week" : "اسبوع"}</option>
                                                                            <option value="4">{lang == "en" ? "Day" : "يوم"}</option>
                                                                        </> : null}
                                                                    {this.state.property.vacating_period == 3 ?
                                                                        <>
                                                                            <option value="3">{lang == "en" ? "Week" : "اسبوع"}</option>
                                                                            <option value="1">{lang == "en" ? "Year" : "سنة"}</option>
                                                                            <option value="2">{lang == "en" ? "Month" : "شهر"}</option>
                                                                            <option value="4">{lang == "en" ? "Day" : "يوم"}</option>
                                                                        </> : null}
                                                                    {this.state.property.vacating_period == 4 ?
                                                                        <>
                                                                            <option value="4">{lang == "en" ? "Day" : "يوم"}</option>
                                                                            <option value="1">{lang == "en" ? "Year" : "سنة"}</option>
                                                                            <option value="2">{lang == "en" ? "Month" : "شهر"}</option>
                                                                            <option value="3">{lang == "en" ? "Week" : "اسبوع"}</option>

                                                                        </> : null}
                                                                    {this.state.property.vacating_period == 0 ? <>
                                                                        <option value="0">{i18next.t("vacatingperiod")} </option>
                                                                        <option value="1">{lang == "en" ? "Year" : "سنة"}</option>
                                                                        <option value="2">{lang == "en" ? "Month" : "شهر"}</option>
                                                                        <option value="3">{lang == "en" ? "Week" : "اسبوع"}</option>
                                                                        <option value="4">{lang == "en" ? "Day" : "يوم"}</option>
                                                                    </> : null}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label> {i18next.t("permitnumber")}</label>
                                                        <input type="number" defaultValue={this.state.property.permitnumber} name="permitNumber" className="form-control" placeholder={i18next.t("permitnumber")} onChange={this.handlePermitNumber} />
                                                        <div className="wizard-form-error" />
                                                    </div>
                                                    <div className="form-group clearfix">
                                                        <div className="row">
                                                            <div className="col-md-10"></div>
                                                            <div className="col-md-2">
                                                                <a href="javascript:;" className="form-wizard-next-btn float-right rounded" >{lang == "en" ? "Next" : "التالي"}</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </fieldset>
                                                <fieldset className="wizard-fieldset">
                                                    <h5>Uploads</h5>
                                                    <div id="uploads" className="tab-pane" role="tabpanel">
                                                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-image" role="tab" aria-controls="pills-home" aria-selected="true">Image</a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-video" role="tab" aria-controls="pills-profile" aria-selected="false">Video</a>
                                                            </li>
                                                        </ul>
                                                        <div class="tab-content" id="pills-tabContent">
                                                            <div class="tab-pane fade show active" id="pills-image" role="tabpanel" aria-labelledby="pills-home-tab">

                                                                <div style={{ marginBottom: '20px' }}>
                                                                    <label>{lang == "en" ? "Featured Image" : "الصورة الاساسية"} </label>
                                                                    <input type="file" name="featured_image" onChange={this.handleFeaturedImage} />

                                                                    {this.state.featuredimage != null ? <img src={this.state.imageShow} alt='tt' height="70px" width="90px" /> : <img src={baseurlImg + "/uploads/properties/" + this.state.property.image} alt='tt' height="70px" width="90px" />}
                                                                </div>

                                                                <label>{lang == "en" ? "Gallery Images" : "معرض الصور"} </label>
                                                                <input type="file" id="file" multiple name="file" onChange={this.handleChange} />
                                                                <table class="table">
                                                                    {/* <thead>
                                  {this.state.image.length>0?
                                        <tr>
                                        <th scope="col">Image preview</th>
                                        <th scope="col">Image Description</th>
                                        <th scope="col">Primary Image</th>
                                        <th scope="col">Delete Image</th>
                                        </tr>:null
                                  }
                                        </thead> */}
                                                                    <tbody>
                                                                        {this.state.medias.map((media) => (
                                                                            <tr key={media.id}>
                                                                                <td>
                                                                                    <img src={baseurlImg + "/uploads/properties/" + media.image} alt='tt' height="70px" width="90px" />
                                                                                </td>


                                                                                <td><a href="#" className="btn btn-sm btn-danger" onClick={this.removeFileFromserver.bind(this, media)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                                    </svg>
                                                                                </a></td>

                                                                            </tr>

                                                                        ))}
                                                                        {this.state.image.length > 0 ? (
                                                                            this.state.image.map((file, index) => (
                                                                                <tr key={index}>

                                                                                    <td>
                                                                                        <img src={URL.createObjectURL(file)} alt='tt' height="70px" width="90px" />
                                                                                    </td>
                                                                                    {/* <td>  <input type="text" defaultValue={file.title} onChange={this.handleTitle.bind(this, file)} /></td> */}

                                                                                    <td><a href="#" className="btn btn-sm btn-danger" onClick={this.removeFile.bind(this, file)}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                                        </svg>
                                                                                    </a></td>
                                                                                </tr>

                                                                            ))) : (
                                                                            <h6 className="text-danger text-center"></h6>
                                                                        )}

                                                                    </tbody>


                                                                </table>
                                                            </div>
                                                            <div class="tab-pane fade" id="pills-video" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                {/* <VideoLinkComponent handleVideo={this.handleVideo} /> */}

                                                                {/* video */}
                                                                <table class="table" id="myTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <option value="0"> {lang == "en" ? "Video Source" : "مصدر الفيديو"}</option>
                                                                            <option value="1"> {lang == "en" ? "Youtube" : "يتيوب"}</option>
                                                                            <option value="2"> {lang == "en" ? "Vimeo " : "فيمو"}</option>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr  >
                                                                            <td>
                                                                                <select className="form-select" aria-label="Default select example" onChange={this.handleVideo_source}>
                                                                                    {this.state.video_source == 0 ? <>
                                                                                        <option value="0"> {lang == "en" ? "Video Source" : "مصدر الفيديو"}</option>
                                                                                        <option value="1"> {lang == "en" ? "Youtube" : "يتيوب"}</option>
                                                                                        <option value="2"> {lang == "en" ? "Vimeo " : "فيمو"}</option></> : null}

                                                                                    {this.state.video_source == 1 ? <>
                                                                                        <option value="1"> {lang == "en" ? "Youtube" : "يتيوب"}</option>
                                                                                        <option value="2"> {lang == "en" ? "Vimeo " : "فيمو"}</option>
                                                                                    </> : null}

                                                                                    {this.state.video_source == 2 ? <>
                                                                                        <option value="2"> {lang == "en" ? "Vimeo " : "فيمو"}</option>
                                                                                        <option value="1"> {lang == "en" ? "Youtube" : "يتيوب"}</option>
                                                                                    </> : null}

                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <input aria-invalid="false" name="video_link" type="url" class="form-control" placeholder="Video Link" onChange={this.handleVideo_link} defaultValue={this.state.video_link} />
                                                                            </td>
                                                                            <td>

                                                                                <input aria-invalid="false" name="video_title" type="url" class="form-control" placeholder="Video Title" onChange={this.handleVideo_title} defaultValue={this.state.video_title} />
                                                                            </td>

                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                                {/* end video */}

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group clearfix">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <a href="javascript:;" className="form-wizard-previous-btn float-left rounded">{lang == "en" ? "Previous" : "السابق"}</a>
                                                            </div>
                                                            <div className="col-md-6"></div>
                                                            <div className="col-md-3">
                                                                <a href="javascript:;" className="form-wizard-next-btn float-right rounded">{lang == "en" ? "Next" : "التالي"}</a>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="wizard-fieldset">
                                                    <h5>{lang == "en" ? "Amenities" : "مميزات"}</h5>

                                                    <div id="amenties" className="tab-pane" role="tabpanel">
                                                        <div className="row d-flex justify-content-center">
                                                            <div className="col col-sm-5">
                                                                <div className="quiz_content_area">
                                                                    <h1 className="quiz_title">Amenties</h1>
                                                                    <div className="row">
                                                                        <h2 className="amenties-header">Health and Fitness</h2>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.gym} onClick={this.handleGym.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-dumbbell"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />GYM</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.swimmingpool} onClick={this.handleSwimmingbool.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-swimmer"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Swimming pool</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.medical} onClick={this.handleMedical.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-clinic-medical"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Medical</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.sauna_steem} onClick={this.handleSaunasteem.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-hot-tub"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Sauna/Steam</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.disability_access} onClick={this.handleDisabilityaccess.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-wheelchair"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Disability Access</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        <h2 className="amenties-header">Features</h2>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.built_in_wardobes} onClick={this.handleBuiltinwardobes.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-door-closed"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Built in wardobes</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.barbaque_area} onClick={this.handleBarbaquearea.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-dumpster-fire"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Barbaque area</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.cafeteria} onClick={this.handleCafeteria.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-coffee"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Cafeteria</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.kitchen} onClick={this.handleKitchen.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-sink"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Kitchen</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.central_ac} onClick={this.handleCentralac.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-wind"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Central A/C</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.garden} onClick={this.handleGarden.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-tree"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Garden</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.furnished} onClick={this.handleFurnished.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-couch"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Furnished</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.disposal} onClick={this.handleDisposal.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-dumpster"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Disposal</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.kidsplay} onClick={this.handleKidsplay.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-skating"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Kids play</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.parking} onClick={this.handleParking.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-parking"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Parking</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.balcony} onClick={this.handleBalcony.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-store"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Balcony</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <h2 className="amenties-header">Miscellaneous</h2>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.atm_acxeess} onClick={this.handleAtmacxeess.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-money-check"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />ATM Acxeess</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.water_view} onClick={this.handleWaterview.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-water"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Water View</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.landmark_view} onClick={this.handleLandmarkview.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-landmark"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Landmark view</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.day_care} onClick={this.handleDaycare.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-child"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Day care</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        <h2 className="amenties-header">Security and Technology</h2>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.security_guard} onClick={this.handleSecurityguard.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-user-secret"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Security Guard</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.satelite_cable_tv} onClick={this.handleSatelitecabletv.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-tv"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Satelite/Cable TV</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.cctv} onClick={this.handleCctv.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-video"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />cctv</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col" >
                                                                            <div className="quiz_card_area">
                                                                                <input className="quiz_checkbox" type="checkbox" checked={this.state.internet} onClick={this.handleInternet.bind(this)} />
                                                                                <div className="single_quiz_card">
                                                                                    <div className="quiz_card_content">
                                                                                        <div className="quiz_card_icon">
                                                                                            <i className="fas fa-network-wired"></i>
                                                                                        </div>
                                                                                        <div className="quiz_card_title">
                                                                                            <h3><i className="fas fa-check" aria-hidden="true" />Internet</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group clearfix ">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <a href="javascript:;" className="form-wizard-previous-btn rounded">Previous</a>
                                                            </div>
                                                            <div className="col-md-6"></div>
                                                            <div className="col-md-3">


                                                                {this.buttonRender()}
                                                            </div>


                                                        </div>
                                                    </div>
                                                </fieldset>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditAgentProperty