const initialState = {
    propertyStyle: 0,
    propertytypes: [],
    scrollStyle: {},
    locations: [],
    selectedLocations: [],
    subLocationSend: [],
    purpose: 1,
    selectedPurpose: true,
    pricefrom: 0,
    priceto: 1000000000,
    bedroom: -1,
    bathroom: 0,
    frequency: 0,
    furnishedornot: 0,
}
const PropertyReducer = (state = initialState, action) =>{
    // console.log("actions come here", action.payload)
switch (action.type) {
    case "FETCH_PROPERTIES":
        return {
            ...state,
            propertyStyle: 0,
            propertytypes: [],
            scrollStyle: {},
            locations: [],
            selectedLocations: [],
            subLocationSend: [],
            purpose: 1,
            selectedPurpose: true,
            pricefrom: 0,
            priceto: 1000000000,
            bedroom: -1,
            bathroom: 0,
            frequency: 0,
            furnishedornot: 0,
        };
        case "SET_FILTER":
            return {
                ...state,
                purpose: action?.payload? action?.payload : 1
            };
    default:
        return state;
}
}
export default PropertyReducer