import React, { Component } from 'react'
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import { baseurl} from '../../../Components/BaseUrl'
import { baseurlImg } from '../../../Components/BaseUrl'
import axios from 'axios';
import SingleProperty from './SingleProperty'
//  import './css/image-gallery.scss';

import ImageGallery from 'react-image-gallery';
 

export class SinglePropertyHeader extends Component {
    constructor(props){
        super(props);
        this.state = {
            medias: [],
        }
    }
    componentDidMount () {
    //    this.fetchData();

      this.setState({medias: this.props.handleMedia})
    }  
      
    render() {
         
        return (

             <div style={{maxWidth: '1100px', margin: '0 auto'}} className="">
                 <ImageGallery thumbnailPosition= 'left' items={this.state.medias} /> 
             </div>
        )
    }
}

export default SinglePropertyHeader


