import React, { Component } from "react";

export default class DownloadOurApp extends Component {
  render() {
      const lang= localStorage.getItem("lang") || 'en'
      return (
        <div className="acr-footer footer-2">
          <div className="footer-top">
            <div className="container">
              <div className="row footer-btn-wrapper">
                <div className="col-lg-7 footer-widget">
                <h4>{lang=="en"?"Download Our App":"قم بتحميل تطبيق فايند بروبرتيز "}</h4>
                  <p>
                     {lang=="en"?"Download Find Properties App today & Start searching for your Dream Home.":" ابحث عن افضل العقارات في تطبيقنا على جووجل بلاى واب ستور، قم بتحميل تطبيق فايند بروبرتيز الآن"}
                  </p>
        
              </div>
              <div className="col-lg-5 col-md-12 footer-widget">
                <div className="footer-btn">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.findproperties.uae"
                    className="btn-custom-2 grey"
                  >
                    {" "}
                    <i className="flaticon-playstore"></i>Google Play
                  </a>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/app/find-properties-uae/id1579229585"
                    className="btn-custom-2 grey"
                  >
                    {" "}
                    <i className="flaticon-apple"></i>App Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
