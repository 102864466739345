import React, { Component } from "react";
import Collapse from "react-bootstrap/Collapse";
import $ from 'jquery'
import i18next from 'i18next'

export default class HomePageBottomAr extends Component {
    componentDidMount() {
        // var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
        // var collapseList = collapseElementList.map(function (collapseEl) {
        //   return new bootstrap.Collapse(collapseEl)
        // })
    }
    render() {
        const lang = "ar";
        return (
            <footer className="acr-footer footer-dark ">
                <div className="container">
                    <ul className="nav nav-pills mb-3" id="locations-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-abu" type="button" role="tab" aria-controls="pills-abu" aria-selected="true">{i18next.t("abudhabi")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-dub" type="button" role="tab" aria-controls="pills-dub" aria-selected="false">{i18next.t("dubai")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-shr" type="button" role="tab" aria-controls="pills-shr" aria-selected="false">{i18next.t("sharjah")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-ajm" type="button" role="tab" aria-controls="pills-ajm" aria-selected="false">{i18next.t("ajman")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-umq" type="button" role="tab" aria-controls="pills-umq" aria-selected="false">{i18next.t("umalq")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-rak" type="button" role="tab" aria-controls="pills-rak" aria-selected="false">{i18next.t("rak")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-fuj" type="button" role="tab" aria-controls="pills-fuj" aria-selected="false">{i18next.t("fuj")}</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-abu" role="tabpanel" aria-labelledby="pills-abu-tab">
                            <div className="row">
                                <div className="col">
                                    <b>{i18next.t("popularsearches")}</b>
                                    <ul>
                                        <li><a href="https://findpropertiesae/ar/for-rent/properties/abu-dhabi/">عقارات للإيجار في أبوظبي</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi">شقق للإيجار في أبوظبي</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/studio-apartments/abu-dhabi">شقق استوديو للإيجار في أبو ظبي</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi"> شقق مفروشة للإيجار في أبوظبي</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi"> شقق للإيجار في أبو ظبي</a></li>
                                    </ul>
                                </div>
                                <div className="col"><b>{i18next.t("popularareas")}</b>
                                    <ul>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/hamdan-street"> شقق للإيجار في شارع حمدان</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/mohammed-bin-zayed-city"> شقق للإيجار في مدينة محمد بن زايد</a></li >
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/al-reem-island"> شقق للإيجار في جزيرة الريم</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/mussafah"> شقق للإيجار في المصفح</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/corniche-area"> شقق للإيجار في منطقة الكورنيش</a></li>
                                    </ul>
                                </div>
                                <div className="col"><b>{i18next.t("trendingareas")}</b>
                                    <ul>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/al-bateen"> شقق للإيجار في البطين</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/mussafah/shabiya"> شقق للإيجار في الشعبية</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/al-muroor"> شقق للإيجار في المرور</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/apartments/abu-dhabi/tourist-club-area-tca"> شقق للإيجار في منطقة النادي السياحي TCA</a></li >
                                        <li><a href="https://findpropertiesae/ar/for-rent/residential-villa/abu-dhabi"> فيلا للإيجار في أبو ظبي</a></li>
                                    </ul>
                                </div>
                                <div className="col"><b>{i18next.t("trendingsearches")}</b>
                                    <ul>
                                        <li><a href="https://findpropertiesae/ar/for-rent/shops/abu-dhabi">محلات تجارية للإيجار في أبوظبي</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-rent/office/abu-dhabi">مكتب للإيجار في أبو ظبي</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-sale/properties/abu-dhabi">عقارات للبيع في أبوظبي</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-sale/apartments/abu-dhabi">شقق للبيع في أبوظبي</a></li>
                                        <li><a href="https://findpropertiesae/ar/for-sale/residential-villa/abu-dhabi">فيلا للبيع في أبوظبي</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-dub" role="tabpanel" aria-labelledby="pills-dub-tab">
                            <div className="row">
                                {/* <div className="col">
                      <b>{i18next.t("popularsearches")}</b>
                      <ul>
                          <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                      </ul>
                    </div>
                    <div className="col"><b>{i18next.t("popularareas")}</b>
                    <ul>
                         <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                    </ul>
                  </div>
                  <div className="col"><b>{i18next.t("trendingareas")}</b>
                      <ul>
                          <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                        </ul>
                  </div>
                  <div className="col"><b>{i18next.t("trendingsearches")}</b>
                        <ul>
                         <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                        </ul>
                  </div> */}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-shr" role="tabpanel" aria-labelledby="pills-shr-tab">
                            <div className="row">
                            <div className="col">
                                     <b>{i18next.t("popularsearches")}</b>
                                     <ul>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/sharjah">شقق للإيجار في الشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/residential-villa/sharjah">فلل للإيجار في الشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/residential-villa/sharjah">فيلا للبيع في الشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/apartments/sharjah">شقق للبيع في الشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/studio-apartments/sharjah">استوديو للإيجار في الشارقة</a></li>
                                     </ul>
                                 </div>
                                 <div className="col"><b>{i18next.t("popularareas")}</b>
                                     <ul>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/sharjah/rolla-area">شقق للإيجار في منطقة الرولة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/sharjah/al-majaz">شقق للإيجار في المجاز</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/sharjah/muwailih-commercial">شقق للإيجار في مويلح التجارية</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/sharjah/al-qasimia">شقق للإيجار في القاسمية</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/residential-villa/sharjah/al-azra">فلل للبيع في العزرة</a></li>
                                     </ul>
                                 </div>
                                 <div className="col"><b>{i18next.t("trendingareas")}</b>
                                     <ul>
                                         <li><a href="https://findpropertiesae/ar/for-sale/properties/sharjah">عقارات للبيع في الشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/residential-land/sharjah/hoshi">أرض سكنية للبيع في الشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/properties/sharjah/hoshi">عقارات للبيع في الحوشي</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/warehouse/sharjah/al-saja">مستودعات للإيجار في السجع</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/commerical-land/sharjah">أرض تجارية للإيجار في الشارقة</a></li>
                                     </ul>
                                 </div>
                                 <div className="col"><b>{i18next.t("trendingsearches")}</b>
                                     <ul>
                                         <li><a href="https://findpropertiesae/ar/for-rent/properties/sharjah">عقارات للإيجار في الشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/properties/sharjah/abu-shagara">عقارات للإيجار في أبو شغارة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/office/sharjah">مكتب للإيجار في الشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/properties/sharjah/industrial-area">عقارات للإيجار في المنطقة الصناعية بالشارقة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/properties/sharjah/emirates-industrial-city">عقارات للإيجار في مدينة الإمارات الصناعية</a></li>
                                     </ul>
                                 </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-ajm" role="tabpanel" aria-labelledby="pills-ajm-tab">
                            <div className="row">
                            <div className="col">
                                     <b>{i18next.t("popularsearches")}</b>
                                     <ul>
                                         <li><a href="https://findpropertiesae/ar/for-rent/properties/ajman">عقارات للإيجار في عجمان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/ajman">شقق للإيجار في عجمان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/residential-villa/ajman">فلل للإيجار في عجمان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/residential-villa/ajman">فلل للبيع في عجمان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/apartments/ajman">شقق للبيع في عجمان</a></li>
                                     </ul>
                                 </div>
                                 <div className="col"><b>{i18next.t("popularareas")}</b>
                                     <ul>
                                         <li><a href="https://findpropertiesae/ar/for-rent/studio-apartments/ajman">استوديو للإيجار في عجمان شهري</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/studio-apartments/ajman">استوديو للإيجار في عجمان سنوي</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/ajman/al-bustan">شقق للبيع في البستان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/ajman/al-nuaimiya">شقق للإيجار في النعيمية</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/ajman/al-rashediya">شقق للإيجار في الراشدية</a></li>
                                     </ul>
                                 </div>
                                 <div className="col"><b>{i18next.t("trendingareas")}</b>
                                     <ul>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/ajman/corniche-ajman">شقق للإيجار في كورنيش عجمان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-rent/apartments/ajman/al-sawan">شقق للإيجار في الصوان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/residential-land/ajman">أرض للبيع في عجمان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/properties/ajman">عقارات للبيع في عجمان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/residential-villa/ajman/al-yasmeen">فيلا للبيع في الياسمين</a></li>
                                     </ul>
                                 </div>
                                 <div className="col"><b>{i18next.t("trendingsearches")}</b>
                                     <ul>
                                         <li><a href="https://findpropertiesae/ar/for-sale/residential-villa/ajman/al-rawda">فيلا للبيع في الروضة</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/residential-villa/ajman/al-zahya">فيلا للبيع في الزاهية</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/apartments/ajman/al-nuaimiya">شقق للبيع في النعيمية</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/apartments/ajman/al-bustan">شقق للبيع في البستان</a></li>
                                         <li><a href="https://findpropertiesae/ar/for-sale/apartments/ajman/al-nuaimiya/city-tower">شقق للبيع في برج المدينة</a></li>
                                     </ul>
                                 </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-umq" role="tabpanel" aria-labelledby="pills-umq-tab">
                            <div className="row">
                                {/* <div className="col">
                      <b>{i18next.t("popularsearches")}</b>
                      <ul>
                          <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                      </ul>
                    </div>
                    <div className="col"><b>{i18next.t("popularareas")}</b>
                    <ul>
                         <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                    </ul>
                  </div>
                  <div className="col"><b>{i18next.t("trendingareas")}</b>
                      <ul>
                          <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                        </ul>
                  </div>
                  <div className="col"><b>{i18next.t("trendingsearches")}</b>
                        <ul>
                         <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                        </ul>
                  </div> */}
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-rak" role="tabpanel" aria-labelledby="pills-rak-tab">
                            <div className="row">
                                {/* <div className="col">
                      <b>{i18next.t("popularsearches")}</b>
                      <ul>
                          <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                      </ul>
                    </div>
                    <div className="col"><b>{i18next.t("popularareas")}</b>
                    <ul>
                         <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                    </ul>
                  </div>
                  <div className="col"><b>{i18next.t("trendingareas")}</b>
                      <ul>
                          <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                        </ul>
                  </div>
                  <div className="col"><b>{i18next.t("trendingsearches")}</b>
                        <ul>
                         <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                        </ul>
                  </div> */}
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-fuj" role="tabpanel" aria-labelledby="pills-fuj-tab">
                            <div className="row">
                                {/* <div className="col">
                      <b>{i18next.t("popularsearches")}</b>
                      <ul>
                          <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                      </ul>
                    </div>
                    <div className="col"><b>{i18next.t("popularareas")}</b>
                    <ul>
                         <li><a href="">Properties for rent in UAE</a></li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                    </ul>
                  </div>
                  <div className="col"><b>{i18next.t("trendingareas")}</b>
                      <ul>
                          <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                        </ul>
                  </div>
                  <div className="col"><b>{i18next.t("trendingsearches")}</b>
                        <ul>
                         <li>Properties for rent in UAE</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                          <li>Apartments for rent in UAE</li>
                          <li>Apartments for rent in Dubai</li>
                        </ul>
                  </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
