import React, { Component } from 'react'
import AllBlogs from './AllBlogs'

class BuildingGuide extends Component {
    render() {
        return (
            <div>
              <AllBlogs categoryId="2"></AllBlogs>
            </div>
        )
    }
}

export default BuildingGuide
