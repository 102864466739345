import React, { Component } from "react";
import { baseurl, baseurlImg } from "../../../../Components/BaseUrl";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import cookie from "js-cookie";
import Pusher from "pusher-js";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { MultiSelect } from "react-multi-select-component";
import { store } from "../../../../store";
import { connect } from "react-redux";
import API from "../../../../API";
import i18next from "i18next";

const lang = localStorage.getItem("lang") || "en";

const userdata = store.getState(state=> state);
const token= userdata.auth.token
const activeUser = userdata.auth.user.id;

export class Favourites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      users: [],
      messages: [],
      messageBody: "",
      messageContent: 0,
      recieverUserName: "",
      recieverId: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      NewProfilePic: "",
      offset: 0,
      favouriteProps: [],
      perPage: 6,
      currentPage: 1,
      favouriteProperties: [],
      propertytypes: [],
      selectedLocation: [],
      locations: [],
      agents: [],
      selectedAgents: [],
      languages: [],
      nationality: [],
      selectedLanguages: [],
      selectedNationality: 0,
      bed: 0,
      price: 0,
      bath: 0,
      area: 0,
      type: 0,
      purpose: 0,
      furnished: 0,
      myPropertyLocation: "",
      input: {},
      errors: {},
      inputMessage: {},
      errorsTM: {},

    };
  }
  handleWishList = (e) => {
    // alert(e.target.value)
    const data = { property_id: e.target.value };
    const dataForCheckBox = {
      property_id: e.target.value,
    };
    API.post("wishlist", data).then((response) => {
      $("input[value=" + e.target.value + "]").trigger("click");
    });
    this.fetchFavouriteProperties();
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        const data = {
          displayType: this.state.displayType,
          subLocationSend: this.props.location.state
            ? this.props.location.state["passsubLocationSend"]
            : this.state.subLocationSend,
          furnished: this.state.furnished,
          popular: this.state.popular,
          bathRoomSelected: this.props.location.state
            ? this.props.location.state["passBath"]
            : this.state.bathRoomSelected,
          bedRoomSelected: this.props.location.state
            ? this.props.location.state["passBed"]
            : this.state.bedRoomSelected,
          propertyType: this.props.location.state
            ? this.props.location.state["passType"]
            : this.state.propertyType,
          purpose: this.props.location.state
            ? this.props.location.state["passPurpose"]
            : this.state.purpose,
          frequencySelected: this.props.location.state
            ? this.props.location.state["passFrequency"]
            : this.state.frequencySelected,
          selectedLocations: this.props.location.state
            ? this.props.location.state["passLocation"]
            : this.state.selectedLocations,
          priceFrom: this.props.location.state
            ? this.props.location.state["passPricefrom"]
            : this.state.priceFrom,
          priceTo: this.props.location.state
            ? this.props.location.state["passPriceto"]
            : this.state.priceTo,
          subLocationArr: this.state.subLocationArr,
          selectedFeatures: this.state.selectedFeatures,
          displayType: this.state.displayType,
        };
        this.fetchFavouriteProperties(data);
      }
    );
  };

 
  handleLogout = () => {
    // e.preventDefault();

    this.props.logout();
    // window.location.reload();
    // this.props.history.push('/login')
  };
  handleFormErrorSend = () => {
    let inputMessage = this.state.inputMessage;
    let errorsTM = {};
    let formIsValid = true;
    if (!inputMessage["message"]) {
      formIsValid = false;
      errorsTM["message"] = "Message can not be Empty!";
    }

    this.setState({
      errors: errorsTM,
    });
    console.log()
    return formIsValid;
  };
 
  
 
  componentDidMount() {
    this.fetchFavouriteProperties();
  }

  
  fetchFavouriteProperties = () => {
    API.get("allwishlist")
      .then((response) => {
        console.log("responded data", response.data);
        this.setState({
          isLoading: false,
          favouriteProperties: response.data.wishlists,
        });
        const favourite = response.data.wishlists;
        // console.log("response filter", response.data.filteredproperties.length);
        const slice = favourite.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const postData = slice.map((property, i) => (
          <React.Fragment>
            <div className="listing listing-list">
              <div className="listing-thumbnail col-sm-2">
                <Link to={"/single-property/" + property.id}>
                  <img
                    src={baseurlImg + "/uploads/properties/" + property.image}
                    alt="listing"
                  />
                </Link>
                <div className="listing-badges">
                  <span className="listing-badge featured">
                    {" "}
                    <i className="fas fa-star" />{" "}
                  </span>
                  <span
                    className={
                      property.purpose == 1
                        ? "listing-badge rent"
                        : "listing-badge sale"
                    }
                  >
                    {" "}
                    {property.purpose == 1 ? "For rent" : "For Sale"}
                  </span>
                </div>
                <div className="listing-controls">
                  <div className="_card_flex_last">
                    <div className="prt_saveed_12lk">
                      <label className="toggler toggler-danger">
                        <input
                          onChange={this.handleWishList}
                          value={property.id}
                          type="checkbox"
                          checked={
                            (property.wishlist_status == 1 ? true : false) ||
                            this.state.checked
                          }
                        />
                        <i className="ti-heart" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="listing-body call-buttons">
                <div className="flex">
                  <span className="listing-price">
                    {property.price}
                    <span>{" "}{i18next.t("aed")}</span>
                  </span>
                  <span className="right">
                    {lang == "en" ? property.typeName_en : property.typeName_ar}
                  </span>
                </div>
                <h5 className="listing-title">
                  <Link to={"/single-property/" + property.id}>
                    {property.title_en}
                  </Link>
                </h5>
                <div className="acr-listing-icons">
                  <div
                    className="acr-listing-icon"
                    data-toggle="tooltip"
                    title="Beds"
                  >
                    <i className="flaticon-bedroom" />
                    <span className="acr-listing-icon-value">
                      {property.beds == 0 ? "Studio" : property.beds}
                    </span>
                  </div>
                  {property.baths != 0 ? (
                    <div
                      className="acr-listing-icon"
                      data-toggle="tooltip"
                      title="Bathrooms"
                    >
                      <i className="flaticon-bathroom" />
                      <span className="acr-listing-icon-value">
                        {property.baths}
                      </span>
                    </div>
                  ) : null}
                  <div
                    className="acr-listing-icon"
                    data-toggle="tooltip"
                    title="Square Feet"
                  >
                    <i className="flaticon-ruler" />
                    <span className="acr-listing-icon-value">
                      {property.area}
                    </span>
                  </div>
                </div>
                <div className="listing-author">
                  <div className="listing-gallery-wrapper">
                    <a
                      href={"tel:" + property.mobile}
                      data-bs-toggle="modal"
                      data-bs-target="#callModal"
                      onClick={() => {
                        this.setState({
                          phone: property.mobile,
                          agentName: property.name,
                        });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fa fa-phone" aria-hidden="true">
                        {" "}
                      </i>
                      &nbsp;&nbsp;Call&nbsp;&nbsp;&nbsp;&nbsp;
                    </a>
                  </div>
                   <div className="listing-gallery-wrapper">
                    <a
                      href={"mailto:" + property.email}
                      onClick={() => {
                        this.setState({ email: property.email });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fas fa-envelope"></i> &nbsp; Email
                    </a>
                  </div>
                  <div className="listing-gallery-wrapper">
                    <a
                      href={
                        "https://wa.me/" +
                        property.mobile +
                        "?text=Hey%20" +
                        property.name_en +
                        ", %20I'm%20interested%20in%20your%20 " +
                        property.title_en +
                        ""
                      }
                      onClick={() => {
                        this.setState({ email: property.mobile });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </div>
                  <div className="agentNames">
                    <Link to={"/single-property/" + property.id}>
                      <img
                        src={
                          property.logo == undefined
                            ? baseurlImg + "/uploads/profiles/no_avatar.png"
                            : baseurlImg + "/uploads/profiles/" + property.logo
                        }
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ));
        this.setState({
          pageCount: Math.ceil(favourite.length / this.state.perPage),
          postData,
        });
      });
  };
  render() {
    return (
      <div>
        {/* ============================ User Dashboard ================================== */}
        <section className="gray pt-5 pb-5">
          <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="dashboard-body dashboard-wraper tab-content dash_user_menues flex-column"
                  id="v-pills-tabContent"
                  role="tablist"
                  aria-orientation="vertical"
                >
       
                    <div className="frm_submit_block">
                      <h4>Favourites</h4>
                      {this.state.postData}
                      <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                   </div>

                </div>
              </div>
            </div>
          </div>
         </section>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default connect(null, mapDispatchToProps)(Favourites);
