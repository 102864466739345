import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { baseurl } from '../Components/BaseUrl'

export default function SiteMapGenerator() {
    //  const [propertyType, setPropertyType] = useState([]);
     const [location, setLocation] = useState([]);
    //  const [purpose,   ]
   let propertyType = [];

        axios.get(baseurl + "propertytypes")
        .then((response) => {
            propertyType= (response.data.propertytypes)
           //  console.log("propertyTypes sitemap", propertyType)
        })
        .catch();
    

    const pro =()=> {

    }

 return (<urlset xmlns='http://www.sitemaps.org/schemas/sitemap/0.9'>
            {propertyType.map(res=>(
                <url>     
                <loc>https://findproperties.ae/for-rent/{res.slug_en}/uae</loc>
                <lastmod>2022-01-01</lastmod>
                <changefreq>daily</changefreq>
                <priority>0.8</priority>
                </url>
            )


            )
   }
        </urlset>
 )


}
