import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { baseurl } from '../../Components/BaseUrl'
import { baseurlImg } from '../../Components/BaseUrl'
import ReactPaginate from 'react-paginate'
import cookie from 'js-cookie'
import i18next from "i18next";

const lang =localStorage.getItem("lang") || "en";
const token = cookie.get("token")
var language = lang == "ar" ? "/ar" : ""

export class SingleAgent extends Component {
    constructor(props){
        super(props)
        this.state = {
            properties: [],
            offset: 0,
            perPage: 10,
            currentPage: 1,
            agentInfo: [],
            messageToAgent: ''
        }
        this.handlePageClick = this
        .handlePageClick
        .bind(this);
    }

    messageInputOnChange =(e)=> {
        this.setState({messageToAgent: e.target.value});
    }
    sendMessage = (agentId) => {
      const data = {messageBody:this.state.messageToAgent, reciever: agentId}

      axios.post(baseurl+"newmessage", data, {headers:{'Authorization': `Bearer ${token}`,'Accept':'application/json','Content-Type':'application/json'}}).then(response=>{    
      console.log("send Message", response.data)
      })
    }


    fetchAgent =() =>{
        this.setState({isLoading:true})
         let id = this.props.match.params.id;
         axios.get(baseurl+"show-agent/"+id)
         .then(response =>{
          console.log("response agent", response.data)
           this.setState({
            agentInfo: response.data.agent
           })
           console.log("agentInfo", this.state.agentInfo)
         const propertiesArr = response.data.properties;
         const sliceProp = propertiesArr.slice(this.state.offset, this.state.offset + this.state.perPage)
         const postPropertyData = sliceProp.map(property => <React.Fragment>
                               <div className="col-lg-6 col-md-6 col-sm-12">
                                   <div className="property-listing property-2">
                                   <div className="listing-img-wrapper">
                                       <div className="_exlio_125">{property.purpose==1?i18next.t("forrent"): i18next.t("forsale")}</div>
                                       <div className="list-img-slide">
                                       <div className="click">
                                           <div><a href={language+"/single-property/"+property.id}><img src={baseurlImg + "/uploads/properties/" + property.image} className="img-fluid mx-auto" alt /></a></div>
                                       </div>
                                       </div>
                                   </div>
                                   <div className="listing-detail-wrapper">
                                       <div className="listing-short-detail-wrap">
                                       {/* <div className="_card_list_flex mb-2">
                                           <div className="_card_flex_01">
                                           <span className="_list_blickes _netork">6 Network</span>
                                           <span className="_list_blickes types">Family</span>
                                           </div>
                                           <div className="_card_flex_last">
                                           <div className="prt_saveed_12lk">
                                               <label className="toggler toggler-danger"><input type="checkbox" /><i className="ti-heart" /></label>
                                           </div>
                                           </div>
                                       </div> */}
                                       <div className="_card_list_flex">
                                           <div className="_card_flex_01">
                                               <h4 className="listing-name verified"><a href={language+"/single-property/"+property.id} className="prt-link-detail">{lang=='en'?property.title_en:property.title_ar}</a></h4>
                                           </div>
                                       </div>
                                       </div>
                                   </div>
                                   <div className="price-features-wrapper">
                                       <div className="list-fx-features">
                                       {property.beds > -1? 
                                       <div className="listing-card-info-icon">
                                           <div className="inc-fleat-icon"><i className="flaticon-bedroom"></i></div>{property.beds == 0 ? i18next.t("studio") : property.beds}
                                       </div>
                                       :null}
                                       {property.baths>0?
                                       <div className="listing-card-info-icon">
                                           <div className="inc-fleat-icon"><i className="flaticon-bathroom"></i></div>{property.baths}
                                       </div>
                                       :null}
                                       {property.area>0?
                                       <div className="listing-card-info-icon">
                                           <div className="inc-fleat-icon"><i className="flaticon-ruler"></i></div>{property.area}
                                       </div>
                                       :null}
                                       </div>
                                   </div>
                                   <div className="listing-detail-footer">
                                       <div className="footer-first">
                                       <h6 className="listing-card-info-price mb-0 p-0">{property.price.toLocaleString()} {i18next.t("aed")}</h6>
                                       </div>
                                       <div className="footer-flex">
                                       <a href={language+"/single-property/"+property.id} className="prt-view"> {i18next.t("viewdetail")}</a>
                                       </div>
                                   </div>
                                   </div>
                              </div>
         </React.Fragment>)
             this.setState({
              pageCount: Math.ceil(propertiesArr.length / this.state.perPage), postPropertyData
         })
         
          //this.setState({categories:response.data.categories})
       })
         .catch()

    }
    componentDidMount(){
        this.fetchAgent();

     }
     handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * this.state.perPage;

      this.setState({
          currentPage: selectedPage,
          offset: offset
      }, () => {
          this.fetchAgent()

      });
  };
    render() {
       const lang= localStorage.getItem("lang") || "en"
        return (
            <section className="gray">
            <div className="container">
                <div className="row" style={{marginTop: '3vw'}}>
                {/* property main detail */}
                <div className="col-lg-8 col-md-12 col-sm-12">
                    {/* Single Block Wrap */}

                    {/* {this.state.agentInfo.agency_summary_en || this.state.agencySummary.agency_summary_ar? */}

                    {/* {this.state.agentInfo.profile == undefined?  */}
                    {/* <div className="property_block_wrap">
                        <div className="property_block_wrap_header">
                            <h4 className="property_block_title">{i18next.t("agentinfo")}</h4>
                        </div>
                        <div className="block-body">
                            {/* <p>{''}</p> */}
                            {/* <ul className="social-icons">
                                {/* <li><a className="facebook" href="#"><i className="lni-facebook" /></a></li>
                                <li><a className="twitter" href="#"><i className="lni-twitter" /></a></li>
                                <li><a className="linkedin" href="#"><i className="lni-instagram" /></a></li>
                                <li><a className="linkedin" href="#"><i className="lni-linkedin" /></a></li> */}
                            {/* </ul>
                        </div>
                    </div>   */}
                    {/* :null} */}
                    {/* Single Block Wrap */}
                    <div className="_prtis_list mb-4">
                    </div>
                    {/* Single Block Wrap */}
                    <div className="div_wraps">
                    <div className="row">
                        <div className="col-lg-12 col-md 12 col-sm-12">
                        <h4>{i18next.t("allproperties")}</h4>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                      {this.state.postPropertyData}
                                    <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}/>
                    </div>
                </div>
                {/* property Sidebar */}
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="property-sidebar">
                    {/* Agent Detail */}
                    <div className="sider_blocks_wrap">
                        <div className="side-booking-body">
                        <div className="agent-_blocks_title">
                            <div className="agent-_blocks_thumb">
                                {this.state.agentInfo.profile == undefined? 
                                <img src={baseurlImg + "/uploads/profiles/no_avatar.png"}  alt />:
                                <img src={baseurlImg + '/uploads/profiles/' + this.state.agentInfo.profile}  alt />}
                            </div>
                            <div className="agent-_blocks_caption">
                            <h4>{lang=="en"?this.state.agentInfo.name_en: this.state.agentInfo.name_ar}</h4>
                            <span className="approved-agent"><i className="ti-check" />{i18next.t("approved")}</span>
                            </div>
                            <div className="clearfix" />
                        </div>

                             <a href={"tel: +"+this.state.agentInfo.mobile} className="mortgage" >{i18next.t("call")}</a>
                  
                         </div>
                        <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                            <div  style={{ width: "22rem", margin: "0 auto" }}
                                    className="modal-dialog modal-dialog-centered modal-dialog-scrollable" >
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{textAlign: 'centered'}}className="modal-title" id="exampleModalToggleLabel">Contact us</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{margin: '0 auto'}}>
                                    {/* <p style={{textAlign: 'center'}}><b>Madina Real Estate</b></p> */}
                                    <div className="row border">
                                        <div className="col">Mobile</div>
                                        <div className="col">: <a href={"tel:"+ this.state.agentInfo.mobile+""}>{this.state.agentInfo.mobile}</a></div>
                                    </div>
                                    <div className="row border">
                                        <div className="col">Agent</div>
                                        <div className="col">{lang=="en"?this.state.agentInfo.name_en: this.state.agentInfo.name_ar}</div>
                                    </div>         
                                    <div className="row" style={{paddingTop : '2rem'}}>
                                        <div className="col">Please mention that you have seen this Ad on findproperties.ae</div>
                                    </div>      
                                </div>

                                </div>
                            </div>
                            </div>

                    </div>
                    {/* Featured Property */}
                    <div className="sidebar-widgets">
                        {/* <h4>Similar Property</h4> */}
                        <div className="sidebar-property">
                        {/* List Sibar Property */}
                        {/* <div className="sides_list_property">
                            <div className="sides_list_property_thumb">
                            <img src="./images/p-1.png" className="img-fluid" alt />
                            </div>
                            <div className="sides_list_property_detail">
                            <h4><a href="single-property-1.html">Loss vengel New Apartment</a></h4>
                            <span><i className="ti-location-pin" />Sans Fransico</span>
                            <div className="lists_property_price">
                                <div className="lists_property_types">
                                <div className="property_types_vlix sale">{}</div>
                                </div>
                                <div className="lists_property_price_value">
                                <h4>$4,240</h4>
                                </div>
                            </div>
                            </div>
                        </div> */}
                        {/* List Sibar Property */}
                        {/* <div className="sides_list_property">
                            <div className="sides_list_property_thumb">
                            <img src="./images/p-4.png" className="img-fluid" alt />
                            </div>
                            <div className="sides_list_property_detail">
                            <h4><a href="single-property-1.html">Montreal Quriqe Apartment</a></h4>
                            <span><i className="ti-location-pin" />Liverpool, London</span>
                            <div className="lists_property_price">
                                <div className="lists_property_types">
                                <div className="property_types_vlix">For Rent</div>
                                </div>
                                <div className="lists_property_price_value">
                                <h4>$7,380</h4>
                                </div>
                            </div>
                            </div>
                        </div> */}
                        {/* List Sibar Property */}
                        {/* <div className="sides_list_property">
                            <div className="sides_list_property_thumb">
                            <img src="./images/p-7.png" className="img-fluid" alt />
                            </div>
                            <div className="sides_list_property_detail">
                            <h4><a href="single-property-1.html">Curmic Studio For Office</a></h4>
                            <span><i className="ti-location-pin" />Montreal, Canada</span>
                            <div className="lists_property_price">
                                <div className="lists_property_types">
                                <div className="property_types_vlix buy">For Buy</div>
                                </div>
                                <div className="lists_property_price_value">
                                <h4>$8,730</h4>
                                </div>
                            </div>
                            </div>
                        </div> */}
                        {/* List Sibar Property */}
                        {/* <div className="sides_list_property">
                            <div className="sides_list_property_thumb">
                            <img src="./images/p-5.png" className="img-fluid" alt />
                            </div>
                            <div className="sides_list_property_detail">
                            <h4><a href="single-property-1.html">Montreal Quebec City</a></h4>
                            <span><i className="ti-location-pin" />Sreek View, New York</span>
                            <div className="lists_property_price">
                                <div className="lists_property_types">
                                <div className="property_types_vlix">For Rent</div>
                                </div>
                                <div className="lists_property_price_value">
                                <h4>$6,240</h4>
                                </div>
                            </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>					
            </div>	
            </section>
        )
    }
}

export default SingleAgent