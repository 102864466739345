import React, { Component } from "react";
import $ from "jquery";
// import API from "API";
import { Link } from "react-router-dom";
import { baseurl } from "../../Components/BaseUrl";
import i18next from "i18next";
// import Particles from "react-particles-js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import API from "../../API";
import {  FaSearch, FaMap} from "react-icons/fa";
// import Form from 'react-bootstrap/Form';

var width = $(window).width();
// $(".advanced-search-trigger").on("click", function () {
//   $(".advanced-search").slideToggle(200);
//   $(this).toggleClass("active");
//   $('#searchProperty').appendTo("#searchPropertyOnMap");
//   alert("hello dea")
// });
$(".filter-trigger").on("click", function () {
  $(".acr-filter-form").slideToggle(300);
});

// const lang = localStorage.getItem("lang") || "en"


var url = window.location.href;
var url = url.split("?")[0]
var splitUrl = url.split("/")
var lang = splitUrl[3] == "ar" ? "ar" : "en"
var language = lang == "en" ? "" : "/ar"

localStorage.setItem("lang", lang)


export default class BannerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyStyle: 0,
      propertytypes: [],
      scrollStyle: {},
      locations: [],
      selectedLocations: [],
      subLocationSend: [],
      passPurpose: 1,
      selectedPurpose: true,
      passType: lang == "en" ? "properties" : "عقارات",
      pricefrom: 0,
      priceto: 1000000000,
      bedroom: -1,
      bathroom: 0,
      frequency: 0,
      locForUrl: lang == "en" ? "uae" : "الامارات",
      furnishedornot: 0,
      urlPurpose: "for-rent",
      urlPropertyType: "properties",
      urlLocation: "uae",
      urlbedroom: "",
      passTypeNameSelected: lang == "en" ? "Properties" : "عقارات",
      passDisplayType: 0,
      passLocationTypeId: 0,
      passUrlForSeo: "for-rent/properties/uae",
    };
    this.propertyPurposeChangleHandle = this.propertyPurposeChangleHandle.bind(this);

  }

  HandlePropertyStyle = (id, e) => {
    e.preventDefault();
    this.setState({ propertyStyle: id });
  };
  propertyPurposeChangleHandle = (e) => {
    this.setState({
      passPurpose: e.target.value,
      urlPurpose: e.target.value == 1 ? "for-rent" : e.target.value == 2 ? "for-sale" : "for-sharing",
    });
    // var bedroom = $("#bedroom").find(":selected").attr('name')===undefined?"":$("#bedroom").find(":selected").attr('name')
    // const urlPurpose = {
    //   purposeUrl:  $("#propertyPurpose").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-')+"/",
    //   propertyTypeUrl: $("#propertyType").find(":selected").attr('name')=="properties"? "properties/": bedroom + $("#propertyType").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-')+"/",
    //   subLocationSend: this.state.subLocationSend
    // };
    const data = { lang: lang, id: e.target.value }
    API.post("propertytypeByPurposeTwo", data)
      .then((response) => {
        //  //console.log(response.data)
        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch();
  };

  // propertyUrl =(data) => {
  //   var type = data.propertyType
  //   var purpose= data.purpose
  //   var location = data.subLocationSend==undefined || data.subLocationSend.length ==0? "uae" :  data.subLocationSend.location.toLowerCase().replace(/\s+/g, '-')+"/";
  //   const url= purpose + type + location
  //   path= "/"+ url
  // }

  propertyTypeOnChangeHadnler = (e) => {
    this.setState({
      passType: $("#propertyTypeSelect").find(":selected").attr('name'),
      furnishedornot: e.target.selectedOptions[0].getAttribute('furnishedornot'),
      urlPropertyType: $("#propertyTypeSelect").find(":selected").attr('data-type-for-url'),
      passTypeNameSelected: $("#propertyTypeSelect").find(":selected").attr('typeSelected'),

    });
    // var bedroom = $("#bedroom").find(":selected").attr('name')===undefined?"":$("#bedroom").find(":selected").attr('name')
    // const urlPurpose = {
    //   purpose:  $("#propertyPurpose").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-')+"/",
    //   propertyType: $("#propertyTypeSelect").find(":selected").attr('name')=="properties"? "properties/": bedroom + $("#propertyTypeSelect").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-')+"/",
    //   subLocationSend: this.state.subLocationSend
    // };
    //console.log("pass Type name", this.state.passTypeNameSelected)
  };
  priceFromHandle = (e) => {
    //console.log("price from", e.target.value);
    this.setState({
      pricefrom: e.target.value,
    });
  };
  priceToHandle = (e) => {
    this.setState({
      priceto: e.target.value,
    });
  };
  frequencyHandle = (e) => {
    this.setState({
      frequency: e.target.value,
    });
  };
  bedRoomOnChangeHandle = (e) => {
    this.setState({
      bedroom: e.target.value,
      urlbedroom: e.target.value == 0 ? "studio" + "-" : e.target.value + "-" + "bedroom" + "-",
    });
  }
  bathRoomOnChangeHandle = (e) => {
    this.setState({
      bathroom: e.target.value,
    });
    //console.log("frequency", e.target.value);
  }
  fetchData = () => {
    this.setState({ isLoading: true });

    const data = { lang: lang, id: this.state.passPurpose }
    API
      .post("propertytypeByPurposeTwo", data)
      .then((response) => {

        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch();

    const data2 = { lang: lang }

    API
      .get("locations")
      .then((response) => {
        this.setState({
          isLoading: false,
          locations: lang == "en" ? response.data.locations : response.data.locations_ar,
        });
      })
      .catch();
  };

  componentDidMount() {
    // const { containerStyle } = "position: 'fixed', top: '0', zIndex: '999', background: '#fff', padding: '10px'";
    this.fetchData();
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      $(".zoom img").css({
        transform: 'translate3d(-50%, -' + (scroll / 100) + '%, 0) scale(' + (100 + scroll / 5) / 100 + ')',
        //Blur suggestion from @janwagner: https://codepen.io/janwagner/ in comments
        //"-webkit-filter": "blur(" + (scroll/200) + "px)",
        //filter: "blur(" + (scroll/200) + "px)"
      });
    });

    // $(document).ready(function () {
    //   width <= 600 ? $('#searchProperty').insertBefore("#searchPropertyOnMap") : $('#searchProperty').insertAfter("#propertyType")
    //   $(".advanced-search-trigger").on("click", function () {
    //     $(".advanced-search").slideToggle(200);
    //     $(this).toggleClass("active");
    //     //  width<=600 && $('.advanced-search-trigger').hasClass('active')? $('#searchProperty').insertBefore("#searchPropertyOnMap"):$('#searchProperty').insertAfter("#propertyType")

    //   });
    // });
  }


  render() {
    // var langinner = (window.location.href).includes("ar")? "ar": "en"

    // if(localStorage.getItem("lang")=="en" && (window.location.href).includes("ar")) {
    //   localStorage.setItem("lang", "ar")
    //   langinner = "ar"

    // }
    // else if(localStorage.getItem("lang")=="ar" && !(window.location.href).includes("ar")) {
    //   localStorage.setItem("lang", "en")
    //   langinner = "en"
    // }

    //alert(langinner)

    // var language= langinner=="en"?"": "/ar"

    return (
      <>
        {/* <div>
          <header className="zoom">
            <img src="./frontendUI/img/banner.jpg" />
          </header>
          <main role="main">
            <div className="content">

            </div>
          </main>
        </div> */}
        <div className="banner banner-1 banner-3 dark-overlay bg-cover bg-center">
          <div className="container">
            <div className="banner-item">
              <div className="banner-inner">
                <div className="banner-text">
                  <h1 lang={lang == "en" ? "en" : "ar"} className="title">
                    {i18next.t("findPropertieslogo")}
                  </h1>
                  <p lang={lang == "en" ? "en" : "ar"} className="subtitle">
                    {i18next.t("uaelargestportal")}
                  </p>
                </div>
                <div className="acr-filter-form">
                  <div className="for-sale-rent-wrapper" onChange={this.propertyPurposeChangleHandle}>
                    
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="for-rent"
                        // defaultValue="option1"
                        defaultChecked
                        value={1}
                      // checked={this.state.purpose == 1}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="for-rent" >
                        <span> {i18next.t("rent")}</span>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="for-sale"
                        defaultValue="option2"
                        value={2}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="for-sale">
                        <span> {i18next.t("buy")}</span>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="sharing"
                        defaultValue="option3"
                        value={3}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="sharing">
                        <span> {i18next.t("sharing")}</span>
                      </label>
                    </div>
                  </div>
                  <form method="post">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <Autocomplete
                          multiple
                          limitTags={3}
                          id="multiple-limit-tags"
                          options={this.state.locations}
                          onChange={(event, newValue) => {
                            console.log("home page ac change", newValue)
                            this.setState({
                              selectedLocations: newValue,
                              subLocationSend: newValue.length > 0 ? newValue[0] : [],
                              urlLocation: newValue == undefined || newValue.length == 0 ? "uae" : newValue[0].slug_en,
                              locForUrl: newValue.length > 0 ? newValue[0].location : [],
                              passLocationTypeId: newValue[0].type,
                            });
                            var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('slug_en')
                            const urlPurpose = {
                              purpose: $("#propertyPurpose").find(":selected").attr('slug_en'),
                              propertyType: "/" + $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + "/" + this.state.propertyType + "/",
                              subLocationSend: newValue.length > 0 ? newValue[0] : []
                            };
                            // this.propertyUrl(urlPurpose)

                          }}
                          getOptionLabel={(option) => option.location}
                          value={this.state.selectedLocations}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label=""
                              placeholder={lang == 'en' ? "Location/Emirate/city/street/building" : "الموقع/اماره/مدينه/شارع/مبنى"}
                            />
                          )}

                        />
                      </div>
                      <div id="propertyType" className="col-lg-4 col-md-6">
                        <div className="form-group">
                          {/* <label>Property Type: </label> */}
                          <select className="form-group" id="propertyTypeSelect"
                            onChange={this.propertyTypeOnChangeHadnler}>
                            {/* <option selected value="*">
                            {i18next.t("propertytype")}
                          </option> */}
                            {this.state.propertytypes.map((prop) => (
                              <option
                                name={lang == "en" ? prop.slug_en : prop.slug_ar}
                                typeSelected={lang == "en" ? prop.typeName_en : prop.typeName_ar}
                                data-type-for-url={prop.slug_en}
                                data-furnished={prop.furnishedornot}
                                selected={prop.id == this.state.propertyType ? 'selected' : null}
                                bedroomBath={prop.bedandbath}
                                value={prop.id}>{lang == "en" ? prop.typeName_en : prop.typeName_ar}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div id="searchProperty" className="submit-btn col-lg-2 col-md-6">
                        <div className="form-group">
                          <Link to={{
                            pathname: language + "/" + this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation,
                            state: {
                              passDisplayType: 0,
                              passLocation: this.state.selectedLocations,
                              passsubLocationSend: this.state.subLocationSend,
                              passPurpose: this.state.passPurpose,
                              passType: this.state.passType,
                              passBed: this.state.bedroom,
                              passBath: this.state.bathroom,
                              passPricefrom: this.state.pricefrom,
                              passPriceto: this.state.priceto,
                              passFrequency: this.state.frequency,
                              furnishedornot: this.state.furnishedornot,
                              locForUrl: this.state.locForUrl,
                              passPropTypeName: this.state.passTypeNameSelected,
                              passLocationTypeId: this.state.passLocationTypeId,
                              passUrlForSeo: this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation,
                              // i18next.t("properties")
                            }
                          }
                          }
                            type="submit"
                            className="btn-custom secondary btn-block"
                            name="button">
                            <FaSearch />&nbsp;
                            {i18next.t("findProperties")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="advanced-search2">
                      <div className="row">
                        <div
                          // style={{ marginBottom: "-10px" }}
                          className="col-lg-2 col-md-6  d-sm-none d-md-block d-none">
                          <div className="form-group">
                            <input
                              onChange={this.priceFromHandle}
                              className="form-group col-lg-2"
                              type="number"
                              placeholder={i18next.t("from")}
                            ></input>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 d-sm-none d-md-block d-none ">
                          <div className="form-group">
                            <input
                              onChange={this.priceToHandle}
                              className="form-group col-lg-2"
                              type="number"
                              placeholder={i18next.t("to")}
                            ></input>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 d-none d-lg-block d-xl-block">
                          <div className="form-group">
                            <select id="bedroom" className="form-group" onChange={this.bedRoomOnChangeHandle}>
                              <option value="-1" selected>{i18next.t("bedroom")}</option>
                              <option value="0">{i18next.t("studiobed")}</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="20+">20+</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 d-none d-lg-block d-xl-block">
                          <div className="form-group">
                            <select id="bathroom" className="form-group" name="baths" onChange={this.bathRoomOnChangeHandle}>
                              <option selected value="*">
                                {i18next.t("bathroom")}
                              </option>
                              <option val="1">1</option>
                              <option val="2">2</option>
                              <option val="3">3</option>
                              <option val="4">4</option>
                              <option val="5">5</option>
                              <option val="6">6</option>
                              <option val="7">7</option>
                              <option val="8">8</option>
                              <option val="9">9</option>
                              <option val="10">10</option>
                              <option val="11">11</option>
                              <option val="12">12</option>
                              <option val="13">13</option>
                              <option val="14">14</option>
                              <option val="15">15</option>
                              <option val="16">16</option>
                              <option val="17">17</option>
                              <option val="18">18</option>
                              <option val="19">19</option>
                              <option val="20">20</option>
                              <option val="20+">20+</option>
                            </select>
                          </div>
                        </div>

                        {/* Mobile bath and bed */}
                        {/* <div className="d-flex d-xs-block d-sm-block d-md-none d-lg-none">
                        <div className="col">
                          <div className="form-group">
                            <select className="form-group" onChange={this.bedRoomOnChangeHandle}>
                                <option value="-1" selected>  {i18next.t("bedroom")}</option>
                                <option value="0">{i18next.t("studiobed")}</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="20+">20+</option>
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <select className="form-group" name="baths" onChange={this.bathRoomOnChangeHandle}>
                              <option selected value="*">
                                {i18next.t("bathroom")}
                              </option>
                              <option val="1">1</option>
                              <option val="2">2</option>
                              <option val="3">3</option>
                              <option val="4">4</option>
                              <option val="5">5</option>
                              <option val="6">6</option>
                              <option val="7">7</option>
                              <option val="8">8</option>
                              <option val="9">9</option>
                              <option val="10">10</option>
                              <option val="11">1</option>
                              <option val="12">12</option>
                              <option val="13">13</option>
                              <option val="14">14</option>
                              <option val="15">15</option>
                              <option val="16">16</option>
                              <option val="17">17</option>
                              <option val="18">18</option>
                              <option val="19">19</option>
                              <option val="20">20</option>
                              <option val="20+">20+</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex d-xs-block d-sm-block d-md-none d-lg-none">
                        <div className="col">
                          <div className="form-group">
                            <input
                              onChange={this.priceFromHandle}
                              className="form-group col-lg-2"
                              type="number"
                              placeholder={i18next.t("from")}
                            ></input>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              onChange={this.priceToHandle}
                              className="form-group col-lg-2"
                              type="number"
                              placeholder={i18next.t("to")}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6 d-sm-none d-md-block d-none ">
                        <div className="form-group">
                          {$('#for-rent').prop("checked") == true==1?
                          <select
                            onChange={this.frequencyHandle}
                            className="form-group"
                            name="type">
                            <option selected value="*">
                              {i18next.t("frequency")}
                            </option>
                            <option value="1">{i18next.t("daily")}</option>
                            <option value="2">{i18next.t("weekly")}</option>
                            <option value="3">{i18next.t("monthly")}</option>
                            <option value="4">{i18next.t("yearly")}</option>
                          </select>:null}
                        </div>
                      </div> */}
                        <div id="searchPropertyOnMap" className="submit-btn col-lg-2 col-md-6">
                          <div className="form-group">
                            <Link
                               type="submit"
                              className="btn-custom secondary btn-block"
                              name="button"
                              //   to={{
                              to={{
                                // pathname: language+"/"+this.state.urlPurpose+"/"+this.state.urlbedroom+this.state.urlPropertyType+"/"+this.state.urlLocation,
                                pathname: language + "/" + this.state.urlPurpose + "/" + this.state.urlbedroom + this.state.urlPropertyType + "/" + this.state.urlLocation,
                                state: {
                                  passDisplayType: 2,
                                  passLocation: this.state.selectedLocations,
                                  passsubLocationSend: this.state.subLocationSend,
                                  passPurpose: this.state.passPurpose,
                                  passType: this.state.passType,
                                  passBed: this.state.bedroom,
                                  passBath: this.state.bathroom,
                                  passPricefrom: this.state.pricefrom,
                                  passPriceto: this.state.priceto,
                                  passFrequency: this.state.frequency,
                                  furnishedornot: this.state.furnishedornot,
                                  locForUrl: this.state.locForUrl,
                                  passPropTypeName: this.state.passTypeNameSelected,
                                  passLocationTypeId: this.state.passLocationTypeId,
                                }
                              }}
                            >
                              <FaMap/> &nbsp;
                              {i18next.t("findonmap")}{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                  </form>
                  {/* <div className="advanced-search-trigger semi-circle active">
                  <i className="fas fa-chevron-down" />
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}
