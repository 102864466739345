import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { baseurl, baseurlImg } from '../Components/BaseUrl'
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";
// import ResponsiveDisplayAd from '../Components/Ads/ResponsiveDisplayAd';
import AdSense from 'react-adsense';


const lang = localStorage.getItem('lang') || 'en'

var url = window.location.href;
var splitUrl = url.split("/")
var urlEn =  splitUrl[4];
var urlAr = splitUrl[5];
var urlForSeo = lang == "en" ? urlEn : urlAr
var language = lang == "ar" ? "ar/" : ""

class SingleBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: {},
            blogCategories: []
        }
    }

    fetchBlog = () => {
        let id = this.props.match.params.id;
        axios.get(baseurl + "single-blog/" + id)
            .then(response => {
                //console.log(response.data)
                this.setState({ blog: response.data.blog })
            })
    }
    fetchCategories = () => {
        axios.get(baseurl + "blogscategories")
            .then(response => {
                this.setState({ blogCategories: response.data.blogcategories })
                //console.log(response.data.blogcategories)
            })
    }
    componentDidMount() {
        this.fetchBlog()
        this.fetchCategories()
    }
    render() {
        return (
            <section className="gray">
                <Helmet>
                    <title>{lang == 'en' ? this.state.blog.seotitle_en : this.state.seotitle_ar}</title>
                    <meta name="title" content={lang == 'en' ? this.state.blog.seotitle_en : this.state.seotitle_ar} data-react-helmet="true"/>

                    <meta name="description" content={lang == 'en' ? this.state.blog.metadescription_en : this.state.metadescription_ar} data-react-helmet="true"/>
                    <meta name="keywords" content={lang == 'en' ? this.state.blog.focuskeyphrases_en : this.state.focuskeyphrases_ar} data-react-helmet="true" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content={lang == 'en' ? this.state.blog.seotitle_en : this.state.seotitle_ar} data-react-helmet="true" />
                    <meta itemprop="description" content={lang == 'en' ? this.state.blog.metadescription_en : this.state.metadescription_ar} data-react-helmet="true" />
                    <meta itemprop="image" content={lang == "en" ? baseurlImg + "/uploads/blogs/" + this.state.blog.image : baseurlImg + "/uploads/blogs/" + this.state.blog.image_ar} data-react-helmet="true"/>

                    {/* <!-- Facebook Meta Tags --> */}
                    {lang=="en"?
                       <meta property="og:url" content={"https://findproperties.ae/single-blog/"+this.state.blog.slug_en} data-react-helmet="true" />:
                       <meta property="og:url" content={"https://findproperties.ae/single-blog/"+this.state.blog.slug_ar} data-react-helmet="true"/>
                      }
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={lang == 'en' ? this.state.blog.seotitle_en : this.state.seotitle_ar} />
                    <meta property="og:description" content={lang == 'en' ? this.state.blog.metadescription_en : this.state.metadescription_ar} />
                    <meta property="og:image" content={lang == "en" ? baseurlImg + "/uploads/blogs/" + this.state.blog.image : baseurlImg + "/uploads/blogs/" + this.state.blog.image_ar} data-react-helmet="true" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content={lang == "en" ? baseurlImg + "/uploads/blogs/" + this.state.blog.image : baseurlImg + "/uploads/blogs/" + this.state.blog.image_ar} data-react-helmet="true"/>
                    <meta name="twitter:title" content={lang == 'en' ? this.state.blog.seotitle_en : this.state.seotitle_ar} />
                    <meta name="twitter:description" content={lang == 'en' ? this.state.blog.metadescription_en : this.state.metadescription_ar} />
                    <meta name="twitter:image" content={lang == "en" ? baseurlImg + "/uploads/blogs/" + this.state.blog.image : baseurlImg + "/uploads/blogs/" + this.state.blog.image_ar} data-react-helmet="true" />
                    
                    {lang== "en"?<link rel="canonical" href={language+"/blogs/" + urlForSeo} />: <link rel="canonical" href={language +"/blogs/"+ urlForSeo} />}
                    <link rel="alternate" hreflang="en" href={"/blogs/"+urlForSeo} />
                    <link rel="alternate" hreflang="ar" href={"/ar/blogs/" + urlForSeo} />
                    <meta name="robots" content="index, follow"></meta>

                </Helmet>
                <div className="container blog">
                    {/* row Start */}
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            {/* <ResponsiveDisplayAd classApply="desktop_banner" /> */}
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                          {/* <ResponsiveDisplayAd /> */}
                        </div>
                    </div>
                    <div className="row">
                        {/* Blog Detail */}
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="article_detail_wrapss single_article_wrap format-standard">
                                <h1  lang={lang=="en"?"en":"ar"}>{lang == 'en' ? this.state.blog.title_en : this.state.title_ar} </h1>
                                <div className="article_body_wrap">
                                    <div className="article_featured_image">
                                        {/* <Link to={'/blogs/' + this.state.blog.id}> */}
                                        <img className="img-fluid" src={lang == "en" ? baseurlImg + "/uploads/blogs/" + this.state.blog.image : baseurlImg + "/uploads/blogs/" + this.state.blog.image_ar} alt />
                                        {/* </Link> */}
                                    </div>
                                    {/* <div className="article_top_info"> */}
                                    {/* <ul className="article_middle_info">
                                    <li><a href="#"><span className="icons"><i className="ti-user" /></span>by Rosalina Doe</a></li>
                                    <li><a href="#"><span className="icons"><i className="ti-comment-alt" /></span>45 Comments</a></li>
                                </ul>
                                </div> */}
                                    {/* <h2 className="post-title">Lorem ipsum dolor sit amet, cons pisicing elit, sed do.</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut 
                                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem. <br /><br /> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p> */}
                                    {/* <blockquote>
                                {/* <span className="icon"><i className="fas fa-quote-left" /></span>
                                <p className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tem
                                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ullamco laboris nisi ut aliquip ex ea commodo onsequat.</p>
                                <h5 className="name">- Rosalina Pong</h5> */}
                                    {/* </blockquote> */}
                                    {ReactHtmlParser(lang == "en" ? this.state.blog.description_en : this.state.blog.description_ar)}
                                </div>


                            </div>
                            {/* Author Detail */}
                            {/* <div className="article_detail_wrapss single_article_wrap format-standard">
                        <div className="article_posts_thumb">
                            <span className="img"><img className="img-fluid" src="assets/img/user-1.jpg" alt /></span>
                            <h3 className="pa-name">Rosalina William</h3>
                            <ul className="social-links">
                            <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="#"><i className="fab fa-twitter" /></a></li>
                            <li><a href="#"><i className="fab fa-behance" /></a></li>
                            <li><a href="#"><i className="fab fa-youtube" /></a></li>
                            <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                            </ul>
                            <p className="pa-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                        </div>
                        </div> */}
                            {/* Blog Comment */}

                        </div>
                        {/* Single blog Grid */}
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            {/* Categories */}
                            {/* <div className="single_widgets widget_category">
                        <h4 className="title">Categories</h4>
                            <ul>
                                {this.state.blogCategories.map((blogcat)=>(
                                    <li><a href="#">{lang=='en'?blogcat.name_en:blogcat.name_ar} <span>{blogcat.count_of_blogs}</span></a></li>
                                ) )}
                            </ul>
                        </div> */}
                            {/* Trending Posts */}
                            {/* <div className="single_widgets widget_thumb_post">
                        <h4 className="title">Trending Posts</h4> */}
                            {/* <ul>
                                    <li>
                                    <span className="left">
                                        <img src="assets/img/b-1.jpg" alt className />
                                    </span>
                                    <span className="right">
                                        <a className="feed-title" href="#">Alonso Kelina Falao Asiano Pero</a> 
                                        <span className="post-date"><i className="ti-calendar" />10 Min ago</span>
                                    </span>
                                    </li>
                                    <li>
                                    <span className="left">
                                        <img src="assets/img/b-2.jpg" alt className />
                                    </span>
                                    <span className="right">
                                        <a className="feed-title" href="#">It is a long established fact that a reader</a> 
                                        <span className="post-date"><i className="ti-calendar" />2 Hours ago</span>
                                    </span>
                                    </li>
                                    <li>
                                    <span className="left">
                                        <img src="assets/img/b-3.jpg" alt className />
                                    </span>
                                    <span className="right">
                                        <a className="feed-title" href="#">Many desktop publish packages and web</a> 
                                        <span className="post-date"><i className="ti-calendar" />4 Hours ago</span>
                                    </span>
                                    </li>
                                    <li>
                                    <span className="left">
                                        <img src="assets/img/b-4.jpg" alt className />
                                    </span>
                                    <span className="right">
                                        <a className="feed-title" href="#">Various versions have evolved over the years</a> 
                                        <span className="post-date"><i className="ti-calendar" />7 Hours ago</span>
                                    </span>
                                    </li>
                                    <li>
                                    <span className="left">
                                        <img src="assets/img/b-5.jpg" alt className />
                                    </span>
                                    <span className="right">
                                        <a className="feed-title" href="#">Photo booth anim 8-bit PBR 3 wolf moon.</a> 
                                        <span className="post-date"><i className="ti-calendar" />3 Days ago</span>
                                    </span>
                                    </li>
                                </ul> */}
                            {/* </div> */}
                            {/* Tags Cloud */}
                            <div className="single_widgets widget_tags">
                                <h4 className="title">Tags</h4>
                                <ul>
                                    <li><a href="#">Lifestyle</a></li>
                                    <li><a href="#">Travel</a></li>
                                    <li><a href="#">Fashion</a></li>
                                    <li><a href="#">Branding</a></li>
                                    <li><a href="#">Music</a></li>
                                </ul>
                            </div>
                            <AdSense.Google
                                client='ca-pub-6069660640889179'
                                slot='2582825295'
                                style={{ display: 'block' }}
                                format='auto'
                                responsive='true'
                                layoutKey='-gw-1+2a-9x+5c'
                            />
                            {/* <ResponsiveDisplayAd />
                            <ResponsiveDisplayAd /> */}



                            {/* <div className="foot-news-last">
  <div className="input-group">
    <input type="text" className="form-control" placeholder="Email Address" />
    <div className="input-group-append">
      <button type="button" className="input-group-text theme-bg b-0 text-light">Subscribe</button>
    </div>
  </div>
</div> */}



                        </div>
                    </div>
                    {/* /row */}
                </div>
            </section>

        )
    }
}

export default SingleBlog
