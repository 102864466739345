import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import { Loader } from '@googlemaps/js-api-loader';
import "../pages/Property/SingleProperty/SingleProperty.css";
import { Link } from "react-router-dom";
import { websitebaseurl } from "../Components/BaseUrl";
import $ from "jquery";
import PropagateLoader from "react-spinners/PropagateLoader";
import "./Property/SingleProperty/SingleProperty.css";
import i18next from "i18next";
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { baseurlImg } from "../Components/BaseUrl";
import ReactPaginate from "react-paginate";
import User from './CommonPageComponets/User';
import API from "../API";
import { store } from '../store';
// import { PropertyPageTrack } from "../Utility/track";
import Pagination from "react-js-pagination";
// import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaSortAmountDown } from "react-icons/fa";

var w = window.innerWidth;
var url = window.location.href;
var url = url.split("?")[0]
var splitUrl = url.split("/")
var lang = splitUrl[3] == "ar" ? "ar" : "en"
var language = lang == "en" ? "" : "/ar"

localStorage.setItem("lang", lang)


// if (localStorage.getItem("lang") == "en" && containAr == 2) {
//   localStorage.setItem("lang", "ar")
//   lang = "ar"
// }
// else if (localStorage.getItem("lang") == "ar" && containAr == 1) {
//   localStorage.setItem("lang", "en")
//   lang = "en"
// }
// else if (localStorage.getItem("lang") == "en" && containAr == 1) {
//   localStorage.setItem("lang", "en")
//   lang = "en"
// }
// else if (localStorage.getItem("lang") == "ar" && containAr == 2) {
//   localStorage.setItem("lang", "ar")
//   lang = "ar"
// }
// else if (localStorage.getItem("lang") === null && containAr == 2) {
//   localStorage.setItem("lang", "ar")
//   lang = "ar"
// }
// else if (localStorage.getItem("lang") === null && containAr == 1) {
//   localStorage.setItem("lang", "en")
//   lang = "en"
// }
const google = window.google;
// The location of Uluru
const loader = new Loader({
  apiKey: "AIzaSyCq6FzlBsSuT-S2zaRh2Rd-i4205DCu57s",
  // libraries: ["places"]
});
const UAE_BOUND = {
  north: 26.037042,
  south: 23.765237,
  west: 53.211269,
  east: 56.896095,
};

var width = $(window).width();

// const forrentar = "للايجار";
// const forsalear = "للبيع";
// const forsharear = "سكن-مشترك";

var url = window.location.href;

var url = url.split("?")[0]

var splitUrl = url.split("/")

var locationForUrl = decodeURI(splitUrl[splitUrl.length - 1].replace("-", " "));

console.log("locationForUrl", locationForUrl);
var indexLang = lang == "en" ? 0 : 1;
var typeForUrl = splitUrl[4 + indexLang] ? splitUrl[4 + indexLang] : lang == "en" ? "properties" : "عقارات"

const propTypeName = splitUrl[4 + indexLang] ? splitUrl[4 + indexLang] : lang == "en" ? "properties" : "عقارات"
var purposeEn = splitUrl[3] == "for-rent" ? 1 : splitUrl[3] == "for-sale" ? 2 : 3
var purposeAr = splitUrl[4] == "for-rent" ? 1 : splitUrl[4] == "for-sale" ? 2 : 3

var propPurposeFromUrl = lang == "en" ? purposeEn : purposeAr

var propertyPurposename = lang == "en" ? splitUrl[3] : decodeURI(splitUrl[4])


var bedPropertyType = splitUrl[4 + indexLang] ? splitUrl[4 + indexLang] : lang == "en" ? "properties" : "عقارات"
var urlLength = splitUrl.length;
var typeIdAr = urlLength == 9 ? 3 : urlLength == 8 ? 2 : urlLength == 7 ? 1 : 0;
var typeIdEn = urlLength == 8 ? 3 : urlLength == 7 ? 2 : urlLength == 6 ? 1 : 0;
var typeId = lang == "en" ? typeIdEn : typeIdAr;

console.log("split length", urlLength)

var splitBedPropertyType = bedPropertyType.split("-")

let propertyTypeName;

if (splitBedPropertyType.length > 2) {
  propertyTypeName = splitBedPropertyType.slice(2);
  propertyTypeName = propertyTypeName[0];
}
else if (bedPropertyType.includes("studio")) {
  propertyTypeName = splitBedPropertyType[1]
}
else {
  propertyTypeName = splitUrl[4 + indexLang] ? splitUrl[4 + indexLang] : lang == "en" ? "properties" : "عقارات"
}
propertyTypeName = decodeURI(propertyTypeName)


let bedroomFromUrl;
if (splitBedPropertyType[0] == "studio" || splitBedPropertyType[0] == "استوديو") {
  bedroomFromUrl = 0
}
else if (splitBedPropertyType.length <= 2) {
  bedroomFromUrl = -1
} else {
  bedroomFromUrl = splitBedPropertyType[0];
}

const splitUrlLocEn = splitUrl.length == 6 ? splitUrl[5] : splitUrl.length == 7 ? splitUrl[5] + "/" + splitUrl[6] : splitUrl.length == 8 ? splitUrl[5] + "/" + splitUrl[6] + "/" + splitUrl[7] : null

const splitUrlLocAr = splitUrl.length == 7 ? decodeURI(splitUrl[6]) : splitUrl.length == 8 ? decodeURI(splitUrl[6]) + "/" + decodeURI(splitUrl[7]) : splitUrl.length == 9 ? decodeURI(splitUrl[6]) + "/" + decodeURI(splitUrl[7]) + "/" + decodeURI(splitUrl[8]) : null

const splitUrlLoc = lang == "en" ? splitUrlLocEn : splitUrlLocAr
console.log("splitUrlLoc", splitUrlLoc)

const locIn = lang == "ar" ? "الامارات" : "uae"

var urlEn = decodeURI(splitUrl[3]) + "/" + decodeURI(splitUrl[4]) + "/" + splitUrlLoc
var urlAr = decodeURI(splitUrl[4]) + "/" + decodeURI(splitUrl[5]) + "/" + splitUrlLoc
var urlForSeo = lang == "en" ? urlEn : urlAr


const priceRangeFromForRent = [
  0, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 200000,
  300000, 400000, 500000, 600000, 700000, 800000, 900000,
];
const priceRangeToForRent = [
  30000, 50000, 60000, 70000, 80000, 90000, 100000, 200000, 300000, 400000,
  500000, 600000, 700000, 800000, 900000, 100000, 1100000, 1200000,
];
const priceRangeFromForSale = [
  0, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000,
  2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000,
];
const priceRangeToForSale = [
  300000, 500000, 600000, 700000, 800000, 900000, 1000000, 2000000, 3000000,
  4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 1000000, 11000000,
  12000000,
];

var language = lang == "ar" ? "ar/" : ""
const propertylocation = $("#propertyLocation").val();
var typeNameforSeo = $("#propertyType option:selected").text()
var slug = lang == "en" ? "slug_en" : "slug_ar"


export default class Properties extends Component {

  constructor(props) {
    super(props);
    this.state = {
      center: { lat: 24.863878, lng: 55.713461 },
      propertyStyle: 0,
      propertytypes: [],
      scrollStyle: {},
      location: [],
      propertyPurpose: "Rent",
      selected: [],
      features: [],
      open: false,
      locationValue: [],
      locations: [],
      filteredProperties: [],
      phone: "",
      email: "",
      agentName: "",
      propertyTypeName: this.props.location.state ? this.props.location.state["passPropTypeName"] : decodeURI(propertyTypeName),
      offset: 0,
      data: [],
      perPage: 12,
      currentPage: 1,
      purpose: this.props.location.state ? this.props.location.state["passPurpose"] : decodeURI(propPurposeFromUrl),
      propertyType: this.props.location.state ? this.props.location.state["passType"] : decodeURI(propertyTypeName),
      pageTitle: 'Default Title', // Initial title

      pprtyTitle: "",
      purposeTitle: "",
      locationSlugTitle: this.props.location.state ? this.props.location.state["passsubLocationSend"]["location"] ? this.props.location.state["passsubLocationSend"]["location"] : locIn : splitUrlLoc,
      propertyTypeTitle: this.props.location.state ? this.props.location.state["passPropTypeName"] : decodeURI(propertyTypeName),
      typeId: this.props.location.state ? this.props.location.state["passLocationTypeId"] : locationForUrl == "uae" ? 0 : typeId,
      urlForSeo: this.props.location.state ? this.props.location.state["passUrlForSeo"] : urlForSeo,

      showModal: false,

      pageContent: "",
      bathRoomSelected: 0,
      bedRoomSelected: this.props.location.state ? this.props.location.state["passBed"] : decodeURI(bedroomFromUrl),
      frequencySelected: this.props.location.state ? this.props.location.state["passFrequency"] : 0,
      popular: "",
      furnished: -1,
      priceFrom: this.props.location.state ?this.props.location.state["passPricefrom"] :0,
      priceTo: this.props.location.state ?this.props.location.state["passPriceto"] :0,
      locforUrl: this.props.location.state ? this.props.location.state["locForUrl"] : decodeURI(locationForUrl),
      selectedLocations: lang == "en" ?
        locationForUrl == "uae" || "" || "الامارات" ? [] : [{ slug_en: splitUrlLoc }] :
        locationForUrl == "uae" || "" || "الامارات" ? [] : [{ slug_ar: splitUrlLoc }],
      selectedLocForUrl: locationForUrl == "uae" || "" ? [] : [{ slug_en: splitUrlLoc }],
      selectedFeatures: [],
      furnishedActive: "furnishedActive",
      subLocationSend: this.props.location.state ? this.props.location.state["passsubLocationSend"] : locationForUrl == "uae" ? [] : { slug_en: decodeURI(locationForUrl), slug_ar: decodeURI(locationForUrl), type: 1 },
      subLocationArr: lang == "en" ?
        locationForUrl == "uae" || "" || "الامارات" ? [] : [{ slug_en: decodeURI(locationForUrl), type: 2 }] :
        locationForUrl == "uae" || "" || "الامارات" ? [] : [{ slug_ar: decodeURI(locationForUrl), type: 2 }],
      isLoading: false,
      checked: false,
      displayType: this.props.location.state ? this.props.location.state["passDisplayType"] : 0,
      priceActive: "active filter-input",
      furnishedornot: this.props.location.state ? this.props.location.state["furnishedornot"] : 0,
      showMobilefilter: false,
      bedbathview: 1,
      markerCliked: 0,
      priceVisiblity: true,
      activePage: 1,
      totalResult: 0
    };
  }

  cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
  }

  decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
  }

  callClickProperty = (property, e) => {

    const sender = "Find Properties"
    const message = "You have got a call from Find Properties for your property" + " https://findproperties.ae/single-property/" + property.id

    API.post("https://elitbuzz-me.com/sms/smsapi?api_key=C200343061a1e16b4924a3.21883164&type=text&contacts=00" + property.mobile + "&senderid=MFRE&msg=" + message
    ).then((response) => {

    });

    const data = { property_id: property.id }
      API.post("calltrack", data
      ).then((response) => {

    });
  }

  mobileFilterClose = () => {
    this.setState({
      showMobilefilter: false,
      propertyTypeTitle: this.state.propertyType,
    })

  }

  mobileFilterOpen = () => {
    this.setState({
      showMobilefilter: true,
      bedRoomSelected: -1
    })
    this.resetFilter();

  }
  mobilefilter = () => {
    $(".filterWindowMob").toggleClass('open');
    $("body").toggleClass('aside-open');
    this.setState({
      activePage: 1,
      propertyTypeTitle: this.state.propertyType,
    });

    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1, data);
    var purpose = this.state.purpose == 1 ? i18next.t("forrent").toLowerCase() : this.state.purpose == 2 ? i18next.t("forsale").toLowerCase() : i18next.t("forsharing")
    var bedroom = $("#bedroom").find(":selected").val() > 0 ? $("#bedroom").find(":selected").text() + " bedroom" : "Studio";
    if ($("#bedroom").find(":selected").val() == -1 || $("#bedroom").find(":selected").val() == undefined) { bedroom = '' }

    const urlPurpose = {
      purpose: $("#propertyPurpose").find(":selected").attr('purposeForUrl').toLowerCase().replace(/\s+/g, '-') + "/",
      propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" :  $("#propertyType").find(":selected").attr('data-type-for-url') + "/",
      subLocationSend: this.state.subLocationSend,
      bedroom: this.state.bedRoomSelected
    };
    this.propertyUrl(urlPurpose)
    // alert(this.state.bedRoomSelected)
  }

  subLocationClickHandler(subLocation, e) {
    const locTest = [];
    locTest.push(subLocation);
    // console.log("data on subloc click", locTest)
    var autoComplete= locTest[0]?locTest[0]:[]
    var autoCompleteSelect = [{value: autoComplete.value , slug_en: autoComplete.slug_en, slug_ar: autoComplete.slug_ar, location: lang=="en"?autoComplete.location_en: autoComplete.location_ar, type: autoComplete.type }]
    // console.log("autoCompleteSelect on subloc click", autoCompleteSelect)

    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      subLocationSend: subLocation,
      popular: this.state.popular,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: locTest,
      selectedFeatures: this.state.selectedFeatures,
    };

    this.setState({
      subLocationSend: subLocation,
      selectedLocations: autoCompleteSelect,
      locforUrl: subLocation.location,
      locationSlugTitle: lang=="en"?autoComplete.location_en: autoComplete.location_ar,
      activePage: 1,

    });


    this.fetchPropertyData(1, data);
    // const urlPurpose = {
    //   purpose: $("#propertyPurpose").find(":selected").attr('purposeForUrl').toLowerCase().replace(/\s+/g, '-') + "/",
    //   propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" :  $("#propertyType").find(":selected").attr('data-type-for-url') + "/",
    //   subLocationSend: locTest,
    // };
    var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('name')

    const urlPurpose = {
      purpose: $("#propertyPurpose").find(":selected").attr('purposeForUrl').toLowerCase().replace(/\s+/g, '-') + "/",
      propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" :  $("#propertyType").find(":selected").attr('data-type-for-url') + "/",
      subLocationSend: subLocation,
      bedroom: this.state.bedRoomSelected
    };
    this.propertyUrl(urlPurpose)
  }

  newestPropetyOnClick = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      popular: "newest",
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      popular: "newest",
    });
    this.fetchPropertyData(1, data);
  };
  lowestPriceFilteronClick = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      popular: "lowest",
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      popular: "lowest",
      activePage: 1,
    });
    this.fetchPropertyData(1, data);
  };
  highestPriceonClick = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      popular: "highest",
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      popular: "highest",
    });
    this.fetchPropertyData(1, data);
  };

  propertyUrl = (data) => {
    var bedroom = data.bedroom
    var bedroomURL = bedroom == "-1" || bedroom== -1 || bedroom == undefined?"":bedroom=="0"?"studio-": bedroom+"-bedroom-"
    console.log("data for url", data)
    var type = bedroomURL+data.propertyType
    var purpose = data.purpose
    var language = lang == "ar" ? "ar/" : ""
    var locinit = "uae"
    var location = data.subLocationSend == undefined || data.subLocationSend.length == 0 ? locinit : data.subLocationSend["slug_en"];
    data = purpose + type + location
    this.props.history.push("/" + language + data)
  }

  propertyPurposeChangleHandle = (e) => {
    e.preventDefault()
    this.setState({
      purpose: e.target.value,
      propertyType: lang=="ar"?"عقارات":"properties",
      propertyTypeTitle: lang=="ar"?"عقارات":"properties",
      activePage: 1,  
    });
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: 0,
      bedRoomSelected: -1,
      propertyType: lang=="ar"?"عقارات":"properties",
      purpose: e.target.value,
      frequencySelected: 0,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1, data);

    console.log("send data on purpose change", data);

    const data2 = { lang: lang, id: e.target.value }
    // API.post("propertytypeByPurposeTwo", data2)
     API.post("propertytypeByPurposeTwo", data2)
      .then((response) => {
        //  //console.log(response.data)
        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch();
      this.resetFilter;
      var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('name')
    const urlPurpose = {
      purpose: e.target.value == 1 ? "for-rent" + "/" : e.target.value == 2 ? "for-sale" + "/" : "for-sharing" + "/",
      propertyType: lang=="ar"?"عقارات":"properties" + "/",
      subLocationSend: this.state.subLocationSend,
      bedroom: -1
    };
    this.resetFilter();
    this.propertyUrl(urlPurpose)
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  handleHideModal = () => {
    this.setState({ showModal: false });
  }
  resetFilter = ()=> {
    $('#bedroom option[value=-1]').prop("selected", true);
    $('#bathroom option[value=0]').prop("selected", true);
    $('#frequencyview option[value=0]').prop("selected", true);

    // $('#propertyType option[value=0]').prop("selected", true);
    this.setState({
      bathRoomSelected: 0,
      bedRoomSelected: -1,
      // propertyTypeTitle: 
    })
  }
  propertyTypeOnChangeHadnler = (e) => {
    this.setState({
      propertyType: e.target.selectedOptions[0].getAttribute('name'),
      furnishedornot: e.target.selectedOptions[0].getAttribute('data-furnished'),
      bedbathview: e.target.selectedOptions[0].getAttribute('data-bed'),
      propertyTypeName: $("#propertyType").find(":selected").attr('data-type-selected'),
      propertyTypeTitle: e.target.selectedOptions[0].getAttribute('name'),
      activePage: 1,
    });

    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: 0,
      bedRoomSelected: -1,
      propertyType: e.target.selectedOptions[0].getAttribute('name'),
      purpose: this.state.purpose,
      frequencySelected: 0,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
// console.log("data bed",$("#propertyType").find(":selected").attr('data-bed'))

    const urlPurpose = {
      purpose: $("#propertyPurpose").find(":selected").attr('purposeForUrl').toLowerCase().replace(/\s+/g, '-') + "/",
      propertyType: e.target.selectedOptions[0].getAttribute('data-type-for-url') + "/",
      subLocationSend: this.state.subLocationSend,
      language: lang,
      bedroom: -1,
    };
    // console.log("send data on type change", propertyType);
    this.fetchPropertyData(1, data);
    this.propertyUrl(urlPurpose)
    this.resetFilter();
  };

  frequencyOnChangeHandler = (e) => {
    // this.resetFilter()
    this.setState({
      frequencySelected: e.target.value,
      // activePage: 1,
    });
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: e.target.value,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1, data);
  };
  bathroomOnChangeHadnler = (e) => {
    this.setState({
      bathRoomSelected: e.target.value,
    });
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: e.target.value,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1, data);
  };
  bedroomOnChangeHadnler = (e) => {
    this.setState({
      bedRoomSelected: e.target.value,
    });
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: e.target.value,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1, data);
    var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('name')
    const urlPurpose = {
      purpose: $("#propertyPurpose").find(":selected").attr('purposeForUrl').toLowerCase().replace(/\s+/g, '-') + "/",
      propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" :  $("#propertyType").find(":selected").attr('data-type-for-url') + "/",
      subLocationSend: this.state.subLocationSend,
      language: lang,
      bedroom: e.target.value,

    };
    this.propertyUrl(urlPurpose)

  };

  toggleVisibility = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  HandlePropertyStyle = (id, e) => {
    e.preventDefault();
    this.setState({ propertyStyle: id });
  };
  furnishedUnfurnishedAll = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: -1,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({ furnished: -1 });
    document
      .getElementById("allFurnishedStatus")
      .classList.add("furnishedActive");
    document
      .getElementById("furnishedOnly")
      .classList.remove("furnishedActive");
    document
      .getElementById("unfurnishedOnly")
      .classList.remove("furnishedActive");
    this.fetchPropertyData(1, data);
  };
  furnishedClickHandle = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: 1,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      furnished: 1,
      furnishedActive: this.state.furnishedActive,
    });
    document
      .getElementById("allFurnishedStatus")
      .classList.remove("furnishedActive");
    document.getElementById("furnishedOnly").classList.add("furnishedActive");
    document
      .getElementById("unfurnishedOnly")
      .classList.remove("furnishedActive");
    this.fetchPropertyData(1, data);
  };
  unfurnishedClickHandle = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: 0,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({ furnished: 0 });
    document
      .getElementById("allFurnishedStatus")
      .classList.remove("furnishedActive");
    document
      .getElementById("furnishedOnly")
      .classList.remove("furnishedActive");
    document.getElementById("unfurnishedOnly").classList.add("furnishedActive");
    this.fetchPropertyData(1, data);
  };
  handleWishList = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the link
    e.preventDefault();  // Prevent the default link behavior
    const userdata = store.getState(state => state);
    const token = userdata.auth.token
    const isLoggedIn = userdata.auth.loggedIn
    if (isLoggedIn) {
      const data = { property_id: e.target.value };
      const dataForCheckBox = {
        property_id: e.target.value,
        subLocationSend: this.state.subLocationSend,
        furnished: this.state.furnished,
        popular: this.state.popular,
        bathRoomSelected: this.state.bathRoomSelected,
        bedRoomSelected: this.state.bedRoomSelected,
        propertyType: this.state.propertyType,
        purpose: this.state.purpose,
        frequencySelected: this.state.frequencySelected,
        selectedLocations: this.state.selectedLocations,
        selectedFeatures: this.state.selectedFeatures,
      };
      API.post("wishlist", data).then((response) => {
        $("input[value=" + e.target.value + "]").trigger("click");
      });
    }
    else {
      this.setState({ showModal: true });
    }
  }

  displayTypeHandler = (e, displayType) => {
    var i = 0;
    var btn = document.getElementsByClassName("displayType");
    for (i; i < btn.length; i++) {
      if (i != displayType) btn[i].classList.remove("active");
      if (i == displayType) btn[i].classList.add("active");
    }
    if (displayType == 0) {
      this.setState({
        displayType: 0,
      });
    } else
      if (displayType == 1) {
        this.setState({
          displayType: 1,
        });
      }
      else {
        this.setState({
          displayType: 2,
        });
      }
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      displayType: this.state.displayType,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      popular: this.state.popular,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1, data);
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ 
      activePage: pageNumber});

      const data = {
        displayType: this.state.displayType,
        subLocationSend: this.props.location.state
          ? this.props.location.state["passsubLocationSend"]
          : this.state.subLocationSend,
        furnished: this.state.furnished,
        popular: this.state.popular,
        bathRoomSelected: this.props.location.state
          ? this.props.location.state["passBath"]
          : this.state.bathRoomSelected,
        bedRoomSelected: this.props.location.state
          ? this.props.location.state["passBed"]
          : this.state.bedRoomSelected,
        propertyType: this.props.location.state ? this.props.location.state["passType"] : this.state.propertyType,
        purpose: this.props.location.state
          ? this.props.location.state["passPurpose"]
          : this.state.purpose,
        frequencySelected: this.props.location.state
          ? this.props.location.state["passFrequency"]
          : this.state.frequencySelected,
        selectedLocations: this.props.location.state
          ? this.props.location.state["passLocation"]
          : this.state.selectedLocations,
        priceFrom: this.props.location.state
          ? this.props.location.state["passPricefrom"]
          : this.state.priceFrom,
        priceTo: this.props.location.state
          ? this.props.location.state["passPriceto"]
          : this.state.priceTo,
        subLocationArr: this.props.location.state
          ? this.props.location.state["passsubLocationSend"] :
          this.state.subLocationArr,
        selectedFeatures: this.state.selectedFeatures,
        displayType: this.state.displayType,
      };
      window.scrollTo(0, 0)
  
      this.fetchPropertyData(pageNumber, data);
  
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,

      });
    const data = {
      displayType: this.state.displayType,
      subLocationSend: this.props.location.state
        ? this.props.location.state["passsubLocationSend"]
        : this.state.subLocationSend,
      furnished: this.state.furnished,
      popular: this.state.popular,
      bathRoomSelected: this.props.location.state
        ? this.props.location.state["passBath"]
        : this.state.bathRoomSelected,
      bedRoomSelected: this.props.location.state
        ? this.props.location.state["passBed"]
        : this.state.bedRoomSelected,
      propertyType: this.props.location.state ? this.props.location.state["passType"] : this.state.propertyType,
      purpose: this.props.location.state
        ? this.props.location.state["passPurpose"]
        : this.state.purpose,
      frequencySelected: this.props.location.state
        ? this.props.location.state["passFrequency"]
        : this.state.frequencySelected,
      selectedLocations: this.props.location.state
        ? this.props.location.state["passLocation"]
        : this.state.selectedLocations,
      priceFrom: this.props.location.state
        ? this.props.location.state["passPricefrom"]
        : this.state.priceFrom,
      priceTo: this.props.location.state
        ? this.props.location.state["passPriceto"]
        : this.state.priceTo,
      subLocationArr: this.props.location.state
        ? this.props.location.state["passsubLocationSend"] :
        this.state.subLocationArr,
      selectedFeatures: this.state.selectedFeatures,
      displayType: this.state.displayType,
    };
    window.scrollTo(0, 0)

    this.fetchPropertyData(selectedPage, data);

  }

  // map request
  initMap = async (data) => {
    const mapOptions = {
      center: this.state.center,
      zoom: w < 900 ? 6 : 5,
      // gestureHandling: 'greedy',
      restriction: {
        latLngBounds: UAE_BOUND,
        strictBounds: false,
      }
    };
    var icons = {
      'rent': {
        url: 'https://goo.gl/qvLZ4R',
        color: '#58D400'
      },
      'sale': {
        url: 'https://goo.gl/G6HyHS',
        color: '#FCCA00'
      },
      'sharing': {
        url: 'https://goo.gl/6hkqX1',
        color: '#D80027'
      }

    };
    await API.post(`filterproperties?page=${this.state.currentPage}&perPage=${this.state.perPage}`, data)
      .then((response) => {
        // this.setState({ isLoading: false })
        loader.load()
          .then((google) => {
            var latLng = new google.maps.LatLng(49.47805, -123.84716);
            var homeLatLng = new google.maps.LatLng(49.47805, -123.84716);

            var map = new google.maps.Map(document.getElementById("map"), {
              zoom: 12,
              center: latLng,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
            });

            var marker1 = new markerWithLabel.MarkerWithLabel({
              position: homeLatLng,
              draggable: false,
              clickable: false,
              map: map,
              labelContent: "$425K",
              labelAnchor: new google.maps.Point(-21, 3),
              labelClass: "labels", // the CSS class for the label
              labelStyle: { opacity: 0.75 },
            });

            var marker2 = new markerWithLabel.MarkerWithLabel({
              position: new google.maps.LatLng(49.475, -123.84),
              draggable: true,
              map: map,
              labelContent: "$395K",
              labelAnchor: new google.maps.Point(-21, 3),
              labelClass: "labels", // the CSS class for the label
              labelStyle: { opacity: 1.0 },
            });

            var iw1 = new google.maps.InfoWindow({
              content: "Home For Sale",
            });
            var iw2 = new google.maps.InfoWindow({
              content: "Another Home For Sale",
            });
            google.maps.event.addListener(marker1, "click", function (e) {
              iw1.open(map, this);
            });
            google.maps.event.addListener(marker2, "click", function (e) {
              iw2.open(map, this);
            });
            // var infoWin = new google.maps.InfoWindow();
            // const map = new google.maps.Map(document.getElementById("map"), mapOptions);
            // var infoWin = new google.maps.InfoWindow();
            // var bounds = map.getBounds();
            // google.maps.event.addListener(map, 'zoom_changed', function () {
            //   var bounds = map.getBounds();
            //   var ne = bounds.getNorthEast();
            //   var sw = bounds.getSouthWest();
            //   var nw = ne.LatLng(ne.lat(), sw.lng());
            //   var se = bounds.LatLng(sw.lat(), ne.lng());
            //   console.log("bounds", bounds)
            // });

            // var reponseData = response.data.filteredproperties.data;

            // console.log("map response data", response)

            // const markers = reponseData.map((location, i) => {
            //   // const infowindow = new google.maps.InfoWindow({
            //   //   content: "Hello",
            //   // });

            //   // var marker = new google.maps.Marker({
            //   //   position: location,
            //   //   map,
            //   //   title: "Uluru (Ayers Rock)",
            //   // });
            //   // marker.addListener("click", () => {
            //   //   infowindow.open({
            //   //     anchor: marker,
            //   //     map,
            //   //     shouldFocus: false,
            //   //   });
            //   // });

            //   // marker.addListener("click", () => {

            //   //   map.setCenter(marker.getPosition());
            //   //   map.setZoom(map.getZoom() + 2);

            //   //   const InfoWindowContent = (
            //   //     <div className="infoMapWindow">
            //   //       {location.price} AED - {location.typeName_en}
            //   //       <div>
            //   //         <i className='flaticon-bedroom' />
            //   //         <span>
            //   //           {location.beds == 0 ? "Studio" : location.beds}
            //   //         </span>
            //   //         {location.baths != 0 ? (
            //   //           <div>
            //   //             <i className='flaticon-bathroom' />
            //   //             <span>
            //   //               {location.baths}
            //   //             </span>
            //   //           </div>
            //   //         ) : null}
            //   //         <i className='flaticon-ruler' />
            //   //         <span className='acr-listing-icon-value'>
            //   //           {location.area}
            //   //         </span>
            //   //       </div>
            //   //     </div>
            //   //   );
            //   //   const content = ReactDOMServer.renderToString(InfoWindowContent);

            //   //   infoWin.setContent(content);
            //   //   infoWin.open(map, marker);
            //   // })

            //   var item = location[i];
            //   var marker = new MarkerWithLabel({
            //     position: location,
            //     map: map,
            //     icon: icons[item[3]].url,
            //     labelContent: item[0],
            //     labelAnchor: new google.maps.Point(item[4], item[5]),
            //     // the CSS class for the label
            //     labelClass: "label " + item[3],
            //     labelInBackground: true
            //   })

            //   return marker;

            // });
            // console.log("markers", markers)
            // for (var i = 0; i < markers.length; i++) {
            //   // console.log("markers [i]:", markers[i].position.lat() )
            // }

            // // let bounds = map.getBounds()

            // markers.filter(m => m.isAdded).forEach(m => {
            //   if (bounds.contains(m.getPosition())) {
            //     // code for showing your object, associated with current marker
            //     console.log("markers [i]:", markers[i].position.lat())

            //   }
            // })
            // Add a marker clusterer to manage the markers.
            // var markerClusterer = new MarkerClusterer({ markers, map });
            // google.maps.event.addListener(markerClusterer, 'clusterclick', function (cluster) {
            //   // map.setCenter(this.state.center);
            //   map.setZoom(map.getZoom() + 1);
            // });
            // google.maps.event.addListener(markerClusterer, "mouseover", function (c) {
            //   $(c.clusterIcon_.div_).effect('bounce', { times: 3 }, 'slow');
            // });

            // this.setState({ isLoading: true })

          })
          .catch(e => {
            // do something
          });
      })

  }


  fetchPropertyData = async (currentPage, data) => {
    console.log("sent data", data)
    this.setState({ isLoading: true });
    const start = Date.now()
    const mapOptions = {
      // center: this.state.center,
      // zoom: w < 900 ? 18 : 5,
      // gestureHandling: 'greedy',
        restriction: {
        latLngBounds: UAE_BOUND,
        strictBounds: false,
      }
    };
    var icons = {
      'green': {
        url: 'https://goo.gl/qvLZ4R',
        color: '#58D400'
      },
      'yellow': {
        url: 'https://goo.gl/G6HyHS',
        color: '#FCCA00'
      },
      'red': {
        url: 'https://goo.gl/6hkqX1',
        color: '#D80027'
      },
      'turquoise': {
        url: 'https://goo.gl/uLRpYZ',
        color: '#00D9D2'
      },
      'brown': {
        url: 'https://goo.gl/XTosFM',
        color: '#BF5300'
      }
    };

    await API.post(`filterproperties?page=${currentPage}&perPage=${this.state.perPage}`, data)
      .then((response) => {
        console.log("responded data", response.data)
        if (this.state.displayType == 2) {
          loader.load()
            .then((google) => {
              var reponseData = response.data.filteredproperties.data;
              var latLng = new google.maps.LatLng(this.state.center);
              // var homeLatLng = new google.maps.LatLng(49.47805, -123.84716);
              var map = new google.maps.Map(document.getElementById("map"), {
                // locationForUrl == "uae" || "" || "الامارات" ? this.state.center: 
                center: { lat: reponseData[0].lat, lng: reponseData[0].lng },
                zoom: w < 900 ? 13 : 12,
                gestureHandling: 'greedy',
                draggable: false,
                restriction: {
                  latLngBounds: UAE_BOUND,
                  strictBounds: false,
                },
                mapTypeId: google.maps.MapTypeId.ROADMAP,
              });

              const markers = reponseData.map((location, i) => {

                const infocnt1 = `<div class="card" style="width: 14rem; margin-bottom: 0px!important">
                <img class="card-img-top" src=${baseurlImg + "/uploads/properties/" + location.image} alt="Card image cap" style="height:130px;width:100%">
                  <div style="display:flex;flex-direction:row;justify-content:space-between">
                       <div style="text-align: right; width: 100%"> 
                         ${location.purpose == 1 ? i18next.t("forrent") : location.purpose == 2 ? i18next.t("forsale") : location.purpose == 3 ? i18next.t("forsharing") : ""}
                       </div> 
                       <div style="text-align: right; width: 100%"> 
                         <span style="text-align: left; font-weight: bold">${location.beds == 0 ? i18next.t("studio") : location.beds > 0 ? location.beds + i18next.t("bedroom") : ""} ${lang == "en" ? location.typeName_en : location.typeName_ar} </span>
                       </div> 
                  </div>
                  <div class="center">
                      <span style="text-align: left"><a href=${websitebaseurl + "single-property/" + location.id} class="">View Property</a></span>
                  </div>
              </div>`

                const mapInfo = `<div class='map_info_wrapper'><a href=${websitebaseurl + "single-property/" + location.id}><div class='img_wrapper'><img src=${baseurlImg + "/uploads/properties/" + location.image}></div>
               <div class='property_content_wrap'>
               <div class='property_title'>
                  <span>${lang == "en" ? location.title_en : lang == "ar" ? location.title_ar : ""}</span>
               </div>
           
               <div class='property_price'>
                 <span>${location.price + " " + i18next.t("aed")}</span>
               </div>
           
               <div class='property_bed_type'>
                  <span>${location.beds == 0 ? i18next.t("studio") : location.beds > 0 ? location.beds + i18next.t("bedroom") : ""} ${lang == "en" ? location.typeName_en : location.typeName_ar}</span>
                   <!--ul><li></li></ul-->
               </div>         
               <div class='property-view-link'>
                 <span><a href=${websitebaseurl + "single-property/" + location.id} class="">${i18next.t("viewdetail")}</a></span>
                </div>
               </div></a></div>`


                // console.log("location inside", location)
                var marker = new MarkerWithLabel({
                  position: new google.maps.LatLng(location.lat, location.lng),
                  // draggable: false,
                  map,
                  labelContent: location.beds == 0 ? i18next.t("studio") + "</br>" + location.price + " " + i18next.t("aed") : location.beds > 0 ? location.beds + i18next.t("bedroom") + "</br>" + location.price + " " + i18next.t("aed") : "" + "</br>" + location.price + i18next.t("aed") + " ",
                  labelAnchor: new google.maps.Point(-30, 3),
                  labelClass: "labels_map", // the CSS class for the label
                  labelStyle: { opacity: 1.0 },
                });

                var iwc = new google.maps.InfoWindow({
                  content: mapInfo
                  // content: location.purpose == 1 ? i18next.t("forrent") : location.purpose == 2 ? i18next.t("forsale") : location.purpose == 2 ? i18next.t("forsharing") : "",
                });


                // marker.addListener("click", () => {
                // });
                // marker.dragging.disable()
                marker.addListener("click", () => {
                  // alert(location.id)
                  // this.setState({markerCliked: location.id})
                  map.setCenter(marker.getPosition());
                  iwc.open({
                    anchor: marker,
                    map,
                    // shouldFocus: false,
                  });
                  map.setCenter(marker.getPosition());
                  map.setZoom(map.getZoom() + 2);
                  // infoWindow.close();
                  // infoWindow.setContent(marker.getTitle());
                  // infoWindow.open(marker.getMap(), marker);                
                })
                return marker;
              });

              // var infoWin = new google.maps.InfoWindow();
              // const map = new google.maps.Map(document.getElementById("map"), mapOptions);
              // var infoWin = new google.maps.InfoWindow();
              // var bounds = map.getBounds();
              // google.maps.event.addListener(map, 'zoom_changed', function () {
              //   var bounds = map.getBounds();
              //   var ne = bounds.getNorthEast();
              //   var sw = bounds.getSouthWest();
              //   var nw = ne.LatLng(ne.lat(), sw.lng());
              //   var se = bounds.LatLng(sw.lat(), ne.lng());
              //   console.log("bounds", bounds)
              // });

              // var reponseData = response.data.filteredproperties.data;
              // console.log("map response", reponseData)

              // const markers = reponseData.map((location, i) => {


              //   var marker = new google.maps.MarkerWithLabel({
              //     position: location,
              //     map: map,
              //     icon: "https://findproperties.ae/favicon.png",
              //     labelContent: "2 Bhk," + location.price,
              //     labelAnchor: new google.maps.Point(55, 33),
              //     labelClass: "listing-badge sale ",
              //     labelInBackground: true
              //   });

              // marker.addListener("click", () => {
              // map.setCenter(marker.getPosition());
              // map.setZoom(map.getZoom() + 2);

              //     const InfoWindowContent = (
              //       <div className="infoMapWindow">
              //         {location.price} AED - {location.typeName_en}
              //         <div>
              //           <i className='flaticon-bedroom' />
              //           <span>
              //             {location.beds == 0 ? "Studio" : location.beds}
              //           </span>
              //           {location.baths != 0 ? (
              //             <div>
              //               <i className='flaticon-bathroom' />
              //               <span>
              //                 {location.baths}
              //               </span>
              //             </div>
              //           ) : null}
              //           <i className='flaticon-ruler' />
              //           <span className='acr-listing-icon-value'>
              //             {location.area}
              //           </span>
              //         </div>
              //       </div>
              //     );
              //     const content = ReactDOMServer.renderToString(InfoWindowContent);

              //     infoWin.setContent(content);
              //     infoWin.open(map, marker);
              //   })
              //   return marker;

              // });
              // console.log("markers", markers)
              // for (var i = 0; i < markers.length; i++) {
              //   // console.log("markers [i]:", markers[i].position.lat() )
              // }

              let bounds = map.getBounds()

              markers.filter(m => m.isAdded).forEach(m => {
                if (bounds.contains(m.getPosition())) {
                  // code for showing your object, associated with current marker


                }
              })
              // // Add a marker clusterer to manage the markers.
              // var markerClusterer = new MarkerClusterer({ markers, map });
              // google.maps.event.addListener(markerClusterer, 'clusterclick', function (cluster) {
              //   // map.setCenter(this.state.center);
              //   map.setZoom(map.getZoom() + 1);
              // });
              // google.maps.event.addListener(markerClusterer, "mouseover", function (c) {
              //   $(c.clusterIcon_.div_).effect('bounce', { times: 3 }, 'slow');
              // });
              // this.setState({ isLoading: true })
            })
          // .catch(e => {
          //   // do something
          // });
        }
        this.setState({
          isLoading: false,
          filteredProperties: response.data.filteredproperties.data,
          subLocations: response.data.property_location,

          // pprtyTitle: lang=="en"?response.data.pprytype[0].typeName_en:response.data.pprytype[0].typeName_ar,
          pageContent: response.data.pages,
          subLocationArr: response.data.sublocation,
          displayType: this.state.displayType,
          pageCount: (response.data.filteredproperties.total) / this.state.perPage,
          totalResult: (response.data.filteredproperties.total)
        });
        const data = response.data.filteredproperties.data;
        // console.log("response data", data);

        const postData = data.map((property, i) => (
          <React.Fragment>
            {this.state.displayType == 0 || this.state.displayType == undefined ? (
              <div className='listing listing-list'>
                <div className='listing-thumbnail col-sm-2'>
                  <a href={"/" + language + "single-property/" + property.id}>
                    <img width="300px" height="200px" loading="eager"
                      src={baseurlImg + "/uploads/properties/" + property.image}
                      alt='listing'
                    />
                  </a>
                  <div className='listing-controls'>
                    <div className='_card_flex_last'>
                      <div className='prt_saveed_12lk'>
                        <label className='toggler toggler-danger'>
                          <input onChange={this.handleWishList} value={property.id} type='checkbox' checked={(property.wishlist_status == 1 ? true : false) || this.state.checked} />
                          <i className='ti-heart' />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='listing-body call-buttons'>
                  {/* <div className='flex'>
                    <span
                      className={property.purpose == 1 ? "listing-badge rent" : "listing-badge sale"}>
                      {" "}
                      {property.purpose == 1 ? i18next.t("forrent") : property.purpose == 2 ? i18next.t("forsale") : property.purpose == 3 ? i18next.t("sharing") : null}
                    </span>
                    <span className='right listing-price'>
                      {property.price.toLocaleString()}
                      <span>{i18next.t("aed") + " "}
                        {property.frequency == 1 ? <span> {i18next.t("yearly")}</span> : property.frequency == 2 ? <span> {i18next.t("monthly")}</span> : property.frequency == 3 ? <span> {i18next.t("weekly")}</span> : property.frequency == 3 ? <span> {i18next.t("daily")}</span> : null}
                      </span>
                    </span>
                  </div>
                  <div style={{ position: 'relative', top: 5, bottom: 4,left: 5 }} className="flex">
                    <span style={{ fontSize: '0.7rem', color: '#aaa', textTransform: 'uppercase',background: 'aliceblue',padding: '3px 6px', borderRadius: '12px',color: 'black',textTransform: 'uppercase'}}>
                      {lang == "en"
                        ? property.typeName_en
                        : property.typeName_ar}
                    </span>
                    {property.package_type == 2 ?
                      <span className="featured-property" >
                        {i18next.t("featured")}
                      </span> : null}
                    {property.package_type == 3 ?
                      <span className="premium-property" >
                        {i18next.t("premium")}
                      </span> : null}
                  </div>
                  <h5 className='listing-title'>
                    <a lang={lang == "en" ? "en" : "ar"} href={"/" + language + "single-property/" + property.id}>
                      {lang == "en" ?
                        property.title_en : property.title_ar}
                    </a>
                  </h5>
                  <h5 className='listing-title'>
                    <a style={{ color: "#555" }}>
                      <i className="fa fa-map-marker"></i>
                      {lang == "en" ?
                        " " + property.area_en + ", " + property.emirate_en : property.area_ar + ", " + property.emirate_ar}
                    </a>
                  </h5>
                  <div className='acr-listing-icons'>
                    {property.beds > -1 ?
                      <div
                        className='acr-listing-icon'
                        data-toggle='tooltip'
                        title='Beds'
                      >
                        <i className='flaticon-bedroom' />
                        <span className='acr-listing-icon-value'>
                          {property.beds == 0 ? i18next.t("studio") : property.beds}
                        </span>
                      </div> : null}
                    {property.baths > 0 ? (
                      <div
                        className='acr-listing-icon'
                        data-toggle='tooltip'
                        title='Bathrooms'>
                        <i className='flaticon-bathroom' />
                        <span className='acr-listing-icon-value'>
                          {property.baths}
                        </span>
                      </div>
                    ) : null}
                    {property.area > 0 ?
                      <div
                        className='acr-listing-icon'
                        data-toggle='tooltip'
                        title='Square Feet'>
                        <i className='flaticon-ruler' />
                        <span className='acr-listing-icon-value'>
                          {property.area} {i18next.t("sqft")}
                        </span>
                      </div>
                      : null}
                  </div>
                  <div className='listing-author'>
                    <div className='listing-gallery-wrapper'>
                      <a
                        href={"tel: 00" + property.mobile}
                        // data-bs-toggle='modal'
                        // data-bs-target='#callModal'
                        onClick={this.callClickProperty.bind(this, property)}
                        className='btn-custom btn-sm secondary'
                        tabIndex={0}>
                        <i className='fa fa-phone' aria-hidden='true'>
                          {" "}
                        </i>
                      </a>
                    </div>

                    <div className='listing-gallery-wrapper'>
                      <a href={"mailto:" + property.email} onClick={() => { this.setState({ email: property.email }); }} className='btn-custom btn-sm secondary' tabIndex={0} >
                        <i className='fas fa-envelope'></i>
                      </a>
                    </div>

                    <div className='listing-gallery-wrapper'>
                      <a
                        href={"https://wa.me/" + property.mobile + "?text=Hey%20" + property.name_en + ", %20I am%20interested%20in%20getting%20more%20information%20about%20this%20property%20you%20posted%20on%20Find%20Properties, Link:" + websitebaseurl + "single-property/" + property.id + ""}
                        onClick={() => {
                          const data = { property_id: property.id }
                          API.post("whatsapptrack", data
                          ).then((response) => {

                          });
                        }}
                        className='btn-custom btn-sm secondary'
                        tabIndex={0}>
                        <i className='fa fa-whatsapp'></i>
                      </a>
                    </div>
                    <div className='d-none d-xs-none d-sm-none d-md-block'>
                      <Link to={"/" + language + "single-property/" + property.id}>
                        <img
                          src={
                            property.logo == undefined
                              ? baseurlImg + "/uploads/profiles/no_avatar.png"
                              : baseurlImg +
                              "/uploads/profiles/" +
                              property.logo
                          }
                        />
                      </Link>
                    </div>
                  </div> */}
                  <div className="flex">

                  <span className="listing-price">
                    {property.price}
                    <span>{" "}{i18next.t("aed")}</span>
                  </span>
                  <span className="right pt-header">
                    {lang == "en" ? property.typeName_en : property.typeName_ar}
                  </span>
                </div>
                <h5 className="listing-title">
                  <Link to={"/single-property/" + property.id}>
                    {property.title_en}
                  </Link>
                </h5>
                {property.beds > -1 ?
                <div className="acr-listing-icons">
                  <div
                    className="acr-listing-icon"
                    data-toggle="tooltip"
                    title="Beds"
                  >
                    <i className="flaticon-bedroom" />
                    <span className="acr-listing-icon-value">
                      {property.beds == 0 ? "Studio" : property.beds}
                    </span>
                  </div>
                  {property.baths != 0 ? (
                    <div
                      className="acr-listing-icon"
                      data-toggle="tooltip"
                      title="Bathrooms"
                    >
                      <i className="flaticon-bathroom" />
                      <span className="acr-listing-icon-value">
                        {property.baths}
                      </span>
                    </div>
                  ) : null}
                  <div
                    className="acr-listing-icon"
                    data-toggle="tooltip"
                    title="Square Feet"
                  >
                    <i className="flaticon-ruler" />
                    <span className="acr-listing-icon-value">
                      {property.area}
                    </span>
                  </div>
                </div>
                :null}
                <div className="listing-author">
                  <div className="listing-gallery-wrapper">
                    <a
                      href={"tel:" + property.mobile}
                      data-bs-toggle="modal"
                      data-bs-target="#callModal"
                      onClick={() => {
                        this.setState({
                          phone: property.mobile,
                          agentName: property.name,
                        });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fa fa-phone" aria-hidden="true">
                        {" "}
                      </i>
                      &nbsp;&nbsp;Call&nbsp;&nbsp;&nbsp;&nbsp;
                    </a>
                  </div>
                   <div className="listing-gallery-wrapper">
                    <a
                      href={"mailto:" + property.email}
                      onClick={() => {
                        this.setState({ email: property.email });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fas fa-envelope"></i> &nbsp; Email
                    </a>
                  </div>
                  <div className="listing-gallery-wrapper">
                    <a
                      href={
                        "https://wa.me/" +
                        property.mobile +
                        "?text=Hey%20" +
                        property.name_en +
                        ", %20I'm%20interested%20in%20your%20 " +
                        property.title_en +
                        ""
                      }
                      onClick={() => {
                        this.setState({ email: property.mobile });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </div>
                  <div className="agentNames">
                    <Link to={"/single-property/" + property.id}>
                      <img
                        src={
                          property.logo == undefined
                            ? baseurlImg + "/uploads/profiles/no_avatar.png"
                            : baseurlImg + "/uploads/profiles/" + property.logo
                        }
                      />
                    </Link>
                  </div>
                </div>
                </div>
              </div>
            ) :
              <>
                {this.state.displayType == 1 ? (
                  <div className='listing gridProperty col-sm-6'>
                    <div className='listing-thumbnail'>
                      <Link
                        to={"/" + language + "single-property/" + property.id}
                        style={{ dir: lang == "en" ? "ltr" : "rtl" }}>
                        <img
                          src={baseurlImg + "/uploads/properties/" + property.image}
                          alt=""
                        />
                      </Link>
                      <div className='listing-badges'>
                        <span
                          className={
                            property.purpose == 1 || property.purpose == 3
                              ? "listing-badge rent"
                              : "listing-badge sale"
                          }>
                          {property.purpose == 1 ? i18next.t("forrent") : property.purpose == 2 ? i18next.t("forsale") : property.purpose == 3 ? i18next.t("sharing") : null}
                        </span>
                      </div>
                      <div className='listing-controls'>
                        <div className='_card_flex_last'>
                          <div className='prt_saveed_12lk'>
                            <label className='toggler toggler-danger'>
                              <input
                                onChange={this.handleWishList}
                                value={property.id}
                                type='checkbox'
                                checked={
                                  (property.wishlist_status == 1 ? true : false) ||
                                  this.state.checked
                                }
                              />
                              <i className='ti-heart' />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='listing-body'>
                      <div className='flex'>
                        <span className='listing-price'>
                          {property.price.toLocaleString()}
                          <span>{i18next.t("aed")}</span>
                        </span>
                        <span className='right pt-header'>{property.typeName_en}</span>
                      </div>
                       <h5 className='listing-title'>
                        <a
                          href={"/" + language + "single-property/" + property.id}
                          style={{ direction: lang == "en" ? "ltr" : "rtl" }}
                        >
                          {lang == "en" ? property.title_en : property.title_ar}
                        </a>
                      </h5>
                      <h4>{property.area_en}</h4>
                      <div
                        style={{ direction: lang == "en" ? "ltr" : "rtl" }}
                        className='acr-listing-icons'>
                        {property.beds > -1 ?
                          <div
                            className='acr-listing-icon'
                            data-toggle='tooltip'
                            title='Beds'>
                            <i className='flaticon-bedroom' />
                            <span className='acr-listing-icon-value'>
                              {property.beds == 0 ? i18next.t("studio") : property.beds}
                            </span>
                          </div> : null}
                        {property.baths > 0 ?
                          <div
                            style={{
                              direction:
                                lang == "en" ? "ltr!important" : "rtl!important",
                            }}
                            className='acr-listing-icon'
                            data-toggle='tooltip'
                            title='Bathrooms'>
                            <i className='flaticon-bathroom' />
                            <span className='acr-listing-icon-value'>
                              {property.baths}
                            </span>
                          </div> : null}
                        {property.area > 0 ?
                          <div
                            className='acr-listing-icon'
                            data-toggle='tooltip'
                            title='Square Feet'>
                            <i className='flaticon-ruler' />
                            <span className='acr-listing-icon-value'>
                              {property.area}
                            </span>
                          </div> : null}
                      </div>
                      <div className='listing-author'>
                        <img
                          src={baseurlImg + "/uploads/profiles/" + property.logo}
                          alt='author'
                        />
                        <div className='listing-gallery-wrapper'>
                          <a
                            href={"tel:" + property.mobile}
                            className='btn-custom btn-sm secondary'
                            tabIndex={0}>
                            <i className='fa fa-phone' aria-hidden='true'>
                              {" "}
                            </i>
                          </a>
                        </div>
                        <div className='listing-gallery-wrapper'>
                          <a
                            href={"mailto:" + property.email}
                            className='btn-custom btn-sm secondary'
                            tabIndex={0}
                          >
                            <i className='fas fa-envelope'></i>
                            
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ) :
                  (
                    <>
                      <div className="listing similar d-none d-lg-block d-md-block d-xl-block" aria-hidden="false">
                        <div className="listing-thumbnail">
                          <a
                            href={"/" + language + "single-property/" + property.id}
                            style={{ direction: lang == "en" ? "ltr" : "rtl" }}>
                            <img
                              src={
                                baseurlImg +
                                "/uploads/properties/" +
                                property.image
                              }
                              alt
                            />
                          </a>
                        </div>
                        <div className="listing-body">
                          <div className="flex">
                            <span className="listing-price">
                              {property.price.toLocaleString()}
                              <span>{i18next.t("aed")}
                                {property.rent_frequency == 1 ? <span> {i18next.t("yearly")}</span> : property.rent_frequency == 2 ? <span> {i18next.t("monthly")}</span> : property.rent_frequency == 3 ? <span> {i18next.t("weekly")}</span> : property.rent_frequency == 3 ? <span> {i18next.t("daily")}</span> : null}
                              </span>
                            </span>
                            <span className="typeName">
                              {lang == "en"
                                ? property.typeName_en
                                : property.typeName_ar}
                            </span>
                          </div>
                          <h5 className="listing-title">
                            <a
                              href={"/" + language + "single-property/" + property.id}
                              style={{
                                direction: lang == "en" ? "ltr" : "rtl",
                              }}>
                              {lang == "en"
                                ? property.title_en
                                : property.title_ar}
                            </a>
                          </h5>
                          <div
                            style={{ direction: lang == "en" ? "ltr" : "rtl" }}
                            className="acr-listing-icons"
                          >
                            {property.beds > -1 ?
                              <div
                                className="acr-listing-icon"
                                data-toggle="tooltip"
                                title="Beds"
                              >
                                <i className="flaticon-bedroom" />
                                <span className="acr-listing-icon-value">
                                  {property.beds == 0 ? i18next.t("studio") : property.beds}
                                </span>
                              </div>
                              : null}
                            {property.baths > 0 ?
                              <div
                                style={{
                                  direction:
                                    lang == "en"
                                      ? "ltr!important"
                                      : "rtl!important",
                                }}
                                className="acr-listing-icon"
                                data-toggle="tooltip"
                                title="Bathrooms"
                              >
                                <i className="flaticon-bathroom" />
                                <span className="acr-listing-icon-value">
                                  {property.baths}
                                </span>
                              </div>
                              : null}
                            {property.area > -1 ?

                              <div
                                className="acr-listing-icon"
                                data-toggle="tooltip"
                                title="Square Feet">
                                <i className="flaticon-ruler" />
                                <span className="acr-listing-icon-value">
                                  {property.area}
                                </span>
                              </div>
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="d-block d-sm-none">
                        <div className='listing listing-list'>
                          <div className='listing-thumbnail col-sm-2'>
                            <a href={"/" + language + "single-property/" + property.id}>
                              <img width="300px" height="200px" loading="eager"
                                src={baseurlImg + "/uploads/properties/" + property.image}
                                alt='listing'
                              />
                            </a>
                            <div className='listing-controls'>
                              <div className='_card_flex_last'>
                                <div className='prt_saveed_12lk'>
                                  <label className='toggler toggler-danger'>
                                    <input onChange={this.handleWishList} value={property.id} type='checkbox' checked={(property.wishlist_status == 1 ? true : false) || this.state.checked} />
                                    <i className='ti-heart' />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='listing-body call-buttons'>
                            <div className='flex'>
                              <span
                                className={property.purpose == 1 ? "listing-badge rent" : "listing-badge sale"}>
                                {" "}
                                {property.purpose == 1 ? i18next.t("forrent") : property.purpose == 2 ? i18next.t("forsale") : property.purpose == 3 ? i18next.t("sharing") : null}
                              </span>
                              <span className='right listing-price'>
                                {property.price.toLocaleString()}
                                <span>{i18next.t("aed") + " "}
                                  {property.frequency == 1 ? <span> {i18next.t("yearly")}</span> : property.frequency == 2 ? <span> {i18next.t("monthly")}</span> : property.frequency == 3 ? <span> {i18next.t("weekly")}</span> : property.frequency == 3 ? <span> {i18next.t("daily")}</span> : null}
                                </span>
                              </span>
                            </div>
                            <div style={{ position: 'relative' }} className="flex">
                              <span style={{ fontSize: '0.7rem', color: '#aaa', textTransform: 'uppercase' }}>
                                {lang == "en"
                                  ? property.typeName_en
                                  : property.typeName_ar}
                              </span>
                              {property.package_type == 2 ?
                                <span className="featured-property" >
                                  {i18next.t("featured")}
                                </span> : null}
                              {property.package_type == 3 ?
                                <span className="premium-property" >
                                  {i18next.t("premium")}
                                </span> : null}
                            </div>
                            <h5 className='listing-title'>
                              <a href={"/" + language + "single-property/" + property.id}>
                                {lang == "en" ?
                                  property.title_en : property.title_ar}
                              </a>
                            </h5>
                            <h4>{property.area_en}</h4>
                            <h5 className='listing-title'>
                              <a style={{ color: "#555" }}>
                                <i className="fa fa-map-marker"></i>
                                {lang == "en" ?
                                  " " + property.area_en + ", " + property.emirate_en : property.area_ar + ", " + property.emirate_ar}
                              </a>
                            </h5>
                            <div className='acr-listing-icons'>
                              {property.beds > -1 ?
                                <div
                                  className='acr-listing-icon'
                                  data-toggle='tooltip'
                                  title='Beds'
                                >
                                  <i className='flaticon-bedroom' />
                                  <span className='acr-listing-icon-value'>
                                    {property.beds == 0 ? i18next.t("studio") : property.beds}
                                  </span>
                                </div> : null}
                              {property.baths > 0 ? (
                                <div
                                  className='acr-listing-icon'
                                  data-toggle='tooltip'
                                  title='Bathrooms'>
                                  <i className='flaticon-bathroom' />
                                  <span className='acr-listing-icon-value'>
                                    {property.baths}
                                  </span>
                                </div>
                              ) : null}
                              {property.area > 0 ?
                                <div
                                  className='acr-listing-icon'
                                  data-toggle='tooltip'
                                  title='Square Feet'>
                                  <i className='flaticon-ruler' />
                                  <span className='acr-listing-icon-value'>
                                    {property.area} {i18next.t("sqft")}
                                  </span>
                                </div>
                                : null}
                            </div>
                            <div className='listing-author'>
                              <div className='listing-gallery-wrapper'>
                                <a
                                  href={"tel: 00" + property.mobile}
                                  // data-bs-toggle='modal'
                                  // data-bs-target='#callModal'
                                  onClick={this.callClickProperty.bind(this, property)}
                                  className='btn-custom btn-sm secondary'
                                  tabIndex={0}>
                                  <i className='fa fa-phone' aria-hidden='true'>
                                    {" "}
                                  </i>
                                  &nbsp;&nbsp;Call&nbsp;&nbsp;&nbsp;&nbsp;
                                </a>
                              </div>
                              {/* <div className="listing-gallery-wrapper d-lg-none d-sm-block d-md-none">
                    <a
                      href="listing-details-v1.html"
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}>
                      <i className="fas fa-envelope"></i>
                    </a>
                  </div> */}
                              <div className='listing-gallery-wrapper'>
                                <a href={"mailto:" + property.email} onClick={() => { this.setState({ email: property.email }); }} className='btn-custom btn-sm secondary' tabIndex={0} >
                                  <i className='fas fa-envelope'></i> &nbsp; Email
                                </a>
                              </div>

                              <div className='listing-gallery-wrapper'>
                                <a
                                  href={"https://wa.me/" + property.mobile + "?text=Hey%20" + property.name_en + ", %20I am%20interested%20in%20getting%20more%20information%20about%20this%20property%20you%20posted%20on%20Find%20Properties, Link:" + websitebaseurl + "single-property/" + property.id + ""}
                                  onClick={() => {
                                    // this.setState({ email: property.mobile });
                                    // const message= "You have got a what's app message from FindProperties for your property"+ "https://findproperties/single-property/"+property.id     
                                    const data = { property_id: property.id }
                                    API.post("whatsapptrack", data
                                    ).then((response) => {
                                      // //console.log("message send", response.data)
                                    });
                                  }}
                                  className='btn-custom btn-sm secondary'
                                  tabIndex={0}>
                                  <i className='fab fa-whatsapp'></i>
                                </a>
                              </div>
                              <div className='d-none d-xs-none d-sm-none d-md-block'>
                                <Link to={"/" + language + "single-property/" + property.id}>
                                  <img
                                    src={
                                      property.logo == undefined
                                        ? baseurlImg + "/uploads/profiles/no_avatar.png"
                                        : baseurlImg +
                                        "/uploads/profiles/" +
                                        property.logo
                                    }
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
              </>

              //    (
              //   <div className='listing gridProperty col-sm-6'>
              //     <div className='listing-thumbnail'>
              //       <Link
              //         to={"/" + language + "single-property/" + property.id}
              //         style={{ dir: lang == "en" ? "ltr" : "rtl" }}>
              //         <img
              //           src={baseurlImg + "/uploads/properties/" + property.image}
              //           alt=""
              //         />
              //       </Link>
              //       <div className='listing-badges'>

              //         <span
              //           className={
              //             property.purpose == 1 || property.purpose == 3
              //               ? "listing-badge rent"
              //               : "listing-badge sale"
              //           }>
              //           {property.purpose == 1 ? i18next.t("forrent") : property.purpose == 2 ? i18next.t("forsale") : property.purpose == 3 ? i18next.t("sharing") : null}
              //         </span>
              //       </div>
              //       <div className='listing-controls'>
              //         <div className='_card_flex_last'>
              //           <div className='prt_saveed_12lk'>
              //             <label className='toggler toggler-danger'>
              //               <input
              //                 onChange={this.handleWishList}
              //                 value={property.id}
              //                 type='checkbox'
              //                 checked={
              //                   (property.wishlist_status == 1 ? true : false) ||
              //                   this.state.checked
              //                 }
              //               />
              //               <i className='ti-heart' />
              //             </label>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //     <div className='listing-body'>
              //       <div className='flex'>
              //         <span className='listing-price'>
              //           {property.price.toLocaleString()}
              //           <span>{i18next.t("aed")}</span>
              //         </span>
              //         <span className='right'>{property.typeName_en}</span>
              //       </div>


              //       <h4>{property.area_en}</h4>
              //       <h5 className='listing-title'>
              //         <a
              //           href={"/" + language + "single-property/" + property.id}
              //           style={{ direction: lang == "en" ? "ltr" : "rtl" }}
              //         >
              //           {lang == "en" ? property.title_en : property.title_ar}
              //         </a>
              //       </h5>
              //       <div
              //         style={{ direction: lang == "en" ? "ltr" : "rtl" }}
              //         className='acr-listing-icons'>
              //         {property.beds > -1 ?
              //           <div
              //             className='acr-listing-icon'
              //             data-toggle='tooltip'
              //             title='Beds'>
              //             <i className='flaticon-bedroom' />
              //             <span className='acr-listing-icon-value'>
              //               {property.beds == 0 ? i18next.t("studio") : property.beds}
              //             </span>
              //           </div> : null}
              //         {property.baths > 0 ?
              //           <div
              //             style={{
              //               direction:
              //                 lang == "en" ? "ltr!important" : "rtl!important",
              //             }}
              //             className='acr-listing-icon'
              //             data-toggle='tooltip'
              //             title='Bathrooms'>
              //             <i className='flaticon-bathroom' />
              //             <span className='acr-listing-icon-value'>
              //               {property.baths}
              //             </span>
              //           </div> : null}
              //         {property.area > 0 ?
              //           <div
              //             className='acr-listing-icon'
              //             data-toggle='tooltip'
              //             title='Square Feet'>
              //             <i className='flaticon-ruler' />
              //             <span className='acr-listing-icon-value'>
              //               {property.area}
              //             </span>
              //           </div> : null}
              //       </div>
              //       <div className='listing-author'>
              //         <img
              //           src={baseurlImg + "/uploads/profiles/" + property.logo}
              //           alt='author'
              //         />
              //         <div className='listing-gallery-wrapper'>
              //           <a
              //             href={"tel:" + property.mobile}
              //             className='btn-custom btn-sm secondary'
              //             tabIndex={0}>
              //             <i className='fa fa-phone' aria-hidden='true'>
              //               {" "}
              //             </i>
              //             &nbsp;&nbsp; {i18next.t("call")}&nbsp;&nbsp;&nbsp;&nbsp;
              //           </a>
              //         </div>
              //         <div className='listing-gallery-wrapper'>
              //           <a
              //             href={"mailto:" + property.email}
              //             className='btn-custom btn-sm secondary'
              //             tabIndex={0}
              //           >
              //             <i className='fas fa-envelope'></i>&nbsp;{" "}
              //             {i18next.t("email")}
              //           </a>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // )

            }
          </React.Fragment>
        ));

        this.setState({
          pageCount: this.state.pageCount, postData,
        });

      })
      .catch((error) =>
        console.log("Network Error")
      );
  };

  componentDidMount() {
    // PropertyPageTrack();
    this.fetchData();

    var dataForTitle = {
      location: this.state.locationSlugTitle,
      propertyType: this.state.propertyType,
      typeId: this.props.location.state ? this.props.location.state["passLocationTypeId"] : this.state.typeId,
      lang: lang == "en" ? "en" : "ar"
    }

    console.log("data for title", dataForTitle);
    API.post("locfortitle", dataForTitle)
      .then((response) => {
        console.log("location api new", response.data)
        this.setState({
          pprtyTitle: "",
          locationSlugTitle: lang == "en" ? response.data.loc_res["location_en"] : response.data.loc_res["location_ar"],
          propertyTypeTitle: lang == "en" ? response.data.pprytype['typeName_en'] : response.data.pprytype['typeName_ar']
        });
      })
      .catch();

      //I want this to load during on click
    API.get("locations")
      .then((response) => {
        console.log("locationnns", response.data)
        this.setState({
          isLoading: true,
          locations: lang == "en" ? response.data.locations : response.data.locations_ar,

        });

        var splitUrl = url.split("/");
        let slug_en_from_url;
        // //console.log("Split Url on load", splitUrl)
        for (let i = 0; i < response.data.locations.length; i++) {
          if (response.data.locations[i].slug_en == splitUrlLoc && lang == "en") {
            this.setState({
              selectedLocations: [response.data.locations[i]],
              subLocationSend: response.data.locations[i],
              subLocationArr: [response.data.locations[i]],
            })
          }
          else if (response.data.locations[i].slug_en == splitUrlLoc && lang == "ar") {
            this.setState({
              selectedLocations: [response.data.locations_ar[i]],
              subLocationSend: response.data.locations_ar[i],
              subLocationArr: [response.data.locations_ar[i]],
            })
          }
        }

        window.addEventListener("scroll", this.handleScroll);
        const data = {
          displayType: this.state.displayType,
          subLocationSend: this.props.location.state
            ? this.props.location.state["passsubLocationSend"]
            : this.state.subLocationSend,
          furnished: this.state.furnished,
          popular: this.state.popular,
          bathRoomSelected: this.props.location.state
            ? this.props.location.state["passBath"]
            : this.state.bathRoomSelected,
          bedRoomSelected: this.props.location.state
            ? this.props.location.state["passBed"]
            : this.state.bedRoomSelected,
          propertyType: this.props.location.state
            ? this.props.location.state["passType"] : decodeURI(propertyTypeName),
          purpose: this.props.location.state
            ? this.props.location.state["passPurpose"] : decodeURI(propPurposeFromUrl),
          frequencySelected: this.props.location.state
            ? this.props.location.state["passFrequency"]
            : this.state.frequencySelected,
          selectedLocations: this.props.location.state
            ? this.props.location.state["passLocation"]
            : this.state.selectedLocations,
          priceFrom: this.props.location.state
            ? this.props.location.state["passPricefrom"]
            : this.state.priceFrom,
          priceTo: this.props.location.state
            ? this.props.location.state["passPriceto"]
            : this.state.priceTo,
          subLocationArr: this.state.subLocationArr,
          selectedFeatures: this.state.selectedFeatures,
          displayType: 0,
          lang: lang,
        };
        console.log("Data Before sent", data);

        this.fetchPropertyData(1, data);
      })
      .catch();

    $('#bedroom option[value=' + decodeURI(bedroomFromUrl) + ']').prop("selected", true);
    this.setState({
      selectedLocations: this.props.location.state
        ? this.props.location.state["passLocation"]
        : this.state.selectedLocations,

      propertyType: this.props.location.state
        ? this.props.location.state["passType"] : decodeURI(propertyTypeName),
      furnishedornot: this.props.location.state
        ? this.props.location.state["furnishedornot"]
        : this.state.furnishedornot,
    });

    if (this.props.location.state) {
      $(`#bathroom option[value='${this.props.location.state["passBath"]}']`).prop("selected", true);
      $(`#bedroom option[value='${this.props.location.state["passBed"]}']`).prop("selected", true);
      $(`#frequency option[value='${this.props.location.state["passFrequency"]}']`).prop("selected", true);
    }

  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = (e) => {
    if (window.scrollY === 0) {
      $("#scrollStyle").removeClass("scrollTop");
      $("#scrollStyleMobile").removeClass("scrollTop")
    } else if (window.scrollY > 0) {
      $("#scrollStyle").addClass("scrollTop");
      $("#scrollStyleMobile").addClass("scrollTop");
    }
  };

  handleLocation = (e) => {
    e.preventDefault();
  };

  collapseHadler = () => {
    this.setState({ open: !this.state.open });
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.setState({ location: e.target.value });
    }
  };

  priceFromHandler = (e) => {
    console.log("price from", e.target.value)
    const data = {
      priceFrom: e.target.value,
      priceTo: this.state.priceTo,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };

    this.setState({
      priceFrom: e.target.value,
    });
    this.fetchPropertyData(1, data);
  };

  priceToHandler = (e) => {
    console.log("price to", e.target.value)

    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: e.target.value,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      priceTo: e.target.value,
    });
    this.fetchPropertyData(1, data);
  };
  priceFromOnChange = (e) => {
    const data = {
      priceFrom: e.target.value,
      priceTo: this.state.priceTo,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };

    this.setState({
      priceFrom: e.target.value,
    });
    this.fetchPropertyData(1, data);
  };
  priceToOnChange = (e) => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: e.target.value,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      priceTo: e.target.value,
    });
    this.fetchPropertyData(1, data);
  };
  fetchData = () => {
    this.setState({ isLoading: true });
    const data = { lang: lang, id: this.state.purpose }
    API.post("propertytypeByPurposeTwo", data)
      .then((response) => {
        console.log("purpose by property", response.data)
        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch();
  };
  filterCanvas = () => {
    $(".filterWindowMob").toggleClass('open');
    $("body").toggleClass('aside-open');
    // this.resetFilter();
    // bathRoomSelected: 0,
    // bedRoomSelected: -1,
    this.setState({
      bedRoomSelected: -1,
      bathRoomSelected: 0,
      frequencySelected: 0
    })
    this.resetFilter();
    // alert("Aside open")
  }
  closeFilter = () => {
    $(".filterWindowMob").toggleClass('open');
    $("body").toggleClass('aside-open');
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1, data);
    var purpose = this.state.purpose == 1 ? i18next.t("forrent").toLowerCase() : i18next.t("forsale").toLowerCase()
    var bedroom = $("#bedroom").find(":selected").val() > 0 ? $("#bedroom").find(":selected").text() + " bedroom" : i18next.t("studiobed");
    if ($("#bedroom").find(":selected").val() == -1 || $("#bedroom").find(":selected").val() == undefined) { bedroom = '' }

    const urlPurpose = {
      purpose: $("#propertyPurpose").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-') + "/",
      propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + $("#propertyType").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-') + "/",
      subLocationSend: this.state.subLocationSend,
    };
    this.propertyUrl(urlPurpose)
  }


  render() {
    const userdata = store.getState(state => state);
    const token = userdata.auth.token
    const isLoggedIn = userdata.auth.loggedIn

    $(function () {

      var url = window.location.href;
      var splitUrl = url.split("/")
      if (splitUrl.length > 4) {
        var bedPropertyType = splitUrl[4]
        var splitBedPropertyType = bedPropertyType.split("-")

        let propertyTypeName;
        if (splitBedPropertyType.length > 2) {
          propertyTypeName = splitBedPropertyType.slice(2);
          propertyTypeName = propertyTypeName.toString().replace(',', '-')
        }
        else {
          propertyTypeName = splitUrl[4]
        }
      }
    });

    var propertytype = this.state.propertyTypeName
    var purpose = this.state.purpose == 1 ? i18next.t("forrent").toLowerCase() : this.state.purpose == 2 ? i18next.t("forsale").toLowerCase() : this.state.purpose == 3 ? i18next.t("forsharing").toLowerCase() : null
    var bedroom = $("#bedroom").find(":selected").val() > 0 ? $("#bedroom").find(":selected").text() + " " + i18next.t("bedroom") : i18next.t("studiobed");
    if ($("#bedroom").find(":selected").val() == -1 || $("#bedroom").find(":selected").val() == undefined) { bedroom = '' }

    var location = this.state.selectedLocations.length < 1 || this.state.selectedLocations == undefined ? i18next.t("uae") :
      this.state.selectedLocations.length == 1 ? this.state.locforUrl :
        this.state.selectedLocations.length == 2 ? this.state.selectedLocations[0].location + " " + i18next.t("uae") + " " + this.state.selectedLocations[1].location :
          this.state.selectedLocations.length > 2 ?
            this.state.selectedLocations.map((locs, i) => i < this.state.selectedLocations.length - 1 ? locs.location + " , " : " and " + locs.location) : null;
    // const locationFortitle = location.length == 1 ? location.replace("-", " ") : i18next.t("uae")
    const title = bedroom + " " + this.state.propertyTypeforTitle + " " + purpose + " " + i18next.t("in") + " " + this.state.selectedLocations;



    const autoCompleteView =
      <Autocomplete
        multiple
        limitTags={3}
        id='multiple-limit-tags'
        options={this.state.locations}
        onChange={(event, newValue) => {
          console.log("on chage autocom", newValue)
          this.setState({
            selectedLocations: newValue,
            subLocationSend: newValue.length > 0 ? newValue[0] : [],
            locforUrl: newValue.length > 0 ? newValue[0].location : [],
            propertyTypeName: $("#propertyType option:selected").text(),
            locationSlugTitle: newValue.length > 0 ? newValue[0].location : lang == "en" ? "uae" : "الإمارات",

          });
          const data = {
            subLocationSend: newValue.length > 0 ? newValue[0] : [],
            bathRoomSelected: $("#propertyType").find(":selected").attr('data-bed')==1?this.state.bathRoomSelected: 0,
            bedRoomSelected: $("#propertyType").find(":selected").attr('data-bed')==1?this.state.bedRoomSelected: -1,
            propertyType: this.state.propertyType,
            purpose: this.state.purpose,
            frequencySelected: this.state.frequencySelected,
            selectedLocations: newValue,
            selectedFeatures: this.state.selectedFeatures,
          };
          console.log("data autocomplete on change", newValue)
          this.fetchPropertyData(1, data);

          const urlPurpose = {
            purpose: $("#propertyPurpose").find(":selected").attr('purposeForUrl').toLowerCase().replace(/\s+/g, '-') + "/",
            propertyType: $("#propertyType").find(":selected").attr('data-type-for-url') == "properties" ? "properties/" : $("#propertyType").find(":selected").attr('data-type-for-url') + "/",
            subLocationSend: newValue[0],
            bedroom: this.state.bedRoomSelected

          };
          this.propertyUrl(urlPurpose)
        }}
        getOptionLabel={(option) => option.location}
        value={this.state.selectedLocations}

        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label=''
            placeholder={i18next.t("location")
            }
          />
        )}
      />
    const purposeView =
      <select onChange={this.propertyPurposeChangleHandle} className='filter-input form-select mb-3' id='propertyPurpose'>
        {
          lang == "en" ?
            <>
              <option name={"for-rent"} purposeForUrl="for-rent" selected={this.state ?.purpose == 1 ? 1 : null} value='1'>
                {i18next.t("forrent")}
              </option>
              <option name={"for-sale"} purposeForUrl="for-sale" selected={this.state ?.purpose == 2 ? 1 : null} value='2'>
                {i18next.t("forsale")}
              </option>
              <option name={"for-sharing"} purposeForUrl="for-sharing" selected={this.state ?.purpose == 3 ? 1 : null} value='3'>
                {i18next.t("forsharing")}
              </option>
            </>
            :
            <>
              <option name={"للايجار"} purposeForUrl="for-rent" selected={this.state ?.purpose == 1 ? 1 : null} value='1'>
                {i18next.t("forrent")}
              </option>
              <option name={"للبيع"} purposeForUrl="for-sale" selected={this.state ?.purpose == 2 ? 1 : null} value='2'>
                {i18next.t("forsale")}
              </option>
              <option name={"سكن-مشترك"} purposeForUrl="for-sharing" selected={this.state ?.purpose == 3 ? 1 : null} value='3'>
                {i18next.t("forsharing")}
              </option>
            </>
        }

      </select>
    const propertyTypeView =
      <select
        id="propertyType" className='filter-input form-select mb-3' onChange={this.propertyTypeOnChangeHadnler}>
        {this.state.propertytypes.map((prop, i) => (

          lang == "en" ?
            <option
              name={prop.slug_en}
              data-type-selected={prop.typeName_en}
              data-type-for-url={prop.slug_en}
              data-furnished={prop.furnishedornot}
              selected={prop.slug_en == window.location.href.split("/")[4] ? 'selected' : null}
              data-bed={prop.bedandbath}
              value={prop.id}>{prop.typeName_en}
            </option>
            :
            <option
              name={prop.slug_ar}
              data-type-selected={prop.typeName_ar}
              data-type-for-url={prop.slug_en}
              data-furnished={prop.furnishedornot}
              selected={prop.slug_en == decodeURI(window.location.href.split("/")[5]) ? 'selected' : null}
              data-bed={prop.bedandbath}
              value={prop.id}>{prop.typeName_ar}
            </option>

        ))}
      </select>

    // const Amenties =
    //   <input type="text" className="filter-input mb-3" placeholder="Key words" />

    const priceRangeView = <div className="row">
      <div className="col-sm-6">
        <input type="number" onChange={this.priceFromHandler}  value={this.state.priceFrom}  className="form-group" placeholder={i18next.t("from")} />
      </div>
      <div className="col-sm-6">
        <input type="number" onChange={this.priceToHandler} value={this.state.priceTo} className="form-group" placeholder={i18next.t("to")} />
      </div></div>
    // <div className="row">
    // <div
    //   className="form-select">
    //   <div className="form-group">
    //     <input
    //       onChange={this.priceFromHandle}
    //       className="form-group"
    //       type="number"
    //       placeholder={i18next.t("from")}
    //     ></input>
    //   </div>
    // </div>
    // <div className=" form-select">
    //   <div className="form-group">
    //     <input
    //       onChange={this.priceToHandle}
    //       className="form-group"
    //       type="number"
    //       placeholder={i18next.t("to")}
    //     ></input>
    //   </div>
    // </div>
    //  </div>
    const bedRoomView =
      <select
        className='filter-input form-select mb-3'
        onChange={this.bedroomOnChangeHadnler}
        name=''
        id='bedroom'>
        <option name="" value='-1' selected>
          {i18next.t("bedroom")}
        </option>
        <option name={"studio" + "-"} value='0'>{i18next.t("studiobed")}</option>
        <option name={"1-" + "bedroom" + "-"} value='1'>1</option>
        <option name={"2-" + "bedroom" + "-"} value='2'>2</option>
        <option name={"3-" + "bedroom" + "-"} value='3'>3</option>
        <option name={"4-" + "bedroom" + "-"} value='4'>4</option>
        <option name={"5-" + "bedroom" + "-"} value='5'>5</option>
        <option name={"6-" + "bedroom" + "-"} value='6'>6</option>
        <option name={"7-" + "bedroom" + "-"} value='7'>7</option>
        <option name={"8-" + "bedroom" + "-"} value='8'>8</option>
        <option name={"9-" + "bedroom" + "-"} value='9'>9</option>
        <option name={"10-" + "bedroom" + "-"} value='10'>10</option>
        <option name={"11-" + "bedroom" + "-"} value='11'>11</option>
        <option name={"12" + "bedroom" + "-"} value='12'>12</option>
        <option name={"13-" + "bedroom" + "-"} value='13'>13</option>
        <option name={"14-" + "bedroom" + "-"} value='14'>14</option>
        <option name={"15-" + "bedroom" + "-"} value='15'>15</option>
        <option name={"16-" + "bedroom" + "-"} value='16'>16</option>
        <option name={"17-" + "bedroom" + "-"} value='17'>17</option>
        <option name={"18-" + "bedroom" + "-"} value='18'>18</option>
        <option name={"19-" + "bedroom" + "-"} value='19'>19</option>
        <option name={"20-" + "bedroom" + "-"} value='20'>20</option>
        <option name={"20-" + "bedroom" + "-"} value='20+'>20+</option>
      </select>
    const bathRoomView =
      <select
        className='filter-input form-select mb-3'
        onChange={this.bathroomOnChangeHadnler}
        name=''
        id='bathroom'>
        <option value='0' selected>
          {i18next.t("bathroom")}
        </option>
        <option value='1'>1</option>
        <option value='2'>2</option>
        <option value='3'>3</option>
        <option value='4'>4</option>
        <option value='5'>5</option>
        <option value='6'>6</option>
        <option value='7'>7</option>
        <option value='8'>8</option>
        <option value='9'>9</option>
        <option value='10'>10</option>
        <option value='11'>11</option>
        <option value='12'>12</option>
        <option value='13'>13</option>
        <option value='14'>14</option>
        <option value='15'>15</option>
        <option value='16'>16</option>
        <option value='17'>17</option>
        <option value='18'>18</option>
        <option value='19'>19</option>
        <option value='20'>20</option>
        <option value='20+'>20+</option>
      </select>

    const frequencyView =
      <select id="frequencyview"
        className='filter-input form-select mb-3'
        onChange={this.frequencyOnChangeHandler}
        name=''
      // id=''
      >
        <option selected value='0' id='frequency'>
          {i18next.t("per")}
        </option>
        <option value='4'>{i18next.t("daily")}</option>
        <option value='3'>{i18next.t("weekly")}</option>
        <option value='2'>{i18next.t("monthly")}</option>
        <option value='1'>{i18next.t("yearly")}</option>
      </select>

    return (

      <>
        <Helmet>
          <title>{bedroom + " " + this.state.propertyTypeTitle + " " + purpose + " " + i18next.t("in") + " " + this.state.locationSlugTitle}</title>
          <meta name="description" content={
            lang == "en" ? "Find " + this.state.propertyTypeTitle + " " + purpose + " in " + this.state.locationSlugTitle + " Search through a wide range of " + this.state.propertyTypeTitle + " " + purpose + " in " + this.state.locationSlugTitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + this.state.propertyTypeTitle + " " + purpose + " في " + this.state.locationSlugTitle + ". البحث علي مجموعة واسعة من " + this.state.propertyTypeTitle + " " + purpose + " في " + this.state.locationSlugTitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta itemProp="name" content={
            lang == "en" ? "Find " + this.state.propertyTypeTitle + " " + purpose + " in " + this.state.locationSlugTitle + " Search through a wide range of " + this.state.propertyTypeTitle + " " + purpose + " in " + this.state.locationSlugTitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + this.state.propertyTypeTitle + " " + purpose + " في " + this.state.locationSlugTitle + ". البحث علي مجموعة واسعة من " + this.state.propertyTypeTitle + " " + purpose + " في " + this.state.locationSlugTitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta itemProp="description" content={
            lang == "en" ? "Find " + this.state.propertyTypeTitle + " " + purpose + " in " + this.state.locationSlugTitle + " Search through a wide range of " + this.state.propertyTypeTitle + " " + purpose + " in " + this.state.locationSlugTitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + this.state.propertyTypeTitle + " " + purpose + " في " + this.state.locationSlugTitle + ". البحث علي مجموعة واسعة من " + this.state.propertyTypeTitle + " " + purpose + " في " + this.state.locationSlugTitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta itemProp="image" content="../../../frontendUI/img/logo.png" />
          <meta property="og:url" content={lang == "en" ? "https://findproperties.ae" : "https://findproperties.ae/ar"} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this.state.pageContent ? lang == "en" ? this.state.pageContent.seotitle_en : this.state.pageContent.seotitle_ar : title} />
          <meta property="og:description" content={
            lang == "en" ? "Find " + this.state.propertyTypeTitle + " " + purpose + " in " + location + " Search through a wide range of " + this.state.propertyTypeTitle + " " + purpose + " in " + this.state.locationSlugTitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + this.state.propertyTypeTitle + " " + purpose + " في " + location + ". البحث علي مجموعة واسعة من " + this.state.propertyTypeTitle + " " + purpose + " في " + this.state.locationSlugTitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta property="og:image" content="../../../frontendUI/img/logo.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={this.state.pageContent ? lang == "en" ? this.state.pageContent.seotitle_en : this.state.pageContent.seotitle_ar : title} />
          <meta name="twitter:description" content={
            lang == "en" ? "Find " + this.state.propertyTypeTitle + " " + purpose + " in " + location + " Search through a wide range of " + this.state.propertyTypeTitle + " " + purpose + " in " + location + " and get agent contact details for sending enquiries." :
              "ابحث عن " + this.state.propertyTypeTitle + " " + purpose + " في " + location + ". البحث علي مجموعة واسعة من " + this.state.propertyTypeTitle + " " + purpose + " في " + location + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />

          {lang == "en" ? <link rel="canonical" href={websitebaseurl + language + this.state.urlForSeo} /> : <link rel="canonical" href={websitebaseurl + language + this.state.urlForSeo} />}
          <link rel="alternate" hreflang="en" href={websitebaseurl + this.state.urlForSeo} />
          <link rel="alternate" hreflang="ar" href={websitebaseurl + "ar/" + this.state.urlForSeo} />

          <meta name="twitter:image" content="../../../frontendUI/img/logo.png" />
          {/* robot */}
          <meta name="robots" content="index, follow"></meta>
        </Helmet>    
        <User show={this.state.showModal} onHide={this.handleHideModal} />

        <div className='section'>
          <div className='container'>
            {window.innerWidth > 720?
            <div id="scrollStyle" className="">
              <div className='row d-none d-sm-block stickyTop'>
                <div className='row'>
                  <div className='col-sm-3'>
                    {purposeView}
                  </div>
                  <div className='col-sm-6'>
                    {autoCompleteView}
                    {/* <div className="autocomplete">
                      <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Type to search..."
                      />
                      {isFetching && <div className="loading">Loading...</div>}
                      {suggestions.length > 0 && (
                        <ul className="suggestions">
                          {suggestions.map((suggestion, index) => (
                            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div> */}
                  </div>
                  <div className='col-sm-3'>
                    {propertyTypeView}
                  </div>
                </div>
                <div className='row'
                  style={{ height: "3.5rem", zIndex: '9', position: "relative" }}>
                  {/* <div className='col-sm-3'>
                    {Amenties}
                  </div> */}
                  <div className='col-sm-4 col-lg-4' id='accordionPanelsStayOpenExample'>
                    {priceRangeView}
                  </div>
                  {this.state.bedbathview == 1 ?
                    <div className='col-sm-2'>
                      {bedRoomView}
                    </div>
                    : null}
                  {this.state.bedbathview == 1 ?
                    <div className='col-sm-2'>
                      {bathRoomView}
                    </div>
                    : null}
                  <div className='col-lg-1 col-md-2'>
                    {$("#propertyPurpose").val() == 1 ? frequencyView : null}
                  </div>
                </div>
              </div>
            </div>:
             null
           }
            <div
              // id='filter-and-input'
              id="scrollStyleMobile"
              className='d-lg-none d-md-none flex'  >
              <div className="mobile-search-bar">
                <Autocomplete
                  multiple
                  limitTags={3}
                  id='multiple-limit-tags'
                  options={this.state.locations}
                  onChange={(event, newValue) => {
                    this.setState({
                      selectedLocations: newValue,
                      subLocationSend: newValue.length > 0 ? newValue[0] : [],
                      locforUrl: newValue.length > 0 ? newValue[0].location : [],
                      propertyTypeName: $("#propertyType option:selected").text(),
                      locationSlugTitle: newValue.length > 0 ? newValue[0].location : lang == "en" ? "uae" : "الإمارات",

                    });

                    const data = {
                      subLocationSend: newValue.length > 0 ? newValue[0] : [],
                      bathRoomSelected: $("#propertyType").find(":selected").attr('data-bed')==1?this.state.bathRoomSelected: 0,
                      bedRoomSelected: $("#propertyType").find(":selected").attr('data-bed')==1?this.state.bedRoomSelected: -1,
                      propertyType: this.state.propertyType,
                      purpose: this.state.purpose,
                      frequencySelected: this.state.frequencySelected,
                      selectedLocations: newValue,
                      selectedFeatures: this.state.selectedFeatures,
                    };
                    this.fetchPropertyData(1, data);
                    const urlPurpose = {
                      purpose: $("#propertyPurpose").find(":selected").attr('purposeForUrl').toLowerCase().replace(/\s+/g, '-') + "/",
                      propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" :  $("#propertyType").find(":selected").attr('data-type-for-url') + "/",
                      subLocationSend: newValue[0],
                      bedroom: this.state.bedRoomSelected

                    };
                    this.propertyUrl(urlPurpose)

                  }}
                  getOptionLabel={(option) => option.location}
                  value={this.state.selectedLocations}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label=''
                      placeholder={i18next.t("location")
                      }
                    />
                  )}
                />
              </div>

              <div className='mobile-filter-btn'>
                <button
                  onClick={this.filterCanvas}
                  id="filterCanavas"
                  className='filter-canvas-btn'
                  type='button'>
                  <i className="fas fa-sliders-h"></i>
                </button>
                <aside className="main-aside filterWindowMob">
                  <div className="aside-title">
                    <div className="aside-controls aside-trigger">
                      <h4>{i18next.t("filters")}</h4>
                      <div onClick={this.closeFilter} className="close-btn close-dark">
                        <span />
                        <span />
                      </div>
                    </div>
                  </div>
                  <div className="aside-scroll">
                    {purposeView}
                    {propertyTypeView}
                    {/* {Amenties} */}
                    {priceRangeView}
                    {this.state.bedbathview == 1 ? bedRoomView : null}
                    {this.state.bedbathview == 1 ? bathRoomView : null}
                    {$("#propertyPurpose").val() == 1 ? frequencyView : null}
                    <a onClick={this.mobilefilter}
                      className="btn-custom secondary btn-block modal-button">Apply Filter</a>
                  </div>
                </aside>
                <div className="aside-overlay aside-trigger" />
              </div>
            </div>

            <div className='row' style={{ padding: "5px!important" }}>
              <div className='searched-text'>
                {this.state.displayType !== 2 &&
                  <h1 lang={lang == "en" ? "en" : "ar"} className='pages-title'>
                    {bedroom + " " + this.state.propertyTypeTitle + " " + purpose + " " + i18next.t("in") + " " + this.state.locationSlugTitle}
                    {/* {
                        this.state.selectedLocations.length < 1 || this.state.selectedLocations === undefined || this.state.selectedLocations.length === 0
                          ? i18next.t("uae")
                          : this.state.selectedLocations.length === 1
                            ? this.state.locforUrl.replace("-", " ")
                            : this.state.selectedLocations.length === 2
                              ? this.state.selectedLocations[0].location + i18next.t("and") + this.state.selectedLocations[1].location
                              : this.state.selectedLocations.length > 2
                                ? this.state.selectedLocations.map((locs, i) =>
                                    i < this.state.selectedLocations.length - 1
                                      ? locs.location + " , "
                                      : i18next.t("and") + locs.location
                                  )
                                : null
                      } */}

                  </h1>}
              </div>
            </div>
            {this.state.subLocationArr.length > 0 && this.state.displayType !== 2 ? (
              <div className=' wrapperlocation '>
                <div className='locationsOnsearch'>
                  {this.state.subLocationArr.map((subLocs) => (
                    <div className='eachLocations'>
                      <button lang={lang == "en" ? "en" : "ar"}
                        // style={{
                        //   color: "#519fff",
                        //   background: "transparent",
                        //   border: "none",
                        // }}
                        onClick={this.subLocationClickHandler.bind(this, subLocs)}>
                        {lang == "en" ? subLocs.location_en : subLocs.location_ar} <span className="propertyCountSubLoca">({subLocs.property_count})</span>
                      </button>
                    </div>
                  ))}
                </div>
                <div className='viewMoreLocationsWrap'>
                  <div className='viewMoreLocations'>
                    {/* <span> {i18next.t("viewlocation")}</span>{" "}
                    <i className='fa fa-caret-down'></i> */}
                  </div>
                </div>
              </div>
            ) : null}
            {/* {this.state.displayType !==2 && */}
            <div className='row furnish-status-filter'>
              <div className='col '>
                <div className="row">
                  {(this.state.furnishedornot == 1 || this.state.propertyType == 0) && document.getElementById("propertyPurpose").value == "1" ?
                    <>
                      <button
                        id='allFurnishedStatus'
                        onClick={this.furnishedUnfurnishedAll}
                        className='furnishedActive'>
                        {i18next.t("all")}
                      </button>
                      <button
                        id='furnishedOnly'
                        onClick={this.furnishedClickHandle}
                        className=''>
                        {i18next.t("furnished")}
                      </button>
                      <button
                        id='unfurnishedOnly'
                        onClick={this.unfurnishedClickHandle}
                        className=''>
                        {i18next.t("unfurnished")}
                      </button></>
                     : null}
                </div>
              </div>
              <div className='col '>

                <div direction="rtl" className='dropdown right'>
                {/* <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <FaSortAmountDown style={{ marginRight: '2px' }}  />
                       {this.state.popular == "" ? i18next.t("popular") : this.state.popular}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Dropdown.Item>
                        <button onClick={this.highestPriceonClick}
                          // className='popular-button'>
                          {i18next.t("highest")}
                        </button>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item>
                          <button onClick={this.newestPropetyOnClick} className='popular-button'>
                            {i18next.t("newest")}
                          </button>
                      </Dropdown.Item>
                      <Dropdown.Item>
                            <button
                              onClick={this.lowestPriceFilteronClick}
                              className='popular-button'>
                              {i18next.t("lowest")}
                            </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */} 
                  {/* <button
                    className=''
                    type='button'
                    id='dropdownMenuButton1'
                    data-toggle='dropdown'
                    aria-expanded='false'>
                    <i className='fa fa-sort-amount-down'></i>
                    &nbsp;{" "}
                    {this.state.popular == "" ? i18next.t("popular") : this.state.popular}
                    &nbsp;&nbsp;
                    <i className='fa fa-caret-down'></i>
                  </button> */}
                  <ul className='gridList d-none d-md-flex d-md-flex d-l-flex' style={{ marginBottom: 0 }}>
                    <li className='list-inline-item'>
                      <button
                        onClick={() => this.displayTypeHandler(this, 0)}
                        className='displayType active'>
                        <span className='icons-display-type ti-view-list'></span>
                      </button>
                    </li>
                    <li className='list-inline-item'>
                      <button
                        onClick={() => this.displayTypeHandler(this, 1)}
                        className='displayType'>
                        <span className='icons-display-type  ti-layout-grid2'></span>
                      </button>
                    </li>
                    <li className="list-inline-item"><button onClick={() => this.displayTypeHandler(this, 2)} className="displayType"><span className="icons-display-type ti-map-alt"></span></button></li>
                  </ul>
                  <ul
                    className='dropdown-menu popularDD'
                    aria-labelledby='dropdownMenuButton1'>
                    <li className="popular-list">
                      <button onClick={this.newestPropetyOnClick} className='popular-button'>
                        {i18next.t("newest")}
                      </button>
                    </li>
                    <li className="popular-list">
                      <button
                        onClick={this.lowestPriceFilteronClick}
                        className='popular-button'>
                        {i18next.t("lowest")}
                      </button>
                    </li>
                    <li className="popular-list">
                      <button onClick={this.highestPriceonClick}
                        className='popular-button'>
                        {i18next.t("highest")}
                      </button>
                    </li>
                  </ul>
                </div>

              </div>
              {/* <div className='col d-none d-md-flex d-md-flex d-l-flex right'>
            </div> */}
            </div>
            {this.state.displayType != 2 &&
              <div className='row'>
                {/* Sidebar Start */}
                <div className='col-md-12 col-lg-8 col-sm-12 col-xs-12 row wrapper-listing'>
                  {/* Controls Start */}
                  {this.state.isLoading ? <div id="overlay"  >
                    <div className='lds-ripple center' style={{height: "100%"}}>
                      <PropagateLoader
                        style={{ marginTop: '100px' }}
                        color={"#36D7B7"} loading={true} size={20} />
                    </div>
                  </div> : null}
                  <div className='acr-global-listing-controls d-sm-none d-md-none'>
                    <div className='acr-toggle-views '>
                      <a
                        href='javascript:void(0)'
                        onClick={this.HandlePropertyStyle.bind(this, 0)}
                        className={this.state.propertyStyle == 0 ? "active" : ""}
                        data-toggle='tooltip'
                        title='List'>
                        <i className='fas fa-th-list' />
                      </a>
                      <a
                        href='javascript:void(0)'
                        onClick={this.HandlePropertyStyle.bind(this, 1)}
                        className={this.state.propertyStyle == 1 ? "active" : ""}
                        data-toggle='tooltip'
                        title='Grid' >
                        <i className='fas fa-th-large' />
                      </a>
                      {/* <button href="javascript:void(0)" className="float-end" data-toggle="tooltip" title="Grid"><i className="fas fa-th-large"></i></button> */}
                      <button
                        className=''
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#panelsStayOpen-collapseTwo'
                        aria-expanded='false'
                        aria-controls='panelsStayOpen-collapseTwo'
                      ></button>
                    </div>
                    <div className='acr-toggle-views  inline'></div>
                  </div>


                  {/* {this.state.filteredProperties.length > this.state.perPage ? */}

                  {/* : null} */}

                  <>

                    <>
                      {this.state.pageCount > 0 ? (
                        <>
                          {this.state.postData}
                          {this.state.filteredProperties.length > 0 ?
                            <>
                            {/* <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              // forcePage={0}
                              // renderOnZeroPageCount={null}
                              // forcePage={this.state.pageReset}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />  */}
                                  {/* <Pagination count={this.state.pageCount} /> */}

                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalResult}
                            pageRangeDisplayed={7}
                            hideFirstLastPages={true}
                            onChange={this.handlePageChange.bind(this)}
                          /></>
                            : null}
                        </>
                      ) : (<></>

                        )}
                    </>
                    <div
                      style={{ margin: "0 auto", overflow: "hidden" }}
                      className='modal fade center'
                      id='callModal'
                      tabindex='-1'
                      aria-labelledby='callModalLabel'
                      aria-hidden='true'>
                      <div
                        style={{ width: "22rem", margin: "0 auto" }}
                        className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                        <div className='modal-content'>
                          <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalLabel'>
                              Contact Us
                            </h5>
                            <button
                              type='button'
                              className='btn-close'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            ></button>
                          </div>
                          <div
                            className='modal-body'
                            style={{ width: "96", margin: "auto auto" }}>
                            <div className='block'>
                              <div
                                className='row center'
                                style={{
                                  marginBottom: "20px",
                                  textAlign: "center",
                                }}
                              >
                              </div>
                              <div className='row '>
                                <div className='col border'>Mobile</div>
                                <div className='col border'>
                                  <a href={"tel:" + this.state.phone}>
                                    {this.state.phone}
                                  </a>
                                </div>
                              </div>
                              <div
                                className='row center'
                                style={{ marginTop: "20px", textAlign: "center" }}>
                                Please mention that you have seen this Ad on
                                findproperties.com
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
                {this.state.displayType !== 2 && <div className='col-lg-4  d-none d-md-block d-lg-block'>
                  <div className='sidebar sidebar-left d-sm-none d-md-block '>
                    {this.state.subLocationArr.length > 0 ?
                      <div className='sidebar-widget d-sm-none d-md-block sideBarMobileApp'>
                        <div className='acr-collapse-trigger acr-custom-chevron-wrapper'>
                          <h5>
                            {i18next.t("relatedsearches")}
                          </h5>
                          <div className='acr-custom-chevron'>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                        <div className='acr-collapsable'>
                          {this.state.subLocationArr.map((subLocs) => (
                            <>
                              <Link onClick={this.subLocationClickHandler.bind(this, subLocs)}>
                                {$("#propertyType").find(":selected").val() != 0 ? $("#propertyType").find(":selected").text()
                                  : i18next.t("properties") + " "}
                                {$("#propertyPurpose").find(":selected").text().toLocaleLowerCase()} {i18next.t("in") + " "}
                                {lang=="en"?subLocs.location_en:subLocs.location_ar} </Link>
                              <br />
                            </>
                          ))}
                        </div>
                      </div> : null}
                    <div className="sideBarMobileApp" >
                      <a data_target="_blank" href="https://play.google.com/store/apps/details?id=com.findproperties.uae"> <img src={'https://findproperties.ae/frontendUI/img/find-app-download.jpg'} /></a>
                    </div>
                    {/* {width >= 992?
                  <> */}
                    {/* <SideAds/> */}
                    {/* <ResponsiveDisplayAd classApply="desktop_portrait_side_3" /> */}
                    {/* </>: null
                  } */}
                    {/* // responsive and native ads */}
                    {/* // auto full width responsive ads */}
                    {/* <AdSense.Google
                      client='ca-pub-8608340480208564'
                      slot='4685661291'
                      style={{ display: 'block' }}
                      format='auto'
                      responsive='true'
                      layoutKey='-gw-1+2a-9x+5c'
                    /> */}
                  </div>
                </div>}
                {/* Sidebar End */}
                {/* Posts Start */}

                <div className="bottom-content d-none d-md-block d-lg-block">
                  {/* <ResponsiveDisplayAd classApply="desktop_portrait_side_3" /> */}
                  {/* {width <= 992?
                   <MobileAd />:null
                   } */}
                  <h2>{this.state.pageContent ? lang == "en" ? this.state.pageContent.title_en : this.state.pageContent.title_ar : null}</h2>
                  <div className="bottom-content-body">
                    {this.state.pageContent ? ReactHtmlParser(lang == "en" ? this.state.pageContent.description_en : this.state.pageContent.description_ar) : null}
                  </div>
                </div>
              </div>}
          </div>
          {this.state.displayType == 2 &&

            <><div className='row'>
              <div className='col-lg-6 d-sm-12 d-md-block map-height'>
                <div id="map" style={{ position: 'relative', overflow: 'hidden' }}></div>
              </div>
              <div className='col-md-12 col-lg-6 col-sm-12 col-xs-12 row wrapper-listing'>
                {/* Controls Start */}
                {this.state.isLoading && <div id="overlay"  >
                  <div className='lds-ripple center'>
                    <div></div>
                    <div></div>
                  </div>
                </div>}
                <div className='acr-global-listing-controls d-sm-none d-md-none'>
                  <div className='acr-toggle-views '>
                    <a
                      href='javascript:void(0)'
                      onClick={this.HandlePropertyStyle.bind(this, 0)}
                      className={this.state.propertyStyle == 0 ? "active" : ""}
                      data-toggle='tooltip'
                      title='List'>
                      <i className='fas fa-th-list' />
                    </a>
                    <a
                      href='javascript:void(0)'
                      onClick={this.HandlePropertyStyle.bind(this, 1)}
                      className={this.state.propertyStyle == 1 ? "active" : ""}
                      data-toggle='tooltip'
                      title='Grid' >
                      <i className='fas fa-th-large' />
                    </a>
                    {/* <button href="javascript:void(0)" className="float-end" data-toggle="tooltip" title="Grid"><i className="fas fa-th-large"></i></button> */}
                    <button
                      className=''
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#panelsStayOpen-collapseTwo'
                      aria-expanded='false'
                      aria-controls='panelsStayOpen-collapseTwo'
                    ></button>
                  </div>
                  <div className='acr-toggle-views  inline'></div>
                </div>
                {/* </div> */}
                <div style={{ overflow: 'auto' }}>
                  <div className="similar-group">
                    {/* {this.state.filteredProperties.data.length > this.state.perPage ? */}
                    {this.state.postData}

                  </div>

                </div>
                <>
                  <div
                    style={{ margin: "0 auto", overflow: "hidden" }}
                    className='modal fade center'
                    id='callModal'
                    tabindex='-1'
                    aria-labelledby='callModalLabel'
                    aria-hidden='true'>
                    <div
                      style={{ width: "22rem", margin: "0 auto" }}
                      className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title' id='exampleModalLabel'>
                            Contact Us
                          </h5>
                          <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          ></button>
                        </div>
                        <div
                          className='modal-body'
                          style={{ width: "96", margin: "auto auto" }}>
                          <div className='block'>
                            <div
                              className='row center'
                              style={{
                                marginBottom: "20px",
                                textAlign: "center",
                              }}
                            >
                            </div>
                            <div className='row '>
                              <div className='col border'>Mobile</div>
                              <div className='col border'>
                                <a href={"tel:" + this.state.phone}>
                                  {this.state.phone}
                                </a>
                              </div>
                            </div>
                            <div
                              className='row center'
                              style={{ marginTop: "20px", textAlign: "center" }}>
                              Please mention that you have seen this Ad on
                              findproperties.com
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>

            </div>
              <div className="row">
                {this.state.pageCount > 0 ? (
                  <>
                    {/* {this.state.postData}
                          {this.state.filteredProperties.length > 0?
    :null} */}

                    {/* <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    /> */}
                    
                    <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalResult}
                            pageRangeDisplayed={7}
                            hideFirstLastPages={true}
                            onChange={this.handlePageChange.bind(this)}
                          />
                  </>
                ) : null}
              </div>
            </>
          }
        </div>
      </>
    );
  }
}

  // const mapStateToProps = state =>{
  //   return {
  //      loggedIn:state.auth.loggedIn,
  //      user:state.auth.user
  //   }
  // }

