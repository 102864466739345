import React, { Component } from "react";
import { baseurl, baseurlImg } from "../../../Components/BaseUrl";
import axios from "axios";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import cookie from "js-cookie";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { MultiSelect } from "react-multi-select-component";

const lang = localStorage.getItem("lang") || "en";
const activeUser = cookie.get("user_id");
const token = cookie.get("token");

export class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      users: [],
      messages: [],
      messageBody: "",
      messageContent: 0,
      recieverUserName: "",
      recieverId: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      NewProfilePic: "",
      offset: 0,
      favouriteProps: [],
      perPage: 6,
      currentPage: 1,
      favouriteProperties: [],
      propertytypes: [],
      selectedLocation: [],
      locations: [],
      agents: [],
      selectedAgents: [],
      languages: [],
      nationality: [],
      selectedLanguages: [],
      selectedNationality: 0,
      bed: 0,
      price: 0,
      bath: 0,
      area: 0,
      type: 0,
      purpose: 0,
      furnished: 0,
      myPropertyLocation: "",
      input: {},
      errors: {},
      inputMessage: {},
      errorsTM: {},
      showBrokerNameOnchat: false

    };
  }
  handleWishList = (e) => {
    // alert(e.target.value)
    const data = { property_id: e.target.value };
    const dataForCheckBox = {
      property_id: e.target.value,
    };
    axios.post(baseurl + "wishlist", data).then((response) => {
      $("input[value=" + e.target.value + "]").trigger("click");
    });
    this.fetchFavouriteProperties();
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        const data = {
          displayType: this.state.displayType,
          subLocationSend: this.props.location.state
            ? this.props.location.state["passsubLocationSend"]
            : this.state.subLocationSend,
          furnished: this.state.furnished,
          popular: this.state.popular,
          bathRoomSelected: this.props.location.state
            ? this.props.location.state["passBath"]
            : this.state.bathRoomSelected,
          bedRoomSelected: this.props.location.state
            ? this.props.location.state["passBed"]
            : this.state.bedRoomSelected,
          propertyType: this.props.location.state
            ? this.props.location.state["passType"]
            : this.state.propertyType,
          purpose: this.props.location.state
            ? this.props.location.state["passPurpose"]
            : this.state.purpose,
          frequencySelected: this.props.location.state
            ? this.props.location.state["passFrequency"]
            : this.state.frequencySelected,
          selectedLocations: this.props.location.state
            ? this.props.location.state["passLocation"]
            : this.state.selectedLocations,
          priceFrom: this.props.location.state
            ? this.props.location.state["passPricefrom"]
            : this.state.priceFrom,
          priceTo: this.props.location.state
            ? this.props.location.state["passPriceto"]
            : this.state.priceTo,
          subLocationArr: this.state.subLocationArr,
          selectedFeatures: this.state.selectedFeatures,
          displayType: this.state.displayType,
        };
        this.fetchFavouriteProperties(data);
      }
    );
  };
  oldPassword = (e) => {
    this.setState({ oldPassword: e.target.value });
  };
  newPasswordChange = (e) => {
    this.setState({ newPassword: e.target.value });
  };
  confirmPassword = (e) => {
    this.setState({ confirmPassword: e.target.value });
  };
  savePassChange = () => {
    const data = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
    };
    const token = cookie.get("token");
    axios
      .post(baseurl + "changePassword", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("password", response.data);
      });
  };
  updateprofilePic = (e) => {
    // const data = {newProfilePic: e.target.value}
    // console.log(e.target.files[0].name)
    // e.preventDefault();
    // let files = e.target.files || e.dataTransfer.files;
    // if (!files.length)
    //       return;
    // this.createImage(files[0]);
    // //console.log(e.target.files[0])
    const token = cookie.get("token");
    // this.setState({newProfilePic:URL.createObjectURL(e.target.files[0])})
    const data = new FormData();
    data.append("NewProfilePic", e.target.files[0]);
    axios
      .post(baseurl + "changePicture", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Response Upload pic", response.data.user.profile);
        cookie.set("profile", response.data.user.profile);
        window.location.reload();
      });
  };
  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        NewProfilePic: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }
  handleLogout = () => {
    // e.preventDefault();
    cookie.remove("token");
    cookie.remove("user_id");
    cookie.remove("role");
    cookie.remove("name");
    cookie.remove("name_ar");
    cookie.remove("profile");
    cookie.remove("email");
    this.props.logout();
    window.location.reload();
    // this.props.history.push('/login')
  };
  handleFormErrorSend = () => {
    let inputMessage = this.state.inputMessage;
    let errorsTM = {};
    let formIsValid = true;
    if (!inputMessage["message"]) {
      formIsValid = false;
      errorsTM["message"] = "Message can not be Empty!";
    }

    this.setState({
      errors: errorsTM,
    });
    console.log()
    return formIsValid;
  };
  hadnleFormError = () => {
    let input = this.state.input;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!input["location"]) {
      formIsValid = false;
      errors["location"] = "Location cannot be empty!";
    }

    if (!input["price"]) {
      formIsValid = false;
      errors["price"] = "Price cannot be empty!";
    }
    if (!input["bed"]) {
      formIsValid = false;
      errors["bed"] = "Bedroom cannot be empty!";
    }
    if (!input["bath"]) {
      formIsValid = false;
      errors["bath"] = "Bathroom cannot be empty!";
    }
    if (!input["area"]) {
      formIsValid = false;
      errors["area"] = "Area cannot be empty!";
    }
    if (!input["type"]) {
      formIsValid = false;
      errors["type"] = "Property type cannot be empty!";
    }
    if (!input["purpose"]) {
      formIsValid = false;
      errors["purpose"] = "Purpose cannot be empty!";
    }
    if (typeof input["name"] !== "undefined") {
      if (!input["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  mypropertyLocationOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    console.log("selected location", e);
    this.setState({ myPropertyLocation: e.target.value, input });
    const data = {
      selectedLanguages: this.state.selectedLanguages,
      selectedNationality: this.state.selectedNationality,
      selectedLocation: e.target.value,
    };
    this.fetchAgents(data);
  };
  onChangeLanguage = (e) => {
    this.setState({ selectedLanguages: e });
    const data = {
      selectedLanguages: e,
      selectedNationality: this.state.selectedNationality,
      selectedLocation: this.state.selectedLocation,
    };
    this.fetchAgents(data);
  };
  onChangeNationality = (e) => {
    this.setState({ selectedNationality: e.target.value });
    // const bed = this.state.bed > 0 ? this.state.bed + "bedroom" : "studio";
    // const bath = this.state.bath;
    // const furnished = this.state.furnished;
    // const type = this.state.type;
    // const purpose = this.state.purpose;
    const data = {
      // messageBody: "Hi I have " + bed + " and " + bath + " bathroom " + furnished + " " + type + " for " + purpose + " in " +loc,
      // selectedAgents: this.state.selectedAgents,
      selectedLanguages: this.state.selectedLanguages,
      selectedNationality: e.target.value,
      selectedLocation: this.state.selectedLocation,
    };
    this.fetchAgents(data);
  };
  bathOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({
      bath: e.target.value,
      input,
    });
  };
  bedOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ bed: e.target.value, input });
  };
  areaOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ area: e.target.value, input });
  };
  propertytypesOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ type: e.target.value, input });
  };
  propertyPurposeOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ purpose: e.target.value, input });
  };
  furnishedOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ furnished: e.target.value, input });
  };
  priceOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ price: e.target.value, input });
  };
  sendMessage = () => {
    console.log("handle form", this.hadnleFormError());
    if (this.hadnleFormError()) {
      const loc = this.state.myPropertyLocation;
      const bed = this.state.bed > 0 ? this.state.bed + "bedroom" : "studio";
      const bath = this.state.bath;
      const furnished = this.state.furnished;
      const type = this.state.type;
      const purpose = this.state.purpose;
      const data = {
        messageBody:
          "Hi I am property Owner, I want to list the following property details with you:" +
          bed +
          " and " +
          bath +
          " bathroom " +
          furnished +
          " " +
          type +
          " for " +
          purpose +
          " in " +
          loc,
        selectedAgents: this.state.selectedAgents,
        selectedLanguages: this.state.selectedLanguages,
        selectedNationality: this.state.selectedNationality,
      };
      axios
        .post(baseurl + "ownerMessage", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("send Message", response.data);
        });
    } else {
      // alert("there is missing field")
    }
  };
  selectedAgentsHandle = (e) => {
    this.setState({ selectedAgents: e });
  };
  fetchData = () => {
    this.setState({ isLoading: true });
    axios
      .get(baseurl + "propertytypes")
      .then((response) => {
        console.log("property Types", response.data);
        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch();
    axios
      .get(baseurl + "features")
      .then((response) => {
        //  console.log(response.data)
        this.setState({
          isLoading: false,
          features:
            lang == "en"
              ? response.data.allfeatures_en
              : response.data.allfeatures_ar,
        });
      })
      .catch();
    axios
      .get(baseurl + "locations")
      .then((response) => {
        console.log("auto complete location", response.data);
        this.setState({
          isLoading: false,
          locations: response.data.locations,
          // subLocationSend: response.data.location[0]
        });
      })
      .catch();
  };
  fetchAgentDetail = () => {
    const token = cookie.get("token");
    axios
      .get(baseurl + "agentinfo")
      .then((response) => {
        this.setState({
          isLoading: false,
          nationality: response.data.nationality,
          // languages: response.data.languages
        });
      })
      .catch();
    axios
      .get(baseurl + "languages")
      .then((response) => {
        if (lang == "en") {
          console.log("lang", this.state.languages);
          this.setState({ languages: response.data.languages_en });
        } else {
          this.setState({ languages: response.data.languages_ar });
        }
      })
      .catch();
  };
  fetchAgents = (agentData) => {
    axios
      .post(baseurl + "fetchAgentsByLocation", agentData)
      .then((response) => {
        console.log("selected agents", response.data);
        this.setState({ agents: response.data.agents });
      })
      .catch();
  };
  componentDidMount() {
    const data = {
      selectedLocation: this.state.selectedLocation,
    };
    this.fetchData();
    this.fetchAgentDetail();
    this.fetchAgents(data);
    this.fetchFavouriteProperties();
    this.getUsers();
    const pusher = new Pusher("2955db0221fb16cb6441", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe("chat");
    channel.bind("message", (newMessage) => {
      // alert(JSON.stringify(data));
      //  if(newMessage.sender == activeUser|| newMessage.reciever == activeUser){
      this.setState({ messages: [...this.state.messages, newMessage] });
      //  }
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }
  getUsers() {
    axios
      .get(baseurl + "chatusers", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          users: response.data.users,
        });
        console.log("Chat Users", response.data);
      });
  }
  //     getMessage() {
  //       axios.get(baseurl+"allmessages/"+userid).then((response) => {
  //         this.setState({
  //           users: response.data.users
  //         })
  //         console.log("Chat", response.data)
  //    });
  //  }
  handleUserChat = (user, e) => {
    axios
      .get(baseurl + "allmessages/" + user.id, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          messages: response.data.messages,
          messageContent: 1,
          recieverUserName: user.name,
          recieverId: user.id,
        });
        // console.log("Chat Users", response.data.messages);
      });
  };
  messageSendHandler = (e) => {
   let inputMessage = this.state.inputMessage;
   inputMessage[e.target.name] = e.target.value;
    this.setState({
      // message: e.target.value, inputMessage ,
      messageBody:  e.target.value,inputMessage,
      recieverId: this.state.recieverId,
    });
  };
  submitMessage = () => {
    // e.preventDefault();
    console.log("datas", this.state.messageBody+"|"+this.state.recieverId)

   
    if (this.handleFormErrorSend()) {
      const data = {
        messageBody: this.state.messageBody,
        reciever: this.state.recieverId,
      };
      axios
        .post(baseurl + "newmessage", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data)
          this.setState({ messageBody: "" });
          //this.setState({messageBody:""})
          //this.fetchData();
        });
    }
  };
  fetchFavouriteProperties = () => {
    const token = cookie.get("token");
    axios
      .get(baseurl + "allwishlist", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("responded data", response.data);
        this.setState({
          isLoading: false,
          favouriteProperties: response.data.wishlists,
        });
        const favourite = response.data.wishlists;
        // console.log("response filter", response.data.filteredproperties.length);
        const slice = favourite.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const postData = slice.map((property, i) => (
          <React.Fragment>
            <div className="listing listing-list">
              <div className="listing-thumbnail col-sm-2">
                <Link to={"/single-property/" + property.id}>
                  <img
                    src={baseurlImg + "/uploads/properties/" + property.image}
                    alt="listing"
                  />
                </Link>
                <div className="listing-badges">
                  <span className="listing-badge featured">
                    {" "}
                    <i className="fas fa-star" />{" "}
                  </span>
                  <span
                    className={
                      property.purpose == 1
                        ? "listing-badge rent"
                        : "listing-badge sale"
                    }
                  >
                    {" "}
                    {property.purpose == 1 ? "For rent" : "For Sale"}
                  </span>
                </div>
                <div className="listing-controls">
                  <div className="_card_flex_last">
                    <div className="prt_saveed_12lk">
                      <label className="toggler toggler-danger">
                        <input
                          onChange={this.handleWishList}
                          value={property.id}
                          type="checkbox"
                          checked={
                            (property.wishlist_status == 1 ? true : false) ||
                            this.state.checked
                          }
                        />
                        <i className="ti-heart" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="listing-body call-buttons">
                <div className="flex">
                  <span className="listing-price">
                    {property.price}
                    <span>AED</span>
                  </span>
                  <span className="right">
                    {lang == "en" ? property.typeName_en : property.typeName_ar}
                  </span>
                </div>
                <h5 className="listing-title">
                  <Link to={"/single-property/" + property.id}>
                    {property.title_en}
                  </Link>
                </h5>
                <div className="acr-listing-icons">
                  <div
                    className="acr-listing-icon"
                    data-toggle="tooltip"
                    title="Beds"
                  >
                    <i className="flaticon-bedroom" />
                    <span className="acr-listing-icon-value">
                      {property.beds == 0 ? "Studio" : property.beds}
                    </span>
                  </div>
                  {property.baths != 0 ? (
                    <div
                      className="acr-listing-icon"
                      data-toggle="tooltip"
                      title="Bathrooms"
                    >
                      <i className="flaticon-bathroom" />
                      <span className="acr-listing-icon-value">
                        {property.baths}
                      </span>
                    </div>
                  ) : null}
                  <div
                    className="acr-listing-icon"
                    data-toggle="tooltip"
                    title="Square Feet"
                  >
                    <i className="flaticon-ruler" />
                    <span className="acr-listing-icon-value">
                      {property.area}
                    </span>
                  </div>
                </div>
                <div className="listing-author">
                  <div className="listing-gallery-wrapper">
                    <a
                      href={"tel:" + property.mobile}
                      data-bs-toggle="modal"
                      data-bs-target="#callModal"
                      onClick={() => {
                        this.setState({
                          phone: property.mobile,
                          agentName: property.name,
                        });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fa fa-phone" aria-hidden="true">
                        {" "}
                      </i>
                      &nbsp;&nbsp;Call&nbsp;&nbsp;&nbsp;&nbsp;
                    </a>
                  </div>
                  {/* 
            <div className="listing-gallery-wrapper d-lg-none d-sm-block d-md-none">
               <a
                  href="listing-details-v1.html"
                  className="btn-custom btn-sm secondary"
                  tabIndex={0}>
               <i className="fas fa-envelope"></i>
               </a>
            </div>
            */}
                  <div className="listing-gallery-wrapper">
                    <a
                      href={"mailto:" + property.email}
                      onClick={() => {
                        this.setState({ email: property.email });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fas fa-envelope"></i> &nbsp; Email
                    </a>
                  </div>
                  <div className="listing-gallery-wrapper">
                    <a
                      href={
                        "https://wa.me/" +
                        property.mobile +
                        "?text=Hey%20" +
                        property.name_en +
                        ", %20I'm%20interested%20in%20your%20 " +
                        property.title_en +
                        ""
                      }
                      onClick={() => {
                        this.setState({ email: property.mobile });
                      }}
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}
                    >
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </div>
                  <div className="agentNames">
                    <Link to={"/single-property/" + property.id}>
                      <img
                        src={
                          property.logo == undefined
                            ? baseurlImg + "/uploads/profiles/no_avatar.png"
                            : baseurlImg + "/uploads/profiles/" + property.logo
                        }
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ));
        this.setState({
          pageCount: Math.ceil(favourite.length / this.state.perPage),
          postData,
        });
      });
  };
  render() {
    return (
      <div>
        {/* ============================ User Dashboard ================================== */}
        <section className="gray pt-5 pb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="property_dashboard_navbar">
                  <div className="dash_user_avater">
                    <img
                      src={
                        baseurlImg +
                        "/uploads/profiles/" +
                        cookie.get("profile")
                      }
                      className="img-fluid avater"
                      alt
                    />
                    <button
                      id=""
                      className="changeProfileButton"
                      data-toggle="modal"
                      data-bs-target="#changeProfilePicture"
                    >
                      <i className="fa fa-camera" />
                    </button>
                    <div class="changeProfileButton">
                      <i className="fa fa-camera" />
                      <input
                        name="Select File"
                        onChange={this.updateprofilePic}
                        type="file"
                      />
                    </div>
                    {/* 
               <div class="modal fade" id="changeProfilePicture" tabindex="-1" role="dialog" aria-labelledby="changeProfilePicture" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           ...
                        </div>
                        <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                           <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                     </div>
                  </div>
               </div>
               */}
                    <h4>{cookie.get("name")}</h4>
                    {/* <span>Canada USA</span> */}
                  </div>
                  <div className="dash_user_menues">
                    <ul
                      id="v-pills-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      {/* 
                  <li><button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><i className="fa fa-tachometer-alt" />Dashboard<span className="notti_coun style-1">4</span></button></li>
                  <li><button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i className="fa fa-user-tie" />My Profile</button></li>
                  <li><a href="bookmark-list.html"><i className="fa fa-bookmark" />Favourite Property<span className="notti_coun style-2">7</span></a></li>
                  */}
                      {/* 
                  <li><a href="my-property.html"><i className="fa fa-tasks" />My Properties</a></li>
                  */}
                      {/* 
                  <li><a href="#message"><i className="fa fa-envelope" />Messages<span className="notti_coun style-3">3</span></a></li>
                  <li><a href="choose-package.html"><i className="fa fa-gift" />Choose Package<span className="expiration">10 days left</span></a></li>
                  <li><a href="submit-property-dashboard.html"><i className="fa fa-pen-nib" />Submit New Property</a></li>
                  <li><a href="change-password.html"><i className="fa fa-unlock-alt" />Change Password</a></li>
                  */}
                    </ul>
                    <div
                      class="nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      {/* <button class=" active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><i class="fas fa-tachometer-alt"></i> Dashboard</button> */}
                      {/* <button class="" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i class=" ti-user"></i> Profile</button> */}
                      <button
                        class=""
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                      >
                        <i class="fa fa-envelope"></i> Message
                      </button>
                      <button
                        class=""
                        id="v-pills-settings-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-saved"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-saved"
                        aria-selected="false"
                      >
                        <i className="ti-heart" /> Favourite
                      </button>
                      <button
                        class=""
                        id="v-pills-settings-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-agentContact-tab"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-agentContact"
                        aria-selected="false"
                      >
                        <i className="fa fa-user" /> Send your property to
                        Agent/Owner
                      </button>
                      {/* <button class="" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i class="fas fa-cog"></i> Setting</button> */}
                      <button
                        class=""
                        id="v-pills-settings-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-change-password"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-change-password"
                        aria-selected="false"
                      >
                        <i className="fa fa-unlock-alt" /> Change Password
                      </button>
                    </div>
                  </div>
                  <div className="dash_user_footer">
                    <ul>
                      <li>
                        <button
                          className="footer-profile"
                          onClick={this.handleLogout}
                        >
                          <i className="fa fa-power-off" />
                        </button>
                      </li>
                      <li>
                        <button className="footer-profile">
                          <i className="fa fa-comment" />
                        </button>
                      </li>
                      <li>
                        <button className="footer-profile">
                          <i className="fa fa-cog" />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div
                  className="dashboard-body dashboard-wraper tab-content dash_user_menues flex-column"
                  id="v-pills-tabContent"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <div
                    className="tab-pane fade "
                    id="v-pills-tabContent"
                    id="v-pills-saved"
                    role="tabpanel"
                    aria-labelledby="v-pills-saved"
                  >
                    <div className="frm_submit_block">
                      <h4>Favourites</h4>
                      {this.state.postData}
                      <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                    {/* 
               <div className="frm_submit_block">
                  <h4>Social Accounts</h4>
                  <div className="frm_submit_wrap">
                     <div className="form-row">
                        <div className="form-group col-md-6">
                           <label>Facebook</label>
                           <input type="text" className="form-control" defaultValue="https://facebook.com/" />
                        </div>
                        <div className="form-group col-md-6">
                           <label>Twitter</label>
                           <input type="email" className="form-control" defaultValue="https://twitter.com/" />
                        </div>
                        <div className="form-group col-md-6">
                           <label>Google Plus</label>
                           <input type="text" className="form-control" defaultValue="https://googleplus.com/" />
                        </div>
                        <div className="form-group col-md-6">
                           <label>LinkedIn</label>
                           <input type="text" className="form-control" defaultValue="https://linkedin.com/" />
                        </div>
                        <div className="form-group col-lg-12 col-md-12 mt-4">
                           <button className="btn btn-theme btn-lg" type="submit">Save Changes</button>
                        </div>
                     </div>
                  </div>
               </div>
               */}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-change-password"
                    role="tabpanel"
                    aria-labelledby="v-pills-change-password"
                  >
                    <div className="dashboard-body">
                      <div className="dashboard-wraper">
                        {/* Basic Information */}
                        <div className="frm_submit_block">
                          <h4>Change Your Password</h4>
                          <div className="frm_submit_wrap">
                            <div className="form-row">
                              <div className="form-group col-lg-6 col-md-6">
                                <label>Old Password</label>
                                <input
                                  type="password"
                                  onChange={this.oldPassword}
                                  className="form-control"
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>New Password</label>
                                <input
                                  type="password"
                                  onChange={this.newPasswordChange}
                                  className="form-control"
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Confirm password</label>
                                <input
                                  type="password"
                                  onChange={this.confirmPassword}
                                  className="form-control"
                                  required
                                />
                              </div>
                              <div className="form-group col-lg-4 col-md-4">
                                <button
                                  onClick={this.savePassChange}
                                  className="profile-btn-save"
                                  type="submit"
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div class="messages-container margin-top-0">
                      <div className="messages-headline">
                        <h4>Messages</h4>
                        {/* <a href="#" className="message-action"><i className="ti-trash" /> Delete Conversation</a> */}
                      </div>
                      <div className="messages-container-inner">
                        <div className="dash-msg-inbox">
                          <ul>
                            {this.state.users.map((user) => (
                              <>
                                {cookie.get("user_id") != user.id ? (
                                  <li
                                    onClick={this.handleUserChat.bind(
                                      this,
                                      user
                                    )}
                                  >
                                    <a href="#">
                                      <div className="dash-msg-avatar">
                                        <img
                                          src={
                                            baseurlImg +
                                            "/uploads/profiles/" +
                                            user.profile
                                          }
                                          alt
                                        />
                                        <span className="_user_status online" />
                                      </div>
                                      <div className="message-by">
                                        <div  className="message-by-headline">
                                          <h5>{user.name}</h5>
                                          <span>
                                            {/* <Moment fromNow>
                                              {user.created_at}
                                            </Moment> */}
                                          </span>
                                        </div>
                                        {/* 
                                    <p>Hello, I am a web designer with 5 year.. </p>
                                    */}
                                      </div>
                                    </a>
                                  </li>
                                ) : null}
                              </>
                            ))}
                            {/* 
                           <li className="active-message">
                              <a href="#">
                                 <div className="dash-msg-avatar"><img src="assets/img/team-2.jpg" alt /><span className="_user_status offline" /></div>
                                 <div className="message-by">
                                    <div className="message-by-headline">
                                       <h5>George Howarth</h5>
                                       <span>2 hours ago</span>
                                    </div>
                                    <p>Hello, I am a web designer with 5 year..</p>
                                 </div>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                                 <div className="dash-msg-avatar"><img src="assets/img/team-3.jpg" alt /><span className="_user_status busy" /></div>
                                 <div className="message-by">
                                    <div className="message-by-headline">
                                       <h5>Harriet Ball</h5>
                                       <span>Yesterday</span>
                                    </div>
                                    <p>Hello, I am a web designer with 5 year..</p>
                                 </div>
                              </a>
                           </li>
                           */}
                          </ul>
                        </div>
                        {this.state.messageContent == 1 ? (
                          <div className="dash-msg-content">
                            {this.state.messages.map((message) => (
                              <>
                                {message.reciever == activeUser ? (
                                  <div className="message-plunch">
                                    <div className="sender-name">
                                      <span className="message-moment-sender">
                                        <Moment fromNow>
                                          {message.created_at}
                                        </Moment>
                                      </span>
                                      <span className="bold">
                                        {this.state.recieverUserName}
                                      </span>
                                    </div>
                                    <div className="dash-msg-text">
                                      <p>{message.message}</p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="message-plunch me">
                                    <div className="reciever-name">
                                      <span className="message-moment-reciever">
                                        <Moment fromNow>
                                          {message.created_at}
                                        </Moment>
                                      </span>
                                      <span>Me</span>
                                    </div>
                                    {/* 
                           <div className="dash-msg-avatar"><img src="https://via.placeholder.com/400x400" alt /></div>
                           */}
                                    <div className="dash-msg-text">
                                      <p>{message.message}</p>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                            <div className="clearfix" />
                            <div className="message-reply">
                              <textarea
                                onChange={this.messageSendHandler}
                                cols={40}
                                rows={3}
                                name="message"
                                className="form-control with-light"
                                value={this.state.messageBody}
                                placeholder="Your Message"
                                defaultValue={""}
                              />
                              <button
                                style={{ color: "#fff", width: "10rem" }}
                                onClick={this.submitMessage}
                                type="button"
                                className="btn theme-bg"
                              >
                                Send Message
                                <i className="right fa fa-paper-plane"></i>
                              </button>
                              <span className="error">
                                {this.state.errors["message"]}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-agentContact-tab"
                    role="tabpanel"
                    aria-labelledby="v-pills-agentContact"
                  >
                    <div className="row">
                      <div className="col">
                        <h2 className="breadcrumb-title">Property Details</h2>
                        <div className="col-md-12 form-group">
                          <label>My Property Location</label>
                          <input
                            onChange={this.mypropertyLocationOnChange}
                            type="text"
                            className="form-control"
                            placeholder="Property Location"
                            name="location"
                          />
                          <span className="error">
                            {this.state.errors["location"]}
                          </span>
                        </div>
                        <div className="col-md-12 form-group">
                          <label>Property Purpose</label>
                          <select
                            className="form-control"
                            onChange={this.propertyPurposeOnChange}
                            name="purpose"
                          >
                            <option value="For Rent">For Rent</option>
                            <option value="For Sale">For Sale</option>
                          </select>
                          <span className="error">
                            {this.state.errors["purpose"]}
                          </span>
                        </div>
                        <div className="col-md-12 form-group">
                          <label>Property Type</label>
                          <select
                            id="propertyType"
                            name="type"
                            className="filter-input form-select mb-3"
                            onChange={this.propertytypesOnChange}
                          >
                            {this.props.location.state ? (
                              <option selected value="0">
                                Property Type
                              </option>
                            ) : (
                              <option selected value="0">
                                Property Type
                              </option>
                            )}
                            {this.state.propertytypes.map((prop, i) => (
                              <option value={prop.typeName_en}>
                                {prop.typeName_en}
                              </option>
                            ))}
                          </select>
                          <span className="error">
                            {this.state.errors["type"]}
                          </span>
                        </div>
                        <div className="col-md-12 form-group">
                          <label>Property Price</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">AED</span>
                            </div>
                            <input
                              onChange={this.priceOnChange}
                              type="number"
                              className="form-control"
                              name="price"
                              placeholder="Property Price"
                              name="price"
                            />
                          </div>
                          <span className="error">
                            {this.state.errors["price"]}
                          </span>
                        </div>
                        <div className="col-md-12 form-group">
                          <label>Furnished</label>
                          <select
                            onChange={this.furnishedOnChange}
                            className="form-control"
                            name="furnished"
                          >
                            <option value="Furnished">Furnished</option>
                            <option value="Unfurnished">Unfurnished</option>
                          </select>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-md-4 form-group">
                            <label>Bed Room</label>
                            <input
                              onChange={this.bathOnChange}
                              type="number"
                              className="form-control"
                              placeholder="Bed"
                              name="bed"
                            />
                            <span className="error">
                              {this.state.errors["bed"]}
                            </span>
                          </div>
                          <div className="col-md-4 form-group">
                            <label>Bath Room</label>
                            <input
                              onChange={this.bedOnChange}
                              type="number"
                              className="form-control"
                              placeholder="Bath"
                              name="bath"
                            />
                            <span className="error">
                              {this.state.errors["bath"]}
                            </span>
                          </div>
                          <div className="col-md-4 form-group">
                            <label>Area/Space (Sqft)</label>
                            <input
                              onChange={this.areaOnChange}
                              type="number"
                              className="form-control"
                              placeholder="Area"
                              name="area"
                            />
                            <span className="error">
                              {this.state.errors["area"]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <h2 className="breadcrumb-title">Agent Selection</h2>
                        <div className="col-md-12">
                          <label>Agent Location</label>
                          {/* <input type="text" className="form-control" placeholder="Property Name" name="name" /> */}
                          <Autocomplete
                            // limitTags={3}
                            id="multiple-limit-tags"
                            options={this.state.locations}
                            onChange={(event, newValue) => {
                              this.setState({
                                selectedLocation: newValue,
                              });
                              const data = {
                                selectedLanguages: this.state.selectedLanguages,
                                selectedNationality: this.state
                                  .selectedNationality,
                                selectedLocation: newValue,
                              };
                              console.log("Autocomplete on change", newValue);
                              this.fetchAgents(data);
                              // this.fetchPropertyData(data);
                              //  const agentData= {selectedLanguage: this.selectedLanguage, selectedNationality: this.state.selectedNationality, selectedLocation: newValue, selectedArea: this.state.selectedArea}
                            }}
                            getOptionLabel={(option) => option.location}
                            value={this.state.selectedLocation}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label=""
                                placeholder="Select nearest Available agent in your desired location"
                              />
                            )}
                          />
                        </div>
                        <div className="row">
                          <div className="col">
                            <label>Nationality</label>
                            <select
                              id="propertyType"
                              name="type"
                              className="filter-input form-select mb-3"
                              onChange={this.onChangeNationality}
                            >
                              <option selected value="0">
                                Nationality
                              </option>
                              {this.state.nationality.map((prop, i) => (
                                <option value={prop.id}>
                                  {prop.country_enNationality}
                                </option>
                              ))}
                            </select>
                            <span className="error">
                              {this.state.errors["type"]}
                            </span>
                          </div>
                          <div className="col ">
                            <label>Language</label>
                            <MultiSelect
                              options={this.state.languages}
                              value={this.state.selectedLanguages}
                              onChange={this.onChangeLanguage}
                              labelledBy="Select Languages"
                              // defaultValue = {{ label: 'Amenties', value: 0 }}
                            />
                            <span className="error">
                              {this.state.errors["type"]}
                            </span>
                          </div>
                        </div>
                        <div>
                          <label>Select Agents</label>
                          <MultiSelect
                            options={this.state.agents}
                            value={this.state.selectedAgents}
                            onChange={this.selectedAgentsHandle}
                            labelledBy="Select Agents"
                            // defaultValue = {{ label: 'Amenties', value: 0 }}
                          />
                        </div>
                        <div style={{ paddingTop: "20px" }}>
                          <button
                            type="button"
                            class="profile-btn-save"
                            onClick={this.sendMessage}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
   <div className="col-lg-9 col-md-8"> */}
        </section>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default connect(null, mapDispatchToProps)(MyProfile);
