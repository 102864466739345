import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import i18next from 'i18next'
import { store } from '../../store'
import { connect } from "react-redux";
import { baseurlImg } from '../../Components/BaseUrl'


const lang = localStorage.getItem('lang') || 'en'

var language = lang == "en" ? "" : "/ar"



// console.log("is this person logged in:", loggedIn)
export class MobileMenu extends Component {
    closeFilter =()=> {
        $("#humbergerMenu").toggleClass('open');
        $("body").toggleClass('aside-open'); 
      }
      handleLogout = () => {
        this.props.logout();
      };
      componentDidMount () {


        // $(function() {
            $(document).on('click', function (event) {
                if (!$(event.target).closest('#humbergerMenu').length && $("#humbergerMenu").hasClass( "open" )) {
                    //alert("clicked Outside")
                    //$("#humbergerMenu").toggleClass('open');
                   // $("body").toggleClass('aside-open'); 
                }
              });
              document.addEventListener('click', ({ target }) => {
                if (!target.closest('#aside-trigger')) {
                  $("#humbergerMenu").removeClass('open');
                  $("body").removeClass('aside-open');
                //   alert("Clicked Outsode")
                }
              })

        // });

      }
    render() {
      const userdata = store.getState((state) => state);
      const user = userdata.auth.user;
      const profile = user.profile;
      const name = user.name;
      const loggedIn = userdata.auth.loggedIn;
      // console.log("image", profile)

        return (
         <div>
            <aside id="humbergerMenu" className="main-aside">
                <div className="aside-title">
                    <div className="aside-controls aside-trigger">
                    {/* <h4>{lang=="en"? "Menu": "قائمة" }</h4> */}
                    {loggedIn?
                    <button type="button" className="btn btn-order-by-filt" style={{width: "100%"}} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <img style={{width: '50px', height: '50px', borderRadius: '50%'}} src={baseurlImg + "/uploads/profiles/" + profile} className="avater-img" alt />
                           <div style={{display: 'block', marginLeft: '5px', fontWeight: 'bold'}}>{name}</div>
                    </button>
                    :
                    <button type="button" className="btn btn-order-by-filt" style={{width: "100%"}} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                      <a id="loginForModal"  data-bs-toggle="modal" href="#login" role="button" className="alio_green">
                        <i className="fa fa-user"></i>
                        <span className="dn-lg"> {i18next.t("login")}</span>
                      </a>
                      </button>
                    }

                    {/* <div onClick={this.closeFilter} className="close-btn close-dark">
                        <span />
                        <span />
                    </div> */}
                    </div>
                </div>
                <div className="aside-scroll">
                  <ul>
                    {/* Pages Start */}

                    <li className="menu-item">
                        {/* <div className="language-selector d-xl-none d-lg-none d-xl-block" style={{ margin: "0 7px" }}>
                          {lang == "en" ? (
                            <a onClick={this.changeToArabic} style={{ padding: "2px", fontSize: "1rem", color: "#707e9c" }} className="dropdown-item" href="">
                              العربية
                            </a>
                          ) : (
                            <a
                              onClick={this.changeToEnglish}
                              style={{ padding: "2px", fontSize: "1rem", color: "#707e9c" }}
                              className="dropdown-item"
                              href=""
                            >
                              English
                            </a>
            
                            )}
                          </div> */}


                    </li>
                    <li className="menu-section-title"></li>
                    <li onClick={this.closeFilter} className="menu-item">
                        <Link to={language+"/"}> <i className="flaticon-home" /> {i18next.t("home")}</Link>
                    </li>
                    <li onClick={this.closeFilter} className="menu-item"></li>
                    <li onClick={this.closeFilter} className="menu-item">
                             <Link
                                  to={{
                                    pathname: lang=="en"?language + "/for-rent/properties/uae" : language + "/للايجار/عقارات/الامارات",
                                    state: {
                                    passLocation: [],
                                    passsubLocationSend:[],
                                    passPurpose: 1,
                                    passType: lang=="en"?"properties" : "عقارات",
                                    locForUrl: lang=="en"?"uae": "الامارات"
                                    }
                                  }
                                }> <i className="fa fa-building" /> {i18next.t("search")}</Link>
                    </li>
                    <li onClick={this.closeFilter} className="menu-item">
                        <Link to={language +"/agency"}> <i className="flaticon-company" /> {i18next.t("agency")}</Link>
                    </li>
                    {loggedIn?
                    <li onClick={this.closeFilter} className="menu-item">
                        <Link to={language +"/my-ads"}> <i className="flaticon-house" /> {i18next.t("myads")}</Link>
                    </li>     :  null
                    }
                     {loggedIn?
                    <li onClick={this.closeFilter} className="menu-item">
                        <Link to={language +"/favourites"}> <i className="flaticon-star" /> {i18next.t("favourites")}</Link>
                    </li>     :  null
                    }
                    {/* <li onClick={this.closeFilter} className="menu-item">
                        <Link to={language +"/message"}> <i className="fa fa-comment" /> {i18next.t("messages")}</Link>
                    </li> */}
                    <li onClick={this.closeFilter} className="menu-item">
                        <Link to={lang=="en"?"/blogs": "/ar/blogs"}> <i className="flaticon-headset" /> {i18next.t("blogs")}</Link>
                    </li>
                    {loggedIn?
                    <li onClick={this.closeFilter} className="menu-item">
                        <Link to={language +"/profileSetting"}> <i className="flaticon-company" /> {i18next.t("setting")}</Link>
                    </li>     :  null
                  }
                    {loggedIn?
                    <li onClick={this.closeFilter} className="menu-item">
                        <a  onClick={this.handleLogout}> <i className="fa fa-sign-out" /> {i18next.t("logout")}</a>
                    </li>
                    :  null
                    }

                      {/* 
                      <li className="menu-section-title">Get Social</li>
                      <li className="menu-item"> <a target="_blank" href="https://www.facebook.com/findpropertiesuae"> <i className="flaticon-facebook" />Facebook</a> </li>
                      <li className="menu-item"> <a target="_blank" href="https://www.linkedin.com/company/find-properties/"> <i className="flaticon-linkedin" /> Linkedin </a> </li>
                      <li className="menu-item"> <a target="_blank" href="https://twitter.com/uaefindproperty"> <i className="flaticon-twitter" /> Twitter </a> </li>
                      <li className="menu-item"> <a target="_blank" href="https://www.instagram.com/findpropertiesofficial/"> <i className="flaticon-instagram" /> Instagram </a> </li> */}
                  </ul>
                </div>
                {/* <div className=" submitListing d-lg-none d-sm-block d-md-none"> */}
                        {/* {loggedIn ? (
                            <a href={lang == "en" ? "/add-listing" : "/ar/add-listing"} className="btn-custom primary">
                            {i18next.t("submitlisting")} <i className="fas fa-plus" />{" "}
                         </a>
                              ) : (
                             <a id="loginForModal" data-bs-toggle="modal" href="#login" role="button" className="btn-custom primary">
                               {i18next.t("submitlisting")} <i className="fas fa-plus" />
                             </a>
                        )} */}
                    {/* {!loggedIn? 
                      <a id="loginForModal" data-bs-toggle="modal" href="#login" role="button" className="btn-custom primary">
                        {i18next.t("submitlisting")} <i className="fas fa-plus" />
                      </a>
                        :  */}
                      {/* <a
                        href={lang == "en" ? "/add-listing" : "/ar/add-listing"}
                        className="btn-custom primary">
                        {i18next.t("submitlisting")} <i className="fas fa-plus" />{" "}
                      </a> */}
                    {/* } */}
                    {/* <Link to={lang=="en"?"/add-listing":"/ar/add-listing"} className="btn-custom primary">Submit Listing <i className="fas fa-plus" /> </Link> */}
                {/* </div>   */}
            </aside>

            </div>
        )
    }
}

// export default MobileMenu

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);

