import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import './i18n'
import i18next from 'i18next'
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
const lang = localStorage.getItem("lang") || "en";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


i18next.changeLanguage(lang);
document.documentElement.lang = lang;
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>, rootElement);
} else {
  render(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>, rootElement);
}
serviceWorkerRegistration.register();
reportWebVitals();
