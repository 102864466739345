import React, { Component } from "react";
import { baseurl, baseurlImg } from "../../../../Components/BaseUrl";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import cookie from "js-cookie";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import { store } from "../../../../store";

import ReactPaginate from "react-paginate";
import $ from "jquery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { MultiSelect } from "react-multi-select-component";
import API from "../../../../API";


const lang = localStorage.getItem("lang") || "en";

// const activeUser = cookie.get("user_id");
// const token = cookie.get("token");

const userdata = store.getState(state=> state);
const token= userdata.auth.token
const activeUser = userdata.auth.user.id;



export class Message extends Component {
  constructor(props) {
    super(props);
    // this.myRef = React.createRef()  

    this.state = {
      isLoading: false,
      users: [],
      messages: [],
      messageBody: "",
      messageContent: 0,
      recieverUserName: "",
      recieverId: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      NewProfilePic: "",
      offset: 0,
      favouriteProps: [],
      perPage: 6,
      currentPage: 1,
      favouriteProperties: [],
      propertytypes: [],
      selectedLocation: [],
      locations: [],
      agents: [],
      selectedAgents: [],
      languages: [],
      nationality: [],
      selectedLanguages: [],
      selectedNationality: 0,
      bed: 0,
      price: 0,
      bath: 0,
      area: 0,
      type: 0,
      purpose: 0,
      furnished: 0,
      myPropertyLocation: "",
      input: {},
      errors: {},
      inputMessage: {},
      errorsTM: {},
      showBrokerNameOnchat: true

    };
  }

  handleWishList = (e) => {
    // alert(e.target.value)
    const data = { property_id: e.target.value };
    const dataForCheckBox = {
      property_id: e.target.value,
    };
    API.post("wishlist", data).then((response) => {
      $("input[value=" + e.target.value + "]").trigger("click");
    });
    this.fetchFavouriteProperties();
  };

  handleLogout = () => {
    // e.preventDefault();

    this.props.logout();
    // this.props.history.push('/login')
  };
  handleFormErrorSend = () => {
    let inputMessage = this.state.inputMessage;
    let errorsTM = {};
    let formIsValid = true;
    if (!inputMessage["message"]) {
      formIsValid = false;
      errorsTM["message"] = "Message can not be Empty!";
    }

    this.setState({
      errors: errorsTM,
    });
 
    return formIsValid;
  };
  hadnleFormError = () => {
    let input = this.state.input;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!input["location"]) {
      formIsValid = false;
      errors["location"] = "Location cannot be empty!";
    }

    if (!input["price"]) {
      formIsValid = false;
      errors["price"] = "Price cannot be empty!";
    }
    if (!input["bed"]) {
      formIsValid = false;
      errors["bed"] = "Bedroom cannot be empty!";
    }
    if (!input["bath"]) {
      formIsValid = false;
      errors["bath"] = "Bathroom cannot be empty!";
    }
    if (!input["area"]) {
      formIsValid = false;
      errors["area"] = "Area cannot be empty!";
    }
    if (!input["type"]) {
      formIsValid = false;
      errors["type"] = "Property type cannot be empty!";
    }
    if (!input["purpose"]) {
      formIsValid = false;
      errors["purpose"] = "Purpose cannot be empty!";
    }
    if (typeof input["name"] !== "undefined") {
      if (!input["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  sendMessage = () => {

    if (this.hadnleFormError()) {
      const loc = this.state.myPropertyLocation;
      const bed = this.state.bed > 0 ? this.state.bed + "bedroom" : "studio";
      const bath = this.state.bath;
      const furnished = this.state.furnished;
      const type = this.state.type;
      const purpose = this.state.purpose;
      const data = {
        messageBody:
          "Hi I have " +
          bed +
          " and " +
          bath +
          " bathroom " +
          furnished +
          " " +
          type +
          " for " +
          purpose +
          " in " +
          loc,
        selectedAgents: this.state.selectedAgents,
        selectedLanguages: this.state.selectedLanguages,
        selectedNationality: this.state.selectedNationality,
      };
      API.post("ownerMessage", data)
        .then((response) => {
          // console.log("send Message", response.data);
        });
    } else {
      // alert("there is missing field")
    }
  };
  selectedAgentsHandle = (e) => {
    this.setState({ selectedAgents: e });
  };
  fetchData = () => {
    this.setState({ isLoading: true });
    API.get("propertytypes")
      .then((response) => {

        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch();
    API.get("features")
      .then((response) => {
        //  console.log(response.data)
        this.setState({
          isLoading: false,
          features:
            lang == "en"
              ? response.data.allfeatures_en
              : response.data.allfeatures_ar,
        });
      })
      .catch();
    API.get("locations")
      .then((response) => {

        this.setState({
          isLoading: false,
          locations: response.data.locations,
          // subLocationSend: response.data.location[0]
        });
      })
      .catch();
  };
  fetchAgentDetail = () => {
    API
      .get(baseurl + "agentinfo")
      .then((response) => {
        this.setState({
          isLoading: false,
          nationality: response.data.nationality,
          // languages: response.data.languages
        });
      })
      .catch();
    API.get("languages")
      .then((response) => {
        if (lang == "en") {
         
          this.setState({ languages: response.data.languages_en });
        } else {
          this.setState({ languages: response.data.languages_ar });
        }
      })
      .catch();
  };
  fetchAgents = (agentData) => {
    API.post("fetchAgentsByLocation", agentData)
      .then((response) => {
       
        this.setState({ agents: response.data.agents });
      })
      .catch();
  };

  componentDidMount() {

    // window.addEventListener("resize", this.resize.bind(this));
    // this.resize();

    const data = {
      selectedLocation: this.state.selectedLocation,
    };
    this.fetchData();
    this.fetchAgentDetail();
    this.fetchAgents(data);
    this.getUsers();
    const pusher = new Pusher("2955db0221fb16cb6441", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe("chat");
    channel.bind("message", (newMessage) => {
      this.setState({ messages: [...this.state.messages, newMessage] });
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }
  getUsers() {
    API.get("chatusers")
      .then((response) => {
        this.setState({
          users: response.data.users,
        });
       
      });
  }

  handleUserChat = (user, e) => {
    API.get("allmessages/" + user.id)
      .then((response) => {
        this.setState({
          messages: response.data.messages,
          messageContent: 1,
          recieverUserName: user.name,
          recieverId: user.id,
        });
     
      });
      if(window.innerWidth <= 760) {
        this.setState({showBrokerNameOnchat: false});
      }
       window.scrollTo(150, '1300px');
      // document.getElementById('messageReply').scrollIntoView();
     // document.getElementById('messageReply').scrollIntoView(alignToTop)

    };
  messageSendHandler = (e) => {
   let inputMessage = this.state.inputMessage;
   inputMessage[e.target.name] = e.target.value;
    this.setState({
      // message: e.target.value, inputMessage ,
      messageBody:  e.target.value,inputMessage,
      recieverId: this.state.recieverId,
    });
  };
  submitMessage = () => {
    // e.preventDefault();
    

   
    if (this.handleFormErrorSend()) {
      const data = {
        messageBody: this.state.messageBody,
        reciever: this.state.recieverId,
      };
      API.post("newmessage", data)
        .then((response) => {

          this.setState({ messageBody: "" });
          //this.setState({messageBody:""})
          //this.fetchData();
        });
    }
  };

  render() {
    return (
      <div>
        {/* ============================ User Dashboard ================================== */}
        <section className="gray pt-5 pb-5">
          <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="dashboard-body dashboard-wraper tab-content dash_user_menues flex-column"
                  id="v-pills-tabContent"
                  role="tablist"
                  aria-orientation="vertical"
                >
   
                    <div class="messages-container margin-top-0">
                      <div className="messages-headline">
                        <h4>Messages</h4>
                        {/* <a href="#" className="message-action"><i className="ti-trash" /> Delete Conversation</a> */}

                      </div>
                      <div className="messages-container-inner">
                        <div className="dash-msg-inbox col-sm-2">
                          <ul>
                            {this.state.users.map((user) => (
                              <>
                                {activeUser != user.id ? (
                                  <li
                                    onClick={this.handleUserChat.bind(
                                      this,
                                      user
                                    )}
                                  >
                                    <a href="#">
                                      <div className="dash-msg-avatar">
                                        <img
                                          src={
                                            baseurlImg +
                                            "/uploads/profiles/" +
                                            user.profile
                                          }
                                          alt
                                        />
                                        <span className="_user_status online" />
                                      </div>
                                      <div className="message-by">
                                        <div className="message-by-headline">
                                          <h5 style={{display: this.state.showBrokerNameOnchat==true ? 'block' : 'none' }} >{user.name}</h5>
                                          <span>
                                            {/* <Moment fromNow>
                                              {user.created_at}
                                            </Moment> */}
                                          </span>
                                        </div>
                                        {/* 
                                    <p>Hello, I am a web designer with 5 year.. </p>
                                    */}
                                      </div>
                                    </a>
                                  </li>
                                ) : null}
                              </>
                            ))}
      
                          </ul>
                        </div>
                        {this.state.messageContent == 1 ? (
                          <div className="dash-msg-content  col-sm-10 ">
                            {this.state.messages.map((message) => (
                              <>
                                {message.reciever == activeUser ? (
                                  <div className="message-plunch">
                                    <div className="sender-name">
                                      <span className="message-moment-sender">
                                        <Moment fromNow>
                                          {message.created_at}
                                        </Moment>
                                      </span>
                                      <span className="bold">
                                        {this.state.recieverUserName}
                                      </span>
                                    </div>
                                    <div className="dash-msg-text">
                                      <p>{message.message}</p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="message-plunch me">
                                    <div className="reciever-name">
                                      <span className="message-moment-reciever">
                                        <Moment fromNow>
                                          {message.created_at}
                                        </Moment>
                                      </span>
                                      <span>Me</span>
                                    </div>
                                    <div className="dash-msg-text">
                                      <p>{message.message}</p>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                            <div className="clearfix" />
                            <div id="messageReply" className="message-reply">
                               <textarea
                                onChange={this.messageSendHandler}
                                cols={40}
                                rows={1}
                                name="message"
                                className="form-control with-light"
                                value={this.state.messageBody}
                                placeholder="Your Message"
                                defaultValue={""}
                              />
                              <button
                                style={{ color: "#fff", width: "4rem" }}
                                onClick={this.submitMessage}
                                type="button"
                                className="btn theme-bg"
                              >
                                <i className="right fa fa-paper-plane"></i>
                              </button>
                              <span className="error">
                                {this.state.errors["message"]}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
        </section>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default connect(null, mapDispatchToProps)(Message);
