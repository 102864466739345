import Home from "./pages/Home";
import "./App.css";
import HeaderComponent from "./pages/CommonPageComponets/HeaderComponent";
import Agencies from "./pages/Agency/Agencies";
import SingleAgency from "./pages/Agency/SingleAgency";
import SingleAgent from "./pages/Agent/SingleAgent";
import { Helmet } from 'react-helmet';

import MapCluster from "./pages/Property/MapCluster";
import Maps from "./pages/Property/map/Maps";
import "./pages/Property/AddProperty.css";
import PrivateRoute from "./Components/protectRoutes/PrivateRoute";
import AllBlogs from "./Blog/AllBlogs";
import SingleBlog from "./Blog/SingleBlog";
import AreaGuide from "./Blog/AreaGuide";
import BuildingGuide from "./Blog/BuildingGuide";
import SchoolGuide from "./Blog/Tips";
import ProfileSetting from "./pages/Admin/Settings/Profile/ProfileSetting";
import Messages from "./pages/Admin/Settings/Profile/Messages";
import Favourites from "./pages/Admin/Settings/Profile/Favourites";
import SendProperty from "./pages/Admin/Settings/Profile/SendProperty";
import FooterComponent from "./pages/CommonPageComponets/FooterComponent";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import NotFound from "./NotFound";
import logo from "./logo.svg";
import Footer from "./Components/Footer";
import TermsAndConditions from "./pages/TermsAndConditions";
import ContactUs from "./pages/ContactUs";
import LostPassword from "./pages/CommonPageComponets/ForgotPassword";
// import TagManager from "react-gtm-module";
import { Component } from "react";
import axios from "axios";
import { baseurl, baseurlImg } from "./Components/BaseUrl";
import Properties from "./pages/Properties";
import SingleProperty from "./pages/Property/SingleProperty/SingleProperty";
import TouristDestination from "./Blog/TouristDestination";
import Tips from "./Blog/Tips";
import $ from "jquery";
import SiteMapGenerator from "./Utility/SiteMapGenerator";
import Properties2 from "./pages/Properties2";
import AddProperty from "./dashboard/addProperty";
import MyAds from "./dashboard/myAds";
import EditAgentProperty from "./dashboard/EditAgentProperty";
import PublicRoute from "./Components/PublicRoute";
import NolCard from "./Components/NolCard";
import ReserPassword from "./pages/CommonPageComponets/ResetPassword";
import Terms from "./Components/Terms";
import XmlContentConverter from "./XmlContentConverter";

import { Tab, Nav } from "react-bootstrap";
import { FaHome, FaHeart, FaUserFriends, FaUser, FaDownload  } from "react-icons/fa";

//to check login
import { store } from './store'
import { connect } from "react-redux";

// import Properties from "./pages/Properties";
import FloatingButton from './pages/CommonPageComponets/FloatingButton'
import FloatingDownload from './pages/CommonPageComponets/FloatingDownload'

const lang = localStorage.getItem("lang") || "en";
const currentUrl = window.location.href;

//console.log("my url", window.location.href)
// const tagManagerArgs = {
//   gtmId: "G-TDM76TL7XX",
// };

// TagManager.initialize(tagManagerArgs);

class App extends Component {
  structuredData = {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "url": "https://www.findproperties.ae",
      "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.findproperties.ae/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
      }
  };

  constructor(props) {
    super(props);
    this.state = {
      newpageroute: "/for-rent/apartment/",
      loaded: false,
      propertytypes: [],
    };
    this.isComponentMounted = false; // Track if the component is mounted
  }

  routeChange = (newroue) => {
    this.setState({ newpageroute: newroue, loaded: true });
    //console.log("comming route", newroue)
  };
  onInputChageEnglish = (newroue) => {
    this.setState({ newpageroute: newroue, loaded: true });
    //console.log(newroue)
  };
  // fetchPropertyTypes = () => {
  //   const baseurl = 'your_base_url_here'; // Set your base URL
  //   axios.get(baseurl + 'propertytypes')
  //     .then((response) => {
  //       if (this.isComponentMounted) { // Check if component is still mounted before updating state
  //         this.setState({
  //           isLoading: false,
  //           propertytypes: response.data.propertytypes,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching property types:', error);
  //     });
  // };
  
  componentWillUnmount() {
    this.isComponentMounted = false; // Component is unmounted, cancel any pending operations
  }
  componentDidMount() {

    this.isComponentMounted = true; // Component is mounted
    // this.fetchPropertyTypes(); // Call your API function
  }
  //   routePath =(routePath)=> {
  //    return routePath
  //  }
  render() {
    const userdata = store.getState((state) => state);
    const user = userdata.auth.user;
    const profile = user.profile;
    const name = user.name;
    const loggedIn = userdata.auth.loggedIn;

    return (
      <div
        id="main-wrapper"
        style={{ direction: lang == "en" ? "ltr" : "rtl" }}
        className="show"
        dir={lang == "en" ? "ltr" : "rtl"}
      >
        {/* <NavHeader></NavHeader> */}
        <Router>
          <HeaderComponent lang={lang}/>
          <Switch>

            {/* 4 Level route */}
            <Route
              path={"/for-rent/:xxx/:xxx/:xxx/:xxx/"}
              component={Properties}
            />
            <Route
              path={"/for-sharing/:xxx/:xxx/:xxx/:xxx/"}
              component={Properties}
            />
            <Route
              path={"/for-sale/:xxx/:xxx/:XXX/:xxx/"}
              component={Properties}
            />
    
            <Route
              path={"/ar/للايجار/:xxx/:xxx/:xxx/:xxx/"}
              component={Properties}
            />
            <Route
              path={"/ar/للبيع/:xxx/:xxx/:XXX/:xxx/"}
              component={Properties}
            />
            <Route
              path={"/ar/سكن-مشترك/:xxx/:xxx/:XXX/:xxx/"}
              component={Properties}
            />

            {/* 3 Level route */}
            <Route path={"/for-rent/:xxx/:xxx/:xxx/"} component={Properties} />
            <Route path={"/for-sale/:xxx/:xxx/:XXX/"} component={Properties} />
            <Route exact path={"/terms-and-conditions"} component={Terms} />

            <Route
              path={"/for-sharing/:xxx/:xxx/:XXX/"}
              component={Properties}
            />

            <Route
              path={"/ar/للايجار/:xxx/:xxx/:xxx/"}
              component={Properties}
            />
            <Route path={"/ar/للبيع/:xxx/:xxx/:XXX/"} component={Properties} />
            <Route
              path={"/ar/سكن-مشترك/:xxx/:xxx/:xxx/"}
              component={Properties}
            />

            {/* 2 level route */}
            <Route path={"/for-rent/:xxx/:xxx/"} component={Properties} />
            <Route path={"/for-sale/:xxx/:xxx/"} component={Properties} />
            <Route path={"/for-sharing/:xxx/:xxx/"} component={Properties} />

            <Route path={"/ar/for-rent/:xxx/:xxx/"} component={Properties} />
            <Route path={"/ar/for-sale/:xxx/:xxx/"} component={Properties} />
            <Route path={"/ar/for-sharing/:xxx/:xxx/"} component={Properties} />

             {/* <Route path={"/ar/للايجار/:xxx/:xxx/"} component={Properties} />
             <Route path={"/ar/للبيع/:xxx/:xxx/"} component={Properties} />
             <Route path={"/ar/سكن-مشترك/:xxx/:xxx/"} component={Properties} /> */}

            <Route path={"/single-property/:id"} component={SingleProperty} />
            <Route
              path={"/ar/single-property/:id"}
              component={SingleProperty}
            />

            {/* <Route path={"/ar/للايجار/:xxx/:xxx/:xxx/:xxx/"} component={Properties} />
            <Route path={"/ar/للبيع/:xxx/:xxx/:XXX/:xxx/"} component={Properties} />
            <Route path={"/ar/للايجار/:xxx/:xxx/:xxx/"} component={Properties} />
            <Route path={"/ar/للبيع/:xxx/:xxx/:XXX/"} component={Properties} />
            <Route path={"/ar/للايجار/:xxx/:xxx/"} component={Properties} />
            <Route path={"/ar/للبيع/:xxx/:xxx/"} component={Properties} />
            <Route path="/ar/single-property/:id" component={SingleProperty} /> */}

            <Route path={"/sitemapgenerated"} component={SiteMapGenerator} />

            <Route
              path={lang == "en" ? "/agency" : "/ar/agency"}
              component={Agencies}
            />
            <Route
              path={lang == "en" ? "/agencies/:id" : "/ar/agencies/:id"}
              component={SingleAgency}
            />
            <Route
              path={lang == "en" ? "/agents/:id" : "/ar/agents/:id"}
              component={SingleAgent}
            />

            <Route
              path={lang == "en" ? "/blogs/:id" : "/ar/blogs/:id"}
              component={SingleBlog}
            />
            <Route
              path={lang == "en" ? "/blogs" : "/ar/blogs"}
              component={AllBlogs}
            />
            <Route
              path={lang == "en" ? "/halfmap/" : "/ar/halfmap/"}
              component={MapCluster}
            />
 
            <Route
              path={
                lang == "en"
                  ? "/term-and-conditions"
                  : "/ar/term-and-conditions"
              }
              component={TermsAndConditions}
            />
            <Route
              path={lang == "en" ? "/area-guide" : "/ar/area-guide"}
              component={AreaGuide}
            />
            <Route
              path={lang == "en" ? "/building-guide" : "/ar/building-guide"}
              component={BuildingGuide}
            />
            <Route
              path={
                lang == "en"
                  ? "/tourist-destination"
                  : "/ar//tourist-destination"
              }
              component={TouristDestination}
            />
            <Route
              path={lang == "en" ? "/tips" : "/ar/tips"}
              component={Tips}
            />

            <Route
              path={lang == "en" ? "/contact" : "/ar/contact"}
              component={ContactUs}
            />

           <Route
              path={"/xmlparse"}
              component={XmlContentConverter}
            />
            <Route
              path={lang == "en" ? "/forgot-password" : "/ar/forgot-password"}
              component={LostPassword}
            />
            <Route
              path={
                lang == "en" ? "/reset-password/:id" : "/ar/reset-password/:id"
              }
              component={ReserPassword}
            />

            {/* <Route path="/sitemap" component={Sitemap}/> */}
            <PrivateRoute
              exact
              path={lang == "en" ? "/message" : "/ar/message"}
              component={Messages}
            />
            <PrivateRoute
              exact
              path={
                lang == "en"
                  ? "/editagentProperty/:id"
                  : "/ar/editagentProperty/:id"
              }
              component={EditAgentProperty}
            />

            <PrivateRoute exact path={"/add-listing"} component={AddProperty} />
            <PrivateRoute
              exact
              path={"/ar/add-listing"}
              component={AddProperty}
            />
            <PrivateRoute
              path={lang == "en" ? "/my-ads" : "/ar/my-ads"}
              component={MyAds}
            />
            <PrivateRoute
              exact
              path={lang == "en" ? "/favourites" : "/ar/favourites"}
              component={Favourites}
            />
            <PrivateRoute
              exact
              path={lang == "en" ? "/profileSetting" : "/ar/profileSetting"}
              component={ProfileSetting}
            />
            {/* <Route path={lang=="en"?"/sendProperty" : "/ar/sendProperty" }component={SendProperty}/> */}
            <Route exact path="/ar/" component={() => <Home lang="ar" />} />
            <Route exact path="/" component={() => <Home lang="en" />} />
            <Route component={NotFound} />
          </Switch>
            <Helmet>
              <script type="application/ld+json">
                  {JSON.stringify(this.structuredData)}
             </script>
             <html lang={lang}/>

          </Helmet>
          {/* <FloatingButton /> */}
          {/* <FloatingDownload /> */}
        </Router>

        {/* <Footer /> */}
        <div className="fixed-bottom bg-light bottom-navigation">
                    <Nav justify variant="tabs">
                        <Nav.Item>
                        <Nav.Link aria-label= "home" href="/" active>
                            <div className="nav-item-wrapper">
                            <FaHome />
                            <span className="nav-text">Home</span>
                            </div>
                        </Nav.Link>
                        </Nav.Item>
                        {loggedIn?
                        <Nav.Item>
                        <Nav.Link aria-label= "favourites" href="/favourites">
                            <div className="nav-item-wrapper">
                            <FaHeart />
                            <span className="nav-text">Favourites</span>
                            </div>
                        </Nav.Link>
                        </Nav.Item>
                            : null
                          }

                        <Nav.Item>
                        <Nav.Link aria-label="agencies" href="/agency">
                            <div className="nav-item-wrapper">
                            <FaUserFriends />
                            <span className="nav-text">Agents</span>
                            </div>
                        </Nav.Link>
                        </Nav.Item>
                        {loggedIn?
                        <Nav.Item>
                        <Nav.Link aria-label= "account" href="/my-ads">
                            <div className="nav-item-wrapper">
                            <FaUser />
                            <span className="nav-text">My Ads</span>
                            </div>
                        </Nav.Link>
                        </Nav.Item>
                        : null
                      }
                    </Nav>
                </div>


        <FooterComponent />
      </div>
    );
  }
}

export default App;
