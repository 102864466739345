import React, { Component,  Suspense, lazy } from 'react'
// import { Link } from 'react-router-dom';
// import Particles from "react-particles-js";
// const Particles=lazy(() => import('react-particles-js'));

const NotFound = () => (

<>    
<div
        className="banner banner-1 banner-3 dark-overlay bg-cover bg-center"
        
        style={{height: '37rem', paddingTop: '15rem', padding: '13vw 0 50!important', textAlign: 'center', backgroundImage: 'url("./frontendUI/img/banner.jpg")' }}
      >
              <h1 style={{color: '#FFF',  textDecoration: 'underline!important', textAlign: 'center'}}>Page Not Found!</h1>
              <a style={{color: '#fff!important'}} href="/">
                Go to Homepage
                </a>

<Suspense fallback=
	 { <> </>
		// <Particles
		// params={{
		// 	"particles": {
		// 		"number": {
		// 			"value": 160,
		// 			"density": {
		// 				"enable": false
		// 			}
		// 		},
		// 		"size": {
		// 			"value": 3,
		// 			"random": true,
		// 			"anim": {
		// 				"speed": 4,
		// 				"size_min": 0.3
		// 			}
		// 		},
		// 		"line_linked": {
		// 			"enable": false
		// 		},
		// 		"move": {
		// 			"random": true,
		// 			"speed": 1,
		// 			"direction": "top",
		// 			"out_mode": "out"
		// 		}
		// 	},
		// 	"interactivity": {
		// 		"events": {
		// 			"onhover": {
		// 				"enable": true,
		// 				"mode": "bubble"
		// 			},
		// 			"onclick": {
		// 				"enable": true,
		// 				"mode": "repulse"
		// 			}
		// 		},
		// 		"modes": {
		// 			"bubble": {
		// 				"distance": 250,
		// 				"duration": 2,
		// 				"size": 0,
		// 				"opacity": 0
		// 			},
		// 			"repulse": {
		// 				"distance": 400,
		// 				"duration": 4
		// 			}
		// 		}
		// 	}
		// }} /> 
	  }>
  
</Suspense>



   </div> 
  
  </>
);

export default NotFound;