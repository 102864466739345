import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from "redux-thunk";
import rootReducer from './reducers/rootReducer';
//import { logger } from "redux-logger";
import { composeWithDevTools } from 'redux-devtools-extension';
import storageSession from 'redux-persist/lib/storage';

const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
let store = createStore( persistedReducer, compose(composeWithDevTools(applyMiddleware(...middleware))))

const persistor = persistStore(store)
export { store, persistor };

// const store = createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(thunk))
//   );


// export default store