import React, { Component } from "react";
import Collapse from "react-bootstrap/Collapse";
import $ from 'jquery'
import i18next from 'i18next'
import ResponsiveDisplayAd from "../../Components/Adsense/ResponsiveDisplayAd";
// import MobileAd from "../../Components/Adsense/MobileAd";

export default class FooterComponent extends Component {
  componentDidMount(){
    // var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
    // var collapseList = collapseElementList.map(function (collapseEl) {
    //   return new bootstrap.Collapse(collapseEl)
    // })
  }
  render() {
    const lang =localStorage.getItem("lang") || "en";
    var width = $(window).width();
    return (
      <footer className="acr-footer footer-dark d-none d-sm-block d-md-none" style={{ bottom: '0', width: '100%'}}>
        {/* <div className='d-lg-none d-md-none flex'>
        {width <= 992?
                   <MobileAd />:null
                   }
          </div> */}
        <div className="footer-bottom">
          <div className="footer-container">
            <div className="row" >
            <div className="col-lg-12 left"  >
              <div className="col">
               
                <div className="d-inline " >
                   <a aria-label="Facebook" target="_blank" href="https://www.facebook.com/findpropertiesuae"> <i className="fab fa-facebook-f" /> </a> 
                 </div>
                 <div className="d-inline " style={{paddingLeft: '5px'}}>
                   <a aria-label="Instagram" target="_blank" href="https://www.instagram.com/findpropertiesofficial/"> <i className="fab fa-instagram" /></a>
                 </div>
                 <div className="d-inline " style={{paddingLeft: '10%'}}>
                   <a aria-label="agent hub" target="_blank" className="alio_green" href="https://dashboard.findproperties.ae/"> Agent Hub</a>
                 </div>
                 <div className="d-inline " style={{paddingLeft: '3%'}}>
                   <a aria-label="Contact us" target="_blank" className="alio_green" href="/contact"> Contact Us</a>
                 </div>
                 <div className="d-inline " style={{paddingLeft: '3%'}}>
                   <a aria-label="Terms" target="_blank" className="alio_green" href="/terms-and-conditions"> Terms</a>
                 </div>
             


               </div> 
               {/* <ul>
                  <li>
                    {" "}
                    <a href="#">Find a Home</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Add Listing</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">View Brokers</a>{" "}
                  </li>
                </ul> */}
              </div>
              <div className="col-lg-6 right">
                {/* <p className="m-0">
                  © Copyright 2021 - <a href="#">FindProperties</a> All Rights Reserved.
                </p> */}
              </div>

            </div>
          </div>
        </div>
      </footer>
    );
  }
}
