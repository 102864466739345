const initialState = {
    loggedIn:false,
    user:{},
    token : '',
}
const AuthReducer = (state = initialState, action) =>{
    // console.log("is comming here")
switch (action.type) {
    case "SET_LOGIN":
        return {
            ...state,
            loggedIn:true,
            user: action?.payload?.user ? action?.payload?.user : {},
            token:  action?.payload?.access_token ? action?.payload?.access_token : ''
        };
        case "SET_LOGOUT":
            return {...state,loggedIn:false,user:{},token:''};

    default:
        return state;
}
}
export default AuthReducer