import React, { Component } from 'react'
import '../PageStyles/agentstyle.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { baseurl } from '../../Components/BaseUrl'
import { baseurlImg } from '../../Components/BaseUrl'
import ReactPaginate from 'react-paginate'
import '../PageStyles/agentstyle.css'

import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Helmet } from "react-helmet";

const lang =localStorage.getItem("lang") || "en";
var language = lang == "ar" ? "/ar" : ""

export class Agencies extends Component {
    constructor(props){
        super(props);
          this.state = {
            agencies: [],
            phone: "",
            email: "",
            agentName: "",
            offset: 0,
            data: [],
            perPage: 24,
            currentPage: 1,
            agentAgentViewSwitcher: "agency",
            locationInput: '',
            locationData: [],
            nationality: [],
            languages: [],
            selectedLanguage: 0,
            selectedNationality: 0,
            selectedArea: 0,
            selectedLocation: '',
            locationList: 'd-none'


            }
            this.handlePageClick = this
            .handlePageClick
            .bind(this);
            
            this.agentAgentViewSwitcher = this.agentAgentViewSwitcher.bind(this);  // we require access to the state of component so we have to bind our function 
            this.locationAgentHadle = this.locationAgentHadle.bind(this);  // we require access to the state of component so we have to bind our function 

        }
        agentAgentViewSwitcher =(e) => {
            const value= e.target.value;
            this.setState({
                agentAgentViewSwitcher: e.target.value,
                locationList: 'd-none'
            });
             this.fetchData();

        }
       
        nationalityOnChange =(e)=> {
            const agentData= {agentAgentViewSwitcher: this.state.agentAgentViewSwitcher, selectedLanguage: this.state.selectedLanguage, selectedNationality: e.target.value, selectedLocation: this.state.selectedLocation, selectedArea: this.state.selectedArea}
            this.setState({selectedNationality: e.target.value});
            // axios
            // .post(baseurl + "filterAgent", agentData)
            // .then((response) => {
            //    console.log("filterAgent", response.data)
            //    this.setState({
            // //     isLoading: false,
            //      languages: response.data.languages,
            //      nationality: response.data.nationality,
            //    });
            // })
            // .catch();
           this.fetchData(agentData);
        }
        languageOnChange =(e)=> {
            const agentData= {agentAgentViewSwitcher: this.state.agentAgentViewSwitcher,selectedLanguage: e.target.value, selectedNationality: this.state.selectedNationality, selectedLocation: this.state.selectedLocation, selectedArea: this.state.selectedArea}
            this.setState({selectedLanguage: e.target.value});

            this.fetchData(agentData);
        }

        
        componentDidMount(){
            const agentData= {agentAgentViewSwitcher: this.state.agentAgentViewSwitcher,selectedLanguage: this.state.selectedLanguage, selectedNationality: this.state.selectedNationality, selectedLocation: this.state.selectedLocation, selectedArea: this.state.selectedArea}
            this.fetchData(agentData);

        }
      fetchData =(agentData) =>{
          console.log("sent agent data", agentData)
        axios
        .get(baseurl + "agentinfo")
        .then((response) => {
           console.log("countries", response.data)
          this.setState({
            isLoading: false,
            languages: response.data.languages,
            nationality: response.data.nationality,
          });
        })
        .catch();

        axios
        .get(baseurl + "locations")
        .then((response) => {
            console.log(response.data)
          this.setState({
            isLoading: false,
            locations: response.data.locations,
          });
        })
        .catch();


        axios.post(baseurl + "filterAgent", agentData)
        .then(response =>{
            console.log("new Data", response.data)
            // this.setState({isLoading:false, properties:response.data.recentproperties,pageCount: Math.ceil(response.data.length / this.state.perPage)})
             const dataAgents = response.data.agents
             const dataAgencies = response.data.agencies

            //  const data = dataAgents
              const data= this.state.agentAgentViewSwitcher=="agent"?dataAgents:dataAgencies
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postAgencyData = slice.map((data, i) => <React.Fragment>
                <div className="col-lg-3 col-md-6 col-sm-12">
                
                   <Link to={this.state.agentAgentViewSwitcher=="agent"?language+"/agents/"+data.id:language+"/agencies/"+data.id}>
                    <div className="agency_gridio_wrap">
                        <div className="agency_gridio_caption">
                            {this.state.agentAgentViewSwitcher=="agent"?
                                <div className="agent_gridio_thumb">
                                <Link  to={language+"/agents/"+data.id}>
                                    <img src={data.profile == undefined? baseurlImg + "/uploads/profiles/no_avatar.png": baseurlImg +
                                        "/uploads/profiles/" +data.profile} className="img-fluid" alt />
                                </Link>	     
                                </div>:
                                <div className="agency_gridio_thumb">
                                <Link  to={language+"/agencies/"+data.id}>
                                    <img src={baseurlImg + '/uploads/profiles/' + data.logo} className="img-fluid" alt />
                                    </Link>	
                                </div>
                            }
                    
                            <div className="agency_gridio_txt">
                                <h4>{this.state.agentAgentViewSwitcher=="agent"?
                                    <Link to={language+"/agents/"+data.id}>{lang=='en'?data.name_en:data.name_ar}</Link>	
                                :null}
                                {this.state.agentAgentViewSwitcher=="agency"?
                                    <Link to={language+"/agencies/"+data.id}>{lang=='en'?data.name_en:data.name_ar}</Link>	
                                :null}
                            </h4>
                            <span className="agt_gridio_ocat">{lang=='en'?data.address_en: data.address_ar}</span>

                            {this.state.agentAgentViewSwitcher=="agent"?
                                <div style={{marginTop: '25px', textAlign: 'center'}} className="agentNationalityLang">
                                    {/* <p style={{background: '#fff',fontWeight: '500'}} className="agt_gridio_ocat">Agency: <Link to={"/agencies/"+data.id}>{lang=='en'?data.agency_en:data.agency_ar}</Link></p> */}
                                <p style={{fontWeight: '100'}} >Nationality:<span  style={{fontWeight: '500', display: 'inline'}} > {lang=='en'?data.country_enNationality:data.country_arNationalityy}</span></p>
                                <p style={{fontWeight: '100', marginRight: '5'}}>Speaks: {data['language'].map((language)=>(<span  style={{fontWeight: '500', display: 'inline'}} >{language.label}, &nbsp;</span>))}</p>
                                
                                </div>
                            :null}
                            </div>
                            <div className="agency_gridio_header">
                                {this.state.agentAgentViewSwitcher=="agent"?<div className="agentAgencyLogo "><Link to={language+"agencies/"+data.id}><img src={baseurlImg + '/uploads/profiles/' + data.logo}/></Link>	</div>:null}
                                {this.state.agentAgentViewSwitcher=="agency"?<span className="agents_count">{data.agents_count} Agents</span>:null}
                                <span className="agent_properties_count">{this.state.agentAgentViewSwitcher=="agent"?data.agentproperty_count: data.property_count} Properties</span>
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
              </React.Fragment>)
                  this.setState({
                   pageCount: Math.ceil(data.length / this.state.perPage), postAgencyData
              })
            
        })
        .catch()
        }
      componentDidMount(){
        this.fetchData();
     }

     locationListClickHandler =(locationData, e)=> {
          console.log("location Data", locationData['title'])
         
         const agentData= {agentAgentViewSwitcher: this.state.agentAgentViewSwitcher, selectedLanguage: this.state.selectedLanguage, selectedNationality: this.state.selectedNationality, selectedLocation: locationData['title'], selectedArea: this.state.selectedArea}
         console.log("agent Data", agentData)
          this.fetchData(agentData);
         this.setState({
            locationInput: locationData['title'],
            locationList: 'd-none'
         })

     }

     locationAgentHadle=(e)=>{
         
          this.setState({
            locationInput: e.target.value,
            agentAgentViewSwitcher: this.state.agentAgentViewSwitcher,
            selectedLanguage: this.state.selectedLanguage, 
            selectedNationality: this.state.selectedNationality,
        })
        axios
        .get(baseurl + "getLocation/"+ this.state.agentAgentViewSwitcher+"/"+ e.target.value)
        .then((response) => {
          console.log("returned locaation", response.data)
          this.setState({
            isLoading: false,
            locationData: response.data.switcher==1?response.data.agenciesandlocations:response.data.agentsandlocations,
            locationList: 'd-block dropdownLocationlist',

          });
        })
        .catch();
     }
     handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * this.state.perPage;

      this.setState({
          currentPage: selectedPage,
          offset: offset
      }, () => {
          this.fetchData()
      });
  };
    render() {

        return (
            <div>
             <Helmet>
                <title>{lang=="en"?"Real Estate agencies in UAE | Find Properties": "وكالات عقارية في الإمارات العربية المتحدة | ابحث عن خصائص"}</title>
                <meta name="description" content={lang=="en"?"Browse all real estate agencies and their properties in UAE | See all latest listings, directly contact by call, WhatsApp or email": "تصفح جميع الوكالات العقارية وممتلكاتها في الإمارات | شاهد جميع أحدث القوائم ، اتصل مباشرة عن طريق الاتصال أو WhatsApp أو البريد الإلكتروني"}></meta>
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="page-title" style={{background: '#f4f4f4 url(./images/agency.jpg)', backgroundPosition: '-150px'}} data-overlay={5}>
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12 center">
                    <div className="breadcrumbs-wrap">         
                    {/* <h2 className="breadcrumb-title">Meet All {this.state.agentAgentViewSwitcher}</h2> */}
                        <div className="btn-group" onChange={this.agentAgentViewSwitcher} role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" value="agency"  autoComplete="off" defaultChecked />
                            <label  className="btn btn-outline-primary buttonSwitch" htmlFor="btnradio1">Companies</label>
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" value="agent" autoComplete="off" />
                            <label  className="btn btn-outline-primary buttonSwitch" htmlFor="btnradio2">Agents</label>

                        </div>
                        {this.state.agentAgentViewSwitcher=="agent"?
                            <div style={{marginTop: '10px'}}className="col-sm-6">
                                <div className="row">
                                    <div className="col">
                                    <select onChange={this.languageOnChange} className="filter-input form-select mb-3" name="" id="">
                                            <option selected value="0">
                                                Language
                                            </option>
                                            {this.state.languages.map((languages)=>
                                             <option value={languages.id}>{languages.language_en}</option>
                                          )}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <select onChange= {this.nationalityOnChange} className="filter-input form-select mb-3" name="" id="">
                                            <option selected value="0">
                                                Nationality
                                            </option>
                                          {this.state.nationality.map((nationality)=>
                                             <option value={nationality.id}>{nationality.country_enNationality}</option>
                                          )}
                                        </select>
                                    </div>
                                    {/* <div className="col">
                                        <select className="filter-input form-select mb-3" name="" id="">
                                            <option selected value="*">
                                                Service Area
                                            </option>
                                            <option value="1">Daily</option>
                                            <option value="2">Weekly</option>
                                            <option value="3">Monthly</option>
                                            <option value="3">Yearly</option>
                                        </select>
                                    </div> */}
                                </div>
                           </div>
                          :null}
                        <div  style={{position: 'relative'}}  className="col-sm-6">
                        {/* <Autocomplete
                            id="multiple-limit-tags"
                            options={this.state.locations}
                            onChange={(event, newValue) => {
                                this.setState({ locationValue: newValue });
                                const agentData= {selectedLanguage: this.selectedLanguage, selectedNationality: this.state.selectedNationality, selectedLocation: newValue, selectedArea: this.state.selectedArea}
                                this.fetchData(agentData);
                                console.log("selected Loca", this.state.locationValue)

                            }}
                            getOptionLabel={(option) => option.location}
                            // defaultValue={[top100Films[13],]}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                variant="outlined"
                                label=""
                                placeholder="Locations"
                                />
                            )}
                              /> */}

                              <input value={this.state.locationInput} placeholder={this.state.agentAgentViewSwitcher=='agent'?"Location or agent name": "Location or company name"} onChange= {this.locationAgentHadle} className="filter-input form-control mb-3"/>
                              <ul style={{zIndex: '9999',maxHeight: '450px', position: 'absolute', width: '100%'}} className={this.state.locationList} >
                                 {this.state.locationInput.length>0?this.state.locationData.map((locationData)=>(
                                     <li value={locationData.title} onClick={this.locationListClickHandler.bind(this, locationData)}>{locationData.title}</li>
                                  )): null}
                              </ul>
                            </div>
                     </div>
                </div>
                </div>
            </div>
            </div>

            <div className="container" style={{marginTop: '30px'}}>
                <div className="row">
                {/* Single Agent */}

               {/* {this.state.agencies.length>0? */}
               {/* <> */}
               {this.state.postAgencyData}
               <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                            {/* </> */}
                            {/* : */}
              
                {/* <div className="noResult center"> 
                <div class="no-result-icon">
                    <i class="fa fa-ban" aria-hidden="true"></i>
                 </div>
                <div class="no-result-header">No Agent Found!</div>
              </div> */}
               
               {/* }   */}
       					
                </div>
            </div>
            
            </div>


        )
    }
}

export default Agencies