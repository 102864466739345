import React, { Component } from 'react'
import AllBlogs from './AllBlogs'

class Tips extends Component {
    render() {
        return (
            <div>
               <AllBlogs categoryId="3"></AllBlogs>
            </div>
        )
    }
}

export default Tips
