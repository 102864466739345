import React, { Component } from 'react'
// import {Adsense} from '@ctrl/react-adsense';

class SideAds extends Component {

    componentDidMount() {
        // (window.adsbygoogle = window.adsbygoogle || []).push({})
       } 
    render () {
       return(
           <>         
           {/* <div style={{marginTop: '20px'}}>
              <ins className="adsbygoogle"
              .
                    style={{display:'block', textAlign:'center'}}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-6069660640889179"
                    data-ad-slot="8799606675">
               </ins>
           </div> */}
           {/* <div style={{marginTop: '20px'}}>
                <ins className="adsbygoogle"
                    style={{display:'block', textAlign:'center'}}
                    data-ad-client="ca-pub-6069660640889179"
                    data-ad-slot="5537763567"
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>
           </div> */}
           {/* <div style={{marginTop: '20px'}}>
             <ins className="adsbygoogle"
                style={{display:'block', textAlign:'center'}}
                data-ad-format="fluid"
                data-ad-layout-key="-gh+d-e-kk+17p"
                data-ad-client="ca-pub-6069660640889179"
                data-ad-slot="3582323415">
              </ins>
           </div> */}
           </>          
           )
       }
   }

export default SideAds