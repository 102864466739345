import React from "react";

function Terms() {
  return (
    <div className="container" style={{ marginTop: "50px" }}>
      <div>
        <h3
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 18,
              }}
            >
              Terms &amp; Conditions For Users
            </span>
          </strong>
        </h3>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              1. TERMS
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            1.1In these terms and conditions for users (“Terms of Use”) the
            terms “we", "our", "us" means
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            &nbsp;Find properties F.Z.E &nbsp;and its subsidiaries, employees,
            officers, agents, affiliates or assigned parties.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            1.2"Website" refers to Findproperties.ae
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            1.3By accessing and using this Website, you are agreeing to be bound
            by the Website’s Terms of Use and the Privacy Policy (together the
            “Terms”), all applicable laws and regulations, and agree that you
            are responsible for compliance with any applicable local laws. If
            you do not agree with any of these Terms, your sole option is to
            immediately cease your use of this Website. The materials contained
            in this Website are protected by applicable copyright and trademark
            law.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            1.4You may not use the Website and may not accept these Terms if (a)
            you are not of eighteen (18) years of age, or (b) you are a person
            who is either barred or otherwise legally prohibited from receiving
            or using the Website under the laws of the country in which you are
            a resident or from which you access or use the Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            1.5These Terms are effective between you and us as of the date you
            accept these Terms, and you do so by default through the use of the
            Website.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              2. USE LICENSE &amp; RESTRICTIONS
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            2.1Permission is granted to temporarily download copies of the
            materials (information or software) on the Website for personal,
            non-commercial transitory viewing only. This is the grant of a
            license, not a transfer of title, and under this license you may
            not:
          </span>
        </p>
        <ul
          className="decimal_type"
          style={{ listStyleType: "undefined", marginLeft: 26 }}
        >
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              a.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              modify or copy the materials;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              b.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              c.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              attempt to decompile or reverse engineer any software contained on
              the Website;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              d.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              remove any copyright or other proprietary notations from the
              materials; or
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              e.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              transfer the materials to another person or "mirror" the materials
              on any other server.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            2.2In accessing or using our Website you irrevocably agree and
            undertake to ensure that you will not:
          </span>
        </p>
        <ul
          className="decimal_type"
          style={{ listStyleType: "undefined", marginLeft: 26 }}
        >
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              a.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              use any automated device, software process or means to access,
              retrieve, scrape, or index our Website or any content on our
              Website;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              b.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              use any device, software, process or means to interfere or attempt
              to interfere with the proper working on our Website;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              c.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              undertake any action that will impose a burden or make excessive
              traffic demands on our infrastructure that we deem, in our sole
              discretion to be unreasonable or disproportionate Website usage;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              d.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              use or index any content or data on our Website for purposes of:
            </span>
            <ul style={{ listStyleType: "undefined" }}>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  i.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  constructing or populating a searchable database of
                  properties,
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  ii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  building a database of property information; or
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  iii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  competing with us in any manner that we have not
                  specifically&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  authorized
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  ;
                </span>
              </li>
            </ul>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              e.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              transmit spam, chain letters, contents, junk email, surveys, or
              other mass messaging, whether commercial in nature or not;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              f.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              use our Website or any content from our Website in any manner
              which we in our sole discretion determine as not reasonable and/or
              not for the purpose which it is made available;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              g.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              violate the rights of any person, including copyright, trade
              secret, privacy right, or any other intellectual property or
              proprietary right;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              h.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              pose as any person or entity or attempt to solicit money,
              passwords or personal information from any person;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              i.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              act in violation of any such terms or other condition posed by us
              or any applicable law;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              j.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              reproduce, republish,&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              re transmit
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              , modify, adapt, distribute, translate, create derivative works or
              adaptation of, publicly display, sell, trade, or in any way
              exploit our Website or any content on our Website, except as
              expressly&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              authorized
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              &nbsp;by us; or
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              k.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              transmit or attempt to transmit any computer viruses, worms,
              defects or other items of a destructive manner.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            2.3We reserve the right to exercise whatever means we deem necessary
            to prevent&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            unauthorized
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            &nbsp;access to our use of the Website, including but not limited
            to, instituting technological barriers, or reporting your conduct to
            any person or entity.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            2.4This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by us at any time. Upon
            terminating your viewing of these materials or upon the termination
            of this license, you must destroy any downloaded materials in your
            possession whether in electronic or printed format.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              3. YOUR RESPONSIBILITIES
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            3.1We are not an estate agency and we provide a service whereby
            agents may market and you may view property details (“Details”)
            together with other content hosted and developed by us. Agents and
            third parties are responsible for preparing the Details and
            fielding&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            enquiries&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            directly from you. We do not get involved in any communications
            between you and agents and we do not participate in any part of the
            transaction.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            3.2Details are hosted by us in good faith but are produced directly
            by agents and/or third parties and have not been verified by us. You
            are responsible for making your own enquiries and we provide no
            guarantee and accept no responsibility for the accuracy or
            completeness of any information contained within the Details.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            3.3You are responsible for checking, confirming and satisfying
            yourself as to the accuracy of any Details.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            3.4You are responsible for instructing a surveyor and/or obtaining
            legal advice before committing to any purchase.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            3.5You are responsible for ensuring that you act in good faith
            towards any other parties.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            3.6You represent and warrant that your use of our Website will
            comply at all times with these Terms of Use and any further terms
            that may apply to you in relation to your use of our Website,
            including all amendments and revisions to these Terms in accordance
            with Clause 8 herein;
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              4. LIMITATIONS
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            4.1In no event will we be liable for any damages (including, without
            limitation, damages for loss of data or profit, or due to business
            interruption) arising out of the use or inability to use the
            materials on the Website, even if one of our&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            authorized
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            &nbsp;representatives has been notified orally or in writing of the
            possibility of such damage.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            4.2We will not be liable for any loss or damage arising under or in
            connection with:
          </span>
        </p>
        <ul
          className="decimal_type"
          style={{ listStyleType: "undefined", marginLeft: 26 }}
        >
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              a.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              any failures due to software or internet errors or unavailability,
              or any other circumstances beyond our reasonable control;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              b.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              any loss of your password or account if caused by a breakdown,
              error, loss of power or otherwise cause by or to your computer
              system and/or your account;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              c.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              the use of, or inability to use, our Website;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              d.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              the reliance on any content or information displayed on our
              Website;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              e.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              any direct, consequential, special or punitive loss, damage, costs
              and expenses;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              f.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              loss of profit;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              g.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              loss of business;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              h.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              loss of reputation;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              i.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              depletion of goodwill; or loss of, damage to or corruption of
              data.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            4.3Unless we otherwise expressly agree in writing, you agree not to
            use our Website for any commercial or business purposes.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            4.4We will not be liable for any loss or damage caused by a virus,
            distributed denial of service attack or other technological harmful
            material that may infect your computer equipment, computer programs
            , data or other proprietary material due to your use of our Website
            or to your downloading of any content on it, or any website linked
            to it.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            4.5If you enquire about a property on this Website, you acknowledge
            and agree that your details will be sent by email directly to the
            agent, estate agent, landlord, developer marketing the property or
            properties you are enquiring about. We do not accept any liability
            for any subsequent communications that you receive directly from
            that estate agent, landlord or developer and/or any third party.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              5. REVISIONS AND ERRATA
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            The materials appearing on the Website could include technical,
            typographical, or photographic errors. We do not warrant that any of
            the materials on its Website are accurate, complete, or current. We
            may make changes to the materials contained on the Website at any
            time without notice.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              6. AVAILABILITY OF WEBSITE
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            We strive to ensure that our Website and the services are available
            to you at all times but cannot guarantee that either the Website or
            the services will operate continuously, without interruptions or be
            fault free. On occasion, necessary maintenance or upgrade work
            requires us to make the Website and the services unavailable without
            notice, but we aim to keep downtime to a minimum. We accept no
            liability for any interruption or loss of service. We reserve the
            absolute right to alter, suspend or discontinue any part of our
            Website or the services, including your access to it.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              7. LINKS &amp; THIRD PARTIES
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            Our Website may contain links, hyperlinks and pointers to third
            party products, services and/or websites that are not affiliated
            with Us. We have no control over the products, services or websites
            of these third parties and We do not guarantee or take
            responsibility for them. Our Website may also contain advertising
            from third parties and we are not responsible, nor do we make any
            warranties or representations for any misleading or inaccurate
            advertisements which are the sole responsibility of the advertiser.
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            <br />
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            <br />
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            Any links or advertisements on our Website should not be taken as an
            endorsement by us of any kind. Furthermore, our Website contains
            data provided by third parties and we accept no responsibility, nor
            do we make any warranties or representations for any inaccuracies in
            this material. You agree to release us from any claims or disputes
            of any kind arising from or in any way connected to such disputes
            with third parties.
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            <br />
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            <br />
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            By using the Website, you grant us an irrevocable, world-wide,
            royalty free license to&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            commercial
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            , copy, license to other persons, use and adapt for any purpose any
            material you generate or submit to make use of the Website. We do
            not warrant that the content, links, or sub-domains contained on, or
            associate with our Website will be available and accessible to you
            at all times. Information on our publications, posts, inserts,
            information, content should not be regarded as a substitute for
            professional legal, financial or real estate advice.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              8. SITE TERMS OF USE MODIFICATIONS
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            We may revise these Terms of Use and any such Terms for the Website
            at any time without notice. By using this Website you are agreeing
            to be bound by the Terms of Use.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              9. CONTRIBUTIONS
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            9.1In these Terms of Use “Contributions” means any information
            including data, text, video, still images, audio or other material
            that we have permitted you to host, share, publish, post, store or
            upload on our Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            9.2We may at any time, without liability to you, remove, alter or
            disable access to any or all of your Contributions in our sole
            discretion without prior notice to you. Without limiting the
            previous sentence, we may remove or disable access to any or all of
            your Contributions if we consider that:
          </span>
        </p>
        <ul style={{ listStyleType: "undefined", marginLeft: 26 }}>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              a.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              those Contributions are in breach of any law or regulation;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              b.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              those Contributions infringe the intellectual property rights of
              any third party;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              c.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              it is required to do so by a regulatory body or any relevant
              authority pursuant to an interim or final take-down notice;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              d.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              those Contributions;
            </span>
            <ul className="decimal_type" style={{ listStyleType: "undefined" }}>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  i.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  misleading or deceptive;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  ii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  inappropriate having regard to the purpose of our Website;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  iii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  likely to cause offence;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  iv.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  materially incorrect;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  v.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  obscene;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  vi.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  defamatory;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  vii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  otherwise unlawful and/or against the customs or norms of the
                  region in which this Website is referred too; or
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  viii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  corrupted, due to the presence of a virus or other disabling
                  code.
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            9.3To the extent that any Contributions are proprietary in nature,
            you grant us a worldwide, non-exclusive, royalty-free, perpetual,
            transferable and irrevocable license to use, reproduce, modify,
            adapt, translate, distribute, publish, create derivative works from
            and display and publicly perform your Contributions throughout the
            world in any medium, whether currently in existence or not.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            9.4You also grant each user of our Website the right to use your
            name or the name you submit with the Contribution, and, the right to
            represent and warrant that:
          </span>
        </p>
        <ul
          className="decimal_type"
          style={{ listStyleType: "undefined", marginLeft: 26 }}
        >
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              a.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              you own and control all of the rights to the Contributions; or
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              b.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              you have the lawful right including all necessary&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                fontSize: "10.5000pt",
                color: "rgb(45,56,63)",
                fontStyle: "normal",
              }}
            >
              licences&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              , rights, consents and permissions to use and&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              authorize
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              &nbsp;us to display the Contributions.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            9.5For any Contributions that you may retain moral rights in, you
            declare that:
          </span>
        </p>
        <ul
          className="decimal_type"
          style={{ listStyleType: "undefined", marginLeft: 26 }}
        >
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              a.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              you do not require that any personally identifying information be
              used in connection with the Contribution, or any derivative work,
              upgrade or update of the Contribution; and
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              b.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              You understand that when accessing our Website you may be exposed
              to the Contributions of other users of our Website. You
              acknowledge and agree that we do not have control of and are not
              responsible nor do we warrant the veracity of these other
              Contributions.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            9.6You represent and warrant that:
          </span>
        </p>
        <ul style={{ listStyleType: "undefined", marginLeft: 26 }}>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              a.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              you have the lawful right including all necessary licenses,
              rights, consents and permissions to use and&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              authorize
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              &nbsp;us to display your Contributions;
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              b.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              you will not make any Contributions that infringe the intellectual
              property rights of any third party, and you agree to pay all
              royalties, fees or other monies payable by reason of any
              Contributions made by you; and
            </span>
          </li>
          <li>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "12.0000pt",
              }}
            >
              c.&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontSize: "10.5000pt",
              }}
            >
              you will not make any Contributions that:
            </span>
            <ul style={{ listStyleType: "undefined" }}>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  i.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  are misleading;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  ii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  are deceptive;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  iii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  are materially incorrect;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  iv.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  are likely to cause offence;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  v.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  directly or indirectly involve the advertising or marketing of
                  any products or services;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  vi.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  are obscene, including pornographic, hateful, racially or
                  ethnically offensive material;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  vii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  are defamatory;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  viii.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  are otherwise unlawful or encourage unlawful conduct; or
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "12.0000pt",
                  }}
                >
                  ix.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Open Sans"',
                    color: "rgb(45,56,63)",
                    fontSize: "10.5000pt",
                  }}
                >
                  are otherwise inappropriate having regard to the purpose of
                  our Website.
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              10. INTELLECTUAL PROPERTY
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            10.1 Unless otherwise expressly stated, all contents of the Website
            are copyrights, trademarks, trade dress and/or other intellectual
            property owned, controlled or licensed by us, or one of our
            affiliates or by third parties who have licensed their materials to
            us and are protected by the applicable laws.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            10.2 We, together with our suppliers and licensors expressly reserve
            all intellectual property rights in all text, programs, products,
            processes, technology, content and other materials, which appear on
            this Website. Access to this Website does not confer and shall not
            be considered as conferring upon anyone any license under any of our
            or any third party's intellectual property rights. Any use of this
            Website or its contents, including copying or storing it or them in
            whole or part, other than for your own personal, non-commercial use,
            is prohibited without our permission. You may not modify, distribute
            or re-post anything on this Website for any purpose.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            10.3 Our names and logos and all related product and service names,
            design marks and slogans are the trademarks or service marks of us
            or our licensors. No trademark or service mark license is granted in
            connection with the materials contained on this Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            10.4 Access to this Website does not&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            authorize
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            &nbsp;anyone to use any name, logo or mark in any manner whatsoever.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              11. ELECTRONIC COMMUNICATIONS
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            11.1 When you use the Website or send emails to us, you are
            communicating with us electronically. You consent to receive
            electronically any communications related to your use of this
            Website. We will communicate with you by email or by posting notices
            on this Website. You agree that all agreements, notices, disclosures
            and other communications that are provided to you electronically
            satisfy any legal requirement that such communications be in
            writing. All notices from us intended for receipt by you shall be
            deemed delivered and effective when sent to the email address you
            provide on the Website for your account.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              12. INDEMNITY
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            12.1 You agree to indemnify and hold us and our affiliates (and our
            officers, agents, partners and employees) against any and all loss,
            liability, claim or demand, including reasonable attorney’s fees,
            arising out of, or in connection with your use of and access to our
            Website or making Contributions not in accordance with the Terms.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              13. DISCLAIMER
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            The materials on our Website are provided on an "as is" and “as
            available” basis and we make no warranties, expressed or implied,
            and hereby disclaim and negate all other warranties, including
            without limitation, implied warranties or conditions of&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            merchantability
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            , fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights. Further, we do
            not warrant or make any representation concerning the accuracy,
            likely results, or reliability of the use of the materials on the
            Website or otherwise relating to such materials or on any site
            linked to this Website.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              14. MONITORING AND RECORDING TELEPHONE CALLS
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            Real estate broker and developer clients who have subscribed to our
            call tracking service have elected to have their call enquires
            generated through the Website. Such calls may be tracked and
            recorded for training and customer service assessment purposes. You
            consent in advance to any such recording. We will remind you of our
            recording before each phone conversation.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              15. GOVERNING LAW
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            15.1 These Terms of Use and your access to the Website is subject to
            and governed by the laws of the United Arab Emirates as applied in
            the Emirate of Dubai. You agree to submit to the exclusive
            jurisdiction of the Courts of Dubai.
          </span>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            15.2 If any term of the Agreement is or may become for any reason
            invalid or unenforceable at law, the validity and&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            enforceability&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            of the remainder will not be affected.
          </span>
        </p>
        <h4
          style={{
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 16,
              }}
            >
              16. CONTACT US
            </span>
          </strong>
        </h4>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            16.1findproperties.ae subscribers are licensed real estate brokers,
            developers and hotel apartment providers. Our advertisers are
            contractually obligated to only list properties that are available
            for sale or lease with the proper authority from the owner and all
            other required governing bodies. Properties listed on&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            findproperties.ae
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            &nbsp;should be a fair and accurate portrayal of the property itself
            and the proposed transaction. To report any suspected fraudulent or
            misleading property postings on our site please send us an email
            with the details to&nbsp;
          </span>
          <a href="mailto:abuse@propertyfinder.ae">
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(0,0,255)",
                fontStyle: "normal",
                fontSize: 14,
              }}
            >
              i
            </span>
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontStyle: "normal",
                fontSize: 14,
              }}
            >
              nfo@findproperties.ae
            </span>
          </a>
        </p>
        <p
          style={{
            marginTop: "0.0000pt",
            marginRight: "0.0000pt",
            marginBottom: "0.0000pt",
            marginLeft: "0.0000pt",
            textIndent: "0.0000pt",
            padding: "0pt 0pt 0pt 0pt",
          }}
        >
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            If you have any queries, complaints or recommendations about these
            Terms of Use, please contact us at the following address
          </span>
          <span
            style={{
              fontFamily: '"Open Sans"',
              color: "rgb(45,56,63)",
              fontStyle: "normal",
              fontSize: 14,
            }}
          >
            :&nbsp;
          </span>
          <a href="mailto:info@propertyfinder.ae">
            <span
              style={{
                fontFamily: '"Open Sans"',
                color: "rgb(45,56,63)",
                fontStyle: "normal",
                fontSize: 14,
              }}
            >
              info@findproperties.ae
            </span>
          </a>
        </p>
        <p>
          <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
        </p>
      </div>
    </div>
  );
}

export default Terms;
