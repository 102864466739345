import React, { Component } from 'react'
import AllBlogs from './AllBlogs'

class AreaGuide extends Component {
    constructor(props){
        super(props)
        this.state= {

        }
    }

    render() {
        return (
            <div>
                <AllBlogs categoryId="1"></AllBlogs>
            </div>
        )
    }
}

export default AreaGuide
