import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseurl, baseurlImg } from "../Components/BaseUrl";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Moment from "react-moment";
import i18next from "i18next";

const lang = localStorage.getItem("lang") || "en";

class AllBlogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      data: [],
      perPage: 9,
      currentPage: 1,
      blogId: 0,
      blogCategories: [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        const data = { id: this.props.categoryId };

        this.fetchData(data);
      }
    );
  };

  fetchData = (data) => {
    axios.post(baseurl + "allblogs", data).then((response) => {
      //console.log("Blogs Data", response.data)
      // this.setState({isLoading:false, properties:response.data.recentproperties,pageCount: Math.ceil(response.data.length / this.state.perPage)})
      const data = response.data.blogs;

      //  const data = dataAgents
      const slice = data.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      const postBlogs = slice.map((data, i) => (
        <React.Fragment>
          <div className="col-lg-4 col-md-6 ">
            <div className="grid_blog_box">
              {lang == "en" ? (
                <a href={"/blogs/" + data.slug_en}>
                  <div className="gtid_blog_thumb">
                    <img
                      src={baseurlImg + "/uploads/blogs/" + data.image}
                      className="img-fluid"
                      alt
                    />
                    {/* <Moment format="D" >{data.created_at}</Moment>}</span>{<Moment format="MMM" >{data.created_at}</Moment>} &nbsp; {<Moment format="YYYY" >{data.created_at}</Moment> */}
                    <div className="gtid_blog_info">
                      <span>{data.name_en}</span>
                    </div>
                  </div>
                </a>
              ) : (
                <a href={"/ar/blogs/" + data.slug_ar}>
                  <div className="gtid_blog_thumb">
                    <img
                      src={baseurlImg + "/uploads/blogs/" + data.image}
                      className="img-fluid"
                      alt
                    />
                    {/* <Moment format="D" >{data.created_at}</Moment>}</span>{<Moment format="MMM" >{data.created_at}</Moment>} &nbsp; {<Moment format="YYYY" >{data.created_at}</Moment> */}
                    <div className="gtid_blog_info">
                      <span>{data.name_ar}</span>
                    </div>
                  </div>
                </a>
              )}

              <div className="blog-body">
                <h4 className="bl-title">
                  {lang == "en" ? (
                    <Link to={"/blogs/" + data.slug_en}>{data.title_en}</Link>
                  ) : (
                    <Link to={"/ar/blogs/" + data.slug_ar}>
                      {data.title_ar}
                    </Link>
                  )}
                  {/* <span className="latest_new_post">New</span> */}
                </h4>
                <p>
                  {lang == "en"
                    ? data.metadescription_en
                    : data.metadescription_ar}
                </p>
              </div>
              {/* <div className="modern_property_footer">
                            <div className="property-author">
                            <div className="path-img"><a href="agent-page.html" tabIndex={-1}><img src="assets/img/user-1.jpg" className="img-fluid" alt /></a></div>
                            <h5><a href="agent-page.html" tabIndex={-1}>Shaurya Preet</a></h5>
                            </div>
                            <span className="article-pulish-date"><i className="ti-comment-alt mr-2" />202</span>
                        </div> */}
            </div>
          </div>
        </React.Fragment>
      ));

      this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),
        postBlogs,
      });
    });
  };

  fetchAllBlogsInMenu = () => {
    axios.get(baseurl + "blogscategories").then((response) => {
      this.setState({ blogCategories: response.data.blogcategories });
      //console.log (response.data.blogcategories)
    });
  };
  componentDidMount() {
    const data = { id: this.props.categoryId };
    this.fetchData(data);

    // //console.log('Prop parameter',  this.props.categoryid )
  }
  render() {
    return (
      <>
        <Helmet>
          <title>All Blogs</title>‍
        </Helmet>
        <div>
          {/* <div className="page-title" style={{background: '#f4f4f4 url(./images/slider-1.jpg)'}} data-overlay={5}>
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                        <div className="breadcrumbs-wrap">
                            <ol className="breadcrumb"> */}
          {/* <li className="breadcrumb-item active" aria-current="page">Agency Detail</li> */}
          {/* </ol> */}
          {/* <h1 className="breadcrumb-title">{i18next.t("blogs")}</h1> */}
          {/* <div className="col-sm-3">
                                <select className="filter-input form-select mb-3" id="propertyPurpose">
                                    <option value="1">Blog Rent</option>
                                </select></div> */}
          {/* </div>
                        </div>
                    </div>
                    </div>
            </div> */}
          <section className="gray">
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <div className="sec-heading center">
                    {/* <h2>Latest Blogs</h2> */}
                    {/* <p>We post regulary most powerful articles for help and support.</p> */}
                  </div>
                </div>
              </div>
              {/* row Start */}
              <div className="row">
                {/* Single blog Grid */}
                {this.state.postBlogs}
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default AllBlogs;
