import React, { Component } from "react";
// import HeaderComponent from "./CommonPageComponets/HeaderComponent";

import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import AdSense from 'react-adsense';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// import API from "API";
// import "../pages/Property/SingleProperty/SingleProperty.css";
import { Link } from "react-router-dom";
import { baseurl, baseurlImg, websitebaseurl } from '../../Components/BaseUrl'
import $, { data } from "jquery";

import { MultiSelect } from "react-multi-select-component";
// import "./Property/SingleProperty/SingleProperty.css";
import i18next from "i18next";
import Chip from "@material-ui/core/Chip";
import cookie from 'js-cookie'
import PropagateLoader from "react-spinners/PropagateLoader";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ReactPaginate from "react-paginate";
import { Button } from "@material-ui/core";
import Offcanvas from 'react-bootstrap/Offcanvas'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Offline, Online } from "react-detect-offline"
import API from "../../API";
// import SideAds from "../Components/SideAds";
// import ResponsiveDisplayAd from "../Components/Ads/ResponsiveDisplayAd";
// import MobileAd from "../Components/Ads/MobileAd";
import { connect } from 'react-redux';
import { store } from '../../store';

var url = window.location.href;
var url = url.split("?")[0]
var splitUrl = url.split("/")
var lang = splitUrl[3] == "ar"? "ar":"en"
var language = lang == "en"? "": "/ar"

localStorage.setItem("lang", lang)
// var lang = (window.location.href).includes("ar") ? "ar" : "en"

// var containAr = (window.location.href).split("/ar").length
var w = window.innerWidth;

// //console.log("containAr", containAr + localStorage.getItem("lang"))
//Check if it contains ar
// if (localStorage.getItem("lang") == "en" && containAr == 2) {
//   localStorage.setItem("lang", "ar")
//   lang = "ar"
// }
// else if (localStorage.getItem("lang") == "ar" && containAr == 1) {
//   localStorage.setItem("lang", "en")
//   lang = "en"
// }
// else if (localStorage.getItem("lang") == "en" && containAr == 1) {
//   localStorage.setItem("lang", "en")
//   lang = "en"
// }
// else if (localStorage.getItem("lang") == "ar" && containAr == 2) {
//   localStorage.setItem("lang", "ar")
//   lang = "ar"
// }
// else if (localStorage.getItem("lang") === null && containAr == 2) {
//   localStorage.setItem("lang", "ar")
//   lang = "ar"
// }
// else if (localStorage.getItem("lang") === null && containAr == 1) {
//   localStorage.setItem("lang", "en")
//   lang = "en"
// }

// //console.log("lang", lang)

// const lang = localStorage.getItem("lang") || "en";
// const token = cookie.get("token")

// const selectedPropPurposeValue = $("#propertyPurpose option:selected").val();
// //console.log("pupooopse", selectedPropPurposeValue)


var width = $(window).width();

const forrentar = "للايجار";
const forsalear = "للبيع";
const forsharear = "سكن-مشترك";

var url = window.location.href;

var url = url.split("?")[0]

var splitUrl = url.split("/")

var locationForUrl = decodeURI(splitUrl[splitUrl.length - 1].replace("-", " "))

var indexLang = lang == "en" ? 0 : 1;

var typeForUrl = splitUrl[4 + indexLang] ? splitUrl[4 + indexLang] : lang == "en" ? "properties" : "عقارات"

const propTypeName = splitUrl[4 + indexLang] ? splitUrl[4 + indexLang] : lang == "en" ? "properties" : "عقارات"
// splitUrl[3]==1? i18next.t("for-rent")+ "/": e.target.value==2? i18next.t("for-sale")+ "/" : i18next.t("for-sharing")+ "/",
var purposeEn = splitUrl[3] == "for-rent" ? 1 : splitUrl[3] == "for-sale" ? 2 : 3
var purposeAr = decodeURI(splitUrl[4]) == forrentar ? 1 : decodeURI(splitUrl[4]) == forsalear ? 2 : 3
var propPurposeFromUrl = lang == "en" ? purposeEn : purposeAr

// var propPurposeFromUrl = lang == "en" ? splitUrl[3] == "for-rent" ? 1 : splitUrl[3] == "for-sale" ? 2 : splitUrl[3] == "for-sharing" ? 3 : decodeURI(splitUrl[4]) == forrentar ? 1 : decodeURI(splitUrl[4]) == forsalear ? 2 : decodeURI(splitUrl[4]) == forsharear ? 3 : null : null;
var propertyPurposename = lang == "en" ? splitUrl[3] : decodeURI(splitUrl[4])


var bedPropertyType = splitUrl[4 + indexLang] ? splitUrl[4 + indexLang] : lang == "en" ? "properties" : "عقارات"

// //console.log("bedPropertyType", bedPropertyType)

var splitBedPropertyType = bedPropertyType.split("-")
//  var urlPropId= $('option', this).attr(name);
//slice to get the property name
let propertyTypeName;

if (splitBedPropertyType.length > 2) {
  propertyTypeName = splitBedPropertyType.slice(2);
  propertyTypeName = propertyTypeName[0];
}
else if (bedPropertyType.includes("studio") || bedPropertyType.includes('استوديو')) {
  propertyTypeName = splitBedPropertyType[1]
}
else {
  propertyTypeName = splitUrl[4 + indexLang] ? splitUrl[4 + indexLang] : lang == "en" ? "properties" : "عقارات"
}
propertyTypeName = decodeURI(propertyTypeName)
//  var urlPropId= $('#propertyType').find("option[name="+propertyTypeName+"]").attr("value");
//  //console.log("LOCATION url", urlPropId)
//  apply parameters to select components

let bedroomFromUrl;
if (splitBedPropertyType[0] == "studio" || splitBedPropertyType[0] == "استوديو") {
  bedroomFromUrl = 0
}
else if (splitBedPropertyType.length <= 2) {
  bedroomFromUrl = -1
} else {
  bedroomFromUrl = splitBedPropertyType[0];
}

const splitUrlLocEn = splitUrl.length == 6 ? splitUrl[5] : splitUrl.length == 7 ? splitUrl[5] + "/" + splitUrl[6] : splitUrl.length == 8 ? splitUrl[5] + "/" + splitUrl[6] + "/" + splitUrl[7] : null

const splitUrlLocAr = splitUrl.length == 7 ? decodeURI(splitUrl[6]) : splitUrl.length == 8 ? decodeURI(splitUrl[6]) + "/" + decodeURI(splitUrl[7]) : splitUrl.length == 9 ? decodeURI(splitUrl[6]) + "/" + decodeURI(splitUrl[7]) + "/" + decodeURI(splitUrl[8]) : null

const splitUrlLoc = lang == "en" ? splitUrlLocEn : splitUrlLocAr

const google = window.google;
// The location of Uluru
const loader = new Loader({
  apiKey: "AIzaSyCq6FzlBsSuT-S2zaRh2Rd-i4205DCu57s",
  // libraries: ["places"]
});
const UAE_BOUND = {
  north: 26.037042,
  south: 23.765237,
  west: 53.211269,
  east: 56.896095,
};

const priceRangeFromForRent = [
  0, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 200000,
  300000, 400000, 500000, 600000, 700000, 800000, 900000,
];
const priceRangeToForRent = [
  30000, 50000, 60000, 70000, 80000, 90000, 100000, 200000, 300000, 400000,
  500000, 600000, 700000, 800000, 900000, 100000, 1100000, 1200000,
];
const priceRangeFromForSale = [
  0, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000,
  2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000,
];
const priceRangeToForSale = [
  300000, 500000, 600000, 700000, 800000, 900000, 1000000, 2000000, 3000000,
  4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 1000000, 11000000,
  12000000,
];

var language = lang == "ar" ? "ar/" : ""

const propertylocation = $("#propertyLocation").val();
var typeNameforSeo = $("#propertyType option:selected").text()


var slug = lang == "en" ? "slug_en" : "slug_ar"
// var propertyTypeNameAr = propertyTypeObj.find( ({ type_name_ar }) => type_name_ar === decodeURI(propertyTypeName) ).type_name_ar;
// var propertyTypeNameEn = propertyTypeObj.find( ({ type_name_en }) => type_name_en === propertyTypeName ).type_name_en;
// propertyTypeName = lang== "en"? propertyTypeNameEn: decodeURI(propertyTypeNameAr)

// //console.log("propertyTypeName", decodeURI(propertyTypeName))

export default class MapCluster extends Component {

  constructor(props) {
    super(props);
    this.state = {
      propertyStyle: 0,
      propertytypes: [],
      scrollStyle: {},
      location: [],
      propertyPurpose: "Rent",
      selected: [],
      features: [],
      open: false,
      locationValue: [],
      locations: [],
      filteredProperties: [],
      phone: "",
      email: "",
      agentName: "",
      propertyTypeName: this.props.location.state ? this.props.location.state["passPropTypeName"] : decodeURI(propertyTypeName),
      offset: 0,
      data: [],
      perPage: 9,
      currentPage: 1,
      purpose: this.props.location.state ? this.props.location.state["passPurpose"] : decodeURI(propPurposeFromUrl),
      propertyType: this.props.location.state ? this.props.location.state["passType"] : decodeURI(propertyTypeName),
      // purpose: propPurposeFromUrl,
      // propertyType: propertyTypeName,
      pageContent: "",
      bathRoomSelected: 0,
      bedRoomSelected: this.props.location.state ? this.props.location.state["bedroom"] : decodeURI(bedroomFromUrl),
      frequencySelected: 0,
      popular: "",
      furnished: -1,
      priceFrom: 0,
      priceTo: 0,
      locforUrl: this.props.location.state ? this.props.location.state["locForUrl"] : decodeURI(locationForUrl),
      selectedLocations: lang == "en" ?
        locationForUrl == "uae" || "" || "الامارات" ? [] : [{ slug_en: splitUrlLoc }] :
        locationForUrl == "uae" || "" || "الامارات" ? [] : [{ slug_ar: splitUrlLoc }],
      selectedFeatures: [],
      furnishedActive: "furnishedActive",
      subLocationSend: locationForUrl == "uae" || "" || "الامارات" ? [] : { slug_en: decodeURI(locationForUrl), slug_ar: decodeURI(locationForUrl), type: 1 },
      subLocationArr: lang == "en" ?
        locationForUrl == "uae" || "" || "الامارات" ? [] : [{ slug_en: decodeURI(locationForUrl), type: 2 }] :
        locationForUrl == "uae" || "" || "الامارات" ? [] : [{ slug_ar: decodeURI(locationForUrl), type: 2 }],
      isLoading: false,
      checked: false,
      displayType: 0,
      priceActive: "active filter-input",
      furnishedornot: this.props.location.state ? this.props.location.state["furnishedornot"] : 0,
      showMobilefilter: false,
      bedbathview: 1,
      center: { lat: 23.024, lng: 53.887 }
    };
  }

  cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
  }

  decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
  }



  fetchByPropertyId = (id) => {
    API.get("show-property/" + id)
      .then((response) => {
        console.log("signle", response.data)
      })

  }

  showVisibleMarkers = () => {
    var bounds = map.getBounds(),
      count = 0;
    for (var i = 0; i < markers.length; i++) {
      console.log("markers [i]:", markers[i].position.lat())
    }

  }
  zoomChanged = (map) => {
 
    google.maps.event.addListener(map, 'zoom_changed', function () {
      var bounds = map.getBounds();
      var ne = bounds.getNorthEast(); // LatLng of the north-east corner
      var sw = bounds.getSouthWest();
      var nw = ne.LatLng(ne.lat(), sw.lng());
      var se = bounds.LatLng(sw.lat(), ne.lng());        // var ne = bounds.getNorthEast();
      // var sw = bounds.getSouthWest();

      console.log("bounds", bounds)
    });
  }


  initMap = async (data) => {
    const mapOptions = {
      center: this.state.center,
      zoom: w < 900 ? 6 : 5,
      // gestureHandling: 'greedy',
      restriction: {
        latLngBounds: UAE_BOUND,
        strictBounds: false,
      }
    };
    await API.post(`filterproperties?page=${this.state.currentPage}&perPage=${this.state.perPage}`, data)
      .then((response) => {
        // this.setState({ isLoading: false })
        loader.load()
          .then((google) => {
            var infoWin = new google.maps.InfoWindow();
            const map = new google.maps.Map(document.getElementById("map"), mapOptions);
            var infoWin = new google.maps.InfoWindow();
            var bounds = map.getBounds();
            google.maps.event.addListener(map, 'zoom_changed', function () {
              var bounds = map.getBounds();
              var ne = bounds.getNorthEast(); 
              var sw = bounds.getSouthWest();
              var nw = ne.LatLng(ne.lat(), sw.lng());
              var se = bounds.LatLng(sw.lat(), ne.lng());     
              console.log("bounds", bounds)
            });

            var reponseData = response.data.filteredproperties.data;

            const markers = reponseData.map((location, i) => {
           

              var marker = new google.maps.Marker({
                position: location
              });
            
              marker.addListener("click", () => {
           
                map.setCenter(marker.getPosition());
                map.setZoom(map.getZoom() + 2);

                const InfoWindowContent = (
                  <div className="infoMapWindow">
                    {location.price} AED - {location.typeName_en}
                    <div>
                      <i className='flaticon-bedroom' />
                      <span>
                        {location.beds == 0 ? "Studio" : location.beds}
                      </span>
                      {location.baths != 0 ? (
                        <div>
                          <i className='flaticon-bathroom' />
                          <span>
                            {location.baths}
                          </span>
                        </div>
                      ) : null}
                      <i className='flaticon-ruler' />
                      <span className='acr-listing-icon-value'>
                        {location.area}
                      </span>
                    </div>
                  </div>
                );
                const content = ReactDOMServer.renderToString(InfoWindowContent);

                infoWin.setContent(content);
                infoWin.open(map, marker);
              })
              return marker;

            });
            console.log("markers", markers)
            for (var i = 0; i < markers.length; i++) {
              // console.log("markers [i]:", markers[i].position.lat() )
            }

            // let bounds = map.getBounds()

            markers.filter(m => m.isAdded).forEach(m => {
              if (bounds.contains(m.getPosition())) {
                // code for showing your object, associated with current marker
                console.log("markers [i]:", markers[i].position.lat())

              }
            })
            // Add a marker clusterer to manage the markers.
            var markerClusterer = new MarkerClusterer({ markers, map });
            google.maps.event.addListener(markerClusterer, 'clusterclick', function (cluster) {
              // map.setCenter(this.state.center);
              map.setZoom(map.getZoom() + 1);
            });
            google.maps.event.addListener(markerClusterer, "mouseover", function (c) {
              $(c.clusterIcon_.div_).effect('bounce', { times: 3 }, 'slow');
            });

           // this.setState({ isLoading: true })

          })
          .catch(e => {
            // do something
          });
      })

  }



  callClickProperty = (property, e) => {
    // this.setState({
    //   phone: property.mobile,
    //   agentName: property.name,
    // });
    // //console.log("mobile", property.id + property.mobile + property.agent_id)
    const sender = "Find Properties"
    const message = "You have got a call from Find Properties for your property" + " https://findproperties.ae/single-property/" + property.id

    API.post("https://elitbuzz-me.com/sms/smsapi?api_key=C200343061a1e16b4924a3.21883164&type=text&contacts=00" + property.mobile + "&senderid=MFRE&msg=" + message
    ).then((response) => {
      // //console.log("message send", response.data)
    });

    //   vonage.message.sendSms(sender, 971547963078, message, (err, responseData) => {
    //     if (err) {
    //         //console.log(err);
    //     } else {
    //         if(responseData.messages[0]['status'] === "0") {
    //             //console.log("Message sent successfully.");
    //         } else {
    //             //console.log(`Message failed with error: ${responseData.messages[0]['error-text']}`);
    //         }
    //     }
    // })

    const data = { property_id: property.id }
    API.post("calltrack", data
    ).then((response) => {
      // //console.log("Call Clicked", response.data)
    });
  }

  mobileFilterClose = () => {
    this.setState({
      showMobilefilter: false
    })
  }

  mobileFilterOpen = () => {
    this.setState({
      showMobilefilter: true
    })
  }
  mobilefilter = () => {
    $(".filterWindowMob").toggleClass('open');
    $("body").toggleClass('aside-open');
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1,data);
    var purpose = this.state.purpose == 1 ? i18next.t("forrent").toLowerCase() : i18next.t("forsale").toLowerCase()
    // $("#propertyPurpose").find(":selected").text().toLowerCase();
    var bedroom = $("#bedroom").find(":selected").val() > 0 ? $("#bedroom").find(":selected").text() + " bedroom" : "Studio";
    if ($("#bedroom").find(":selected").val() == -1 || $("#bedroom").find(":selected").val() == undefined) { bedroom = '' }

    const urlPurpose = {
      purpose: this.state.purpose == 1 ? 'for-rent' : 'for-sale',
      propertyType: "/" + $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + "/" + this.state.propertyType + "/",
      subLocationSend: this.state.subLocationSend,
    };
    // //console.log("Url Purpose", urlPurpose)
    // this.propertyUrl(urlPurpose)
  }

  subLocationClickHandler(subLocation, e) {
    const locTest = [];
    locTest.push(subLocation);

    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      subLocationSend: subLocation,
      popular: this.state.popular,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: locTest,
      selectedFeatures: this.state.selectedFeatures,
    };

    this.setState({
      subLocationSend: subLocation,
      selectedLocations: locTest,
      locforUrl: subLocation.location
    });

    // //console.log("sublocation click", locTest)

    this.fetchPropertyData(1,data);

    var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('name')
    const urlPurpose = {
      purpose: $("#propertyPurpose").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-') + "/",
      propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + $("#propertyType").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-') + "/",
      subLocationSend: locTest,
    };
    // this.propertyUrl(urlPurpose)
  }

  newestPropetyOnClick = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      popular: "newest",
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      popular: "newest",
    });
    this.fetchPropertyData(1,data);
  };
  lowestPriceFilteronClick = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      popular: "lowest",
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      popular: "lowest",
    });
    this.fetchPropertyData(1,data);
  };
  highestPriceonClick = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      popular: "highest",
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      popular: "highest",
    });
    this.fetchPropertyData(1,data);
  };

  propertyUrl = (data) => {
    var type = data.propertyType
    var purpose = data.purpose
    var language = lang == "ar" ? "ar/" : ""

    // //console.log("data utl", data)

    // var locOnLoad = 

    var locinit = lang == "en" ? "uae" : "الامارات"
    var location = data.subLocationSend[0] === undefined || data.subLocationSend.length == 0 ? locinit : lang == "en" ? data.subLocationSend[0].slug_en : data.subLocationSend[0].slug_ar;

    data = purpose + type + location
    // //console.log("sublocation send", data)

    this.props.history.push("/" + language + data)
    //  window.location.reload()
  }

  propertyPurposeChangleHandle = (e) => {
    //console.log("property Purpose value", e.target.value)
    e.preventDefault()
    this.setState({
      purpose: e.target.value,
      // frequencyView: 'none' 
    });
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: e.target.value,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1,data);

    API.get("propertytypeByPurpose/" + e.target.value)
      .then((response) => {
        this.setState({
          // isLoading: false,
          propertytypes: response.data.propertytypes,

        });
      })
      .catch();

    // e.target.value==1?$('#frequencyview').show():$('#frequency').hide();
    // $('#propertyType option[value='+e.target.value+']').prop("selected", true);
    var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('name')
    const urlPurpose = {
      purpose: e.target.value == 1 ? i18next.t("for-rent") + "/" : e.target.value == 2 ? i18next.t("for-sale") + "/" : i18next.t("for-sharing") + "/",
      // e.target.value == 1 ||]? lang == "en" ? "for-rent" + "/" : "للايجار" + "/" : lang == "en" ? "for-sale" + "/" : "للبيع" + "/"
      propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + this.state.propertyType + "/",
      // propertyType: e.target.value ==0? "studio-"+ this.state.propertyType+"/":e.target.value+"-bedroom-" + this.state.propertyType+"/",
      subLocationSend: this.state.selectedLocations
    };
    // //console.log("Url Purpose", this.state.selectedLocations[0].location)
    // this.propertyUrl(urlPurpose)
    // //console.log("selected purpose", e.target.value)
  };
  propertyTypeOnChangeHadnler = (e) => {
    // this.prop.routeChange("/"+e.target.value)
    this.setState({
      propertyType: e.target.selectedOptions[0].getAttribute('name'),
      furnishedornot: e.target.selectedOptions[0].getAttribute('furnishedornot'),
      bedbathview: e.target.selectedOptions[0].getAttribute('bedroomBath'),
      propertyTypeName: $("#propertyType").find(":selected").attr('typeSelected')
    });

    //console.log("Property type on chagn", e.target.selectedOptions[0].getAttribute('name'))
    // //console.log("Property Furnished", e.target.selectedOptions[0].getAttribute('furnishedornot'));
    // //console.log("property Type", e.target.selectedOptions[0].getAttribute('name'))
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: e.target.selectedOptions[0].getAttribute('name'),
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };

    // //console.log("send Property type change data", data)

    // var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('name')
    const urlPurpose = {
      purpose: $("#propertyPurpose").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-') + "/",
      propertyType: e.target.selectedOptions[0].getAttribute('name') + "/",
      // propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + $("#propertyType").find(":selected").attr('name') + "/",
      subLocationSend: this.state.selectedLocations,
      language: lang
    };
    this.fetchPropertyData(1,data);

    // //console.log("propertyType on Change", $("#propertyType").find(":selected").attr('name'))
    // //console.log("slg prop", window.location.href.split("/")[4] )
    // this.propertyUrl(urlPurpose)
    // //console.log('urlPurpose', urlPurpose)
  };

  frequencyOnChangeHandler = (e) => {
    this.setState({
      frequencySelected: e.target.value,
    });
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: e.target.value,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1,data);
  };
  bathroomOnChangeHadnler = (e) => {
    this.setState({
      bathRoomSelected: e.target.value,
    });
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: e.target.value,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1,data);
  };
  bedroomOnChangeHadnler = (e) => {
    this.setState({
      bedRoomSelected: e.target.value,
    });
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: this.state.furnished,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: e.target.value,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    // //console.log("bedroom a", data);
    this.fetchPropertyData(1,data);
    var bedroom = $("#bedroom").find(":selected").attr('name') === undefined ? "" : $("#bedroom").find(":selected").attr('name')
    const urlPurpose = {

      purpose: $("#propertyPurpose").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-') + "/",
      propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + $("#propertyType").find(":selected").attr('name') + "/",
      subLocationSend: this.state.selectedLocations,
      language: lang

    };
    // this.propertyUrl(urlPurpose)

  };

  // selectedFeatureHandle = (e) => {
  //   this.setState({ selectedFeatures: e });
  //   const data = {
  //     priceFrom: this.state.priceFrom,
  //     priceTo: this.state.priceTo,
  //     furnished: this.state.furnished,
  //     subLocationSend: this.state.subLocationSend,
  //     popular: this.state.popular,
  //     bathRoomSelected: this.state.bathRoomSelected,
  //     bedRoomSelected: this.state.bedRoomSelected,
  //     propertyType: this.state.propertyType,
  //     purpose: this.state.purpose,
  //     frequencySelected: this.state.frequencySelected,
  //     selectedLocations: this.state.selectedLocations,
  //     selectedFeatures: e,
  //   };
  //   this.fetchPropertyData(data);
  // };
  HandlePropertyStyle = (id, e) => {
    e.preventDefault();
    this.setState({ propertyStyle: id });
  };
  furnishedUnfurnishedAll = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: -1,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({ furnished: -1 });
    document
      .getElementById("allFurnishedStatus")
      .classList.add("furnishedActive");
    document
      .getElementById("furnishedOnly")
      .classList.remove("furnishedActive");
    document
      .getElementById("unfurnishedOnly")
      .classList.remove("furnishedActive");
    this.fetchPropertyData(1,data);
  };
  furnishedClickHandle = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: 1,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      furnished: 1,
      furnishedActive: this.state.furnishedActive,
    });
    document
      .getElementById("allFurnishedStatus")
      .classList.remove("furnishedActive");
    document.getElementById("furnishedOnly").classList.add("furnishedActive");
    document
      .getElementById("unfurnishedOnly")
      .classList.remove("furnishedActive");
    this.fetchPropertyData(1,data);
  };
  unfurnishedClickHandle = () => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      furnished: 0,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({ furnished: 0 });
    document
      .getElementById("allFurnishedStatus")
      .classList.remove("furnishedActive");
    document
      .getElementById("furnishedOnly")
      .classList.remove("furnishedActive");
    document.getElementById("unfurnishedOnly").classList.add("furnishedActive");
    this.fetchPropertyData(1,data);
  };
  handleWishList = (e) => {
    const userdata = store.getState(state => state);
    const token = userdata.auth.token
    const isLoggedIn = userdata.auth.loggedIn
    if (isLoggedIn) {
      $("body").removeClass('modal-open');
      $("#login").removeClass('show');
      $("body").remove('modal-backdrop')
      $("#login").css("display", "none");

      const data = { property_id: e.target.value };
      const dataForCheckBox = {
        property_id: e.target.value,
        subLocationSend: this.state.subLocationSend,
        furnished: this.state.furnished,
        popular: this.state.popular,
        bathRoomSelected: this.state.bathRoomSelected,
        bedRoomSelected: this.state.bedRoomSelected,
        propertyType: this.state.propertyType,
        purpose: this.state.purpose,
        frequencySelected: this.state.frequencySelected,
        selectedLocations: this.state.selectedLocations,
        selectedFeatures: this.state.selectedFeatures,
      };
      API.post("wishlist", data).then((response) => {
        $("input[value=" + e.target.value + "]").trigger("click");
      });

    }
    else {
      alert("not logged in")
      $("#login").children('.modal-dialog').children('.modal-content').children('.modal-header').children('.close').focus();
      // e.preventDefault();
      // $.noConflict();
      $(".ti-close").focus();
      $("body").addClass('modal-open');
      $("#login").addClass('show');
      $("#login").css("display", "block");
      $("#login").attr("role", "dialog");
      $("body").append('<div className="modal-backdrop fade show"></div>')
      // window.$('#login').modal().show();
    }
    //  $('#login').click();
  }

  displayTypeHandler = (e, displayType) => {
    var i = 0;
    var btn = document.getElementsByClassName("displayType");
    for (i; i < btn.length; i++) {
      if (i != displayType) btn[i].classList.remove("active");
      if (i == displayType) btn[i].classList.add("active");
    }
    if (displayType == 0) {
      this.setState({
        displayType: 0,
      });
    } else {
      this.setState({
        displayType: 1,
      });
    }
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      displayType: this.state.displayType,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      popular: this.state.popular,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(1,data);
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected+1;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        
      });
        const data = {
          displayType: this.state.displayType,
          subLocationSend: this.props.location.state
            ? this.props.location.state["passsubLocationSend"]
            : this.state.subLocationSend,
          furnished: this.state.furnished,
          popular: this.state.popular,
          bathRoomSelected: this.props.location.state
            ? this.props.location.state["passBath"]
            : this.state.bathRoomSelected,
          bedRoomSelected: this.props.location.state
            ? this.props.location.state["passBed"]
            : this.state.bedRoomSelected,
          propertyType: this.props.location.state ? this.props.location.state["passType"] : this.state.propertyType,
          purpose: this.props.location.state
            ? this.props.location.state["passPurpose"]
            : this.state.purpose,
          frequencySelected: this.props.location.state
            ? this.props.location.state["passFrequency"]
            : this.state.frequencySelected,
          selectedLocations: this.props.location.state
            ? this.props.location.state["passLocation"]
            : this.state.selectedLocations,
          priceFrom: this.props.location.state
            ? this.props.location.state["passPricefrom"]
            : this.state.priceFrom,
          priceTo: this.props.location.state
            ? this.props.location.state["passPriceto"]
            : this.state.priceTo,
          subLocationArr: this.props.location.state
            ? this.props.location.state["passsubLocationSend"] :
            this.state.subLocationArr,
          selectedFeatures: this.state.selectedFeatures,
          displayType: this.state.displayType,
        };
        
        
          
          this.fetchPropertyData(selectedPage,data);
  };

  fetchPropertyData = async (currentPage,data) => {
    // //console.log("data before fetch", data);
    this.initMap(data)

    this.setState({ isLoading: true });
    const start = Date.now()

    await API.post(`filterproperties?page=${currentPage}&perPage=${this.state.perPage}`, data)
      .then((response) => {
        this.setState({ isLoading: false });
        if(this.state.propertytypes.length < 1){
          this.fetchData();
        }

        if(this.state.locations.length < 1){
          API.get("locations")
          .then((response) => {
            this.setState({
              //isLoading: true,
              locations: lang == "en" ? response.data.locations : response.data.locations_ar,
            
            });
         
            var splitUrl = url.split("/");
            let slug_en_from_url;
            for (let i = 0; i < response.data.locations.length; i++) {
    
              if (response.data.locations[i].slug_en == splitUrlLoc) {
                this.setState({
                  selectedLocations: [response.data.locations[i]],
                  subLocationSend: response.data.locations[i],
                  subLocationArr: [response.data.locations[i]],
                })
              }
              else if (response.data.locations_ar[i].slug_ar == decodeURI(splitUrlLoc)) {
                this.setState({
                  selectedLocations: [response.data.locations_ar[i]],
                  subLocationSend: response.data.locations_ar[i],
                  subLocationArr: [response.data.locations_ar[i]],
                })
              }
            }
    
            window.addEventListener("scroll", this.handleScroll);
           
          })
          .catch();
        
    
        $('#bedroom option[value=' + decodeURI(bedroomFromUrl) + ']').prop("selected", true);
    
  
    
    
       }
      
        this.setState({
          isLoading: false,
          // locations: lang == "en" ? response.data.locations : response.data.locations_ar,
          filteredProperties: response.data.filteredproperties.data,
          subLocations: response.data.property_location,
          // subLocationArr: response.data.sublocation,
          pageContent: response.data.pages,
          subLocationArr: lang == "en" ? response.data.sublocation : response.data.sublocation_ar,
          displayType: this.state.displayType,
          pageCount: (response.data.filteredproperties.total) / this.state.perPage
        });
        const data = response.data.filteredproperties.data;
        // //console.log("response filter", response.data);
        // //console.log("display Type", this.state.displayType)
        // //console.log("content", this.state.pageContent)
        // const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = data.map((property, i) => (
          <React.Fragment>
            {this.state.displayType == 0 ? (
              <>
                <div className="listing similar d-none d-lg-block d-md-block d-xl-block" aria-hidden="false">
                  <div className="listing-thumbnail">
                    <a
                      href={"/" + language + "single-property/" + property.id}
                      style={{ direction: lang == "en" ? "ltr" : "rtl" }}>
                      <img
                        src={
                          baseurlImg +
                          "/uploads/properties/" +
                          property.image
                        }
                        alt
                      />
                    </a>
                  </div>
                  <div className="listing-body">
                    <div className="flex">
                      <span className="listing-price">
                        {property.price.toLocaleString()}
                        <span>{i18next.t("aed")}
                          {property.rent_frequency == 1 ? <span> {i18next.t("yearly")}</span> : property.rent_frequency == 2 ? <span> {i18next.t("monthly")}</span> : property.rent_frequency == 3 ? <span> {i18next.t("weekly")}</span> : property.rent_frequency == 3 ? <span> {i18next.t("daily")}</span> : null}
                        </span>
                      </span>
                      <span className="typeName">
                        {lang == "en"
                          ? property.typeName_en
                          : property.typeName_ar}
                      </span>
                    </div>
                    <h5 className="listing-title">
                      <a
                        href={"/" + language + "single-property/" + property.id}
                        style={{
                          direction: lang == "en" ? "ltr" : "rtl",
                        }}>
                        {lang == "en"
                          ? property.title_en
                          : property.title_ar}
                      </a>
                    </h5>
                    <div
                      style={{ direction: lang == "en" ? "ltr" : "rtl" }}
                      className="acr-listing-icons"
                    >
                      {property.beds > -1 ?
                        <div
                          className="acr-listing-icon"
                          data-toggle="tooltip"
                          title="Beds"
                        >
                          <i className="flaticon-bedroom" />
                          <span className="acr-listing-icon-value">
                            {property.beds == 0 ? i18next.t("studio") : property.beds}
                          </span>
                        </div>
                        : null}
                      {property.baths > 0 ?
                        <div
                          style={{
                            direction:
                              lang == "en"
                                ? "ltr!important"
                                : "rtl!important",
                          }}
                          className="acr-listing-icon"
                          data-toggle="tooltip"
                          title="Bathrooms"
                        >
                          <i className="flaticon-bathroom" />
                          <span className="acr-listing-icon-value">
                            {property.baths}
                          </span>
                        </div>
                        : null}
                      {property.area > -1 ?

                        <div
                          className="acr-listing-icon"
                          data-toggle="tooltip"
                          title="Square Feet">
                          <i className="flaticon-ruler" />
                          <span className="acr-listing-icon-value">
                            {property.area}
                          </span>
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
                <div className="d-block d-sm-none">
                  <div className='listing listing-list'>
                    <div className='listing-thumbnail col-sm-2'>
                      <a href={"/" + language + "single-property/" + property.id}>
                        <img
                          src={baseurlImg + "/uploads/properties/" + property.image}
                          alt='listing'
                        />
                      </a>
                      <div className='listing-controls'>
                        <div className='_card_flex_last'>
                          <div className='prt_saveed_12lk'>
                            <label className='toggler toggler-danger'>
                              <input onChange={this.handleWishList} value={property.id} type='checkbox' checked={(property.wishlist_status == 1 ? true : false) || this.state.checked} />
                              <i className='ti-heart' />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='listing-body call-buttons'>
                      <div className='flex'>
                        <span
                          className={property.purpose == 1 ? "listing-badge rent" : "listing-badge sale"}>
                          {" "}
                          {property.purpose == 1 ? i18next.t("forrent") : property.purpose == 2 ? i18next.t("forsale") : property.purpose == 3 ? i18next.t("sharing") : null}
                        </span>
                        <span className='right listing-price'>
                          {property.price.toLocaleString()}
                          <span>{i18next.t("aed") + " "}
                            {property.frequency == 1 ? <span> {i18next.t("yearly")}</span> : property.frequency == 2 ? <span> {i18next.t("monthly")}</span> : property.frequency == 3 ? <span> {i18next.t("weekly")}</span> : property.frequency == 3 ? <span> {i18next.t("daily")}</span> : null}
                          </span>
                        </span>
                      </div>
                      <div style={{ position: 'relative' }} className="flex">
                        <span style={{ fontSize: '0.7rem', color: '#aaa', textTransform: 'uppercase' }}>
                          {lang == "en"
                            ? property.typeName_en
                            : property.typeName_ar}
                        </span>
                        {property.package_type == 2 ?
                          <span className="featured-property" >
                            {i18next.t("featured")}
                          </span> : null}
                        {property.package_type == 3 ?
                          <span className="premium-property" >
                            {i18next.t("premium")}
                          </span> : null}
                      </div>
                      <h5 className='listing-title'>
                        <a href={"/" + language + "single-property/" + property.id}>
                          {lang == "en" ?
                            property.title_en : property.title_ar}
                        </a>
                      </h5>
                      <h5 className='listing-title'>
                        <a style={{ color: "#555" }}>
                          <i className="fa fa-map-marker"></i>
                          {lang == "en" ?
                            " " + property.area_en + ", " + property.emirate_en : property.area_ar + ", " + property.emirate_ar}
                        </a>
                      </h5>
                      <div className='acr-listing-icons'>
                        {property.beds > -1 ?
                          <div
                            className='acr-listing-icon'
                            data-toggle='tooltip'
                            title='Beds'
                          >
                            <i className='flaticon-bedroom' />
                            <span className='acr-listing-icon-value'>
                              {property.beds == 0 ? i18next.t("studio") : property.beds}
                            </span>
                          </div> : null}
                        {property.baths > 0 ? (
                          <div
                            className='acr-listing-icon'
                            data-toggle='tooltip'
                            title='Bathrooms'>
                            <i className='flaticon-bathroom' />
                            <span className='acr-listing-icon-value'>
                              {property.baths}
                            </span>
                          </div>
                        ) : null}
                        {property.area > 0 ?
                          <div
                            className='acr-listing-icon'
                            data-toggle='tooltip'
                            title='Square Feet'>
                            <i className='flaticon-ruler' />
                            <span className='acr-listing-icon-value'>
                              {property.area} {i18next.t("sqft")}
                            </span>
                          </div>
                          : null}
                      </div>
                      <div className='listing-author'>
                        <div className='listing-gallery-wrapper'>
                          <a
                            href={"tel: 00" + property.mobile}
                            // data-bs-toggle='modal'
                            // data-bs-target='#callModal'
                            onClick={this.callClickProperty.bind(this, property)}
                            className='btn-custom btn-sm secondary'
                            tabIndex={0}>
                            <i className='fa fa-phone' aria-hidden='true'>
                              {" "}
                            </i>
                            &nbsp;&nbsp;Call&nbsp;&nbsp;&nbsp;&nbsp;
                          </a>
                        </div>
                        {/* <div className="listing-gallery-wrapper d-lg-none d-sm-block d-md-none">
                    <a
                      href="listing-details-v1.html"
                      className="btn-custom btn-sm secondary"
                      tabIndex={0}>
                      <i className="fas fa-envelope"></i>
                    </a>
                  </div> */}
                        <div className='listing-gallery-wrapper'>
                          <a href={"mailto:" + property.email} onClick={() => { this.setState({ email: property.email }); }} className='btn-custom btn-sm secondary' tabIndex={0} >
                            <i className='fas fa-envelope'></i> &nbsp; Email
                          </a>
                        </div>

                        <div className='listing-gallery-wrapper'>
                          <a
                            href={"https://wa.me/" + property.mobile + "?text=Hey%20" + property.name_en + ", %20I am%20interested%20in%20getting%20more%20information%20about%20this%20property%20you%20posted%20on%20Find%20Properties, Link:" + websitebaseurl + "single-property/" + property.id + ""}
                            onClick={() => {
                              // this.setState({ email: property.mobile });
                              // const message= "You have got a what's app message from FindProperties for your property"+ "https://findproperties/single-property/"+property.id     
                              const data = { property_id: property.id }
                              API.post("whatsapptrack", data
                              ).then((response) => {
                                // //console.log("message send", response.data)
                              });
                            }}
                            className='btn-custom btn-sm secondary'
                            tabIndex={0}>
                            <i className='fa fa-whatsapp'></i>
                          </a>
                        </div>
                        <div className='d-none d-xs-none d-sm-none d-md-block'>
                          <Link to={"/" + language + "single-property/" + property.id}>
                            <img
                              src={
                                property.logo == undefined
                                  ? baseurlImg + "/uploads/profiles/no_avatar.png"
                                  : baseurlImg +
                                  "/uploads/profiles/" +
                                  property.logo
                              }
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='listing gridProperty col-sm-6'>
                <div className='listing-thumbnail'>
                  <Link
                    to={"/" + language + "single-property/" + property.id}
                    style={{ dir: lang == "en" ? "ltr" : "rtl" }}>
                    <img
                      src={baseurlImg + "/uploads/properties/" + property.image}
                      alt=""
                    />
                  </Link>
                  <div className='listing-badges'>
                    {/* <span className='listing-badge featured'>
                      <i className='fas fa-star' />{" "}
                    </span> */}
                    <span
                      className={
                        property.purpose == 1 || property.purpose == 3
                          ? "listing-badge rent"
                          : "listing-badge sale"
                      }>
                      {property.purpose == 1 ? i18next.t("forrent") : property.purpose == 2 ? i18next.t("forsale") : property.purpose == 3 ? i18next.t("sharing") : null}
                    </span>
                  </div>
                  <div className='listing-controls'>
                    <div className='_card_flex_last'>
                      <div className='prt_saveed_12lk'>
                        <label className='toggler toggler-danger'>
                          <input
                            onChange={this.handleWishList}
                            value={property.id}
                            type='checkbox'
                            checked={
                              (property.wishlist_status == 1 ? true : false) ||
                              this.state.checked
                            }
                          />
                          <i className='ti-heart' />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='listing-body'>
                  <div className='flex'>
                    <span className='listing-price'>
                      {property.price.toLocaleString()}
                      <span>{i18next.t("aed")}</span>
                    </span>
                    <span className='right'>{property.typeName_en}</span>
                  </div>
                  <h4>{property.area_en}</h4>
                  <h5 className='listing-title'>
                    <a
                      href={"/" + language + "single-property/" + property.id}
                      style={{ direction: lang == "en" ? "ltr" : "rtl" }}
                    >
                      {lang == "en" ? property.title_en : property.title_ar}
                    </a>
                  </h5>
                  <div
                    style={{ direction: lang == "en" ? "ltr" : "rtl" }}
                    className='acr-listing-icons'>
                    {property.beds > -1 ?
                      <div
                        className='acr-listing-icon'
                        data-toggle='tooltip'
                        title='Beds'>
                        <i className='flaticon-bedroom' />
                        <span className='acr-listing-icon-value'>
                          {property.beds == 0 ? i18next.t("studio") : property.beds}
                        </span>
                      </div> : null}
                    {property.baths > 0 ?
                      <div
                        style={{
                          direction:
                            lang == "en" ? "ltr!important" : "rtl!important",
                        }}
                        className='acr-listing-icon'
                        data-toggle='tooltip'
                        title='Bathrooms'>
                        <i className='flaticon-bathroom' />
                        <span className='acr-listing-icon-value'>
                          {property.baths}
                        </span>
                      </div> : null}
                    {property.area > 0 ?
                      <div
                        className='acr-listing-icon'
                        data-toggle='tooltip'
                        title='Square Feet'>
                        <i className='flaticon-ruler' />
                        <span className='acr-listing-icon-value'>
                          {property.area}
                        </span>
                      </div> : null}
                  </div>

                </div>
              </div>
            )}
          </React.Fragment>
        ));
        this.setState({
          pageCount: this.state.pageCount, postData
        });
      })
      .catch((error) =>
        console.log("Network Error")
      );
  };

  componentDidMount() {

    const data = {
      displayType: this.state.displayType,
      subLocationSend: this.props.location.state
        ? this.props.location.state["passsubLocationSend"]
        : this.state.subLocationSend,
      furnished: this.state.furnished,
      popular: this.state.popular,
      bathRoomSelected: this.props.location.state
        ? this.props.location.state["passBath"]
        : this.state.bathRoomSelected,
      bedRoomSelected: this.props.location.state
        ? this.props.location.state["passBed"]
        : this.state.bedRoomSelected,
      propertyType: this.props.location.state
        ? this.props.location.state["passType"] : decodeURI(propertyTypeName),
      purpose: this.props.location.state
        ? this.props.location.state["passPurpose"] : decodeURI(propPurposeFromUrl),
      frequencySelected: this.props.location.state
        ? this.props.location.state["passFrequency"]
        : this.state.frequencySelected,
      selectedLocations: this.props.location.state
        ? this.props.location.state["passLocation"]
        : this.state.selectedLocations,
      priceFrom: this.props.location.state
        ? this.props.location.state["passPricefrom"]
        : this.state.priceFrom,
      priceTo: this.props.location.state
        ? this.props.location.state["passPriceto"]
        : this.state.priceTo,
      subLocationArr: this.state.subLocationArr,
      selectedFeatures: this.state.selectedFeatures,
      displayType: 0,
    };
    
   
    this.fetchPropertyData(1,data);

  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = (e) => {
    if (window.scrollY === 0) {
      this.setState({ scrollStyle: {} });
    } else if (window.scrollY > 0) {
      this.setState({
        scrollStyle: {
          position: "fixed",
          top: "0",
          zIndex: "999",
          background: "#fff",
          padding: "10px",
          width: "100%",
          left: 0,
        },
      });
    }
  };

  handleLocation = (e) => {
    e.preventDefault();
  };

  collapseHadler = () => {
    this.setState({ open: !this.state.open });
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.setState({ location: e.target.value });
    }
  };

  priceFromHandler = (id, e) => {
    const data = {
      priceFrom: e.target.value,
      priceTo: this.state.priceTo,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };

    this.setState({
      priceFrom: e.target.value,
    });
    this.fetchPropertyData(data);
  };

  priceToHandler = (id, e) => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: e.target.value,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      priceTo: e.target.value,
    });
    this.fetchPropertyData(data);
  };
  priceFromOnChange = (e) => {
    const data = {
      priceFrom: e.target.value,
      priceTo: this.state.priceTo,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };

    this.setState({
      priceFrom: e.target.value,
    });
    this.fetchPropertyData(data);
  };
  priceToOnChange = (e) => {
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: e.target.value,
      popular: this.state.popular,
      subLocationSend: this.state.subLocationSend,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.setState({
      priceTo: e.target.value,
    });
    this.fetchPropertyData(data);
  };
  fetchData = () => {
    //this.setState({ isLoading: true });

    //console.log("property Types new", this.state.purpose)

    API.get("propertytypeByPurpose/" + this.state.purpose)
      .then((response) => {
        this.setState({
          // isLoading: false,
          propertytypes: response.data.propertytypes,

        });
      })
      .catch();

    // API.get(baseurl + "features")
    //   .then((response) => {
    //     //  //console.log(response.data)
    //     this.setState({
    //       // isLoading: false,
    //       features:
    //         lang == "en"
    //           ? response.data.allfeatures_en
    //           : response.data.allfeatures_ar,
    //     });
    //   })
    //   .catch();

  };
  filterCanvas = () => {
    $(".filterWindowMob").toggleClass('open');
    $("body").toggleClass('aside-open');
  }
  closeFilter = () => {
    $(".filterWindowMob").toggleClass('open');
    $("body").toggleClass('aside-open');
    const data = {
      priceFrom: this.state.priceFrom,
      priceTo: this.state.priceTo,
      subLocationSend: this.state.subLocationSend,
      popular: this.state.popular,
      furnished: this.state.furnished,
      bathRoomSelected: this.state.bathRoomSelected,
      bedRoomSelected: this.state.bedRoomSelected,
      propertyType: this.state.propertyType,
      purpose: this.state.purpose,
      frequencySelected: this.state.frequencySelected,
      selectedLocations: this.state.selectedLocations,
      selectedFeatures: this.state.selectedFeatures,
    };
    this.fetchPropertyData(data);
    var purpose = this.state.purpose == 1 ? i18next.t("forrent").toLowerCase() : i18next.t("forsale").toLowerCase()
    // $("#propertyPurpose").find(":selected").text().toLowerCase();
    var bedroom = $("#bedroom").find(":selected").val() > 0 ? $("#bedroom").find(":selected").text() + " bedroom" : i18next.t("studiobed");
    if ($("#bedroom").find(":selected").val() == -1 || $("#bedroom").find(":selected").val() == undefined) { bedroom = '' }

    const urlPurpose = {
      purpose: this.state.purpose == 1 ? 'for-rent' : 'for-sale',
      propertyType: "/" + $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + "/" + this.state.propertyType + "/",
      subLocationSend: this.state.subLocationSend,
    };
    // //console.log("Url Purpose", urlPurpose)
    // this.propertyUrl(urlPurpose)
  }


  render() {
    const userdata = store.getState(state => state);
    const token = userdata.auth.token
    const isLoggedIn = userdata.auth.loggedIn

    //console.log("is logged in", isLoggedIn)
    //console.log("propertyPurposename", propertyPurposename)
    // $("#propertyPurpose").val(propPurposeFromUrl).change();

    //Get the url on document load and set property type
    $(function () {

      var url = window.location.href;
      var splitUrl = url.split("/")
      if (splitUrl.length > 4) {
        var bedPropertyType = splitUrl[4]
        var splitBedPropertyType = bedPropertyType.split("-")
        //  var urlPropId= $('option', this).attr(name);
        //slice to get the property name
        let propertyTypeName;
        if (splitBedPropertyType.length > 2) {
          propertyTypeName = splitBedPropertyType.slice(2);
          propertyTypeName = propertyTypeName.toString().replace(',', '-')
        }
        else {
          propertyTypeName = splitUrl[4]
        }

        //  var urlPropId= $('#propertyType').find("option[name="+propertyTypeName+"]").attr("value");

        //  apply parameters to select components
        //  $('#propertyPurpose option[value='+propPurposeFromUrl+']').prop("selected", true);

        // $('#propertyType option[value='+urlPropId+']').prop("selected", true);
        // $('#bedroom option[value='+splitBedPropertyType[0]+']').prop("selected", true);

      }
    });

    var propertytype = this.state.propertyTypeName
    //  propertytype = propertytype!==undefined?(propertytype.charAt(0).toUpperCase()+propertytype.slice(1)).replace("-", " "):null

    // : i18next.t("properties");
    var purpose = this.state.purpose == 1 ? i18next.t("forrent").toLowerCase() : this.state.purpose == 2 ? i18next.t("forsale").toLowerCase() : this.state.purpose == 3 ? i18next.t("forsharing").toLowerCase() : null

    // $("#propertyPurpose").find(":selected").text().toLowerCase();
    var bedroom = $("#bedroom").find(":selected").val() > 0 ? $("#bedroom").find(":selected").text() + " " + i18next.t("bedroom") : i18next.t("studiobed");
    if ($("#bedroom").find(":selected").val() == -1 || $("#bedroom").find(":selected").val() == undefined) { bedroom = '' }

    // const j = this.state.selectedLocations.length;


    // //console.log("selected location length", this.state.selectedLocations)
    var location = this.state.selectedLocations.length < 1 || this.state.selectedLocations == undefined ? i18next.t("uae") :
      this.state.selectedLocations.length == 1 ? this.state.locforUrl :
        this.state.selectedLocations.length == 2 ? this.state.selectedLocations[0].location + " " + i18next.t("all") + " " + this.state.selectedLocations[1].location :
          this.state.selectedLocations.length > 2 ?
            this.state.selectedLocations.map((locs, i) => i < this.state.selectedLocations.length - 1 ? locs.location + " , " : " and " + locs.location) : null;
    // location = location.charAt(0).toUpperCase()+location.slice(1).replace("-", " ")
    const locationFortitle = location.length > 0 ? location.replace("-", " ") : i18next.t("uae")
    const title = bedroom + " " + this.state.propertyType + " " + purpose + " " + i18next.t("in") + " " + decodeURI(this.state.locforUrl);
    const autoCompleteView =
    <Autocomplete
    multiple
    limitTags={3}
    id='multiple-limit-tags'
    options={this.state.locations}
    onChange={(event, newValue) => {
      this.setState({
        selectedLocations: newValue,
        subLocationSend: newValue.length > 0 ? newValue[0] : [],
        locforUrl: newValue.length > 0 ? newValue[0].location : [],
        propertyTypeName: $("#propertyType option:selected").text()
      });
      const data = {
        subLocationSend: newValue.length > 0 ? newValue[0] : [],
        bathRoomSelected: this.state.bathRoomSelected,
        bedRoomSelected: this.state.bedRoomSelected,
        propertyType: this.state.propertyType,
        purpose: this.state.purpose,
        frequencySelected: this.state.frequencySelected,
        selectedLocations: newValue,
        selectedFeatures: this.state.selectedFeatures,
      };
      this.fetchPropertyData(1,data);

      const urlPurpose = {
        purpose: $("#propertyPurpose").find(":selected").attr('name').toLowerCase().replace(/\s+/g, '-') + "/",
        propertyType: $("#propertyType").find(":selected").attr('name') == "properties" ? "properties/" : bedroom + $("#propertyType").find(":selected").attr('name') + "/",
        subLocationSend: newValue
      };
      this.propertyUrl(urlPurpose)
    }}
    getOptionLabel={(option) => option.location}
    value={this.state.selectedLocations}

    renderInput={(params) => (
      <TextField
        {...params}
        variant='outlined'
        label=''
        placeholder={i18next.t("location")
        }
      />
    )}
  />
    const purposeView =
      <select onChange={this.propertyPurposeChangleHandle} className='filter-input form-select mb-3' id='propertyPurpose'>
        {
            lang == "en" ?
            <>
              <option name={"for-rent"} selected={this.state?.purpose == 1 ? 1 : null} value='1'>
                {i18next.t("forrent")}
              </option>
              <option name={"for-sale"} selected={this.state?.purpose == 2 ? 1 : null} value='2'>
                {i18next.t("forsale")}
              </option>
              <option name={"for-sharing"} selected={this.state?.purpose == 3 ?  1 : null} value='3'>
                {i18next.t("Sharing")}
              </option>
            </>
            :
            <>
              <option name={"للايجار"} selected={this.state?.purpose == 1 ? 1 : null} value='1'>
                {i18next.t("forrent")}
              </option>
              <option name={"للبيع"} selected={this.state?.purpose == 2 ? 1 : null} value='2'>
                {i18next.t("forsale")}
              </option>
              <option name={"سكن-مشترك"} selected={this.state?.purpose == 3 ? 1 : null} value='3'>
                {i18next.t("سكن مشترك")}
              </option>
            </>


        }
        {/* <option name={lang=="en"?"for-rent": "للايجار"} selected={propertyPurposename ==  "for-rent" || propertyPurposename == 'للايجار' ? 1 : null} value='1'>
          {i18next.t("forrent")}
        </option>
        <option name={lang=="en"?"for-sale":"للبيع"} selected={propertyPurposename ==  "for-sale" || propertyPurposename == 'للبيع' ? 2 : null} value='2'>
          {i18next.t("forsale")}
        </option> */}
      </select>
    // alert(propertyTypeName)
    const propertyTypeView =
    <select
    id="propertyType" className='filter-input form-select mb-3' onChange={this.propertyTypeOnChangeHadnler}>
    {this.state.propertytypes.map((prop, i) => (

      lang == "en" ?
        <option
          name={prop.slug_en}
          typeSelected={prop.typeName_en}
          furnishedornot={prop.furnishedornot}
          selected={prop.slug_en == this.props.location.state["passType"] ? 'selected' : null}
          bedroomBath={prop.bedandbath}
          value={prop.id}>{prop.typeName_en}
        </option>
        :
        <option
          name={prop.slug_ar}
          typeSelected={prop.typeName_ar}
          furnishedornot={prop.furnishedornot}
          selected={prop.slug_ar == this.props.location.state["passType"] ? 'selected' : null}
          bedroomBath={prop.bedandbath}
          value={prop.id}>{prop.typeName_ar}
        </option>

    ))}
  </select>

    const Amenties =
      <input type="text" className="filter-input mb-3" placeholder="Key words" />
    {/* <MultiSelect
              options={this.state.features}
              value={this.state.selectedFeatures}
              onChange={this.selectedFeatureHandle}
              labelledBy='Amenties'
              defaultValue = {{ label: 'Amenties', value: 0 }}
            /> */}
    const priceRangeView =
      <div className='accordion-item'>
        <button
          style={{ border: "none", padding: "0" }}
          // className="accordion-button"
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#panelsStayOpen-collapseOne'
          aria-expanded='false'
          aria-controls='panelsStayOpen-collapseOne'
        >
          <div className='priceRangeValues'>
            <div
              className='col-price-range-input-wrap'
              style={{ marginLeft: "3px" }}
            >
              <div style={{ height: "1rem" }}>
                {" "}
                <span
                  style={{ width: "90%", textAlign: "left" }}
                  className='left'
                >
                  {i18next.t("pricerange")}
                </span>
              </div>
              <input
                disabled
                style={{ border: "none!important" }}
                className='col-price-range-input'
                value={this.props.location.state ? this.props.location.state["passPricefrom"] : this.state.priceFrom}
                type='text'
                placeholder='0'
              />
            </div>
            <span>to</span>
            <div className='col-price-range-input-wrap'>
              <input
                disabled
                style={{ border: "none!important", outline: "none" }}
                className='col-price-range-input'
                value={
                  this.props.location.state ? this.props.location.state["passPriceto"] : this.state.priceTo}

                // == 0 ? "Any" : this.state.priceTo
                // }
                type='text'
                placeholder='Any'
              />
            </div>
          </div>
        </button>
        <div
          id='panelsStayOpen-collapseOne'
          className='collapse'
          aria-labelledby='panelsStayOpen-headingOne'
        >
          <div
            style={{
              height: "200px",
              overflow: "hidden",
              textAlign: "center",
              paddingTop: "0px",
            }}
            className='accordion-body row'
          >
            <div className='col-md-6'>
              <label style={{ marginBottom: "0" }}>Min</label>
              <input
                type='number'
                onChange={this.priceFromOnChange}
                value={this.state.priceFrom}
                className={this.state.priceActive}
              />
            </div>
            <div className='col-md-6'>
              <label style={{ marginBottom: "0" }}>Max</label>
              <input
                type='number'
                onChange={this.priceToOnChange}
                value={this.state.priceTo}
                className={this.state.priceActive}
              />
            </div>
            <div className='col-md-6'>
              <ul
                style={{ height: "200px", overflow: "scroll" }}
                id='price-from'
                className='price-range-list'
              >
                {this.state.purpose == 1 ? (
                  <>
                    {priceRangeFromForRent.map((res, i) => (
                      <li
                        className={this.state.priceActive}
                        value={priceRangeFromForRent[i]}
                        onClick={this.priceFromHandler.bind(this, i)}
                      >
                        {priceRangeFromForRent[i]}
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                    {priceRangeFromForSale.map((res, i) => (
                      <li
                        value={priceRangeFromForSale[i]}
                        onClick={this.priceFromHandler.bind(this, i)}
                      >
                        {priceRangeFromForSale[i]}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
            <div className='col-md-6'>
              <ul
                style={{ height: "200px", overflow: "scroll" }}
                id='price-to'
                className='price-range-list'
              >
                {this.state.purpose == 1 ? (
                  <>
                    {priceRangeToForRent.map((res, i) => (
                      <li
                        value={priceRangeToForRent[i]}
                        onClick={this.priceToHandler.bind(this, i)}
                      >
                        {priceRangeToForRent[i]}
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                    {priceRangeToForSale.map((res, i) => (
                      <li
                        value={priceRangeToForSale[i]}
                        onClick={this.priceToHandler.bind(this, i)}
                      >
                        {priceRangeToForSale[i]}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className='row'>
            <div className='right'>
              {/* <button className="resetPriceBtn"> Reset </button> */}
              <button
                id='collapseOne' // className="accordion-button"
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#panelsStayOpen-collapseOne'
                aria-expanded='false'
                aria-controls='panelsStayOpen-collapseOne'
                className='closePriceBtn colapsePrice'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    const bedRoomView =
      <select
        className='filter-input form-select mb-3'
        onChange={this.bedroomOnChangeHadnler}
        name=''
        id='bedroom'>
        <option name="" value='-1' selected>
          {i18next.t("bedroom")}
        </option>
        <option name={i18next.t("studiobed") + "-"} value='0'>{i18next.t("studiobed")}</option>
        <option name={"1-" + i18next.t("bedroomUrl") + "-"} value='1'>1</option>
        <option name={"2-" + i18next.t("bedroomUrl") + "-"} value='2'>2</option>
        <option name={"3-" + i18next.t("bedroomUrl") + "-"} value='3'>3</option>
        <option name={"4-" + i18next.t("bedroomUrl") + "-"} value='4'>4</option>
        <option name={"5-" + i18next.t("bedroomUrl") + "-"} value='5'>5</option>
        <option name={"6-" + i18next.t("bedroomUrl") + "-"} value='6'>6</option>
        <option name={"7-" + i18next.t("bedroomUrl") + "-"} value='7'>7</option>
        <option name={"8-" + i18next.t("bedroomUrl") + "-"} value='8'>8</option>
        <option name={"9-" + i18next.t("bedroomUrl") + "-"} value='9'>9</option>
        <option name={"10-" + i18next.t("bedroomUrl") + "-"} value='10'>10</option>
        <option name={"11-" + i18next.t("bedroomUrl") + "-"} value='11'>11</option>
        <option name={"12" + i18next.t("bedroomUrl") + "-"} value='12'>12</option>
        <option name={"13-" + i18next.t("bedroomUrl") + "-"} value='13'>13</option>
        <option name={"14-" + i18next.t("bedroomUrl") + "-"} value='14'>14</option>
        <option name={"15-" + i18next.t("bedroomUrl") + "-"} value='15'>15</option>
        <option name={"16-" + i18next.t("bedroomUrl") + "-"} value='16'>16</option>
        <option name={"17-" + i18next.t("bedroomUrl") + "-"} value='17'>17</option>
        <option name={"18-" + i18next.t("bedroomUrl") + "-"} value='18'>18</option>
        <option name={"19-" + i18next.t("bedroomUrl") + "-"} value='19'>19</option>
        <option name={"20-" + i18next.t("bedroomUrl") + "-"} value='20'>20</option>
        <option name={"20-" + i18next.t("bedroomUrl") + "-"} value='20+'>20+</option>
      </select>
    const bathRoomView =
      <select
        className='filter-input form-select mb-3'
        onChange={this.bathroomOnChangeHadnler}
        name=''
        id='bathroom'>
        <option value='0' selected>
          {i18next.t("bathroom")}
        </option>
        <option value='1'>1</option>
        <option value='2'>2</option>
        <option value='3'>3</option>
        <option value='4'>4</option>
        <option value='5'>5</option>
        <option value='6'>6</option>
        <option value='7'>7</option>
        <option value='8'>8</option>
        <option value='9'>9</option>
        <option value='10'>10</option>
        <option value='11'>11</option>
        <option value='12'>12</option>
        <option value='13'>13</option>
        <option value='14'>14</option>
        <option value='15'>15</option>
        <option value='16'>16</option>
        <option value='17'>17</option>
        <option value='18'>18</option>
        <option value='19'>19</option>
        <option value='20'>20</option>
        <option value='20+'>20+</option>
      </select>

    const frequencyView =
      <select id="frequencyview"
        className='filter-input form-select mb-3'
        onChange={this.frequencyOnChangeHandler}
        name=''
      // id=''
      >
        <option selected value='0' id='frequency'>
          {i18next.t("per")}
        </option>
        <option value='4'>{i18next.t("daily")}</option>
        <option value='3'>{i18next.t("weekly")}</option>
        <option value='2'>{i18next.t("monthly")}</option>
        <option value='1'>{i18next.t("yearly")}</option>
      </select>

    return (
      <>
        <Helmet>
          <title>{this.state.pageContent ? lang == "en" ? this.state.pageContent.seotitle_en : this.state.pageContent.seotitle_ar : title}</title>
          {/* bedroom +" "+ propertytype+ " " + purpose+ " in "+ location; */}
          <meta name="description" content={
            lang == "en" ? "Find " + propertytype + " " + purpose + " in " + locationFortitle + " Search through a wide range of " + propertytype + " " + purpose + " in " + locationFortitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + propertytype + " " + purpose + " في " + locationFortitle + ". البحث علي مجموعة واسعة من " + propertytype + " " + purpose + " في " + locationFortitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta itemProp="name" content={
            lang == "en" ? "Find " + propertytype + " " + purpose + " in " + locationFortitle + " Search through a wide range of " + propertytype + " " + purpose + " in " + locationFortitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + propertytype + " " + purpose + " في " + locationFortitle + ". البحث علي مجموعة واسعة من " + propertytype + " " + purpose + " في " + locationFortitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta itemProp="description" content={
            lang == "en" ? "Find " + propertytype + " " + purpose + " in " + locationFortitle + " Search through a wide range of " + propertytype + " " + purpose + " in " + locationFortitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + propertytype + " " + purpose + " في " + locationFortitle + ". البحث علي مجموعة واسعة من " + propertytype + " " + purpose + " في " + locationFortitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta itemProp="image" content="../../../frontendUI/img/logo.png" />
          <meta property="og:url" content={lang == "en" ? "https://findproperties.ae" : "https://findproperties.ae/ar"} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this.state.pageContent ? lang == "en" ? this.state.pageContent.seotitle_en : this.state.pageContent.seotitle_ar : title} />
          <meta property="og:description" content={
            lang == "en" ? "Find " + propertytype + " " + purpose + " in " + locationFortitle + " Search through a wide range of " + propertytype + " " + purpose + " in " + locationFortitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + propertytype + " " + purpose + " في " + locationFortitle + ". البحث علي مجموعة واسعة من " + propertytype + " " + purpose + " في " + locationFortitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta property="og:image" content="../../../frontendUI/img/logo.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={this.state.pageContent ? lang == "en" ? this.state.pageContent.seotitle_en : this.state.pageContent.seotitle_ar : title} />
          <meta name="twitter:description" content={
            lang == "en" ? "Find " + propertytype + " " + purpose + " in " + locationFortitle + " Search through a wide range of " + propertytype + " " + purpose + " in " + locationFortitle + " and get agent contact details for sending enquiries." :
              "ابحث عن " + propertytype + " " + purpose + " في " + locationFortitle + ". البحث علي مجموعة واسعة من " + propertytype + " " + purpose + " في " + locationFortitle + " والحصول على تفاصيل الاتصال بالوكيل لإرسال الاستفسارات"
          } />
          <meta name="twitter:image" content="../../../frontendUI/img/logo.png" />
          {/* robot */}
          <meta name="robots" content="index, follow"></meta>

        </Helmet>
        <div style={{ maxHeight: '770px' }} className='section'>
          {/* <div className='container'> */}
          <div id="scrollStyle" className="">
            <div className='row d-none d-sm-block stickyTop'>
              <div className='row'>
                <div className='col-sm-3'>
                  {purposeView}
                </div>
                <div className='col-sm-6'>
                  {autoCompleteView}
                </div>
                <div className='col-sm-3'>
                  {propertyTypeView}
                </div>
              </div>
              <div className='row'
                style={{ height: "3.5rem", zIndex: '9', position: "relative" }}>
                <div className='col-sm-3'>
                  {Amenties}
                </div>
                <div className='col-sm-4 col-md-6 col-lg-4' id='accordionPanelsStayOpenExample'>
                  {priceRangeView}
                </div>
                {this.state.bedbathview == 1 ?
                  <div className='col-sm-2'>
                    {bedRoomView}
                  </div>
                  : null}
                {this.state.bedbathview == 1 ?
                  <div className='col-sm-2'>
                    {bathRoomView}
                  </div>
                  : null}
                <div className='col-lg-1 col-md-2'>
                  {$("#propertyPurpose").val() == 1 ? frequencyView : null}
                </div>
              </div>
            </div>
          </div>
          <div
            // id='filter-and-input'
            id="scrollStyleMobile"
            className='d-lg-none d-md-none flex'  >
            <div className="mobile-search-bar">
              <Autocomplete
                multiple
                limitTags={3}
                id='multiple-limit-tags'
                options={this.state.locations}
                onChange={(event, newValue) => {
                  this.setState({
                    selectedLocations: newValue,
                    subLocationSend: newValue.length > 0 ? newValue[0] : [],
                  });
                  // //console.log("Autocomplete on change", newValue[0]);
                  const data = {
                    subLocationSend: newValue.length > 0 ? newValue[0] : [],
                    bathRoomSelected: this.state.bathRoomSelected,
                    bedRoomSelected: this.state.bedRoomSelected,
                    propertyType: this.state.propertyType,
                    purpose: this.state.purpose,
                    frequencySelected: this.state.frequencySelected,
                    selectedLocations: newValue,
                    selectedFeatures: this.state.selectedFeatures,
                  };
                  this.fetchPropertyData(data);
                  //  const agentData= {selectedLanguage: this.selectedLanguage, selectedNationality: this.state.selectedNationality, selectedLocation: newValue, selectedArea: this.state.selectedArea}
                }}
                getOptionLabel={(option) => option.location}
                value={this.state.selectedLocations}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label=''
                    placeholder={i18next.t("location")
                    }
                  />
                )}
              />
            </div>

            <div className='mobile-filter-btn'>
              <button
                onClick={this.filterCanvas}
                id="filterCanavas"
                className='filter-canvas-btn'
                type='button'>
                <i className="fas fa-sliders-h"></i>
              </button>
              <aside className="main-aside filterWindowMob">
                <div className="aside-title">
                  <div className="aside-controls aside-trigger">
                    <h4>{i18next.t("filters")}</h4>
                    <div onClick={this.closeFilter} className="close-btn close-dark">
                      <span />
                      <span />
                    </div>
                  </div>
                </div>
                <div className="aside-scroll">
                  {purposeView}
                  {propertyTypeView}
                  {Amenties}
                  {priceRangeView}
                  {this.state.bedbathview == 1 ? bedRoomView : null}
                  {this.state.bedbathview == 1 ? bathRoomView : null}
                  {$("#propertyPurpose").val() == 1 ? frequencyView : null}
                  <a onClick={this.mobilefilter}
                    className="btn-custom secondary btn-block">Apply Filter</a>
                </div>
              </aside>
              <div className="aside-overlay aside-trigger" />
            </div>
          </div>
          {/* <div className='row' style={{ padding: "5px!important" }}>
              <div className='searched-text'>
                <h1 className='pages-title'>
                  {bedroom + " " + this.state.propertyType + " " + purpose + " " + i18next.t("in") + " "}
                  {this.state.selectedLocations.length < 1 ? decodeURI(this.state.locforUrl) :
                    this.state.selectedLocations.length == 1
                      ? this.state.locforUrl.replace("-", " ")
                      : this.state.selectedLocations.length == 2
                        ? this.state.selectedLocations[0].locations +
                        i18next.t("and") +
                        this.state.selectedLocations[1].location
                        : this.state.selectedLocations.length > 2
                          ? this.state.selectedLocations.map((locs, i) =>
                            i < this.state.selectedLocations.length - 1
                              ? locs.location + " , "
                              : i18next.t("and") + locs.location
                          )
                          : null} */}
          {/* {this.props.location.state["passLocation"].length < 1 ? i18next.t("uae") : 
                 this.props.location.state["passLocation"].length == 1
                   ? this.props.location.state["passLocation"][0].location
                   : this.props.location.state["passLocation"].length == 2
                   ? this.props.location.state["passLocation"][0].location +
                    i18next.t("and") +
                    this.props.location.state["passLocation"][1].location
                   : this.props.location.state["passLocation"].length > 2
                   ?this.props.location.state["passLocation"].map((locs, i) =>
                       i < this.state.selectedLocations.length - 1
                         ? locs.location + " , "
                         : i18next.t("and") + locs.location
                     )
                   : null}  */}
          {/* 
                </h1>
              </div>
            </div> */}
          {/* {this.state.subLocationArr.length > 0 ? (
              <div className=' wrapperlocation '>
                <div className='locationsOnsearch'>
                  {this.state.subLocationArr.map((subLocs) => (
                    <div className='eachLocations'>
                      <button
                        // style={{
                        //   color: "#519fff",
                        //   background: "transparent",
                        //   border: "none",
                        // }}
                        onClick={this.subLocationClickHandler.bind(this, subLocs)}>
                        {subLocs.location} <span className="propertyCountSubLoca">({subLocs.property_count})</span>
                      </button>
                    </div>
                  ))}
                </div>
                <div className='viewMoreLocationsWrap'>
                  <div className='viewMoreLocations'>
                    <span> {i18next.t("viewlocation")}</span>{" "}
                    <i className='fa fa-caret-down'></i>
                  </div>
                </div>
              </div>
            ) : null} */}
          <div className='row furnish-status-filter'>
            <div className='col '>
              <div className="row">
              {this.state.filteredProperties.length > 0?
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />:null}
                  
                {this.state.furnishedornot == 1 || this.state.propertyType == 0 ?
                  <>
                    <button
                      id='allFurnishedStatus'
                      onClick={this.furnishedUnfurnishedAll}
                      className='furnishedActive'>
                      {i18next.t("all")}
                    </button>
                    <button
                      id='furnishedOnly'
                      onClick={this.furnishedClickHandle}
                      className=''>
                      {i18next.t("furnished")}
                    </button>
                    <button
                      id='unfurnishedOnly'
                      onClick={this.unfurnishedClickHandle}
                      className=''>
                      {i18next.t("unfurnished")}
                    </button></>
                  : null}
              </div>
            </div>
            {this.state.isLoading?<div  id="overlaytwo"  >
                <div className=''>
                      <PropagateLoader
                      style={{marginTop:'100px'}}
             color={"#36D7B7"} loading={true}  size={20} />
                      </div>
                </div>:null}
            <div className='col '>
              
              <div direction="rtl" className='dropdown right'>
                
                <button
                  className=''
                  type='button'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'>
                  <i className='fa fa-sort-amount-down'></i>
                  &nbsp;{" "}
                  {this.state.popular == "" ? i18next.t("popular") : this.state.popular}
                  &nbsp;&nbsp;
                  <i className='fa fa-caret-down'></i>
                </button>
                
                <ul className='gridList d-none d-md-flex d-md-flex d-l-flex' style={{ marginBottom: 0 }}>
                  <li className='list-inline-item'>
                    <button
                      onClick={() => this.displayTypeHandler(this, 0)}
                      className='displayType active'>
                      <span className='icons-display-type ti-view-list'></span>
                    </button>
                  </li>
                  <li className='list-inline-item'>
                    <button
                      onClick={() => this.displayTypeHandler(this, 1)}
                      className='displayType'>
                      <span className='icons-display-type  ti-layout-grid2'></span>
                    </button>
                  </li>
                  {/* <li className="list-inline-item"><button onClick={()=>this.displayTypeHandler(this, 2)} className="displayType"><span className="icons-display-type ti-map-alt"></span></button></li> */}
                </ul>
                
                <ul
                
                  className='dropdown-menu popularDD'
                  aria-labelledby='dropdownMenuButton1'>
                  <li className="popular-list">
                    <button onClick={this.newestPropetyOnClick} className='popular-button'>
                      {i18next.t("newest")}
                    </button>
                  </li>
                  <li className="popular-list">
                    <button
                      onClick={this.lowestPriceFilteronClick}
                      className='popular-button'>
                      {i18next.t("lowest")}
                    </button>
                  </li>
                  <li className="popular-list">
                    <button onClick={this.highestPriceonClick}
                      className='popular-button'>
                      {i18next.t("highest")}
                    </button>
                  </li>
                </ul>
                
              </div>

            </div>
            {/* <div className='col d-none d-md-flex d-md-flex d-l-flex right'>
            </div> */}
          </div>
          <div className='row'>
            {/* Sidebar Start */}
            <div className='col-lg-6 d-sm-12 d-md-block map-height'>
              <div id="map"></div>
            </div>
            <div className='col-md-12 col-lg-6 col-sm-12 col-xs-12 row wrapper-listing'>
              {/* Controls Start */}
         
              <div className='acr-global-listing-controls d-sm-none d-md-none'>
                <div className='acr-toggle-views '>
                  <a
                    href='javascript:void(0)'
                    onClick={this.HandlePropertyStyle.bind(this, 0)}
                    className={this.state.propertyStyle == 0 ? "active" : ""}
                    data-toggle='tooltip'
                    title='List'>
                    <i className='fas fa-th-list' />
                  </a>
                  <a
                    href='javascript:void(0)'
                    onClick={this.HandlePropertyStyle.bind(this, 1)}
                    className={this.state.propertyStyle == 1 ? "active" : ""}
                    data-toggle='tooltip'
                    title='Grid' >
                    <i className='fas fa-th-large' />
                  </a>
                  {/* <button href="javascript:void(0)" className="float-end" data-toggle="tooltip" title="Grid"><i className="fas fa-th-large"></i></button> */}
                  <button
                    className=''
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseTwo'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseTwo'
                  ></button>
                </div>
                <div className='acr-toggle-views  inline'></div>
              </div>
              {/* </div> */}
              <div style={{ height: 1000, overflow: 'auto' }}>
                <div className="similar-group">
                  {/* {this.state.filteredProperties.data.length > this.state.perPage ? */}
                  <>
                      {this.state.pageCount > 0 ? (
                        <>
                          {this.state.postData}
                        </>
                      ) : (<></>
                     
                      )}
                    </>
           
                </div>
              </div>
              <>
                {this.state.isLoading ? (
                  <>
                    <div className='lds-ripple center'>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.pageCount > 0 ? (
                      <>
                      </>
                    ) : (<></>
                      // <div className='noResult center'>
                      //   <div className='no-result-icon'>
                      //     <i className='fa fa-ban' aria-hidden='true'></i>
                      //   </div>
                      //   <div className='no-result-header'>
                      //      {i18next.t("noresultsfound")} 
                      //   </div>
                      //   <p>{i18next.t("sorrypleasechecknearby")} </p>
                      // </div>
                    )}
                  </>
                )}

                <div
                  style={{ margin: "0 auto", overflow: "hidden" }}
                  className='modal fade center'
                  id='callModal'
                  tabindex='-1'
                  aria-labelledby='callModalLabel'
                  aria-hidden='true'>
                  <div
                    style={{ width: "22rem", margin: "0 auto" }}
                    className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                          Contact Us
                        </h5>
                        <button
                          type='button'
                          className='btn-close'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        ></button>
                      </div>
                      <div
                        className='modal-body'
                        style={{ width: "96", margin: "auto auto" }}>
                        <div className='block'>
                          <div
                            className='row center'
                            style={{
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                          </div>
                          <div className='row '>
                            <div className='col border'>Mobile</div>
                            <div className='col border'>
                              <a href={"tel:" + this.state.phone}>
                                {this.state.phone}
                              </a>
                            </div>
                          </div>
                          <div
                            className='row center'
                            style={{ marginTop: "20px", textAlign: "center" }}>
                            Please mention that you have seen this Ad on
                            findproperties.com
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>

            {/* Sidebar End */}
            {/* Posts Start */}

            <div className="bottom-content">
              {/* <ResponsiveDisplayAd classApply="desktop_portrait_side_3" /> */}
              {/* {width <= 992?
                   <MobileAd />:null
                   } */}
              <h2>{this.state.pageContent ? lang == "en" ? this.state.pageContent.title_en : this.state.pageContent.title_ar : null}</h2>
              <div className="bottom-content-body">
                {this.state.pageContent ? ReactHtmlParser(lang == "en" ? this.state.pageContent.description_en : this.state.pageContent.description_ar) : null}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </>
    );
  }
}

  // const mapStateToProps = state =>{
  //   return {
  //      loggedIn:state.auth.loggedIn,
  //      user:state.auth.user
  //   }
  // }

