import React from 'react'
import {Route,Redirect} from 'react-router-dom'
import { connect,useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import {store} from '../../store'
import cookie from 'js-cookie'


const userdata = store.getState(state=> state);
const mylogged= userdata.auth.loggedIn

// const role= userdata.auth.loggedIn

 const PrivateRoute = ({ component: Component, ...rest }) => {
 const  token = useSelector(state => state.auth)


//  console.log("response ", cookie.get("u"))

  // console.log("login token", token)
 
  return (
      <Route {...rest} render={(props) => (
          (token.loggedIn)
              ? <Component {...props} />
              : <Redirect to='/' />
      )} />
  )
}
  // const mapStateToProps = state =>{
  //   return {
  //      loggedIn:state.auth.loggedIn,
  //      user:state.auth.user
  //   }
  // }
  export default PrivateRoute
 // export default connect(mapStateToProps)(PrivateRoute)