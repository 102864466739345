import React, { Component } from 'react'

class TermsAndConditions extends Component {
    render() {
        return (
            <section className="pt-4">
                <div className="container">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="" role="presentation">
                                <button class="btn btn-outline-primary buttonSwitchactive " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">User</button>
                            </li>
                            <li class="" role="presentation">
                                <button class="btn btn-outline-primary buttonSwitch" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Agent/Agency</button>
                            </li>

                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">...</div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
                        </div>
                 </div>
            </section>
        )
    }
}

export default TermsAndConditions
